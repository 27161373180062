<template>
  <div id="sidenav-collapse-main" class="w-auto h-auto collapse navbar-collapse max-height-vh-100 h-100">
    <ul class="navbar-nav">

      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <ul class="nav pe-0">
          <sidenav-item v-for="(main, setMain) in dashboard" :key="setMain" :to="{ name: main.path }"
            :mini-icon="main.icon" :text="main.name" />
        </ul>
      </li>

      <hr class="horizontal light mt-2 mb-2" />

      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse collapse-ref="settingExamples" :nav-text="t('apps.admin')"
          :class="getRoute() === 'settings' ? 'active' : ''">
          <template #icon>
            <i class="material-icons-round opacity-10">admin_panel_settings</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <sidenav-item v-for="(menu3, setMenu) in setting" :key="setMenu" :to="{ name: menu3.path }"
                :mini-icon="menu3.icon" :text="menu3.name" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <hr class="horizontal light mt-2 mb-2" />

      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse collapse-ref="masterExamples" :nav-text="t('apps.master')"
          :class="getRoute() === 'master' ? 'active' : ''">
          <template #icon>
            <i class="material-icons-round opacity-10">view_list</i>
          </template>
          <template #list>
            <ul class="nav pe-0">
              <sidenav-item v-for="(menu2, setMaster) in master" :key="setMaster" :to="{ name: menu2.path }"
                :mini-icon="menu2.icon" :text="menu2.name" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>

      <hr class="horizontal light mt-2 mb-2" />

      <li class="nav-item" :class="getRoute() === 'dashboards' ? 'active' : ''">
        <sidenav-collapse collapse-ref="userAccount" :nav-text="data.user" :nav-sub-text="data.role"
          :class="getRoute() === 'userAccount' ? 'active' : ''">
          <template #icon>
            <img src="../../assets/img/team-2.jpg" class="avatar" />
          </template>
          <template #list>
            <ul class="nav pe-0">
              <sidenav-item v-for="(accounts, setAccount) in account" :key="setAccount" :to="{ name: accounts.path }"
                :mini-icon="accounts.icon" :text="accounts.name" />
            </ul>
          </template>
        </sidenav-collapse>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { reactive, computed } from 'vue'
import { useRoute } from 'vue-router'
import { useI18n } from "vue-i18n"
import SidenavItem from "./SidenavItem.vue"
import SidenavCollapse from "./SidenavCollapse.vue"

const { t } = useI18n();

const route = useRoute();

const data = reactive(
  {
    user: "Name Lastname",
    role: "Platform Admin"
  }
)

const account = reactive(
  [
    // {
    //   path: "Profile Overview",
    //   icon: "face",
    //   name: computed(() => t('apps.acprofile'))
    // },
    {
      path: "Settings",
      icon: "settings",
      name: computed(() => t('apps.acsetting'))
    },
    {
      path: "Signin Basic",
      icon: "logout",
      name: computed(() => t('apps.aclogout'))
    }
  ]
)

const dashboard = reactive(
  [
    {
      path: "Dashboard",
      icon: "dashboard",
      name: computed(() => t('apps.gdashboard'))
    },
  ]
)

// const general = reactive(
//   [
//     {
//       path: "Home",
//       icon: "chevron_right",
//       name: computed(() => t('apps.ghome'))
//     },
//     {
//       path: "Ticket",
//       icon: "chevron_right",
//       name: computed(() => t('apps.gticket'))
//     },
//     {
//       path: "Finance",
//       icon: "chevron_right",
//       name: computed(() => t('apps.gfinance'))
//     },
//     {
//       path: "SplitAssets",
//       icon: "chevron_right",
//       name: computed(() => t('apps.gsplitasset'))
//     },
//     {
//       path: "History",
//       icon: "chevron_right",
//       name: computed(() => t('apps.ghistory'))
//     },
//     {
//       path: "TicketChain",
//       icon: "chevron_right",
//       name: computed(() => t('apps.gticketchain'))
//     },
//     {
//       path: "CashMovement",
//       icon: "chevron_right",
//       name: computed(() => t('apps.gcashmove'))
//     },
//     {
//       path: "TranctionsManagement",
//       icon: "chevron_right",
//       name: computed(() => t('apps.gtrans'))
//     },
//     {
//       path: "CustomerDeposit",
//       icon: "chevron_right",
//       name: computed(() => t('apps.gdeposit'))
//     },
//     {
//       path: "CustomerInfo",
//       icon: "chevron_right",
//       name: computed(() => t('apps.gcustomer'))
//     },
//     {
//       path: "CustomerPort",
//       icon: "chevron_right",
//       name: computed(() => t('apps.gport'))
//     },
//     {
//       path: "Report",
//       icon: "chevron_right",
//       name: computed(() => t('apps.greport'))
//     },
//     {
//       path: "UnitOverdue",
//       icon: "chevron_right",
//       name: computed(() => t('apps.gunitover'))
//     },
//     {
//       path: "DeferItems",
//       icon: "chevron_right",
//       name: computed(() => t('apps.gdeferitems'))
//     }
//   ]
// )

const master = reactive(
  [

    {
      path: "Warning",
      icon: "chevron_right",
      name: computed(() => t('apps.mwarning'))
    },
    {
      path: "IllegalAssets",
      icon: "chevron_right",
      name: computed(() => t('apps.millegal'))
    },
    // {
    //   path: "AssetGroup",
    //   icon: "chevron_right",
    //   name: computed(() => t('apps.massetgroup'))
    // },
    {
      path: "AssetType",
      icon: "chevron_right",
      name: computed(() => t('apps.mtypeassets'))
    },
    {
      path: "Bank",
      icon: "chevron_right",
      name: computed(() => t('apps.mbank'))
    },
    {
      path: "Address",
      icon: "chevron_right",
      name: computed(() => t('apps.maddress'))
    },
    {
      path: "DocumentType",
      icon: "chevron_right",
      name: computed(() => t('apps.mtypedocument'))
    },
    {
      path: "BankNote",
      icon: "chevron_right",
      name: computed(() => t('apps.mbanknote'))
    },
    {
      path: "Units",
      icon: "chevron_right",
      name: computed(() => t('apps.mtypeunit'))
    },
  ]
)

const setting = reactive(
  [
    {
      path: "SetUser",
      icon: "chevron_right",
      name: computed(() => t('apps.suser'))
    },
    {
      path: "OpenShop",
      icon: "chevron_right",
      name: computed(() => t('apps.sopen'))
    },
    // {
    //   path: "SetBranch",
    //   icon: "chevron_right",
    //   name: computed(() => t('apps.ssetbranch'))
    // },
    // {
    //   path: "PrePrint",
    //   icon: "chevron_right",
    //   name: computed(() => t('apps.spre'))
    // },
    // {
    //   path: "Interest",
    //   icon: "chevron_right",
    //   name: computed(() => t('apps.sinterest'))
    // },
    // {
    //   path: "Stock",
    //   icon: "chevron_right",
    //   name: computed(() => t('apps.sstock'))
    // },
    // {
    //   path: "GoldPrice",
    //   icon: "chevron_right",
    //   name: computed(() => t('apps.sgold'))
    // },
    // {
    //   path: "SecurityRole",
    //   icon: "chevron_right",
    //   name: computed(() => t('apps.srole'))
    // },
    // {
    //   path: "SecuritySetup",
    //   icon: "chevron_right",
    //   name: computed(() => t('apps.ssetup'))
    // },

  ]
)

function getRoute() {
  const routeArr = route.path.split("/");
  return routeArr[1];
}

</script>
