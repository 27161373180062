<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12 ">

        <ul id="customerPortTab" class="nav nav-tabs" role="tablist">

          <li class="nav-item" role="presentation">
            <button id="first-customerPort" class="nav-link active" data-bs-toggle="tab"
              data-bs-target="#firstCustomerPort" type="button" role="tab" aria-controls="firstCustomerPort"
              aria-selected="true">
              {{ $t('content.gport') }}
            </button>
          </li>

          <li class="nav-item" role="presentation">
            <button id="second-customerPort" class="nav-link" data-bs-toggle="tab" data-bs-target="#secondCustomerPort"
              type="button" role="tab" aria-controls="secondCustomerPort" aria-selected="false">
              {{ $t('content.grelation') }}
            </button>
          </li>
        </ul>

        <div id="customerPortTabContent" class="tab-content">

          <div id="firstCustomerPort" class="tab-pane fade show active" role="tabpanel"
            aria-labelledby="first-customerPort">

            <div class="row">
              <div class="col-12">
                <div class="card mt-2">
                  <div class="card-header mb-0">
                    <h5>{{ $t('content.allassets') }} (10)</h5>
                  </div>
                  <div class="card-body pt-0">
                    <div class="row">
                      <div class="col-lg-4">
                        <label class="form-label ms-0">{{ $t('content.names') }}</label>
                        <div class="input-group">
                          <input v-model="datas.searchBox1" type="text" class="form-control"
                            aria-describedby="searchName1" readonly>
                          <button id="searchName1" class="btn btn-primary" type="button" data-bs-toggle="modal"
                            data-bs-target="#searchNameModal">
                            <i class="material-icons material-symbols-outlined">search</i>
                          </button>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <label class="form-label ms-0 png"></label>
                        <div class="d-flex align-items-center pt-2">
                          <h6 class="mb-0">{{ $t('content.statusticket') }}</h6>
                          <material-radio id="hold1" v-model="selectHold" name="selHold" class="radio-horizon" value="0">
                            {{ $t('content.optall') }} (10)
                          </material-radio>
                          <material-radio id="hold2" v-model="selectHold" name="selHold" class="radio-horizon" value="1">
                            {{ $t('content.holding') }} (4)
                          </material-radio>
                          <material-radio id="hold3" v-model="selectHold" name="selHold" class="radio-horizon" value="2">
                            {{ $t('content.nothold') }} (6)
                          </material-radio>
                        </div>
                      </div>

                      <div class="col-lg-2">
                        <label class="form-label ms-0 png"></label>
                        <material-button color="primary" class="float-lg-start float-sm-end mb-3">
                          <i class="material-icons material-symbols-outlined">search</i> {{ btnSearch }}
                        </material-button>
                      </div>
                    </div>
                    <hr>
                    <div class="row">
                      <div class="table-responsive">
                        <table class="table table-flush">
                          <thead class="thead-light">
                            <tr>
                              <th>#</th>
                              <th>{{ $t('content.massetgroup') }}</th>
                              <th>{{ $t('content.totunit') }}</th>
                              <th width="200px">{{ $t('content.totvalue') }}</th>
                              <th width="80px"></th>
                            </tr>
                          </thead>
                          <tbody v-for="(i, ps1) in samples2" :key="ps1" :data-group-index="ps1">
                            <tr class="group-id-ticket"
                              :class="i.st == t('content.holding') ? 'bg-success' : 'bg-pledge'">
                              <td class="text-sm font-weight-normal" colspan="2">
                                <div class="group-tickect">
                                  <div class="d-flex align-items-between">
                                    <h6 class="mb-0" :class="i.st == t('content.holding') ? 'text-white' : 'text-black'">
                                      <b>{{ $t('content.ticketno') }}: #<u>{{ i.tk + ps1 }}</u></b>
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td class="text-sm font-weight-normal">
                                <div class="group-tickect">
                                  <div class="d-flex align-items-between">
                                    <h6 class="mb-0" :class="i.st == t('content.holding') ? 'text-white' : 'text-black'">
                                      <b>{{ $t('content.statusticket') }}: {{ i.st }}</b>
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td class="text-sm font-weight-normal">
                                <div class="group-tickect">
                                  <div class="d-flex align-items-between">
                                    <h6 class="mb-0" :class="i.st == t('content.holding') ? 'text-white' : 'text-black'">
                                      <b>{{ $t('content.totvalue') }}: {{ i.vl }}</b>
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td class="text-sm font-weight-normal">
                                <material-button color="white" class="float-lg-start float-sm-end mb-0"
                                  @click="toggleRow(ps1)">
                                  <i v-if="i.tg" class="material-icons material-symbols-outlined">expand_less</i>
                                  <i v-else class="material-icons material-symbols-outlined">expand_more</i>
                                </material-button>
                              </td>
                            </tr>
                            <template v-if="i.tg">
                              <tr v-for="(j, ps2) in i.rw" :key="ps2">
                                <td class="text-sm font-weight-normal">{{ ps2 + 1 }}</td>
                                <td class="text-sm font-weight-normal">{{ j.ty }}</td>
                                <td class="text-sm font-weight-normal">{{ j.qt }}</td>
                                <td class="text-sm font-weight-normal">{{ j.vl }}</td>
                                <td class="text-sm font-weight-normal"></td>
                              </tr>
                            </template>
                          </tbody>

                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <div class="card mt-4">
                  <div class="card-body">
                    <div class="row no-gutters">
                      <div class="col-12 d-flex align-items-center">
                        <h5 class="m-0">{{ $t('content.amncreditget') }} ( {{ $t('content.curency') }} )</h5>
                        <div class="ms-auto">
                          <h5 class="m-0">88,888,888.88 </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <div class="card mt-4">
                  <div class="card-body">
                    <div class="row no-gutters">
                      <div class="col-12 d-flex align-items-center">
                        <h5 class="m-0">{{ $t('content.amncredituse') }} ( {{ $t('content.curency') }} )</h5>
                        <div class="ms-auto">
                          <h5 class="m-0">88,888,888.88 </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <div class="card mt-4">
                  <div class="card-body">
                    <div class="row no-gutters">
                      <div class="col-12 d-flex align-items-center">
                        <h5 class="m-0">{{ $t('content.amncreditremain') }} ( {{ $t('content.curency') }} )</h5>
                        <div class="ms-auto">
                          <h5 class="m-0">88,888,888.88 </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <div class="card mt-4">
                  <div class="card-body">
                    <div class="row no-gutters">
                      <div class="col-12 d-flex align-items-center">
                        <h5 class="m-0">{{ $t('content.totmaxval') }} ( {{ $t('content.curency') }} )</h5>
                        <div class="ms-auto">
                          <h5 class="m-0">88,888,888.88 </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <div class="card mt-4">
                  <div class="card-body">
                    <div class="row no-gutters">
                      <div class="col-12 d-flex align-items-center">
                        <h5 class="m-0">{{ $t('content.totlimit') }} ( {{ $t('content.curency') }} )</h5>
                        <div class="ms-auto">
                          <h5 class="m-0">88,888,888.88 </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <div class="card mt-4">
                  <div class="card-body">
                    <div class="row no-gutters">
                      <div class="col-12 d-flex align-items-center">
                        <h5 class="m-0">{{ $t('content.totdeposit') }} ( {{ $t('content.curency') }} )</h5>
                        <div class="ms-auto">
                          <h5 class="m-0">88,888,888.88 </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="card mt-4">
                  <div class="card-header">
                    <h5 class="m-0">{{ $t('content.personhis') }}</h5>
                  </div>
                  <div class="card-body pt-0">
                    <div class="row">
                      <div class="table-responsive">
                        <table class="table table-flush">
                          <thead class="thead-light">
                            <tr>
                              <th>#</th>
                              <th>{{ $t('content.dant') }}</th>
                              <th>{{ $t('content.trans') }}</th>
                              <th>{{ $t('content.ticketno') }}</th>
                              <th>{{ $t('content.massetgroup') }}</th>
                              <th>{{ $t('content.allprice') }}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(k, ps3) in 9" :key="ps3">
                              <td class="text-sm font-weight-normal">{{ ps3 + 1 }}</td>
                              <td class="text-sm font-weight-normal">xx/xx/xxxx xx:x{{ ps3 + 1 }}</td>
                              <td class="text-sm font-weight-normal">aaaaaaa{{ ps3 + 1 }}</td>
                              <td class="text-sm font-weight-normal">#000{{ ps3 + 1 }}</td>
                              <td class="text-sm font-weight-normal">bbbbbbb{{ ps3 + 1 }}</td>
                              <td class="text-sm font-weight-normal">88,888,888.8{{ ps3 + 1 }}</td>
                            </tr>
                          </tbody>

                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div id="secondCustomerPort" class="tab-pane fade" role="tabpanel" aria-labelledby="second-customerPort">

            <div class="row">
              <div class="col-12">
                <div class="card mt-2">
                  <div class="card-header">
                    <h5>{{ $t('content.cabove') }}</h5>
                  </div>
                  <div class="card-body pt-0">
                    <div class="row">
                      <div class="col-12">
                        <select class="form-select">
                          <option :value="$t('content.select')">{{ $t('content.select') }}</option>
                          <option v-for="(i, fs1) in 9" :key="fs1" :value="i">Mr.{{ fs1 + 1 }} xxxx{{ fs1 + 1 }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="card mt-4">
                  <div class="card-header mb-0">
                    <h5>{{ $t('content.allassets') }} (10)</h5>
                  </div>
                  <div class="card-body pt-0">
                    <div class="row">
                      <div class="col-12">
                        <div class="d-flex align-items-center">
                          <h6 class="mb-0">{{ $t('content.statusticket') }}</h6>
                          <material-radio id="hold1Above" v-model="selectAbove" name="selectAbove" class="radio-horizon"
                            value="0">
                            {{ $t('content.optall') }} (10)
                          </material-radio>
                          <material-radio id="hold2Above" v-model="selectAbove" name="selectAbove" class="radio-horizon"
                            value="1">
                            {{ $t('content.holding') }} (4)
                          </material-radio>
                          <material-radio id="hold3Above" v-model="selectAbove" name="selectAbove" class="radio-horizon"
                            value="2">
                            {{ $t('content.nothold') }} (6)
                          </material-radio>
                        </div>
                      </div>
                    </div>
                    <hr>
                    <div class="row">
                      <div class="table-responsive">
                        <table class="table table-flush">
                          <thead class="thead-light">
                            <tr>
                              <th>#</th>
                              <th>{{ $t('content.massetgroup') }}</th>
                              <th>{{ $t('content.totunit') }}</th>
                              <th width="200px">{{ $t('content.totvalue') }}</th>
                              <th width="80px"></th>
                            </tr>
                          </thead>
                          <tbody v-for="(i, ps3) in samples2" :key="ps3" :data-group-index="ps1">
                            <tr class="group-id-ticket"
                              :class="i.st == t('content.holding') ? 'bg-success' : 'bg-pledge'">
                              <td class="text-sm font-weight-normal" colspan="2">
                                <div class="group-tickect">
                                  <div class="d-flex align-items-between">
                                    <h6 class="mb-0" :class="i.st == t('content.holding') ? 'text-white' : 'text-black'">
                                      <b>{{ $t('content.ticketno') }}: #<u>{{ i.tk + ps3 }}</u></b>
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td class="text-sm font-weight-normal">
                                <div class="group-tickect">
                                  <div class="d-flex align-items-between">
                                    <h6 class="mb-0" :class="i.st == t('content.holding') ? 'text-white' : 'text-black'">
                                      <b>{{ $t('content.statusticket') }}: {{ i.st }}</b>
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td class="text-sm font-weight-normal">
                                <div class="group-tickect">
                                  <div class="d-flex align-items-between">
                                    <h6 class="mb-0" :class="i.st == t('content.holding') ? 'text-white' : 'text-black'">
                                      <b>{{ $t('content.totvalue') }}: {{ i.vl }}</b>
                                    </h6>
                                  </div>
                                </div>
                              </td>
                              <td class="text-sm font-weight-normal">
                                <material-button color="white" class="float-lg-start float-sm-end mb-0"
                                  @click="toggleRow(ps3)">
                                  <i v-if="i.tg" class="material-icons material-symbols-outlined">expand_less</i>
                                  <i v-else class="material-icons material-symbols-outlined">expand_more</i>
                                </material-button>
                              </td>
                            </tr>
                            <template v-if="i.tg">
                              <tr v-for="(j, ps4) in i.rw" :key="ps4">
                                <td class="text-sm font-weight-normal">{{ ps4 + 1 }}</td>
                                <td class="text-sm font-weight-normal">{{ j.ty }}</td>
                                <td class="text-sm font-weight-normal">{{ j.qt }}</td>
                                <td class="text-sm font-weight-normal">{{ j.vl }}</td>
                                <td class="text-sm font-weight-normal"></td>
                              </tr>
                            </template>
                          </tbody>

                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <div class="card mt-4">
                  <div class="card-body">
                    <div class="row no-gutters">
                      <div class="col-12 d-flex align-items-center">
                        <h5 class="m-0">{{ $t('content.amncreditget') }} ( {{ $t('content.curency') }} )</h5>
                        <div class="ms-auto">
                          <h5 class="m-0">88,888,888.88 </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <div class="card mt-4">
                  <div class="card-body">
                    <div class="row no-gutters">
                      <div class="col-12 d-flex align-items-center">
                        <h5 class="m-0">{{ $t('content.amncredituse') }} ( {{ $t('content.curency') }} )</h5>
                        <div class="ms-auto">
                          <h5 class="m-0">88,888,888.88 </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <div class="card mt-4">
                  <div class="card-body">
                    <div class="row no-gutters">
                      <div class="col-12 d-flex align-items-center">
                        <h5 class="m-0">{{ $t('content.amncreditremain') }} ( {{ $t('content.curency') }} )</h5>
                        <div class="ms-auto">
                          <h5 class="m-0">88,888,888.88 </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <div class="card mt-4">
                  <div class="card-body">
                    <div class="row no-gutters">
                      <div class="col-12 d-flex align-items-center">
                        <h5 class="m-0">{{ $t('content.totmaxval') }} ( {{ $t('content.curency') }} )</h5>
                        <div class="ms-auto">
                          <h5 class="m-0">88,888,888.88 </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <div class="card mt-4">
                  <div class="card-body">
                    <div class="row no-gutters">
                      <div class="col-12 d-flex align-items-center">
                        <h5 class="m-0">{{ $t('content.totlimit') }} ( {{ $t('content.curency') }} )</h5>
                        <div class="ms-auto">
                          <h5 class="m-0">88,888,888.88 </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4">
                <div class="card mt-4">
                  <div class="card-body">
                    <div class="row no-gutters">
                      <div class="col-12 d-flex align-items-center">
                        <h5 class="m-0">{{ $t('content.totdeposit') }} ( {{ $t('content.curency') }} )</h5>
                        <div class="ms-auto">
                          <h5 class="m-0">88,888,888.88 </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <div class="card mt-4">
                  <div class="card-header">
                    <h5 class="m-0">{{ $t('content.personhis') }}</h5>
                  </div>
                  <div class="card-body pt-0">
                    <div class="row">
                      <div class="table-responsive">
                        <table class="table table-flush">
                          <thead class="thead-light">
                            <tr>
                              <th>#</th>
                              <th>{{ $t('content.dant') }}</th>
                              <th>{{ $t('content.trans') }}</th>
                              <th>{{ $t('content.ticketno') }}</th>
                              <th>{{ $t('content.massetgroup') }}</th>
                              <th>{{ $t('content.allprice') }}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(k, ps3) in 9" :key="ps3">
                              <td class="text-sm font-weight-normal">{{ ps3 + 1 }}</td>
                              <td class="text-sm font-weight-normal">xx/xx/xxxx xx:x{{ ps3 + 1 }}</td>
                              <td class="text-sm font-weight-normal">aaaaaaa{{ ps3 + 1 }}</td>
                              <td class="text-sm font-weight-normal">#000{{ ps3 + 1 }}</td>
                              <td class="text-sm font-weight-normal">bbbbbbb{{ ps3 + 1 }}</td>
                              <td class="text-sm font-weight-normal">88,888,888.8{{ ps3 + 1 }}</td>
                            </tr>
                          </tbody>

                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

        <!-- Search Customer Dialog -->
        <div id="searchNameModal" class="modal fade" tabindex="-1" aria-labelledby="searchNameModalLabel"
          aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">

              <div class="modal-header">
                <h4 class="modal-title">{{ $t('content.cnamelist') }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <div class="preview-table">
                  <section class="panel">
                    <table class="table table-striped table-responsive">
                      <thead>
                        <tr>
                          <th style="width: 100px;">{{ $t('content.ccode') }}</th>
                          <th style="width: 180px;">{{ $t('content.cnameth') }}</th>
                          <th style="width: 180px;">{{ $t('content.cnameen') }}</th>
                          <th style="width: 160px;">{{ $t('content.cidcard') }}</th>
                          <th style="width: 110px;">{{ $t('content.cmobile') }}</th>
                          <th style="width: 120px;">{{ $t('content.cgrade') }}</th>
                          <!-- <th></th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="row-search">
                          <td>
                            <material-input type="text" :label="t('content.ccode')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cnameth')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cnameen')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cidcard')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cmobile')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cgrade')" />
                          </td>
                          <!-- <td>
                                <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                                  data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch">
                                  <i class="material-icons material-symbols-outlined">search</i>
                                </material-button>
                              </td> -->
                        </tr>
                        <tr v-for="(i, cs) in datas.cname" :key="cs" data-bs-dismiss="modal">
                          <td>{{ i.cd }}</td>
                          <td>{{ i.th }}</td>
                          <td>{{ i.en }}</td>
                          <td>{{ i.id }}</td>
                          <td>{{ i.mb }}</td>
                          <td>{{ i.gd }}</td>
                          <!-- <td></td> -->
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </div>
              </div>

              <div class="modal-footer">
                <!-- <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button> -->
                <nav aria-label="Table Paginator">
                  <ul class="pagination justify-content-end">
                    <li class="page-item disabled">
                      <a class="page-link" href="#" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item">
                      <a class="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
    
<script setup>
import { reactive, ref, computed } from 'vue'
import { useI18n } from "vue-i18n"

import MaterialButton from "@/components/MaterialButton.vue"
// import MaterialInput from "@/components/MaterialInput.vue"
// import MaterialCheckbox from "@/components/MaterialCheckbox.vue"
import MaterialRadio from "@/components/MaterialRadio.vue"

// import Signature from "@/assets/img/pawn/signature_test.png"
// import FingerPrint from "@/assets/img/pawn/fingerprint_test.jpg"
// import Avatar from "@/assets/img/ivana-squares.jpg"

const { t } = useI18n()
// const swal = inject('$swal')

const selectHold = ref("0")
const selectAbove = ref("0")

const datas = reactive({
  addNew: false,
  rowEdit: false,
  coreCopy: true,
  searchBox1: "",
  searchBox2: "",
  searchBox3: "",
  demo1: [
    { items: "ปกติ" },
    { items: "ล็อค" },
  ],
  demo2: [
    { items: "ปกติ" },
    { items: "เกรด 1" },
    { items: "เกรด 2" },
    { items: "เกรด 3" },
    { items: "เกรด 4" },
  ],
  demo3: [
    { items: "บัตรประชาชน" },
    { items: "ใบอนุญาตทำงาน" },
    { items: "บัตรต่างด้าว" },
    { items: "หนังสือเดินทาง" },
    { items: "ใบขับขี่" },
  ],
  demo4: [
    { items: "นาย" },
    { items: "นาง" },
    { items: "นางสาว" },
  ],
  demo5: [
    { items: "พุทธ" },
    { items: "คริสต์" },
    { items: "อิสลาม" },
    { items: "ฮินดู" },
    { items: "ซิกต์" },
    { items: "ไม่มี" },
  ],
  demo6: [
    { items: "A" },
    { items: "B" },
    { items: "O" },
    { items: "AB" },
  ],
  demo7: [
    { items: "ปกติ" },
    { items: "ค่าเริ่มต้น" },
    { items: "หยุดใช้งาน" },
    { items: "ผิดปกติ" },
  ],
  demo8: [
    { items: "กสิกรไทย" },
    { items: "ไทยพาณิชย์" },
    { items: "กรุงไทย" },
    { items: "กรุงเทพ" },
  ],
  demo9: [
    { items: "1.75%" },
    { items: "1.5%" },
    { items: "1%" },
    { items: "0.75%" },
  ],
  demo10: [
    { items: "ทอง" },
    { items: "เพชร" },
    { items: "กรอบพระ" },
    { items: "นาค" },
    { items: "นาฬิกา" },
    { items: "อื่นๆ" },
  ],
  cname: [
    {
      cd: "xxxxxx",
      th: "พิชัย จิตภักดี",
      en: "Pichai Jitphakdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "A",
    },
    {
      cd: "xxxxxx",
      th: "นิรุจ เสมากิจ",
      en: "Nirut Semakij",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "VIP",
    },
    {
      cd: "xxxxxx",
      th: "บวรศักดิ์ นิลสุภาพ",
      en: "Borwonsak Nilsupap",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "S",
    },
    {
      cd: "xxxxxx",
      th: "ชิดชนก วิมลพัฒน์",
      en: "Chidchanok Wimonpatr",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "C",
    },
    {
      cd: "xxxxxx",
      th: "สมบูรณ์ทรัพย์ ทองดี",
      en: "Somboonsap Thongdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "B",
    },
    {
      cd: "xxxxxx",
      th: "พิชัย จิตภักดี",
      en: "Pichai Jitphakdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "A",
    },
    {
      cd: "xxxxxx",
      th: "นิรุจ เสมากิจ",
      en: "Nirut Semakij",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "VIP",
    },
    {
      cd: "xxxxxx",
      th: "บวรศักดิ์ นิลสุภาพ",
      en: "Borwonsak Nilsupap",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "S",
    },
    {
      cd: "xxxxxx",
      th: "ชิดชนก วิมลพัฒน์",
      en: "Chidchanok Wimonpatr",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "C",
    },
    {
      cd: "xxxxxx",
      th: "สมบูรณ์ทรัพย์ ทองดี",
      en: "Somboonsap Thongdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "B",
    },
  ],
})

const samples2 = reactive([
  {
    tk: "234",
    st: t('content.holding'),
    vl: "88,888,888.88",
    tg: false,
    rw: [
      {
        ty: datas.demo10[0].items,
        qt: "5",
        vl: "88,888,888.88"
      },
      {
        ty: datas.demo10[1].items,
        qt: "3",
        vl: "88,888,888.88"
      },
      {
        ty: datas.demo10[2].items,
        qt: "16",
        vl: "88,888,888.88"
      },
      {
        ty: datas.demo10[3].items,
        qt: "16",
        vl: "88,888,888.88"
      },
      {
        ty: datas.demo10[4].items,
        qt: "7",
        vl: "88,888,888.88"
      },
    ]
  },
  {
    tk: "342",
    st: t('content.nothold'),
    vl: "88,888,888.88",
    tg: false,
    rw: [
      {
        ty: datas.demo10[0].items,
        qt: "3",
        vl: "88,888,888.88"
      },
      {
        ty: datas.demo10[1].items,
        qt: "7",
        vl: "88,888,888.88"
      },
      {
        ty: datas.demo10[2].items,
        qt: "6",
        vl: "88,888,888.88"
      },
      {
        ty: datas.demo10[3].items,
        qt: "1",
        vl: "88,888,888.88"
      },
      {
        ty: datas.demo10[4].items,
        qt: "7",
        vl: "88,888,888.88"
      },
    ]
  },
  {
    tk: "567",
    st: t('content.holding'),
    vl: "88,888,888.88",
    tg: false,
    rw: [
      {
        ty: datas.demo10[0].items,
        qt: "2",
        vl: "88,888,888.88"
      },
      {
        ty: datas.demo10[1].items,
        qt: "1",
        vl: "88,888,888.88"
      },
      {
        ty: datas.demo10[2].items,
        qt: "1",
        vl: "88,888,888.88"
      },
      {
        ty: datas.demo10[3].items,
        qt: "16",
        vl: "88,888,888.88"
      },
    ]
  },
  {
    tk: "234",
    st: t('content.holding'),
    vl: "88,888,888.88",
    tg: false,
    rw: [
      {
        ty: datas.demo10[3].items,
        qt: "5",
        vl: "88,888,888.88"
      },
      {
        ty: datas.demo10[4].items,
        qt: "3",
        vl: "88,888,888.88"
      },

    ]
  },
])

// const btnAddNew = computed(() => t("buttons.impo"))
// const btnEdit = computed(() => t("buttons.edit"))
const btnSearch = computed(() => t("buttons.sear"))
// const btnSave = computed(() => t("buttons.save"))
// const btnDel = computed(() => t("buttons.remo"))
// const btnClose = computed(() => t("buttons.clos"))

const toggleRow = (index) => {
  if (!samples2[index].tg) {
    samples2[index].tg = true
  } else {
    samples2[index].tg = false
  }
}

</script>
    
<style lang="scss" scoped></style>