const messages = {
  th: {
    langs: {
      tha: "ไทย",
      eng: "English",
    },
    apps: {
      name: "แพลตฟอร์ม",
      master: "ข้อมูลพื้นฐาน",
      general: "ทั่วไป",
      setting: "การตั้งค่า",
      admin: "ผู้ดูแล",
      gdashboard: "แดชบอร์ด",
      ghome: "หน้าหลัก",
      gticket: "รวมตั๋ว/แยกตั๋ว",
      gfinance: "การชำระ",
      gsplitasset: "แยกย่อยทรัพย์",
      ghistory: "ประวัติธุรกรรม",
      gticketchain: "ประวัติธุรกรรมตั๋ว",
      gcashmove: "เส้นทางการเงิน",
      gtrans: "จัดการเงิน",
      gdeposit: "ฝากเงินลูกค้า",
      gcustomer: "ข้อมูลลูกค้า",
      gport: "พอร์ตลูกค้า",
      greport: "รายงาน",
      gunitover: "ของหลุด",
      gdeferitems: "ของผลัด",
      mbank: "ธนาคาร",
      mwarning: "บุคคลเฝ้าระวัง",
      millegal: "ทรัพย์โจร",
      mtypeassets: "ข้อมูลทรัพย์",
      mprefix: "คำนำหน้าชื่อ",
      maddress: "ข้อมูลที่อยู่",
      mholiday: "วันหยุด",
      mtypecustomer: "ประเภทลูกค้า",
      mtypedocument: "ประเภทเอกสาร",
      mtypeunit: "หน่วยสินค้า",
      mpawn: "ธนาคารโรงรับจำนำ",
      mcash: "บัญชีเงินสด",
      mbanknote: "ประเภทธนบัตร",
      mbiz: "ประเภทธุรกรรม",
      sopen: "เปิดสาขาใหม่",
      ssetbranch: "กำหนดค่าสาขา",
      spre: "Pre-print & Running",
      sinterest: "ดอกเบี้ย",
      sstock: "คลัง",
      sgold: "ราคาทอง",
      srole: "สิทธิ์ผู้ใช้งาน",
      ssetup: "ตั้งค่าสิทธิ์",
      suser: "ผู้ใช้งาน",
      acprofile: "โปรไฟล์",
      acsetting: "ตั้งค่า",
      aclogout: "ออกจากระบบ",
      massetgroup: "ประเภททรัพย์",
      version: "เวอร์ชั่น",
    },
    heads: {
      men: "เมนู",
      clo: "ปิด",
      hom: "หน้าหลัก",
      new: "ข่าว/โปรโมชั่น",
      pro: "สินค้าและบริการ",
      abo: "เกี่ยวกับเรา",
      con: "ติดต่อเรา",
      ope: "เปิดบัญชี",
      log: "เข้าสู่ระบบ",
      por: "พอร์ต",
      his: "ประวัติ",
      res: "จอง",
      cle: "เคลียร์พอร์ต",
      not: "แจ้งเตือน",
      acc: "ตั้งค่าบัญชี",
      out: "ออกจากระบบ",
      rem: "จดจำบัญชี",
      usr: "ชื่อผู้ใช้งาน",
      pwd: "รหัสผ่าน",
      ema: "อีเมล",
      mob: "มือถือ",
      adr: "ที่อยู่",
    },
    buttons: {
      okay: "ตกลง",
      back: "กลับ",
      prev: "ย้อนกลับ",
      next: "ต่อไป",
      canc: "ยกเลิก",
      conf: "ยืนยัน",
      save: "บันทึก",
      acce: "ยอมรับ",
      decl: "ปฏิเสธ",
      open: "เปิด",
      clos: "ปิด",
      clea: "ล้าง",
      dele: "ลบ",
      sele: "เลือก",
      comp: "เสร็จสิ้น",
      uplo: "อัพโหลด",
      send: "ส่ง",
      sear: "ค้นหา",
      addd: "เพิ่ม",
      show: "แสดง",
      hide: "ซ่อน",
      remo: "ลบ",
      chan: "เปลี่ยน",
      sett: "ตั้งค่า",
      impo: "เพิ่มเข้า",
      edit: "แก้ไข",
      file: "เลือกไฟล์",
      split: "แยกรายการ",
      seed: "ดูรายละเอียด",
      print: "พิมพ์เอกสาร",
    },
    dlg: {
      tt_save: "ต้องการบันทึกรายการนี้",
      sb_save: "คุณต้องการบันทึกรายการนี้ใช่ไหม",
      st_save_fn: "บันทึกสำเร็จ",
      st_save_cc: "ยกเลิกการบันทึก",
    },

    content: {
      act: "จัดการ",
      en: "อังกฤษ",
      th: "ไทย",
      mbank: "ธนาคาร",
      mbankname: "ชื่อธนาคาร",
      mlogo: "โลโก้",
      mbankpshort: "ชื่อย่อ",
      mbankp: "ธนาคารโรงรับจำนำ",
      mcolor: "สี",
      note: "หมายเหตุ",
      mcash: "รายชื่อบัญชีเงินสด",
      mcashname: "ชื่อบัญชีเงินสด",
      mbanknote: "ประเภทธนบัตร",
      mbanknotetype: "ประเภทเงิน",
      mbanknotevalue: "ตัวคูณ",
      maddress: "ข้อมูลที่อยู่",
      detail: "รายละเอียด",
      date: "วันที่",
      mholiday: "วันหยุด",
      munit: "หน่วยสินค้า",
      massetgroup: "ประเภททรัพย์",
      mtypedoc: "ประเภทเอกสาร",
      add1: "ประเทศ",
      add2: "จังหวัด",
      add3: "เขต/อำเภอ",
      add4: "แขวง/ตำบล",
      add5: "รหัสไปรษณีย์",
      gmoney: "จัดการเงิน",
      history: "ประวัติ",
      gmoneybiz: "จัดการธุรกรรม",
      sdate: "วันเริ่มต้น",
      edate: "วันสิ้นสุด",
      refno: "เลขอ้างอิง",
      trans: "ประเภทธุรกรรม",
      dant: "วันที่/เวลา",
      file: "ไฟล์แนบ",
      amount: "จำนวนเงิน",
      gmoneyhis: "ประวัติจัดการเงิน",
      type: "ประเภท",
      glisttrans: "รายการธุรกรรม",
      gmoneytype: "ประเภทเงิน",
      gdepohis: "ประวัติฝากเงินลูกค้า",
      gdepoman: "จัดการฝากเงินลูกค้า",
      names: "ชื่อ-นามสกุล",
      cnamelist: "รายชื่อลูกค้า",
      cname: "ชื่อลูกค้า",
      ccode: "รหัสลูกค้า",
      cnameth: "ชื่อ-นามสกุล(ไทย)",
      cnameen: "ชื่อ-นามสกุล(อังกฤษ)",
      cidcard: "เลขที่บัตรประชาชน",
      cmobile: "เบอร์มือถือ",
      cgrade: "ประเภทลูกค้า",
      optall: "ทั้งหมด",
      gcashmove: "เส้นทางการเงิน",
      customer: "ลูกค้า",
      gcashmovetype: "ประเภท Movement",
      item: "รายการ",
      depo: "ฝาก",
      withd: "ถอน",
      total: "คงเหลือ",
      expfile: "ส่งออกไฟล์",
      millegal: "ทรัพย์โจร",
      assetinfo: "ข้อมูลทรัพย์",
      serial: "หมายเลขรุ่น",
      refer: "แหล่งที่มา",
      docno: "เลขที่เอกสาร",
      docdate: "วันที่เอกสาร",
      criminal: "คดีอาญา",
      agency: "จากหน่วยงาน",
      defect: "ตำหนิ/ลักษณะพิเศษ",
      mwarning: "บุคคลเฝ้าระวัง",
      doctype: "ประเภทเอกสาร",
      cardno: "เลขที่บัตร",
      warnlvl: "ระดับความอันตราย",
      select: "กรุณาเลือก",
      ssetbranch: "กำหนดค่าสาขา",
      status: "สถานะ",
      activity: "หัวข้อ",
      setallopen: "(เปิด-ปิด) ระบบทั้งหมด",
      detailallopen:
        "เปิด (เปิดระบบทั้งหมด), ปิด (ปิดระบบทั้งหมด), ใช้ได้เฉพาะผู้ดูแลระบบ",
      setfixbackend: "วันและเวลา (เปิด-ปิด) ระบบหลังบ้าน",
      detailfixbackend:
        "เปิด (ระบุวันและเวลาเปิด-ปิด), ปิด (ปิดระบบทุกวัน), จะถูกวันหยุดซ้อนทับทุกกรณี และถ้าไม่ตั้งค่าวันเวลาเปิด-ปิดระบบ จะเป็นแบบเปิดตลอด 24 ชม./ 7 วัน",
      day: "วัน",
      onoff: "เปิด-ปิด",
      opentime: "เวลาเปิด",
      to: "ถึง",
      closetime: "เวลาปิด",
      sun: "วันอาทิตย์",
      mon: "วันจันทร์",
      tue: "วันอังคาร",
      wed: "วันพุธ",
      thu: "วันพฤหัสบดี",
      fri: "วันศุกร์",
      sat: "วันเสาร์",
      close: "ปิด",
      open: "เปิด",
      gticketchain: "ประวัติธุรกรรมตั๋ว",
      ticketno: "เลขที่ตั๋ว",
      allweight: "น้ำหนักรวม",
      allqty: "จำนวนรวม",
      allprice: "ราคารวม",
      gcustomer: "ข้อมูลลูกค้า",
      gcustomerman: "จัดการข้อมูลลูกค้า",
      cstatus: "สถานะลูกค้า",
      activate: "วันที่เปิดบัญชี",
      lastact: "วันที่ธุรกรรมล่าสุด",
      resetpwd: "รีเซ็ตรหัสผ่าน",
      cardtype: "ประเภทบัตร",
      pcissue: "สถานที่ออกบัตร",
      cdissue: "วันออกบัตร",
      cdexpire: "วันหมดอายุบัตร",
      cprefix: "คำนำหน้าชื่อ",
      cnam: "ชื่อ",
      clsnam: "นามสกุล",
      cbirth: "วัน/เดือน/ปีเกิด",
      cage: "อายุ",
      crelig: "ศาสนา",
      cblood: "หมู่เลือด",
      cnation: "สัญชาติ",
      cstage: "สถานะลูกค้า",
      caddress: "ที่อยู่",
      cstate: "จังหวัด(รัฐ)",
      csprov: "เขต/อำเภอ(จังหวัด)",
      cscity: "แขวง/ตำบล(เมือง)",
      cemail: "อีเมล",
      cupload: "เลือกภาพ",
      cbankinfo: "ข้อมูลบัญชีธนาคาร",
      cbankname: "ธนาคาร",
      cbankno: "เลขที่บัญชีธนาคาร",
      cbankacc: "ชื่อบัญชีธนาคาร",
      cselectuse: "ใช้งาน",
      cdefault: "ค่าเริ่มต้น",
      cintrate: "อัตราดอกเบี้ย",
      cintrate1: "ปกติ",
      cintrate2: "พิเศษ",
      csign: "ลายเซ็นต์",
      cfprint: "ลายพิมพ์นิ้วมือ",
      cabove: "บุคคลเกี่ยวข้อง",
      crlname: "ค้นหา ชื่อ-นามสกุล",
      gdashboard: "แดชบอร์ด",
      byday: "รายวัน",
      byweek: "รายอาทิตย์",
      bymonth: "รายเดือน",
      gport: "พอร์ตลูกค้า",
      grelation: "พอร์ตบุคคลที่เกี่ยวข้อง",
      allassets: "สินทรัพย์ทั้งหมด",
      holdon: "ฝากตั๋ว",
      totunit: "จำนวนทั้งหมด",
      totprice: "ราคารวม",
      totvalue: "รวมมูลค่า",
      totmaxval: "มูลค่าทรัพย์ทั้งหมด",
      totlimit: "มูลค่าจำนำทั้งหมด",
      personhis: "ประวัติธุรกรรมส่วนบุคคล",
      totdeposit: "เงินที่ฝากไว้",
      holding: "ฝากไว้",
      nothold: "ไม่ได้ฝากไว้",
      statusticket: "สถานะตั๋ว",
      curency: "บาท",
      time: "เวลา",
      actpawn1: "จำนำ",
      actpawn2: "ต่อดอก",
      actpawn3: "เพิ่มต้น",
      actpawn4: "ลดต้น",
      actpawn5: "ไถ่ถอน",
      amnenter: "จำนวนลูกค้าที่เข้ามาทำธุรกรรม(คน)",
      amnqty: "จำนวนธุรกรรมทั้งหมด(ครั้ง)",
      amnmoney: "จำนวนธุรกรรมทั้งหมด(บาท)",
      amndue: "สรุปตั๋วถึงกำหนดชำระ",
      amnticketdueactived: "ตั๋วถึงกำหนดที่เคลียร์แล้วในวันนี้(ใบ)",
      amnticketdued: "ตั๋วถึงกำหนดที่ยังไม่เคลียร์ในวันนี้(ใบ)",
      amnticketoveractived: "ตั๋วเลยกำหนดแล้วเมื่อวานนี้(ใบ)",
      amnticketoverd: "ตั๋วที่เลยกำหนดที่เคลียร์แล้วในวันนี้(ใบ)",
      amnticketdueactivew: "ตั๋วถึงกำหนดที่เคลียร์แล้วในอาทิตย์นี้(ใบ)",
      amnticketduew: "ตั๋วถึงกำหนดที่ยังไม่เคลียร์ในอาทิตย์นี้(ใบ)",
      amnticketoveractivew: "ตั๋วเลยกำหนดแล้วอาทิตย์นี้(ใบ)",
      amnticketoverw: "ตั๋วที่เลยกำหนดที่เคลียร์แล้วในอาทิตย์นี้(ใบ)",
      amnticketdueactivem: "ตั๋วถึงกำหนดที่เคลียร์แล้วในเดือนนี้(ใบ)",
      amnticketduem: "ตั๋วถึงกำหนดที่ยังไม่เคลียร์ในเดือนนี้(ใบ)",
      amnticketoveractivem: "ตั๋วเลยกำหนดแล้วเดือนนี้(ใบ)",
      amnticketoverm: "ตั๋วที่เลยกำหนดที่เคลียร์แล้วในเดือนนี้(ใบ)",
      amncash: "เงินสด",
      amnbankacc: "บัญชีธนาคาร",
      amntotbaht: "รวมบาท",
      amndrawer: "ลิ้นชักเงินสด",
      amnlistcash: "รายการเงินสด",
      amnlistremain: "รายการคงเหลือ",
      amnremain: "คงเหลือ",
      amnpay: "จ่ายเงิน",
      amnget: "รับเงิน",
      amntransfer: "โอนเงิน",
      amntotaltransact: "สรุปยอด",
      amnduedate: "วันครบกำหนด",
      amnduedatenew: "วันครบกำหนด(ใหม่)",
      amnduedateadd: "จำนวนวันที่เพิ่ม",
      amninterest: "ดอกเบี้ย",
      amnticketduelist: "รายการครบกำหนดตั๋ว",
      amnsafe: "เงินสดในเซฟ",
      amntypecustomer: "ประเภทลูกค้า",
      amncredit: "วงเงิน",
      amnchannel: "ช่องทาง",
      amntimestart: "เวลาเริ่มต้น",
      amntimeend: "เวลาสิ้นสุด",
      amncashflow: "รายรับ-รายจ่าย",
      amnincome: "เงินเข้า",
      amnoutcome: "เงินออก",
      amnprofit: "กำไร",
      amndeposit: "ฝากเงิน",
      amnwithdraw: "ถอนเงิน",
      amnpawnlist: "รายการทรัพย์จำนำ",
      amnsplitlist: "รายการแยกทรัพย์",
      amnassetowner: "เจ้าของทรัพย์",
      amncreditget: "วงเงินที่ได้รับ",
      amncredituse: "วงเงินที่ใช้ไป",
      amncreditremain: "วงเงินคงเหลือ",
      amnassetdetail: "รายละเอียดทรัพย์",
      amnassetdatas: "ข้อมูลรายการทรัพย์จำนำ",
      amnweightval: "น้ำหนัก(กรัม)",
      amnweightvalall: "น้ำหนักรวม(กรัม)",
      amnanalysval: "ราคาจำนำ",
      amnanalysvalall: "ราคาจำนำ(รวม)",
      amnmaxval: "มูลค่าเต็ม",
      amnmaxvalall: "มูลค่าเต็ม(รวม)",
      amnqtydiamond: "จำนวน(เม็ด)",
      amnqtyunit: "จำนวน(ชิ้น)",
      amnqtyunitall: "จำนวนรวม(ชิ้น)",
      amnfingerprint: "พิมพ์ลายนิ้วมือ",
      amngold: "ทอง",
      amndiamond: "เพชร",
      amnframe: "กรอบพระ",
      amncopper: "นาค",
      amnwatch: "นาฬิกา",
      amnothers: "อื่นๆ",
      amntypeassets: "ข้อมูลทรัพย์",
      amnstateacting: "กำลังดำเนินการ",
      amnstateending: "เสร็จสิ้น",
      amnsearchticker: "ค้นหาตั๋ว",
      amndatetime: "วัน/เวลา",
      amnassetgroup: "ประเภททรัพย์",
      amnbiz: "ประเภทธุรกรรม",
      amnoldmessage: "รายละเอียดทรัพย์เดิม",
      amngroupticket: "กลุ่มตั๋ว",
      amnticketlist: "รายการตั๋ว",
      amnticketmanage: "จัดการตั๋ว",
      amnseedetail: "ดูรายละเอียด",
      amnsplititem: "แยกรายการ",
      amnscancelitem: "ยกเลิก",
      amnonprocess: "รอดำเนินการ",
      amncancelticket: "ยกเลิกธุรกรรมตั๋ว",
      amnbankprocess: "สถานะยืนยันชำระ",
      amncanceladdnew: "ยกเลิกเพื่อออกตั๋วใหม่",
      amnfinanceno: "เลขที่ชำระ",
      amnfinancestatus: "สถานะชำระ",
      amnfinanceok: "สมบูรณ์",
      amnfinancenot: "ยังไม่ชำระ",
      amnfinancepartial: "ชำระบางส่วน",
      amnfinanceremain: "จำนวนเงินคงเหลือ",
      amnfinancedoneall: "ยอดชำระรวม",
      amnfinancesearch: "ค้นหารายการชำระ",
      amnfinancelist: "รายการชำระ",
      amnfinancenmanage: "จัดการชำระ",
      amnbankinfo: "ข้อมูลธนาคาร",
      amnselectlist: "เลือกรายการ",
      amnbankaccno: "เลขที่บัญชี",
      amnbankactive: "บัญชีใช้งาน",
      amnfinanceinput: "ยอดรับ",
      amnfinanceoutput: "ยอดจ่าย",
      amnfinancetype: "การชำระ",
      amnfinancetotal: "รวมชำระ",
      amnfinanceinfo: "ข้อมูลรายการชำระ",
      amnfinanceprocess: "ดำเนินการชำระ",
      amncreditcard: "บัตรเครดิต",
      amncheque: "เช็ค",
      amntypetrans: "ประเภทชำระ",
      amnhowtomoney: "เช็ค/เลขบัตรเครดิต/เลขอ้างอิง",
      amninputmoneytype: "จำนวนเงินที่จ่าย/ที่รับ",
      amnsourcemoney: "แหล่งเงิน",
      amnqrrunapp: "สแกน QR code เพื่อทำงานต่อบน app",
      amnfromdrawer: "จากลิ้นชัก",
      amnfrombankacc: "จากบัญชี",
      amnfinancenote: "หมายเหตุการชำระ",
      amnpayremain: "ค้างจ่าย",
      amnreceiveremain: "ค้างรับ",
      amnrelatenum: "จำนวนบุคคลเกี่ยวข้อง",
      amnaddnew: "เพิ่มรายการ",
      amndeferitems: "ของผลัด",
      amnuser: "ผู้ใช้งาน",
      amnusermanage: "จัดการผู้ใช้งาน",
      amnusername: "ชื่อผู้ใช้งาน",
      amnusernick: "ชื่อเล่น",
      amnadmin: "ผู้ดูแลระบบ",
      amnuserrole: "ประเภทผู้ใช้งาน",
      amnuserright: "สิทธิ์ผู้ใช้งาน",
      amneffectdate: "วันที่มีผล",
      amnenddate: "วันหมดอายุ",
      amnsplitasset: "แยกย่อยทรัพย์",
      amnsplitassetman: "จัดการแยกย่อยทรัพย์",
      amntypegold: "ทรัพย์เป็นทอง",
      amnnotgold: "ทรัพย์ที่ไม่ใช่ทอง",
      amndefertime: "จำนวนที่เคยผลัด",
      amngem: "พลอย",
      amnsilver: "เงิน",
      amnamulet: "พระ",
      bankcode: "รหัสธนาคาร",
      docgroup: "กลุ่มเอกสาร",
      doccode: "รหัสกลุ่มเอกสาร",
      amncreditmax: "วงเงินสูงสุด",
      openshop1: "เปิดกลุ่ม",
      openshop2: "เปิดร้าน",
      openshop3: "ตั้งค่าร้าน",
      gpsearch: "ค้นหา Group",
      gpadd: "เพิ่ม Group",
      gpedit: "แก้ไข Group",
      gpcode: "Group Code",
      gpnameth: "ชื่อ Group TH",
      gpnameen: "ชื่อ Group EN",
      gpdomain: "ชื่อโดเมน",
      gpcontact: "ผู้ติดต่อ",
      gpcontactno: "เบอร์โทร ผู้ติดต่อ",
      gpemail: "อีเมล ผู้ติดต่อ",
      gpremark: "หมายเหตุ",
      gpfeature: "Group Feature",
      gpfunction: "Group Function",
      gpattach: "แนบไฟล์",
      opsearch: "ค้นหา Shop",
      opedit: "แก้ไข Shop",
      opshopcode: "Shop Code",
      opshopth: "Shop Name TH",
      opshopen: "Shop Name EN",
      opdomain: "Domain name",
      opeffect: "Effective Date",
      openddate: "End Date",
      opaddr: "Shop Address",
      opcon: "เบอร์โทร ผู้ติดต่อ",
      opconno: "เบอร์โทร ผู้ติดต่อ",
      opconemail: "อีเมล ผู้ติดต่อ",
      opattach: "แนบไฟล์",
      opremark: "Remarks",
      oplcbookno: "License Book Number",
      oplcno: "License Number",
      oplcown: "License Owner",
      opshopown: "Shop Owner",
      sssms: "SMS Setting",
      sstype: "Set Type ",
      ssgrade: "Customer Grade",
      ssbank: "Shop Bank ",
      sscash: "Cash Account",
      sstxn: "Transaction Types",
      ssdue: "Due Period",
      ssburole: "Business Role",
      ssrole: "Shop Role",
      ssadminth: "Shop Admin Name TH",
      ssadminen: "Shop Admin Name EN",
      ssadminno: "Shop Admin Number",
      ssadminemail: "Shop Admin Email",
      ssusername: "Username",
      sssetpwd: "ตั้งค่าส่งรีเซ็ตรหัสผ่านใหม่ไปยัง",
      sssetpin: "ตั้งค่าส่งรีเซ็ตรหัส PIN ใหม่ไปยัง",
      disable: "ปิดใช้งาน",
      enable: "เปิดใช้งาน",
      view: "ดูข้อมูล",
      add: "เพิ่มข้อมูล",
      edit: "แก้ไขข้อมูล",
      delete: "ลบข้อมูล",
      print: "พิมพ์ข้อมูล",
      addfilename: "ตั้งชื่อไฟล์แนบ",
      addshop: "เพิ่มร้าน",
      sstype1: "ไม่ต้องหยิบทรัพย์กรณีมีการทำธุรกรรม",
      sstype2: "หยิบทรัพย์กรณีมีการทำธุรกรรม",
      financetypes: "ประเภทธุรกรรมการเงิน",
      transc: "ประเภทการเงิน",
      ctranstin: "ภายในโรงรับจำนำ",
      ctranstout: "ภายนอกโรงรับจำนำ",
      ctranstexp: "ค่าใช้จ่าย",
      ctranstacc: "ปิดบัญชี",
      fillmonth: "กรอกจำนวนเดือน",
      fillrole: "ตั้งชื่อ Role Name",
      allow: "อนุญาต",
      csms: "SMS",
      reviewasset: "รีวิวข้อมูลทรัพย์",
      manageassettk: "จัดการแสดงข้อมูลทรัพย์บนตั๋ว",
      manageasset: "จัดการข้อมูลทรัพย์",
      addassetdate: "วันที่/เวลาที่เพิ่ม",
      weight: "น้ำหนัก",
      viewable: "เปิด/ปิดการมองเห็น",
      useable: "เปิด/ปิดใช้งาน",
      copyData: "คัดลอกข้อมูล",
      maddressth: "ข้อมูลที่อยู่ประเทศไทย",
      docnum: "เลขบัตรประชาชน/เลขที่เอกสารอื่นๆ",
    },
  },
  en: {
    langs: {
      tha: "Thai",
      eng: "English",
    },
    apps: {
      name: "Platform",
      master: "Master",
      general: "General",
      setting: "Setting",
      admin: "Administrator",
      gdashboard: "Dashboard",
      ghome: "Home",
      gticket: "Ticket (Group / Split)",
      gfinance: "Finance",
      gsplitasset: "Split Assets",
      ghistory: "History",
      gticketchain: "Ticket Chain",
      gcashmove: "Cash Movement",
      gtrans: "Transactions Management",
      gdeposit: "Customer Deposit",
      gcustomer: "Customer Info",
      gport: "Customer Port",
      greport: "Report",
      gunitover: "Unit Overdue",
      gdeferitems: "Unit Resume",
      mbank: "Bank",
      mwarning: "Personal Warning",
      millegal: "Illegal Assets",
      mtypeassets: "Asset info",
      mprefix: "Prefix of name",
      maddress: "Address Info",
      mholiday: "Holidays",
      mtypecustomer: "Customer type",
      mtypedocument: "Document type",
      mtypeunit: "Unit type",
      mpawn: "Pawnshop bank",
      mcash: "Cash account",
      mbanknote: "Bank Note type",
      mbiz: "Business Type",
      sopen: "Open Branch",
      ssetbranch: "Branch Settings",
      spre: "Pre-print & Running",
      sinterest: "Interest",
      sstock: "Stock",
      sgold: "Gold Price",
      srole: "Security Role",
      ssetup: "Security Setup",
      suser: "User",
      acprofile: "My Profile",
      acsetting: "Settings",
      aclogout: "Logout",
      massetgroup: "Asset Group",
      version: "Version",
    },
    heads: {
      men: "Menu",
      clo: "Close",
      hom: "Home",
      new: "News/Promotions",
      pro: "Products/Services",
      abo: "About us",
      con: "Contact",
      ope: "Register",
      log: "Log in",
      por: "Portfolio",
      his: "History",
      res: "Reservation",
      cle: "Clear Port",
      not: "Notification",
      acc: "Settings",
      out: "Log out",
      rem: "Remember me",
      usr: "Username",
      pwd: "Password",
      ema: "Email",
      mob: "Mobile",
      adr: "Address",
    },
    buttons: {
      okay: "OK",
      back: "Back",
      prev: "Previous",
      next: "Next",
      canc: "Cancel",
      conf: "Confirm",
      save: "Save",
      acce: "Accept",
      decl: "Decline",
      open: "Open",
      clos: "Close",
      clea: "Clear",
      dele: "Delete",
      sele: "Select",
      comp: "Completed",
      uplo: "Upload",
      send: "Send",
      sear: "Search",
      addd: "Add",
      show: "Show",
      hide: "Hide",
      remo: "Remove",
      chan: "Change",
      sett: "Settings",
      impo: "Add new",
      edit: "Edit",
      file: "Select File",
      split: "Split Items",
      seed: "See Detail",
      print: "Print",
    },
    dlg: {
      tt_save: "Save",
      sb_save: "Do you want to save this data?",
      st_save_fn: "Save Successful",
      st_save_cc: "Cancel to save",
    },
    content: {
      act: "Actions",
      en: "English",
      th: "Thai",
      mbank: "Bank",
      mbankname: "Bank name",
      mlogo: "Logo",
      mbankpshort: "Short code",
      mbankp: "Pawnshop Bank",
      mcolor: "Color",
      note: "Notice",
      mcash: "Cash Account Lists",
      mcashname: "Cash account name",
      mbanknote: "Bank Note Lists",
      mbanknotetype: "Bank note type",
      mbanknotevalue: "Rate value",
      maddress: "Address Info",
      detail: "Detail",
      date: "Date",
      mholiday: "Holidays",
      munit: "Units",
      massetgroup: "Asset Group",
      mtypedoc: "Document type",
      add1: "Nation",
      add2: "Province",
      add3: "District",
      add4: "Sub District",
      add5: "Zip code",
      gmoney: "Money Management",
      history: "History",
      gmoneybiz: "Transactions Management",
      sdate: "From date",
      edate: "To date",
      refno: "Ref No.",
      trans: "Transactions",
      dant: "Date/Time",
      file: "Files",
      amount: "Amount",
      gmoneyhis: "Transactions History",
      type: "Type",
      glisttrans: "Transaction Lists",
      gmoneytype: "Money Type",
      gdepohis: "Customer Deposit History",
      gdepoman: "Customer Deposit Management",
      names: "Name-Lastname",
      cnamelist: "Customer name lists",
      cname: "Customer Name",
      ccode: "Customer Code",
      cnameth: "Name-Lastname(TH)",
      cnameen: "Name-Lastname(EN)",
      cidcard: "ID Card",
      cmobile: "Mobile",
      cgrade: "Customer Grade",
      optall: "All",
      gcashmove: "Cash Movement",
      customer: "Customer",
      gcashmovetype: "Movement Type",
      item: "Item",
      depo: "Deposit",
      withd: "Withdraw",
      total: "Total",
      expfile: "Export to",
      millegal: "Illegal Assets",
      assetinfo: "Asset Info",
      serial: "Serial no.",
      refer: "Source",
      docno: "Document no.",
      docdate: "Document Date",
      criminal: "Criminal Case",
      agency: "Agency",
      defect: "Defect/Spacial",
      mwarning: "Personal Warning",
      doctype: "Document Type",
      cardno: "Card no.",
      warnlvl: "Warning Level",
      select: "Please select",
      ssetbranch: "Branch Settings",
      status: "Status",
      activity: "Activity",
      setallopen: "(On-Off) For all systems",
      detailallopen:
        "On (Open all systems), Off (Close all systems), Available only for ADMIN",
      setfixbackend: "(On-Off) For all systems",
      detailfixbackend:
        "On (Open all systems), Off (Close all systems), Available only for ADMIN",
      day: "Day",
      onoff: "On-Off",
      opentime: "Open Time",
      to: "to",
      closetime: "Close Time",
      sun: "Sunday",
      mon: "Monday",
      tue: "Tuesday",
      wed: "Wednesday",
      thu: "Thursday",
      fri: "Friday",
      sat: "Saturday",
      close: "Close",
      open: "Open",
      gticketchain: "Ticket Chain",
      ticketno: "Ticket no.",
      allweight: "Total Weight",
      allqty: "Total QTY",
      allprice: "Total Price",
      gcustomer: "Customer Info",
      gcustomerman: "Customer Info Management",
      cstatus: "Customer Status",
      activate: "Activated",
      lastact: "Last active",
      resetpwd: "Reset Password",
      cardtype: "Card Type",
      pcissue: "Place of issue",
      cdissue: "Date of issue",
      cdexpire: "Expire date",
      cprefix: "Prefix",
      cnam: "Name",
      clsnam: "Lastname",
      cbirth: "Birthdate",
      cage: "Age",
      crelig: "Religion",
      cblood: "Blood",
      cnation: "National",
      cstage: "Pawner Status",
      caddress: "Address",
      cstate: "Province(State)",
      csprov: "District(Province)",
      cscity: "Sub-district(City)",
      cemail: "Email",
      cupload: "Choose file",
      cbankinfo: "Bank Account Info",
      cbankname: "Bank name",
      cbankno: "Bank account no.",
      cbankacc: "Bank account name",
      cselectuse: "Selected",
      cdefault: "Default",
      cintrate: "Interest Rate",
      cintrate1: "Normal",
      cintrate2: "Extra",
      csign: "Signatures",
      cfprint: "Finget Prints",
      cabove: "Ralations",
      crlname: "Search name and lastname",
      gdashboard: "Dashboard",
      byday: "By date",
      byweek: "By week",
      bymonth: "By date",
      gport: "Customer Port",
      grelation: "Relational Port",
      allassets: "All Assets",
      holdon: "Hold on",
      totunit: "Total QTY",
      totprice: "Total Prices",
      totvalue: "Total Values",
      totmaxval: "Total Max Asset Value",
      totlimit: "Total Pawn Value",
      personhis: "Personal History",
      totdeposit: "Customer Wallet",
      holding: "hold on",
      nothold: "Not hold",
      statusticket: "Status Ticket",
      curency: "BAHT",
      time: "Time",
      actpawn1: "Pledge",
      actpawn2: "Interest",
      actpawn3: "Add Capitals",
      actpawn4: "Reduce Capitals",
      actpawn5: "Redeem",
      amnenter: "Customers who make transactions(persons)",
      amnqty: "Summary of transactions(times)",
      amnmoney: "Summary of money transactions(baht)",
      amndue: "Summary of tickets due(sheets)",
      amnticketdueactived: "Tickets due that cleared on today(sheets)",
      amnticketdued: "Tickets due on today(sheets)",
      amnticketoveractived: "Tickets overdued on yesterday(sheets)",
      amnticketoverd: "Tickets will overdue on today(sheets)",
      amnticketdueactivew: "Tickets due that cleared by week(sheets)",
      amnticketduew: "Tickets due by week(sheets)",
      amnticketoveractivew: "Tickets overdued by week(sheets)",
      amnticketoverw: "Tickets will overdue by week(sheets)",
      amnticketdueactivem: "Tickets due that cleared by month(sheets)",
      amnticketduem: "Tickets due by month(sheets)",
      amnticketoveractivem: "Tickets overdued by month(sheets)",
      amnticketoverm: "Tickets will overdue by month(sheets)",
      amncash: "Cash",
      amnbankacc: "Bank account",
      amntotbaht: "Summary",
      amndrawer: "Drawer",
      amnlistcash: "Cash items",
      amnlistremain: "Remaining items",
      amnremain: "Remaining",
      amnpay: "Pay",
      amnget: "Receive",
      amntransfer: "Transfer money",
      amntotaltransact: "Grand Total",
      amnduedate: "Due date",
      amnduedatenew: "Due date(new)",
      amnduedateadd: "Days added",
      amninterest: "Interest",
      amnticketduelist: "Ticket due lists",
      amnsafe: "Cash in safe",
      amntypecustomer: "Customer type",
      amncredit: "Credit",
      amnchannel: "Channel",
      amntimestart: "Start Time",
      amntimeend: "End Time",
      amncashflow: "Cash Flow",
      amnincome: "Income",
      amnoutcome: "Outcome",
      amnprofit: "Profit",
      amndeposit: "Deposit",
      amnwithdraw: "Withdrawal",
      amnpawnlist: "Assets List",
      amnsplitlist: "Split Asset List",
      amnassetowner: "Asset Owner",
      amncreditget: "Limit Received",
      amncredituse: "Limit Used",
      amncreditremain: "Limit Remaining",
      amnassetdetail: "Asset Detail",
      amnassetdatas: "Asset Informations",
      amnweightval: "Weight(grams)",
      amnweightvalall: "Total Weight(grams)",
      amnanalysval: "Price",
      amnanalysvalall: "Total Price",
      amnmaxval: "Max Price",
      amnmaxvalall: "Total Max Price",
      amnqtydiamond: "QTY(Diamond)",
      amnqtyunit: "QTY(Units)",
      amnqtyunitall: "Total QTY(Units)",
      amnfingerprint: "Finger Print",
      amngold: "Gold",
      amndiamond: "Diamond",
      amnframe: "Monk Frame",
      amncopper: "Copper",
      amnwatch: "Watch",
      amnothers: "Others",
      amntypeassets: "Asset info",
      amnstateacting: "on Process",
      amnstateending: "Completed",
      amnsearchticker: "Ticket Search",
      amndatetime: "Date/Time",
      amnassetgroup: "Asset Type",
      amnbiz: "Business Type",
      amnoldmessage: "Original Asset Detail",
      amngroupticket: "Ticket Group",
      amnticketlist: "Ticket List",
      amnticketmanage: "Ticket Management",
      amnonprocess: "on Process",
      amncancelticket: "Cancel Ticket",
      amnbankprocess: "Bank Process",
      amncanceladdnew: "Cancel to add new ticket",
      amnfinanceno: "Finance no.",
      amnfinancestatus: "Finance Status",
      amnfinanceok: "Completed",
      amnfinancenot: "No action",
      amnfinancepartial: "Partial",
      amnfinanceremain: "Total remaining",
      amnfinancedoneall: "Total transactions",
      amnfinancesearch: "Finance Search",
      amnfinancelist: "Finance Lists",
      amnfinancenmanage: "Finance Management",
      amnbankinfo: "Bank Account info",
      amnselectlist: "Select item",
      amnbankaccno: "Bank Account no.",
      amnbankactive: "Active",
      amnfinanceinput: "Receive",
      amnfinanceoutput: "Paid",
      amnfinancetype: "Clear Type",
      amnfinancetotal: "Total Clear",
      amnfinanceinfo: "Finance Info",
      amnfinanceprocess: "Finance Process",
      amncreditcard: "Credit Card",
      amncheque: "Cheque",
      amntypetrans: "Transcaction Type",
      amnhowtomoney: "Cheque/Credit Card no./Ref.",
      amninputmoneytype: "Paid/Receive",
      amnsourcemoney: "Source of Money",
      amnqrrunapp: "scan QR code to continueing on application.",
      amnfromdrawer: "From drawer",
      amnfrombankacc: "From bank account",
      amnfinancenote: "Transaction Notice",
      amnpayremain: "Paid Remaining",
      amnreceiveremain: "Receive Remaining",
      amnrelatenum: "Relation Amounts",
      amnaddnew: "New Item",
      amndeferitems: "Unit Resume",
      amnuser: "User",
      amnusermanage: "User Management",
      amnusername: "Username",
      amnusernick: "Nickname",
      amnadmin: "Administrator",
      amnuserrole: "User Role",
      amnuserright: "User Right",
      amneffectdate: "Effect date",
      amnenddate: "End date",
      amnsplitasset: "Split Assets",
      amnsplitassetman: "Split Assets Management",
      amntypegold: "Gold Assest",
      amnnotgold: "No Gold Assest",
      amndefertime: "Defer Times",
      amngem: "Gem",
      amnsilver: "Silver",
      amnamulet: "Amulet",
      bankcode: "Bank Code",
      docgroup: "Document Group",
      doccode: "Document Group Code",
      amncreditmax: "Max Credit",
      openshop1: "Open Group",
      openshop2: "Open Shop",
      openshop3: "Shop Setup",
      gpsearch: "Search Group",
      gpadd: "Create Group",
      gpedit: "Edit Group",
      gpcode: "Group Code",
      gpnameth: "Group Name TH",
      gpnameen: "Group Name EN",
      gpdomain: "Domain name",
      gpcontact: "Contact Person",
      gpcontactno: "Contact Person Number",
      gpemail: "Contact Person Email",
      gpremark: "Remarks",
      gpfeature: "Group Feature",
      gpfunction: "Group Function",
      gpattach: "Attach File",
      opsearch: "Search Shop",
      opedit: "Edit Shop",
      opshopcode: "Shop Code",
      opshopth: "Shop Name TH",
      opshopen: "Shop Name EN",
      opdomain: "Domain name",
      opeffect: "Effective Date",
      openddate: "End Date",
      opaddr: "Shop Address",
      opcon: "Contact Person",
      opconno: "Contact Person Number",
      opconemail: "Contact Person Email",
      opattach: "Attach File",
      opremark: "Remarks",
      oplcbookno: "License Book Number",
      oplcno: "License Number",
      oplcown: "License Owner",
      opshopown: "Shop Owner",
      sssms: "SMS Setting",
      sstype: "Set Type ",
      ssgrade: "Customer Grade",
      ssbank: "Shop Bank ",
      sscash: "Cash Account",
      sstxn: "Transaction Types",
      ssdue: "Due Period",
      ssburole: "Business Role",
      ssrole: "Shop Role",
      ssadminth: "Shop Admin Name TH",
      ssadminen: "Shop Admin Name EN",
      ssadminno: "Shop Admin Number",
      ssadminemail: "Shop Admin Email",
      ssusername: "Username",
      sssetpwd: "Send Reset Password by",
      sssetpin: "Send Reset PIN by",
      disable: "Disable",
      enable: "Enable",
      view: "View",
      add: "Add",
      edit: "Edit",
      delete: "Delete",
      print: "Print",
      addfilename: "File name for the Attach File",
      addshop: "Add Shop",
      sstype1: "Do not get any assets when has transaction",
      sstype2: "Get any assets when has transaction",
      financetypes: "Financial Type",
      transc: "Categories",
      ctranstin: "Within Pawnshop",
      ctranstout: "Outside Pawnshop",
      ctranstexp: "Expense",
      ctranstacc: "Account closure",
      fillmonth: "Fill amount of months",
      fillrole: "Fill Role Name",
      allow: "Allow",
      csms: "SMS",
      reviewasset: "Review AssetType",
      manageassettk: "Manage AssetType Ticket Print",
      manageasset: "Manage AssetType",
      addassetdate: "Create Date",
      weight: "Weight",
      viewable: "View/Un View",
      useable: "Useable/Un Useable",
      copyData: "Copy Data",
      maddressth: "Thailand Address Info",
      docnum: "Card ID/Document no.",
    },
  },
};

export default messages;
