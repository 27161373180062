<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 mb-4">
        <div class="card h-100 mt-3">
          <div class="card-header mb-0">
            <h5>{{ $t('content.gdashboard') }}</h5>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="btn-group segment" role="group" :aria-label="$t('content.gdashboard')">
                <material-radio-group id="btn1" v-model="selectInt" name="selectRange" value="รายวัน">
                  {{ $t('content.byday') }}
                </material-radio-group>
                <material-radio-group id="btn2" v-model="selectInt" name="selectRange" value="รายอาทิตย์">
                  {{ $t('content.byweek') }}
                </material-radio-group>
                <material-radio-group id="btn3" v-model="selectInt" name="selectRange" value="รายเดือน">
                  {{ $t('content.bymonth') }}
                </material-radio-group>
              </div>
            </div>
            <div class="row">
              <div class="col-12 text-center">
                <h4 class="mt-4 mb-0"><b>
                    {{ $t('content.amnenter') }} </b>
                </h4>

                <div>
                  <h1 class="mb-0 text-info">
                    <b>
                      {{ selectInt == "รายวัน" ? fmNumber(react.byday) : (selectInt == "รายอาทิตย์" ?
                        fmNumber(react.byweek) : fmNumber(react.bymonth))
                      }}
                    </b>
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">

      <div class="col-lg-6 mb-4">
        <div class="card h-100 mt-3">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="mb-0"><b>{{ $t('content.amnqty') }}</b></h5>

              <h2 class="mb-0 text-info"><b>{{ selectInt == "รายวัน" ? fmNumber(react.daydone) : (selectInt ==
                "รายอาทิตย์" ?
                fmNumber(react.weekdone) : fmNumber(react.monthdone)) }}</b></h2>
            </div>
          </div>
          <div class="p-3 mt-4 card-body">
            <div class="row">
              <div class="col-7 text-start">
                <pie-chart id="chart-times" :chart="{
                  labels: [plegde, interest, addcap, reducecap, redeem], datasets: {
                    label: 'Projects',
                    data: [
                      legendTimes[0].dt,
                      legendTimes[1].dt,
                      legendTimes[2].dt,
                      legendTimes[3].dt,
                      legendTimes[4].dt,
                    ],
                    backgroundColor: colors,
                  },
                }" />
              </div>
              <div class="my-auto col-5">
                <span v-for="(i, pt1) in legendTimes" :key="pt1" class="badge badge-md badge-dot me-4 d-block text-start">
                  <i :class="i.bg"></i>
                  <span class="text-md text-dark">{{ i.hd }} ({{ fmNumber(i.dt) }})</span>
                </span>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="col-lg-6 mb-4">
        <div class="card h-100 mt-3">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="mb-0"><b>{{ $t('content.amnmoney') }}</b></h5>

              <h2 class="mb-0 text-info"><b>{{ selectInt == "รายวัน" ? fmNumber(react.daybaht) : (selectInt ==
                "รายอาทิตย์" ?
                fmNumber(react.weekbaht) : fmNumber(react.monthbaht)) }}</b></h2>
            </div>
          </div>
          <div class="p-3 mt-4 card-body">
            <div class="row">
              <div class="col-7 text-start">
                <pie-chart id="chart-baht" :chart="{
                  labels: [plegde, interest, addcap, reducecap, redeem], datasets: {
                    label: 'Projects',
                    data: [
                      legendBaht[0].dt,
                      legendBaht[1].dt,
                      legendBaht[2].dt,
                      legendBaht[3].dt,
                      legendBaht[4].dt,
                    ],
                    backgroundColor: colors,
                  },
                }" />
              </div>
              <div class="my-auto col-5">
                <span v-for="(i, pt2) in legendBaht" :key="pt2" class="badge badge-md badge-dot me-4 d-block text-start">
                  <i :class="i.bg"></i>
                  <span class="text-md text-dark">{{ i.hd }} ({{ fmNumber(i.dt) }})</span>
                </span>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div class="col-12 mb-4">
        <div class="card h-100 mt-3">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="mb-0"><b>{{ $t('content.amncashflow') }}</b></h5>
            </div>
          </div>
          <div class="p-3 mt-2 card-body">
            <div id="chartStacked">
              <apexchart type="bar" height="350" :options="bars.chartOptions" :series="bars.series"></apexchart>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 mb-4">
        <div class="card h-100 mt-3">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="mb-0"><b>{{ $t('content.amndue') }}</b></h5>
            </div>
          </div>
          <div class="p-3 mt-2 card-body">
            <div v-if="selectInt == 'รายวัน'" class="row">
              <div v-for="(a, pt3) in dueDay" :key="pt3" class="col-12 text-start mb-3">
                <div class="d-flex justify-content-between align-items-center">
                  <p class="mb-1">{{ a.hd }}</p>
                  <div class="d-flex align-items-center">
                    <h4 class="mb-0"><b><span :class="a.tx">{{ fmNumber(a.vs) }}</span></b></h4>&nbsp;
                    <p class="text-sm mb-0">/{{ fmNumber(a.vm) }}</p>
                  </div>
                </div>

                <div class="progress dashboard">
                  <div class="progress-bar" :class="a.bg" role="progressbar"
                    :style="'width: ' + calPercent(a.vs, a.vm) + '%;'" :aria-valuenow="calPercent(a.vs, a.vm)"
                    aria-valuemin="0" aria-valuemax="100">
                    {{ Number(calPercent(a.vs, a.vm)).toLocaleString() }}%
                  </div>
                </div>
              </div>
            </div>

            <div v-else-if="selectInt == 'รายอาทิตย์'" class="row">
              <div v-for="(a, pt4) in dueWeek" :key="pt4" class="col-12 text-start mb-3">
                <div class="d-flex justify-content-between align-items-center">
                  <p class="mb-1">{{ a.hd }}</p>
                  <div class="d-flex align-items-center">
                    <h4 class="mb-0"><b><span :class="a.tx">{{ fmNumber(a.vs) }}</span></b></h4>&nbsp;
                    <p class="text-sm mb-0">/{{ fmNumber(a.vm) }}</p>
                  </div>
                </div>

                <div class="progress dashboard">
                  <div class="progress-bar" :class="a.bg" role="progressbar"
                    :style="'width: ' + calPercent(a.vs, a.vm) + '%;'" :aria-valuenow="calPercent(a.vs, a.vm)"
                    aria-valuemin="0" aria-valuemax="100">
                    {{ Number(calPercent(a.vs, a.vm)).toLocaleString() }}%
                  </div>
                </div>
              </div>
            </div>

            <div v-else-if="selectInt == 'รายเดือน'" class="row">
              <div v-for="(a, pt5) in dueMonth" :key="pt5" class="col-12 text-start mb-3">
                <div class="d-flex justify-content-between align-items-center mb-1">
                  <p class="mb-1">{{ a.hd }}</p>
                  <div class="d-flex align-items-center">
                    <h4 class="mb-0"><b><span :class="a.tx">{{ fmNumber(a.vs) }}</span></b></h4>&nbsp;
                    <p class="text-sm mb-0">/{{ fmNumber(a.vm) }}</p>
                  </div>
                </div>

                <div class="progress dashboard">
                  <div class="progress-bar" :class="a.bg" role="progressbar"
                    :style="'width: ' + calPercent(a.vs, a.vm) + '%;'" :aria-valuenow="calPercent(a.vs, a.vm)"
                    aria-valuemin="0" aria-valuemax="100">
                    {{ Number(calPercent(a.vs, a.vm)).toLocaleString() }}%
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-4" style="overflow-x: auto; padding-right: 1em;">
              <h5 class="">{{ $t('content.amnticketduelist') }}</h5>
              <div class="table-responsive">
                <table class="table">
                  <thead class="thead-light">
                    <tr>
                      <th>#</th>
                      <th>{{ $t('content.amnduedate') }}</th>
                      <th>{{ $t('content.names') }}</th>
                      <th>{{ $t('content.ticketno') }}</th>
                      <th>{{ $t('content.allweight') }}</th>
                      <th>{{ $t('content.allqty') }}</th>
                      <th>{{ $t('content.allprice') }}</th>
                      <th>{{ $t('content.amninterest') }}</th>
                      <th>{{ $t('content.cmobile') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(i, history) in datatable" :key="history">
                      <td class="text-sm font-weight-normal">{{ i.no }}</td>
                      <td class="text-sm font-weight-normal">{{ i.dat }}</td>
                      <td class="text-sm font-weight-normal">{{ i.nam }}</td>
                      <td class="text-sm font-weight-normal">{{ i.new }}</td>
                      <td class="text-sm font-weight-normal">{{ i.wei }}</td>
                      <td class="text-sm font-weight-normal">{{ i.amo }}</td>
                      <td class="text-sm font-weight-normal">{{ i.amo }}</td>
                      <td class="text-sm font-weight-normal">{{ i.int }}</td>
                      <td class="text-sm font-weight-normal">{{ i.mob }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-6 mb-4">
        <div class="card h-100 mt-3">
          <div class="p-3 pb-0 card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="mb-0"><b>{{ $t('content.gmoney') }}</b></h5>
            </div>
          </div>
          <div class="p-3 mt-2 card-body">

            <div class="row mb-5">
              <div class="col-12 ">

                <ul id="dashboardTab" class="nav nav-tabs" role="tablist">

                  <li class="nav-item" role="presentation">
                    <button id="first-dashboard" class="nav-link active" data-bs-toggle="tab"
                      data-bs-target="#firstDashboard" type="button" role="tab" aria-controls="firstDashboard"
                      aria-selected="true">
                      {{ $t('content.amncash') }}
                    </button>
                  </li>

                  <li class="nav-item" role="presentation">
                    <button id="second-dashboard" class="nav-link" data-bs-toggle="tab" data-bs-target="#secondDashboard"
                      type="button" role="tab" aria-controls="secondDashboard" aria-selected="false">
                      {{ $t('content.amnbankacc') }}
                    </button>
                  </li>
                </ul>

                <div id="dashboardTabContent" class="tab-content">

                  <div id="firstDashboard" class="tab-pane fade show active" role="tabpanel"
                    aria-labelledby="first-dashboard">

                    <div class="row mt-3">
                      <div v-for="(a, pt6) in drawers" :key="pt6" class="col-12 text-start mb-3">
                        <div class="d-flex justify-content-between align-items-center mb-1">
                          <div class="d-flex align-items-center">
                            <material-radio :id="a.id" v-model="selectDrawer" name="selectedDrawer"
                              class="radio-horizon ms-0 ps-0" :value="a.vl">
                              {{ a.hd }} {{ pt6 + 1 }}
                            </material-radio>
                          </div>
                          <div class="d-flex align-items-center">
                            <h4 class="mb-0"><b><span :class="a.tx">{{ fmNumber(a.vs) }}</span></b></h4>&nbsp;
                            <p class="text-sm mb-0">/{{ fmNumber(a.vm) }}</p>
                            <material-button color="primary" variant="outline"
                              class="btn btn-icon-only btn-rounded btn-outline-secondary ms-2 d-flex align-items-center justify-content-center"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('content.file')"
                              @click="onLookupDetail">
                              <i class="material-icons material-symbols-outlined">attach_file</i>
                            </material-button>
                          </div>

                        </div>

                        <div class="progress dashboard">
                          <div class="progress-bar" :class="a.bg" role="progressbar"
                            :style="'width: ' + calPercent(a.vs, a.vm) + '%;'" :aria-valuenow="calPercent(a.vs, a.vm)"
                            aria-valuemin="0" aria-valuemax="100">
                            {{ Number(calPercent(a.vs, a.vm)).toLocaleString() }}%
                          </div>
                        </div>
                      </div>

                    </div>

                    <div v-if="selectDrawer == 'drawer1' || selectDrawer == 'drawer2' || selectDrawer == 'drawer3'"
                      class="row mt-3 p-3">
                      <h5 class="mb-3"><u>{{ $t('content.amndrawer') }}</u></h5>
                      <div v-for="(a, pt8) in detailDrawer" :key="pt8" class="col-12 text-start mb-1">
                        <div class="d-flex justify-content-between align-items-center border-top">
                          <p class="mb-1">{{ a.hd }} ({{ a.cr }})</p>
                          <div class="d-flex align-items-center">
                            <h4 class="mb-0"><b><span :class="a.tx">{{ fmNumber(a.vs) }}</span></b></h4>&nbsp;
                            <p class="text-sm mb-0">/{{ fmNumber(a.vm) }}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-else-if="selectDrawer == 'safe1'" class="row mt-3 p-3">
                      <h5 class="mb-3"><u>{{ $t('content.amnsafe') }}</u></h5>
                      <div v-for="(a, pt9) in detailSafe" :key="pt9" class="col-12 text-start mb-1">
                        <div class="d-flex justify-content-between align-items-center border-top">
                          <p class="mb-1">{{ a.hd }} ({{ a.cr }})</p>
                          <div class="d-flex align-items-center">
                            <h4 class="mb-0"><b><span :class="a.tx">{{ fmNumber(a.vs) }}</span></b></h4>&nbsp;
                            <p class="text-sm mb-0">/{{ fmNumber(a.vm) }}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                  <div id="secondDashboard" class="tab-pane fade" role="tabpanel" aria-labelledby="second-dashboard">
                    <div class="row mt-3">
                      <div v-for="(a, pt7) in banks" :key="pt7" class="col-12 text-start mb-3">
                        <div class="d-flex justify-content-between align-items-center mb-1">
                          <div class="d-flex align-items-center">
                            <p class="mb-1">{{ a.hd }} {{ pt7 + 1 }}</p>
                          </div>
                          <div class="d-flex align-items-center">
                            <h4 class="mb-0"><b><span :class="a.tx">{{ fmNumber(a.vs) }}</span></b></h4>&nbsp;
                            <p class="text-sm mb-0">/{{ fmNumber(a.vm) }}</p>
                            <material-button color="primary" variant="outline"
                              class="btn btn-icon-only btn-rounded btn-outline-secondary ms-2 d-flex align-items-center justify-content-center"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('content.file')"
                              @click="onLookupDetail">
                              <i class="material-icons material-symbols-outlined">attach_file</i>
                            </material-button>
                          </div>
                        </div>

                        <div class="progress dashboard">
                          <div class="progress-bar" :class="a.bg" role="progressbar"
                            :style="'width: ' + calPercent(a.vs, a.vm) + '%;'" :aria-valuenow="calPercent(a.vs, a.vm)"
                            aria-valuemin="0" aria-valuemax="100">
                            {{ Number(calPercent(a.vs, a.vm)).toLocaleString() }}%
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
      
<script setup>
import { ref, reactive, computed, inject } from 'vue'
import { useI18n } from "vue-i18n"
import apexchart from "vue3-apexcharts";

import MaterialButton from "@/components/MaterialButton.vue"
import MaterialRadioGroup from "@/components/MaterialRadioGroup.vue"
import MaterialRadio from "@/components/MaterialRadio.vue"

import PieChart from "@/examples/Charts/PieChart.vue"


///// variables
const { t } = useI18n()
const swal = inject('$swal')

///// data
const selectInt = ref("รายวัน")
const selectDrawer = ref("drawer1")

const react = reactive({
  byday: 88,
  byweek: 888,
  bymonth: 8888,
  daydone: 102,
  weekdone: 432,
  monthdone: 1543,
  daybaht: 888888.88,
  weekbaht: 8888888.88,
  monthbaht: 88888888.88,
})

const colors = reactive([
  "#809bce",
  "#95b8d1",
  "#b8e0d2",
  "#d6eadf",
  "#eac4d5",
])

const legendTimes = reactive([
  {
    bg: "bg-pledge",
    hd: computed(() => t("content.actpawn1")),
    dt: 34,
  },
  {
    bg: "bg-interest",
    hd: computed(() => t("content.actpawn2")),
    dt: 34,
  },
  {
    bg: "bg-add-cap",
    hd: computed(() => t("content.actpawn3")),
    dt: 19,
  },
  {
    bg: "bg-reduce-cap",
    hd: computed(() => t("content.actpawn4")),
    dt: 8,
  },
  {
    bg: "bg-redeem",
    hd: computed(() => t("content.actpawn5")),
    dt: 7,
  },
])

const legendBaht = reactive([
  {
    bg: "bg-pledge",
    hd: computed(() => t("content.actpawn1")),
    dt: 177777.67,
  },
  {
    bg: "bg-interest",
    hd: computed(() => t("content.actpawn2")),
    dt: 177777.67,
  },
  {
    bg: "bg-add-cap",
    hd: computed(() => t("content.actpawn3")),
    dt: 177777.67,
  },
  {
    bg: "bg-reduce-cap",
    hd: computed(() => t("content.actpawn4")),
    dt: 177777.67,
  },
  {
    bg: "bg-redeem",
    hd: computed(() => t("content.actpawn5")),
    dt: 177777.67,
  },
])

const ylabels = reactive([
  // computed(() => t("content.amnincome")),
  // computed(() => t("content.amnoutcome")),
  // computed(() => t("content.amnprofit")),

  t("content.amnincome"),
  t("content.amnoutcome"),
  t("content.amnprofit"),
])

const barColors = reactive([
  "#809bce",
  "#b8e0d2",
  "#d6eadf",
  "#eac4d5",
  "#977ECD",
  "#D0AE95",
  "#B8CCE0",
])

const bars = reactive({
  series: [
    {
      name: computed(() => t("content.actpawn1")),
      data: [0, 26, 0],
    },
    {
      name: computed(() => t("content.actpawn3")),
      data: [0, 16, 0]
    },
    {
      name: computed(() => t("content.actpawn4")),
      data: [12, 0, 0]
    },
    {
      name: computed(() => t("content.actpawn5")),
      data: [9, 0, 0]
    },
    {
      name: computed(() => t("content.amndeposit")),
      data: [25, 0, 0]
    },
    {
      name: computed(() => t("content.amnwithdraw")),
      data: [0, 10, 0]
    },
    {
      name: computed(() => t("content.amninterest")),
      data: [25, 0, 19]
    },
  ],
  chartOptions: {
    chart: {
      type: 'bar',
      height: 200,
      stacked: true,
    },
    colors: barColors,
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 0,
            style: {
              fontSize: '14px',
              fontWeight: 900,
              colors: barColors,
            }
          }
        }
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    xaxis: {
      categories: ylabels,
      labels: {
        formatter: function (val) {
          return val + "m"
        }
      }
    },
    yaxis: {
      type: 'String',
      title: {
        text: undefined
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + "m"
        }
      }
    },
    fill: {
      opacity: 1,
      colors: barColors,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'center',
      offsetX: 40,
      fontSize: '14px',
    }
  },
})



const dueDay = reactive([
  {
    hd: computed(() => t("content.amnticketdueactived")),
    vs: 21,
    vm: 24,
    tx: 'text-primary',
    bg: 'bg-primary'
  },
  {
    hd: computed(() => t("content.amnticketdued")),
    vs: 3,
    vm: 24,
    tx: 'text-warning',
    bg: 'bg-warning'
  },
  {
    hd: computed(() => t("content.amnticketoveractived")),
    vs: 4,
    vm: 20,
    tx: 'text-danger',
    bg: 'bg-danger'
  },
  {
    hd: computed(() => t("content.amnticketoverd")),
    vs: 244,
    vm: 786,
    tx: 'text-secondary',
    bg: 'bg-secondary'
  },
])

const dueWeek = reactive([
  {
    hd: computed(() => t("content.amnticketdueactivew")),
    vs: 144,
    vm: 167,
    tx: 'text-primary',
    bg: 'bg-primary'
  },
  {
    hd: computed(() => t("content.amnticketduew")),
    vs: 23,
    vm: 167,
    tx: 'text-warning',
    bg: 'bg-warning'
  },
  {
    hd: computed(() => t("content.amnticketoveractivew")),
    vs: 42,
    vm: 45,
    tx: 'text-danger',
    bg: 'bg-danger'
  },
  {
    hd: computed(() => t("content.amnticketoverw")),
    vs: 244,
    vm: 786,
    tx: 'text-secondary',
    bg: 'bg-secondary'
  },
])

const dueMonth = reactive([
  {
    hd: computed(() => t("content.amnticketdueactivem")),
    vs: 807,
    vm: 851,
    tx: 'text-primary',
    bg: 'bg-primary'
  },
  {
    hd: computed(() => t("content.amnticketduem")),
    vs: 44,
    vm: 851,
    tx: 'text-warning',
    bg: 'bg-warning'
  },
  {
    hd: computed(() => t("content.amnticketoveractivem")),
    vs: 423,
    vm: 425,
    tx: 'text-danger',
    bg: 'bg-danger'
  },
  {
    hd: computed(() => t("content.amnticketoverm")),
    vs: 879,
    vm: 1456,
    tx: 'text-secondary',
    bg: 'bg-secondary'
  },
])

const datatable = reactive([
  {
    no: "1",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "bbbbb",
    biz: "จำนำ",
    ass: "ทองคำ",
    wei: "30.32",
    qty: "5",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ A",
    mob: "xxx-xxx-xxxx",
  },
  {
    no: "2",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "bbbbb",
    biz: "ต่อดอก",
    ass: "เพชร",
    wei: "12.34",
    qty: "2",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ B",
    mob: "xxx-xxx-xxxx",
  },
  {
    no: "3",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "bbbbb",
    biz: "จำนำ",
    ass: "อื่นๆ",
    wei: "30.32",
    qty: "5",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ B",
    mob: "xxx-xxx-xxxx",
  },
  {
    no: "4",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "bbbbb",
    biz: "ไถ่ถอน",
    ass: "ทองคำ",
    wei: "15.63",
    qty: "1",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ A",
    mob: "xxx-xxx-xxxx",
  },
])

const drawers = reactive([
  {
    hd: computed(() => t("content.amndrawer")),
    id: 'sDrawer1',
    vl: 'drawer1',
    vs: 500000.00,
    vm: 2000000.00,
    tx: 'text-primary',
    bg: 'bg-primary'
  },
  {
    hd: computed(() => t("content.amndrawer")),
    id: 'sDrawer2',
    vl: 'drawer2',
    vs: 700000.00,
    vm: 2000000.00,
    tx: 'text-primary',
    bg: 'bg-primary'
  },
  {
    hd: computed(() => t("content.amndrawer")),
    id: 'sDrawer3',
    vl: 'drawer3',
    vs: 1700000.00,
    vm: 2000000.00,
    tx: 'text-primary',
    bg: 'bg-primary'
  },
  {
    hd: computed(() => t("content.amnsafe")),
    id: 'sSafe1',
    vl: 'safe1',
    vs: 6000000.00,
    vm: 24000000.00,
    tx: 'text-info',
    bg: 'bg-info'
  },
])

const banks = reactive([
  {
    hd: computed(() => t("content.amnbankacc")),
    vs: 1500000.00,
    vm: 2000000.00,
    tx: 'text-warning',
    bg: 'bg-warning'
  },
  {
    hd: computed(() => t("content.amnbankacc")),
    vs: 1700000.00,
    vm: 2000000.00,
    tx: 'text-warning',
    bg: 'bg-warning'
  },
  {
    hd: computed(() => t("content.amnbankacc")),
    vs: 900000.00,
    vm: 2000000.00,
    tx: 'text-warning',
    bg: 'bg-warning'
  },
])

const detailDrawer = reactive([
  {
    hd: "1000",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: 'text-primary',
  },
  {
    hd: "500",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: 'text-primary',
  },
  {
    hd: "100",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: 'text-primary',
  },
  {
    hd: "50",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: 'text-primary',
  },
  {
    hd: "20",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: 'text-primary',
  },
  {
    hd: "10",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: 'text-primary',
  },
  {
    hd: "5",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: 'text-primary',
  },
  {
    hd: "1",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: 'text-primary',
  },
  {
    hd: computed(() => t("content.amntotaltransact")),
    cr: computed(() => t("content.amntotbaht")),
    vs: 1400000,
    vm: 2000000,
    tx: 'text-primary',
  },
])

const detailSafe = reactive([
  {
    hd: "1000",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: 'text-info',
  },
  {
    hd: "500",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: 'text-info',
  },
  {
    hd: "100",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: 'text-info',
  },
  {
    hd: "50",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: 'text-info',
  },
  {
    hd: "20",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: 'text-info',
  },
  {
    hd: "10",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: 'text-info',
  },
  {
    hd: "5",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: 'text-info',
  },
  {
    hd: "1",
    cr: computed(() => t("content.curency")),
    vs: 14,
    vm: 1000,
    tx: 'text-info',
  },
  {
    hd: computed(() => t("content.amntotaltransact")),
    cr: computed(() => t("content.amntotbaht")),
    vs: 1800000,
    vm: 2000000,
    tx: 'text-info',
  },
])

///// computed
const plegde = computed(() => t("content.actpawn1"))
const interest = computed(() => t("content.actpawn2"))
const addcap = computed(() => t("content.actpawn3"))
const reducecap = computed(() => t("content.actpawn4"))
const redeem = computed(() => t("content.actpawn5"))
// const input = computed(() => t("content.amnincome"))
// const output = computed(() => t("content.amnoutcome"))
// const profit = computed(() => t("content.amnprofit"))

///// methods
const calPercent = (val, full) => {
  return (parseInt(val) * 100) / parseInt(full)
}

const fmNumber = (val) => {
  return Intl.NumberFormat("en-US").format(val)
}

async function onLookupDetail() {
  swal({
    html:
      '<div class="preview-attached">' +
      '<section class="panel">' +
      '<img src="https://unsplash.it/400/200" alt="1" height="400" width="200">' +
      '<img src="https://unsplash.it/400/200" alt="2" height="400" width="200">' +
      '<img src="https://unsplash.it/400/200" alt="3" height="400" width="200">' +
      '<img src="https://unsplash.it/400/200" alt="4" height="400" width="200">' +
      '</section>' +
      '</div>' +
      '<div class="preview-detail">' +
      '<h6 class="mb">Notice:</h6>' +
      '<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content here, making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for lorem ipsum will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>' +
      '</div>',
    allowOutsideClick: true,
    showCloseButton: true,
    showCancelButton: false,
    showConfirmButton: false,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
  });
}

</script>
      
<style lang="scss" scoped></style>