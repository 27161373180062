<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12 ">

        <ul id="transactionsTab" class="nav nav-tabs" role="tablist">

          <li class="nav-item" role="presentation">
            <button id="first-transactions" class="nav-link active" data-bs-toggle="tab"
              data-bs-target="#firstTransactions" type="button" role="tab" aria-controls="firstTransactions"
              aria-selected="true">{{ $t('content.gmoneyhis')
              }}</button>
          </li>

          <li class="nav-item" role="presentation">
            <button id="second-transactions" class="nav-link" data-bs-toggle="tab" data-bs-target="#secondTransactions"
              type="button" role="tab" aria-controls="secondTransactions" aria-selected="false">{{ $t('content.gmoneybiz')
              }}</button>
          </li>
        </ul>

        <div id="transactionsTabContent" class="tab-content">

          <div id="firstTransactions" class="tab-pane fade show active" role="tabpanel"
            aria-labelledby="first-transactions">
            <div class="card mt-2">
              <div class="card-header mb-0">
                <h5>{{ $t('content.gmoneyhis') }}</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.sdate') }}</label>
                    <material-input id="startdate-fn" type="date" />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.edate') }}</label>
                    <material-input id="enddate-fn" type="date" />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.refno') }}</label>
                    <material-input type="text" />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.trans') }}</label>
                    <select class="form-select">
                      <option :value="$t('content.optall')">{{ $t('content.optall') }}</option>
                      <option v-for="(ax, optx1) in datas.demo1" :key="optx1" :value="ax.items">{{ ax.items }}</option>
                    </select>
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.type') }}</label>
                    <select class="form-select">
                      <option :value="$t('content.optall')">{{ $t('content.optall') }}</option>
                      <option v-for="(b, opt2) in datas.demo2" :key="opt2" :value="b.items">{{ b.items }}</option>
                    </select>
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0 png"></label>
                    <material-button id="cardSearch-fn" color="primary" class="float-lg-start float-sm-end mb-3">
                      <i class="material-icons material-symbols-outlined">search</i> ค้นหา
                    </material-button>
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>{{ $t('content.dant') }}</th>
                          <th>{{ $t('content.refno') }}</th>
                          <th>{{ $t('content.trans') }}</th>
                          <th>{{ $t('content.type') }}</th>
                          <th>{{ $t('content.detail') }}</th>
                          <th>{{ $t('content.amount') }}</th>
                          <th>{{ $t('content.file') }}</th>
                          <th>{{ $t('content.act') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-sm font-weight-normal">1</td>
                          <td class="text-sm font-weight-normal">xx/xx/xxxx xx:xx</td>
                          <td class="text-sm font-weight-normal">ref-1234567890</td>
                          <td class="text-sm font-weight-normal">bbbbbb</td>
                          <td class="text-sm font-weight-normal">deposit</td>
                          <td class="text-sm font-weight-normal">loremi psimaaaaaaaaaaaa</td>
                          <td class="text-sm font-weight-normal">88,888,888,88</td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('content.file')"
                              @click="onLookupDetail">
                              <i class="material-icons material-symbols-outlined">attach_file</i>
                            </material-button>
                          </td>
                          <td class="text-sm font-weight-normal"></td>
                        </tr>

                        <tr>
                          <td class="text-sm font-weight-normal">2</td>
                          <td class="text-sm font-weight-normal">xx/xx/xxxx xx:xx</td>
                          <td class="text-sm font-weight-normal">ref-1234567890</td>
                          <td class="text-sm font-weight-normal">bbbbbb</td>
                          <td class="text-sm font-weight-normal">deposit</td>
                          <td class="text-sm font-weight-normal">loremi psimaaaaaaaaaaaa</td>
                          <td class="text-sm font-weight-normal">88,888,888,88</td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('content.file')"
                              @click="onLookupDetail">
                              <i class="material-icons material-symbols-outlined">attach_file</i>
                            </material-button>
                          </td>
                          <td class="text-sm font-weight-normal"></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="secondTransactions" class="tab-pane fade" role="tabpanel" aria-labelledby="second-transactions">
            <div class="card mt-2">
              <div class="card-header mb-0">
                <h5>{{ $t('content.gmoneybiz') }}</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t('content.trans') }}</label>
                    <select class="form-select">
                      <option value="">{{ $t('content.select') }}</option>
                      <option v-for="(a, opt1) in datas.demo1" :key="opt1" :value="a.items">{{ a.items }}</option>
                    </select>
                  </div>
                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.type') }}</label>
                    <select class="form-select">
                      <option value="">{{ $t('content.select') }}</option>
                      <option v-for="(b, opt2) in datas.demo2" :key="opt2" :value="b.items">{{ b.items }}</option>
                    </select>
                  </div>
                  <div class="col-lg-6">
                    <label class="form-label ms-0">{{ $t('content.amount') }}</label>
                    <material-input type="text" />
                  </div>
                  <div class="col-lg-6">
                    <label class="form-label ms-0">{{ $t('content.detail') }}</label>
                    <material-input type="text" />
                  </div>

                  <div class="col-lg-6">
                    <label for="uploadFile" class="form-label ms-0">{{ $t('content.file') }}</label>
                    <div class="input-group custom-file-button">
                      <label for="uploadFile" class="input-group-text mb-0">{{ $t('buttons.file')
                      }}</label>
                      <input id="uploadFile" type="file" class="form-control mb-0" multiple>
                    </div>
                  </div>
                </div>
                <hr>
                <div class="row no-gutters">
                  <div class="col-12 d-flex justify-content-between align-items-center">
                    <label class="form-label m-0">{{ $t('content.glisttrans') }}</label>
                    <div>
                      <material-button color="primary" class="float-lg-start float-sm-end mb-0" @click="addNewItems">
                        <i class="material-icons material-symbols-outlined">add</i> {{ btnAddNew }}
                      </material-button>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th style="width: 70px;">#</th>
                          <th>{{ $t('content.gmoneytype') }}</th>
                          <th>{{ $t('content.mbankname') }}</th>
                          <th>{{ $t('content.refno') }}</th>
                          <th>{{ $t('content.amount') }}</th>
                          <th style="width: 100px;">{{ $t('content.act') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- <tr class="group-id-ticket">
                          <td class="text-sm font-weight-normal">
                            <div class="group-tickect">
                              <h6 class="mb-0"><b>{{ btnSearch }}</b></h6>
                            </div>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <div class="input-group">
                              <input v-model="datas.coreBox1" type="text" class="form-control">
                              <button class="btn btn-outline-primary dropdown-toggle" type="button"
                                data-bs-toggle="dropdown" aria-expanded="false">
                              </button>
                              <ul class="dropdown-menu dropdown-menu-end">
                                <li>
                                  <div class="input-group">
                                    <input type="text" class="form-control mb-0" placeholder="ค้นหา" />
                                  </div>
                                </li>
                                <li>
                                  <hr class="dropdown-divider">
                                </li>
                                <li v-for="(a, corea) in datas.demo3" :key="corea"><a class="dropdown-item"
                                    href="#"><material-checkbox class="check-inline" checked="false" /> {{
                                      a.items }}</a></li>
                              </ul>
                            </div>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch">
                              <i class="material-icons material-symbols-outlined">search</i>
                            </material-button>
                            <material-button v-if="datas.addNew" color="info" variant="contain" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="คัดลอกข้อมูล"
                              :disabled="checkBeforeCopy(datas.coreCopy)">
                              <i class="material-icons material-symbols-outlined">content_copy</i>
                            </material-button>
                          </td>
                        </tr> -->

                        <tr v-if="datas.addNew">
                          <td class="text-sm font-weight-normal">#</td>
                          <td class="text-sm font-weight-normal">
                            <select class="form-select">
                              <option v-for="(i, opt3) in datas.demo3" :key="opt3" :value="i.items">
                                {{ i.items }}
                              </option>
                            </select>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <select class="form-select">
                              <option v-for="(j, opt4) in datas.demo4" :key="opt4" :value="j.items">
                                {{ j.items }}
                              </option>
                            </select>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-input type="text" class="mb-0" value="" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-input type="text" class="mb-0" value="" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave" @click="saveNewItems">
                              <i class="material-icons material-symbols-outlined">save</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                              @click="cancelSaveNewItems">
                              <i class="material-icons material-symbols-outlined">close</i>
                            </material-button>
                          </td>
                        </tr>

                        <tr v-if="datas.rowEdit">
                          <td class="text-sm font-weight-normal">1</td>
                          <td class="text-sm font-weight-normal">
                            <select class="form-select">
                              <option v-for="(i, opt3) in datas.demo3" :key="opt3" :value="i.items">
                                {{ i.items }}
                              </option>
                            </select>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <select class="form-select">
                              <option v-for="(j, opt4) in datas.demo4" :key="opt4" :value="j.items">
                                {{ j.items }}
                              </option>
                            </select>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-input type="text" class="mb-0" value="" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-input type="text" class="mb-0" value="" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave" @click="saveEditRowItems">
                              <i class="material-icons material-symbols-outlined">save</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                              @click="cancelEditRowItems">
                              <i class="material-icons material-symbols-outlined">close</i>
                            </material-button>
                          </td>
                        </tr>
                        <tr v-else-if="!datas.rowEdit">
                          <td class="text-sm font-weight-normal">1</td>
                          <td class="text-sm font-weight-normal">xxxxx</td>
                          <td class="text-sm font-weight-normal">xxxxx</td>
                          <td class="text-sm font-weight-normal">xxxxx</td>
                          <td class="text-sm font-weight-normal">xxxxx</td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="info" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnEdit" @click="editRowItems">
                              <i class="material-icons material-symbols-outlined">edit</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel">
                              <i class="material-icons material-symbols-outlined">delete</i>
                            </material-button>
                          </td>
                        </tr>

                        <tr>
                          <td class="text-sm font-weight-normal">2</td>
                          <td class="text-sm font-weight-normal">xxxxx</td>
                          <td class="text-sm font-weight-normal">xxxxx</td>
                          <td class="text-sm font-weight-normal">xxxxx</td>
                          <td class="text-sm font-weight-normal">xxxxx</td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="info" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnEdit">
                              <i class="material-icons material-symbols-outlined">edit</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel">
                              <i class="material-icons material-symbols-outlined">delete</i>
                            </material-button>
                          </td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
                  <nav aria-label="Table Paginator">
                    <ul class="pagination justify-content-end">
                      <li class="page-item disabled">
                        <a class="page-link" href="#" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                      <li class="page-item"><a class="page-link" href="#">1</a></li>
                      <li class="page-item"><a class="page-link" href="#">2</a></li>
                      <li class="page-item"><a class="page-link" href="#">3</a></li>
                      <li class="page-item">
                        <a class="page-link" href="#" aria-label="Next">
                          <span aria-hidden="true">&raquo;</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div class="row no-gutters mt-3">
                  <div class="col-12 d-flex justify-content-end">
                    <div>
                      <material-button color="primary" class="float-lg-start float-sm-end mb-3" @click="confirmNewRecord">
                        <i class="material-icons material-symbols-outlined">save</i> {{ btnSave }}
                      </material-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script setup>
import { reactive, inject, computed } from 'vue'
import { useI18n } from "vue-i18n"

import MaterialButton from "@/components/MaterialButton.vue"
import MaterialInput from "@/components/MaterialInput.vue"
// import MaterialCheckbox from "@/components/MaterialCheckbox.vue"
// import MaterialRadio from "@/components/MaterialRadio.vue"

// import QrCode from "@/assets/img/pawn/qr_sample.png"

const { t } = useI18n()
const swal = inject('$swal')

const datas = reactive({
  addNew: false,
  rowEdit: false,
  coreCopy: true,
  coreBox1: "",
  demo1: [
    { items: "ปิดยอดเงินสด" },
    { items: "ปิดยอดบัญชีธนาคาร" },
    { items: "ธุรกรรมเงินสด" },
    { items: "ธุรกรรมบัญชีธนาคาร" }
  ],
  demo2: [
    { items: "ฝาก" },
    { items: "ถอน" },
  ],
  demo3: [
    { items: "เงินสด" },
    { items: "เงินโอน" },
  ],
  demo4: [
    { items: "Bank1" },
    { items: "Bank2" },
  ],
})

const btnAddNew = computed(() => t("buttons.impo"))
const btnEdit = computed(() => t("buttons.edit"))
// const btnSearch = computed(() => t("buttons.sear"))
const btnSave = computed(() => t("buttons.save"))
const btnDel = computed(() => t("buttons.remo"))
const btnClose = computed(() => t("buttons.clos"))

async function onLookupDetail() {
  swal({
    html:
      '<div class="preview-attached">' +
      '<section class="panel">' +
      '<img src="https://unsplash.it/400/200" alt="1" height="400" width="200">' +
      '<img src="https://unsplash.it/400/200" alt="2" height="400" width="200">' +
      '<img src="https://unsplash.it/400/200" alt="3" height="400" width="200">' +
      '<img src="https://unsplash.it/400/200" alt="4" height="400" width="200">' +
      '</section>' +
      // '</div>' +
      // '<div class="preview-detail">' +
      // '<h6 class="mb">Notice:</h6>' +
      // '<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content here, making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for lorem ipsum will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>' +
      '</div>',
    allowOutsideClick: true,
    showCloseButton: true,
    showCancelButton: false,
    showConfirmButton: false,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
  });
}

// const checkBeforeCopy = (bool) => {
//   datas.coreCopy = bool
//   if (datas.coreBox1 === '') {
//     return bool = true
//   } else {
//     return bool = false
//   }
// }

async function addNewItems() {
  swal({
    title: "เพิ่มรายการ",
    text: "คุณต้องการเพิ่มรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      datas.addNew = true
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกรายการ", "", "error")
      datas.addNew = false
    }
  })
}

async function saveNewItems() {
  swal({
    title: "ต้องการบันทึกรายการนี้",
    text: "คุณต้องการบันทึกรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.save'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      swal("บันทึกสำเร็จ", "", "success")
      datas.addNew = false
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกการบันทึก", "", "error")
      datas.addNew = false
    }
  })
}

async function cancelSaveNewItems() {
  swal({
    title: "ยกเลิกบันทึกรายการนี้",
    text: "คุณต้องการยกเลิกบันทึกรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      datas.addNew = false
    } else if (result.dismiss == 'cancel') {
      datas.addNew = true
    }
  })
}

async function editRowItems() {
  swal({
    title: "แก้ไขรายการ",
    text: "คุณต้องการแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      datas.rowEdit = true
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกรายการ", "", "error")
      datas.rowEdit = false
    }
  })
}

async function saveEditRowItems() {
  swal({
    title: "บันทึกแก้ไขรายการ",
    text: "คุณต้องการบันทึกแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.save'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      swal("บันทึกสำเร็จ", "", "success")
      datas.rowEdit = false
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกแก้ไข", "", "error")
      datas.rowEdit = false
    }
  })
}

async function cancelEditRowItems() {
  swal({
    title: "ยกเลิกแก้ไขรายการ",
    text: "คุณต้องการยกเลิกแก้ไขรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      datas.rowEdit = false
    } else if (result.dismiss == 'cancel') {
      datas.rowEdit = true
    }
  })
}

async function confirmNewRecord() {
  swal({
    title: "บันทึกธุรกรรม",
    text: "คุณต้องการบันทึกธุรกรรมนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  })
}

</script>
  
<style lang="scss" scoped></style>