<!-- eslint-disable no-unused-vars -->
<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12 ">
        <ul id="masterInfoAssets" class="nav nav-tabs" role="tablist">

          <li class="nav-item" role="presentation">
            <button id="wait-master-info-assets" class="nav-link active" data-bs-toggle="tab"
              data-bs-target="#waitMasterInfoAssets" type="button" role="tab" aria-controls="waitMasterInfoAssets"
              aria-selected="true" @click="onClickTab('1')">{{
                $t("content.doctype") }}</button>
          </li>

          <li class="nav-item" role="presentation">
            <button id="show-master-info-assets" class="nav-link" data-bs-toggle="tab"
              data-bs-target="#showMasterInfoAssets" type="button" role="tab" aria-controls="showMasterInfoAssets"
              aria-selected="false" @click="onClickTab('2')">{{
                $t("content.docgroup")
              }}</button>
          </li>
        </ul>

        <div id="masterInfoAssetsContent" class="tab-content">

          <div id="waitMasterInfoAssets" class="tab-pane fade show active" role="tabpanel"
            aria-labelledby="wait-master-info-assets">

            <div class="card h-100 mt-2">
              <div class="card-header">
                <div class="row no-gutters">
                  <div class="col-12 d-flex justify-content-between align-items-center">
                    <h5>{{ $t("content.mtypedoc") }}</h5>
                    <div>
                      <material-button color="primary" class="float-lg-start float-sm-end mb-0" @click="addNewItems">
                        <i class="material-icons material-symbols-outlined">add</i>
                        {{ btnAddNew }}
                      </material-button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body pt-0">
                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th style="width: 70px">#</th>
                          <th>{{ $t("content.mtypedoc") }}</th>
                          <th>{{ $t("content.docgroup") }}</th>
                          <th style="width: 100px">{{ $t("content.act") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="group-id-ticket">
                          <td class="text-sm font-weight-normal">
                            <div class="group-tickect">
                              <h6 class="mb-0">
                                <b>{{ btnSearch }}</b>
                              </h6>
                            </div>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-input v-model="filterText" type="search" class="mb-0"
                              :label="$t('content.mtypedoc')" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <select v-model="filterdg" class="form-select">
                              <option value="0">
                                {{ $t("content.optall") }}</option>
                              <option v-for="(item, index) in docgroupdb" :value="item.uid" :key="index">
                                {{ item.name }}
                              </option>
                            </select>
                          </td>

                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch" @click="loadDataApi">
                              <i class="material-icons material-symbols-outlined">search</i>
                            </material-button>
                          </td>
                        </tr>
                        <tr v-if="addNew">
                          <td class="text-sm font-weight-normal">#</td>
                          <td class="text-sm font-weight-normal">
                            <material-input v-model="namedoc" type="text" class="mb-0" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <select v-model="docgroup" class="form-select">
                              <option value="0" disabled selected>
                                {{ $t("content.select") }}</option>
                              <option v-for="(item, index) in docgroupdb" :value="item.uid" :key="index">
                                {{ item.name }}
                              </option>
                            </select>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave"
                              :disabled="namedoc.trim() === '' || docgroup === '0'" @click="saveNewItems">
                              <i class="material-icons material-symbols-outlined">save</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                              @click="cancelSaveNewItems">
                              <i class="material-icons material-symbols-outlined">close</i>
                            </material-button>
                          </td>
                        </tr>

                        <tr v-for="(row, index) in list" :key="index">
                          <td class="text-sm font-weight-normal">
                            {{ formatNumberString((currentPage - 1) * perPage + index + 1) }}
                          </td>
                          <td v-if="row.uid === uid" class="text-sm font-weight-normal">
                            <material-input v-model="namedoc" type="text" class="mb-0" />
                          </td>
                          <td v-if="row.uid === uid" class="text-sm font-weight-normal">
                            <select v-model="docgroup" class="form-select">
                              <option value="0" disabled selected>
                                {{ $t("content.select") }}</option>
                              <option v-for="(item, index) in docgroupdb" :value="item.uid" :key="index">
                                {{ item.name }}
                              </option>
                            </select>
                          </td>
                          <td v-if="row.uid === uid" class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave"
                              :disabled="namedoc.trim() === '' || docgroup === '0'" @click="saveEditRowItems">
                              <i class="material-icons material-symbols-outlined">save</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                              @click="cancelEditRowItems">
                              <i class="material-icons material-symbols-outlined">close</i>
                            </material-button>
                          </td>
                          <td v-if="row.uid !== uid" class="text-sm font-weight-normal">
                            {{ row.name }}
                          </td>
                          <td v-if="row.uid !== uid" class="text-sm font-weight-normal">
                            <span v-if="row.docGroupUid !== ''">
                              {{ checkDocGroup(row.docGroupUid) }}
                            </span>
                          </td>
                          <td v-if="row.uid !== uid" class="text-sm font-weight-normal">
                            <material-button color="info" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnEdit"
                              @click="editRowItems(row)">
                              <i class="material-icons material-symbols-outlined">edit</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel"
                              @click="deleteRowItems(row)">
                              <i class="material-icons material-symbols-outlined">delete</i>
                            </material-button>
                          </td>
                        </tr>

                        <tr v-if="list.length === 0">
                          <td></td>
                          <td class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="4" style="text-align: right">Total Rows : {{
                            totalRows_s }}</td>
                        </tr>
                      </tfoot>
                    </table>

                    <ul v-show="list.length > 0" class="pagination justify-content-end">

                      <div class="project-container">
                        <div class="example-one">
                          <vue-awesome-paginate v-model="currentPage" :total-items="totalRows" :items-per-page="perPage"
                            @click="onClickHandler" />
                        </div>
                      </div>
                    </ul>
                  </div>

                </div>
              </div>
            </div>
          </div>

          <div id="showMasterInfoAssets" class="tab-pane fade" role="tabpanel"
            aria-labelledby="show-master-info-assets">

            <div class="card h-100 mt-2">
              <div class="card-header">
                <div class="row no-gutters">
                  <div class="col-12 d-flex justify-content-between align-items-center">
                    <h5>{{ $t("content.docgroup") }}</h5>
                    <div>
                      <material-button id="saveTicket" color="primary" class="float-lg-start float-sm-end mb-0"
                        @click="addNewItemsDG">
                        <i class="material-icons material-symbols-outlined">add</i> เพิ่มเข้า
                      </material-button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body pt-0">
                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th style="width: 70px">#</th>
                          <th>{{ $t("content.docgroup") }}</th>
                          <th>{{ $t("content.doccode") }}</th>
                          <th style="width: 100px">{{ $t("content.act") }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="group-id-ticket">
                          <td class="text-sm font-weight-normal">
                            <div class="group-tickect">
                              <h6 class="mb-0">
                                <b>{{ btnSearch }}</b>
                              </h6>
                            </div>
                          </td>
                          <td class="text-sm font-weight-normal" colspan="2">
                            <material-input v-model="filterDocGroup" type="search" class="mb-0"
                              :label="$t('content.docgroup')" />
                          </td>

                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch"
                              @click="loadDocGroupApi">
                              <i class="material-icons material-symbols-outlined">search</i>
                            </material-button>
                          </td>
                        </tr>

                        <tr v-if="addNewDG">
                          <td class="text-sm font-weight-normal">#</td>
                          <td class="text-sm font-weight-normal">
                            <material-input v-model="namedocgroup" type="text" class="mb-0" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-input v-model="newCode" type="text" class="mb-0" value="" maxlength="3"
                              @input="onlyDigits" />
                          </td>

                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave"
                              :disabled="namedocgroup.trim() === '' || newCode === ''" @click="saveNewItemsDG">
                              <i class="material-icons material-symbols-outlined">save</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                              @click="cancelSaveNewItemsDG">
                              <i class="material-icons material-symbols-outlined">close</i>
                            </material-button>
                          </td>
                        </tr>

                        <tr v-for="(row, index) in listdocgroup" :key="index">
                          <td class="text-sm font-weight-normal">
                            {{ formatNumberString((currentPageDG - 1) * perPageDG + index + 1) }}
                          </td>

                          <td v-if="row.uid === uidDG" class="text-sm font-weight-normal">
                            <material-input v-model="namedocgroup" type="text" class="mb-0" />
                          </td>
                          <td v-if="row.uid === uidDG" class="text-sm font-weight-normal">
                            <material-input v-model="newCode" type="text" class="mb-0" value="" maxlength="3"
                              @input="onlyDigits" />
                          </td>
                          <td v-if="row.uid === uidDG" class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave"
                              :disabled="namedocgroup.trim() === '' || newCode === ''" @click="saveEditRowItemsDG">
                              <i class="material-icons material-symbols-outlined">save</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                              @click="cancelEditRowItemsDG">
                              <i class="material-icons material-symbols-outlined">close</i>
                            </material-button>
                          </td>

                          <td v-if="row.uid !== uidDG" class="text-sm font-weight-normal">
                            {{ row.name }}
                          </td>
                          <td v-if="row.uid !== uidDG" class="text-sm font-weight-normal">
                            {{ row.code }}
                          </td>
                          <td v-if="row.uid !== uidDG" class="text-sm font-weight-normal">
                            <material-button color="info" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnEdit"
                              @click="editRowItemsDG(row)">
                              <i class="material-icons material-symbols-outlined">edit</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel"
                              @click="deleteRowItemsDG(row)">
                              <i class="material-icons material-symbols-outlined">delete</i>
                            </material-button>
                          </td>
                        </tr>

                        <tr v-if="listdocgroup.length === 0">
                          <td></td>
                          <td class="text-sm font-weight-normal">
                            <p style="text-align: center">No Record Found</p>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="4" style="text-align: right">Total Rows : {{
                            totalRows_sDG }}</td>
                        </tr>
                      </tfoot>
                    </table>

                    <ul v-show="listdocgroup.length > 0" class="pagination justify-content-end">

                      <div class="project-container">
                        <div class="example-one">
                          <vue-awesome-paginate v-model="currentPageDG" :total-items="totalRowsDG"
                            :items-per-page="perPageDG" @click="onClickHandlerDG" />
                        </div>
                      </div>
                    </ul>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import { reactive, inject, computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import { useDocTypeStore } from '@/stores/doctype'
import { useDocGroupStore } from '@/stores/docgroup'


const stored = useDocTypeStore()
const storedg = useDocGroupStore()
const { t } = useI18n();
const swal = inject("$swal");

const btnAddNew = computed(() => t("buttons.impo"));
const btnEdit = computed(() => t("buttons.edit"));
const btnSearch = computed(() => t("buttons.sear"));
const btnSave = computed(() => t("buttons.save"));
const btnDel = computed(() => t("buttons.remo"));
const btnClose = computed(() => t("buttons.clos"));

const filterText = ref("");
const filterdg = ref("0");
const list = ref([]);
const addNew = ref(false);
const rowEdit = ref(false);
const uid = ref("");
const namedoc = ref("");
const docgroup = ref("0");
const version = ref("");

const docgroupdb = ref([]);
const listdocgroup = ref([]);
const filterDocGroup = ref("");
const addNewDG = ref(false);
const rowEditDG = ref(false);
const uidDG = ref("");
const namedocgroup = ref("");
const newCode = ref("");
const versionDG = ref("");

const onClickHandler = (page) => {
  updatePagination()
};

const onClickHandlerDG = (page) => {
  updatePaginationDG()
};

const currentPage = ref(1);
const totalRows = ref(1);
const perPage = ref(10);
const totalRows_s = ref();

const currentPageDG = ref(1);
const totalRowsDG = ref(1);
const perPageDG = ref(10);
const totalRows_sDG = ref();

const loadDataApi = async (val) => {
  clearItems();
  if (currentPage.value !== 1) {
    if (list.value.length === 1 && val === 'del') {
      currentPage.value = currentPage.value - 1
    }
  }

  let data = "";
  let dg = ""

  if (filterdg.value !== '0') {
    dg = filterdg.value
  }

  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    name: filterText.value,
    docGroupUid: dg
  };
  // console.log("filterText : " + filterText.value);
  const payload = data;
  // const response = await store.dispatch("docTypeListFetch", payload);
  const response = await stored.docTypeListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response) {
      if (response.status === 200) {
        if (response.data.rtnDesc === "success") {
          list.value = response.data.data.list;
          totalRows.value = response.data.data.ext.total
          totalRows_s.value = formatNumberString(totalRows.value)
          // console.log('list : '+list.value.length)
        }
      }
    }
  }
};


loadDataApi();

const loadDocGroupData = async (val) => {

  let data = "";
  data = {
    index: 0,
    size: 100,
    name: '',
  };

  const payload = data;
  const response = await storedg.docGroupListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));
  if (response) {
    if (response) {
      if (response.status === 200) {
        if (response.data.rtnDesc === "success") {
          docgroupdb.value = response.data.data.list;
        }
      }
    }
  }
};

loadDocGroupData()


const loadDocGroupApi = async (val) => {
  clearItemsDG();
  if (currentPageDG.value !== 1) {
    if (listdocgroup.value.length === 1 && val === 'del') {
      currentPageDG.value = currentPageDG.value - 1
    }
  }

  let data = "";
  data = {
    index: currentPageDG.value - 1,
    size: perPageDG.value,
    name: filterDocGroup.value,
  };

  const payload = data;
  const response = await storedg.docGroupListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response.data));
  if (response) {
    if (response) {
      if (response.status === 200) {
        if (response.data.rtnDesc === "success") {
          listdocgroup.value = response.data.data.list;
          totalRowsDG.value = response.data.data.ext.total
          totalRows_sDG.value = formatNumberString(totalRowsDG.value)
          // console.log('list : '+list.value.length)
        }
      }
    }
  }
};

async function onClickTab(val) {
  if (val === '1') {
    loadDataApi();
    loadDocGroupData()
  } else if (val === '2') {
    loadDocGroupApi();
  }
}

function formatNumberString(num) {
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}

function checkDocGroup(str) {
  let side = ''
  if (str !== '0') {
    if (docgroupdb.value.length > 0) {
      let ld = filterDG(str)
      if (ld.length > 0) {
        side = ld[0].name
      }
    }
  }
  return side
}

function filterDG(str) {
  return docgroupdb.value.filter((c) => c.uid === str);
}

async function onlyDigits() {

  if (newCode.value !== "") {
    if (newCode.value.match(/^[0-9]+$/)) {
      newCode.value = newCode.value.match(/^[0-9]+$/);

    } else {
      newCode.value = newCode.value.slice(0, -1);
    }
  } else {
    newCode.value = "";
  }
}


async function updatePagination() {
  let data = "";
  let dg = "";
  if (filterdg.value !== '0') {
    dg = filterdg.value
  }

  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    name: filterText.value,
    docGroupUid: dg
  };
  // console.log("filterText : " + filterText.value);
  const payload = data;
  console.log("updatePagination : " + JSON.stringify(payload));
  const response = await stored.docTypeListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));

  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        list.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
        // console.log('list : '+list.value.length)
      }
    }
  }

}

async function updatePaginationDG() {
  let data = "";
  data = {
    index: currentPageDG.value - 1,
    size: perPageDG.value,
    name: filterDocGroup.value,
  };
  // console.log("filterText : " + filterText.value);
  const payload = data;
  const response = await storedg.docGroupListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));

  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        listdocgroup.value = response.data.data.list;
        totalRowsDG.value = response.data.data.ext.total
        totalRows_sDG.value = formatNumberString(totalRows.value)
        // console.log('list : '+list.value.length)
      }
    }
  }

}

async function addNewItems() {
  swal({
    title: "เพิ่มรายการ",
    text: "คุณต้องการเพิ่มรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItems();
      addNew.value = true;
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกรายการ", "", "error");
      clearItems();
    }
  });
}

async function addNewItemsDG() {
  swal({
    title: "เพิ่มรายการ",
    text: "คุณต้องการเพิ่มรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItemsDG();
      addNewDG.value = true;
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกรายการ", "", "error");
      clearItemsDG();
    }
  });
}

async function saveNewItems() {
  swal({
    title: "ต้องการบันทึกรายการนี้",
    text: "คุณต้องการบันทึกรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.save"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      // swal("บันทึกสำเร็จ", "", "success");
      addNew.value = false;
      adddocType();
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกการบันทึก", "", "error");
      clearItems();
    }
  });
}

async function saveNewItemsDG() {
  swal({
    title: "ต้องการบันทึกรายการนี้",
    text: "คุณต้องการบันทึกรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.save"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      addNewDG.value = false;
      adddocGroup()
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกการบันทึก", "", "error");
      clearItemsDG();
    }
  });
}

async function cancelSaveNewItems() {
  swal({
    title: "ยกเลิกบันทึกรายการนี้",
    text: "คุณต้องการยกเลิกบันทึกรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItems();
    } else if (result.dismiss == "cancel") {
      // datas.addNew = true;
      addNew.value = true;
    }
  });
}


async function cancelSaveNewItemsDG() {
  swal({
    title: "ยกเลิกบันทึกรายการนี้",
    text: "คุณต้องการยกเลิกบันทึกรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItemsDG();
    } else if (result.dismiss == "cancel") {
      addNewDG.value = true;
    }
  });
}

async function editRowItems(item) {
  swal({
    title: "แก้ไขรายการ",
    text: "คุณต้องการแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItems();
      rowEdit.value = true;
      uid.value = item.uid;
      namedoc.value = item.name;
      version.value = item.version;
      if (item.docGroupUid !== '') {
        docgroup.value = item.docGroupUid
      }
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกรายการ", "", "error");
      // datas.rowEdit = false;
      clearItems();
    }
  });
}


async function editRowItemsDG(item) {
  swal({
    title: "แก้ไขรายการ",
    text: "คุณต้องการแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItemsDG();
      rowEditDG.value = true;
      uidDG.value = item.uid;
      namedocgroup.value = item.name;
      versionDG.value = item.version;
      newCode.value = item.code
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกรายการ", "", "error");
      clearItemsDG();
    }
  });
}

async function saveEditRowItems() {
  swal({
    title: "บันทึกแก้ไขรายการ",
    text: "คุณต้องการบันทึกแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.save"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      // swal("บันทึกสำเร็จ", "", "success");
      rowEdit.value = false;
      if (uid.value !== "" && version.value !== "") {
        updatedocType();
      }
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกแก้ไข", "", "error");
      clearItems();
    }
  });
}

async function saveEditRowItemsDG() {
  swal({
    title: "บันทึกแก้ไขรายการ",
    text: "คุณต้องการบันทึกแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.save"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      // swal("บันทึกสำเร็จ", "", "success");
      rowEditDG.value = false;
      if (uidDG.value !== "" && versionDG.value !== "") {
        updatedocGroup()
      }
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกแก้ไข", "", "error");
      clearItemsDG();
    }
  });
}

async function cancelEditRowItems() {
  swal({
    title: "ยกเลิกแก้ไขรายการ",
    text: "คุณต้องการยกเลิกแก้ไขรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItems();
    } else if (result.dismiss == "cancel") {
      rowEdit.value = true;
    }
  });
}

async function cancelEditRowItemsDG() {
  swal({
    title: "ยกเลิกแก้ไขรายการ",
    text: "คุณต้องการยกเลิกแก้ไขรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItemsDG();
    } else if (result.dismiss == "cancel") {
      rowEditDG.value = true;
    }
  });
}

async function deleteRowItems(item) {
  swal({
    title: "ลบรายการ",
    text: "คุณต้องการลบรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {

      if (item.uid !== "") {
        deletedocType(item.uid);
      }
    }
  });
}

async function deleteRowItemsDG(item) {
  swal({
    title: "ลบรายการ",
    text: "คุณต้องการลบรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      if (item.uid !== "") {
        deletedocGroup(item.uid);
      }
    }
  });
}

async function adddocType() {
  let data = "";
  data = {
    docGroupUid: docgroup.value,
    name: namedoc.value,
  };
  const payload = data;
  const response = await stored.docTypeAddFetch(payload);
  console.log("adddocType : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi('add');
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function adddocGroup() {
  let data = "";
  data = {
    code: String(newCode.value),
    name: namedocgroup.value,
  };
  const payload = data;
  const response = await storedg.docGroupAddFetch(payload);
  // console.log("adddocType : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDocGroupApi('add');
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function updatedocType() {
  let data = "";
  data = {
    uid: uid.value,
    docGroupUid: docgroup.value,
    name: namedoc.value,
    version: version.value,
  };
  const payload = data;
  // console.log("updatedocType p : " + JSON.stringify(payload));
  const response = await stored.docTypeUpdateFetch(payload);
  console.log('updatedocType r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi('update');
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function updatedocGroup() {
  let data = "";
  data = {
    uid: uidDG.value,
    code: String(newCode.value),
    name: namedocgroup.value,
    version: versionDG.value,
  };
  const payload = data;
  const response = await storedg.docGroupUpdateFetch(payload);
  // console.log('updatedocGroup : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDocGroupApi('update');
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function deletedocType(id) {
  let data = "";
  data = {
    uid: id,
  };
  const payload = data;
  const response = await stored.docTypeDeleteFetch(payload);
  // console.log("deletedocType : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi('del');
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function deletedocGroup(id) {
  let data = "";
  data = {
    uid: id,
  };
  const payload = data;
  const response = await storedg.docGroupDeleteFetch(payload);
  // console.log("deletedocType : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDocGroupApi('del');
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function clearItems() {
  addNew.value = false;
  rowEdit.value = false;
  uid.value = "";
  namedoc.value = "";
  version.value = "";
  docgroup.value = "0"
}

async function clearItemsDG() {
  addNewDG.value = false;
  rowEditDG.value = false;
  uidDG.value = "";
  newCode.value = "";
  versionDG.value = "";
  namedocgroup.value = ""
}
</script>

<style lang="scss" scoped></style>
