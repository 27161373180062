/* eslint-disable no-unused-vars */
import { createRouter, createWebHashHistory } from "vue-router";
// import { getCurrentUser } from '@/firebase'

/// pages from theme
import Default from "../views/dashboards/Default.vue";
// import Overview from "../views/pages/profile/Overview.vue";
import Settings from "../views/pages/account/Settings.vue";
import Basic from "../views/auth/signin/Basic.vue";

/// project pages
/// master
import Mbank from "../views/master/banks.vue";
import Mwarn from "../views/master/warning.vue";
import Millegal from "../views/master/illegalUnits.vue";
// import Mgroup from "../views/master/assetGroup.vue";
import Masset from "../views/master/assetType.vue";
// import Masset from "../views/master/assetType2.vue";
import Mprefix from "../views/master/prefixs.vue";
import Maddress from "../views/master/address.vue";
import Mholiday from "../views/master/holidays.vue";
import Mgrade from "../views/master/customerGrade.vue";
import Mdoc from "../views/master/docType.vue";
import Munit from "../views/master/units.vue";
import Mpawn from "../views/master/bankPawn.vue";
import Mcash from "../views/master/cashAccount.vue";
import Mbanknote from "../views/master/bankNote.vue";
import Mbiz from "../views/master/businessType.vue";

/// generals
import Gdash from "../views/generals/dashboards.vue";
import Ghome from "../views/generals/transaction.vue";
import Gticket from "../views/generals/tickets.vue";
import Gfinance from "../views/generals/finance.vue";
import Gsplitasset from "../views/generals/splitAssets.vue";
import Ghistory from "../views/generals/history.vue";
import Gticketchain from "../views/generals/ticketChain.vue";
import Gcash from "../views/generals/cashMovement.vue";
import Gmoney from "../views/generals/moneyManage.vue";
import Gdeposit from "../views/generals/deposit.vue";
import Gcustomerinfo from "../views/generals/customerInfo.vue";
import Gcustomerport from "../views/generals/portfolio.vue";
import Greport from "../views/generals/report.vue";
import Gover from "../views/generals/unitOverdue.vue";
import Gdeferitems from "../views/generals/deferItems.vue";

/// settings
import Sopen from "../views/settings/openBranch.vue";
import Spre from "../views/settings/prePrint.vue";
import Sinterest from "../views/settings/interest.vue";
import Sstock from "../views/settings/stock.vue";
import Sprice from "../views/settings/goldPrice.vue";
import Srole from "../views/settings/securityRole.vue";
import Ssetup from "../views/settings/securitySetup.vue";
import Suser from "../views/settings/users.vue";
import Ssetbranch from "../views/settings/setBranch.vue";

const routes = [
  // main redirect page
  {
    path: "/",
    name: "/",
    redirect: "/signin",
  },
  // old redirect page
  {
    path: "/dashboards/dashboard-default",
    name: "Default",
    component: Default,
    meta: {
      requiresAuth: true,
    },
  },
  // auth redirect page
  {
    path: "/generals/dashboard",
    name: "Dashboard",
    component: Gdash,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/generals/home",
    name: "Home",
    component: Ghome,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/generals/ticket",
    name: "Ticket",
    component: Gticket,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/generals/finance",
    name: "Finance",
    component: Gfinance,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/generals/split_assets",
    name: "SplitAssets",
    component: Gsplitasset,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/generals/history",
    name: "History",
    component: Ghistory,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/generals/ticket_chain",
    name: "TicketChain",
    component: Gticketchain,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/generals/cash_movement",
    name: "CashMovement",
    component: Gcash,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/generals/transactions_management",
    name: "TranctionsManagement",
    component: Gmoney,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/generals/customer_deposit",
    name: "CustomerDeposit",
    component: Gdeposit,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/generals/customer_info",
    name: "CustomerInfo",
    component: Gcustomerinfo,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/generals/customer_port",
    name: "CustomerPort",
    component: Gcustomerport,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/generals/report",
    name: "Report",
    component: Greport,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/generals/unit_overdue",
    name: "UnitOverdue",
    component: Gover,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/generals/defer_items",
    name: "DeferItems",
    component: Gdeferitems,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/master/bank",
    name: "Bank",
    component: Mbank,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/master/warning",
    name: "Warning",
    component: Mwarn,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/master/illegal_assets",
    name: "IllegalAssets",
    component: Millegal,
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: "/master/asset_group",
  //   name: "AssetGroup",
  //   component: Mgroup,
  // },
  {
    path: "/master/asset_type",
    name: "AssetType",
    component: Masset,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/master/prefix",
    name: "Prefix",
    component: Mprefix,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/master/address",
    name: "Address",
    component: Maddress,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/master/holidays",
    name: "Holidays",
    component: Mholiday,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/master/customer_grade",
    name: "CustomerGrade",
    component: Mgrade,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/master/document_type",
    name: "DocumentType",
    component: Mdoc,
  },
  {
    path: "/master/units",
    name: "Units",
    component: Munit,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/master/pawn_bank",
    name: "PawnBank",
    component: Mpawn,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/master/cash_account",
    name: "CashAccount",
    component: Mcash,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/master/bank_note",
    name: "BankNote",
    component: Mbanknote,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/master/business_type",
    name: "BusinessType",
    component: Mbiz,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: "/settings/open_branch",
    name: "OpenShop",
    component: Sopen,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/setting_branch",
    name: "SetBranch",
    component: Ssetbranch,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/pre_print_and_running",
    name: "PrePrint",
    component: Spre,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/interest",
    name: "Interest",
    component: Sinterest,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/stock",
    name: "Stock",
    component: Sstock,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/gold_price",
    name: "GoldPrice",
    component: Sprice,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/security_setup",
    name: "SecurityRole",
    component: Srole,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/security_setup",
    name: "SecuritySetup",
    component: Ssetup,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/settings/user",
    name: "SetUser",
    component: Suser,
    meta: {
      requiresAuth: true,
    },
  },
  // {
  //   path: "/pages/profile/overview",
  //   name: "Profile Overview",
  //   component: Overview,
  // },
  {
    path: "/pages/account/settings",
    name: "Settings",
    component: Settings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/signin",
    name: "Signin Basic",
    component: Basic,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  linkActiveClass: "active",
});

// router.beforeEach(async (to) => {
router.beforeEach(async (to) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && localStorage.getItem("FR-SDK-nvtx-platform") === null) {
    return "/signin";
  }

  // if (to.path === "/signin") {
  //   await userStore.clearSession();
  //   console.log("to : " + JSON.stringify(to.fullPath));
  // }
});

export default router;
