<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12 ">
        <div class="card h-100 mt-3">
          <div class="card-header mb-0">
            <h5>{{ $t('content.gcashmove') }}</h5>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-lg-2">
                <label class="form-label ms-0">{{ $t('content.sdate') }}</label>
                <material-input id="startdate-fn" type="date" />
              </div>

              <div class="col-lg-2">
                <label class="form-label ms-0">{{ $t('content.edate') }}</label>
                <material-input id="enddate-fn" type="date" />
              </div>

              <div class="col-lg-2">
                <label class="form-label ms-0">{{ $t('content.gcashmovetype') }}</label>
                <select class="form-select">
                  <option value="">{{ $t('content.optall') }}</option>
                  <option v-for="(a, sel1) in datas.demo5" :key="sel1" :value="a.items">{{ a.items }}</option>
                </select>
              </div>

              <div class="col-lg-6">
                <label class="form-label ms-0">{{ $t('content.names') }}</label>
                <div class="input-group">
                  <input v-model="datas.searchBox1" type="text" class="form-control" aria-describedby="searchName"
                    readonly>
                  <button id="searchName" class="btn btn-primary" type="button" data-bs-toggle="modal"
                    data-bs-target="#searchNameModal">
                    <i class="material-icons material-symbols-outlined">search</i>
                  </button>
                </div>
              </div>

              <div class="col-lg-2">
                <label class="form-label ms-0">{{ $t('content.refno') }}</label>
                <material-input type="text" />
              </div>

              <div class="col-lg-2">
                <label class="form-label ms-0">{{ $t('content.amnchannel') }}</label>
                <select v-model="datas.mod1" class="form-select">
                  <option value="">{{ $t('content.optall') }}</option>
                  <option v-for="(a, sel4) in datas.demo6" :key="sel4" :value="a.items">{{ a.items }}</option>
                </select>
              </div>

              <div class="col-lg-2">
                <label class="form-label ms-0">{{ $t('content.amnsourcemoney') }}</label>
                <select class="form-select">
                  <option value="">{{ $t('content.optall') }}</option>
                  <template v-if="datas.mod1 == 'เงินสด'">
                    <option v-for="(a, sel77) in datas.demo7" :key="sel77" :value="a.items">{{ a.items }}</option>
                  </template>
                  <template v-else-if="datas.mod1 == 'ธนาคาร'">
                    <option v-for="(a, sel78) in datas.demo8" :key="sel78" :value="a.items">{{ a.items }}</option>
                  </template>
                </select>
              </div>

              <div class="col-lg-2">
                <label class="form-label ms-0 png"></label>
                <material-button color="primary" class="float-lg-start float-sm-end mb-3">
                  <i class="material-icons material-symbols-outlined">search</i> ค้นหา
                </material-button>
              </div>
            </div>
            <hr>
            <div class="row no-gutters">
              <div class="col-12 d-flex justify-content-end align-items-center">
                <label class="form-label m-0 me-2">{{ $t('content.expfile') }} :</label>
                <div>
                  <material-button v-for="(i, exp) in datas.exportFile" :key="exp" :color="i.cl" variant="outline"
                    class="float-lg-start float-sm-end mb-0 me-2" :class="i.cs">
                    <i class="material-icons material-symbols-outlined">{{ i.ic }}</i> {{ i.nm }}
                  </material-button>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="table-responsive">
                <table class="table table-flush">
                  <thead class="thead-light">
                    <tr>
                      <th>#</th>
                      <th>{{ $t('content.dant') }}</th>
                      <th>{{ $t('content.ccode') }}</th>
                      <th>{{ $t('content.names') }}</th>
                      <th>{{ $t('content.refno') }}</th>
                      <th>{{ $t('content.item') }}</th>
                      <th>{{ $t('content.detail') }}</th>
                      <th>{{ $t('content.amnchannel') }}</th>
                      <th>{{ $t('content.amnsourcemoney') }}</th>
                      <th>{{ $t('content.file') }}</th>
                      <th colspan="3" class="text-center">{{ $t('content.trans') }}</th>
                    </tr>
                    <tr class="sub-header">
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th class="text-center">{{ $t('content.depo') }}</th>
                      <th class="text-center">{{ $t('content.withd') }}</th>
                      <th class="text-center">{{ $t('content.total') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-sm font-weight-normal"></td>
                      <td class="text-sm font-weight-normal"></td>
                      <td class="text-sm font-weight-normal"></td>
                      <td class="text-sm font-weight-normal"></td>
                      <td class="text-sm font-weight-normal"></td>
                      <td class="text-sm font-weight-normal">ยอดยกมา</td>
                      <td class="text-sm font-weight-normal"></td>
                      <td class="text-sm font-weight-normal"></td>
                      <td class="text-sm font-weight-normal"></td>
                      <td class="text-sm font-weight-normal"></td>
                      <td class="text-sm font-weight-normal"></td>
                      <td class="text-sm font-weight-normal"></td>
                      <td class="text-sm font-weight-normal">88,888,888,88</td>
                    </tr>

                    <tr>
                      <td class="text-sm font-weight-normal">1</td>
                      <td class="text-sm font-weight-normal">xx/xx/xxxx xx:xx</td>
                      <td class="text-sm font-weight-normal">code-xxxxxx</td>
                      <td class="text-sm font-weight-normal">name lastname</td>
                      <td class="text-sm font-weight-normal">ref-1234567890</td>
                      <td class="text-sm font-weight-normal">ฝากเพื่อชำระ</td>
                      <td class="text-sm font-weight-normal">loremi psimaaaaaaaaaaaa</td>
                      <td class="text-sm font-weight-normal">เงินสด</td>
                      <td class="text-sm font-weight-normal">ลิ้นชักเงินสด 1</td>
                      <td class="text-sm font-weight-normal">
                        <material-button color="primary" variant="outline" class="rounded-circle" data-bs-toggle="tooltip"
                          data-bs-placement="top" :title="$t('content.file')" @click="onLookupDetail">
                          <i class="material-icons material-symbols-outlined">attach_file</i>
                        </material-button>
                      </td>
                      <td class="text-sm font-weight-normal">88,888,888,88</td>
                      <td class="text-sm font-weight-normal">88,888,888,88</td>
                      <td class="text-sm font-weight-normal">88,888,888,88</td>
                    </tr>

                    <tr>
                      <td class="text-sm font-weight-normal">2</td>
                      <td class="text-sm font-weight-normal">xx/xx/xxxx xx:xx</td>
                      <td class="text-sm font-weight-normal">code-xxxxxx</td>
                      <td class="text-sm font-weight-normal">name lastname</td>
                      <td class="text-sm font-weight-normal">ref-1234567890</td>
                      <td class="text-sm font-weight-normal">ถอนเพื่อจ่ายดอกเบี้ย</td>
                      <td class="text-sm font-weight-normal">loremi psimaaaaaaaaaaaa</td>
                      <td class="text-sm font-weight-normal">ธนาคาร</td>
                      <td class="text-sm font-weight-normal">บัญชี 1</td>
                      <td class="text-sm font-weight-normal">
                        <material-button color="primary" variant="outline" class="rounded-circle" data-bs-toggle="tooltip"
                          data-bs-placement="top" :title="$t('content.file')" @click="onLookupDetail">
                          <i class="material-icons material-symbols-outlined">attach_file</i>
                        </material-button>
                      </td>
                      <td class="text-sm font-weight-normal">88,888,888,88</td>
                      <td class="text-sm font-weight-normal">88,888,888,88</td>
                      <td class="text-sm font-weight-normal">88,888,888,88</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!-- Search Customer Dialog -->
        <div id="searchNameModal" class="modal fade" tabindex="-1" aria-labelledby="searchNameModalLabel"
          aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">

              <div class="modal-header">
                <h4 class="modal-title">{{ $t('content.cnamelist') }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <div class="preview-table">
                  <section class="panel">
                    <table class="table table-striped table-responsive">
                      <thead>
                        <tr>
                          <th style="width: 100px;">{{ $t('content.ccode') }}</th>
                          <th style="width: 180px;">{{ $t('content.cnameth') }}</th>
                          <th style="width: 180px;">{{ $t('content.cnameen') }}</th>
                          <th style="width: 160px;">{{ $t('content.cidcard') }}</th>
                          <th style="width: 110px;">{{ $t('content.cmobile') }}</th>
                          <th style="width: 120px;">{{ $t('content.cgrade') }}</th>
                          <!-- <th></th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="row-search">
                          <td>
                            <material-input type="text" :label="t('content.ccode')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cnameth')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cnameen')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cidcard')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cmobile')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cgrade')" />
                          </td>
                          <!-- <td>
                                <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                                  data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch">
                                  <i class="material-icons material-symbols-outlined">search</i>
                                </material-button>
                              </td> -->
                        </tr>
                        <tr v-for="(i, cs) in datas.cname" :key="cs" data-bs-dismiss="modal">
                          <td>{{ i.cd }}</td>
                          <td>{{ i.th }}</td>
                          <td>{{ i.en }}</td>
                          <td>{{ i.id }}</td>
                          <td>{{ i.mb }}</td>
                          <td>{{ i.gd }}</td>
                          <!-- <td></td> -->
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </div>
              </div>

              <div class="modal-footer">
                <nav aria-label="Table Paginator">
                  <ul class="pagination justify-content-end">
                    <li class="page-item disabled">
                      <a class="page-link" href="#" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item">
                      <a class="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script setup>
import { reactive, inject } from 'vue'
import { useI18n } from "vue-i18n"

import MaterialButton from "@/components/MaterialButton.vue"
import MaterialInput from "@/components/MaterialInput.vue"
// import MaterialCheckbox from "@/components/MaterialCheckbox.vue"
// import MaterialRadio from "@/components/MaterialRadio.vue"

// import QrCode from "@/assets/img/pawn/qr_sample.png"

const { t } = useI18n()
const swal = inject('$swal')

const datas = reactive({
  addNew: false,
  rowEdit: false,
  coreCopy: true,
  searchBox1: "",
  searchBox2: "",
  mod1: "",
  demo1: [
    { items: "ฝากเพื่อชำระ" },
  ],
  demo2: [
    { items: "ฝาก" },
    { items: "ถอน" },
  ],
  demo3: [
    { items: "เงินสด" },
    { items: "เงินโอน" },
  ],
  demo4: [
    { items: "Bank1" },
    { items: "Bank2" },
  ],
  demo5: [
    { items: "ทั้งหมด" },
    { items: "ลูกค้า" },
  ],
  demo6: [
    { items: "เงินสด" },
    { items: "ธนาคาร" },
  ],
  demo7: [
    { items: "ลิ้นชักเงินสด 1" },
    { items: "ลิ้นชักเงินสด 2" },
  ],
  demo8: [
    { items: "บัญชี 1" },
    { items: "บัญชี 2" },
  ],
  exportFile: [
    {
      cl: "info",
      cs: "me-2",
      ic: "picture_as_pdf",
      nm: "pdf",
      ac: "",
    },
    {
      cl: "info",
      cs: "",
      ic: "table_rows",
      nm: "excel",
      ac: "",
    },
  ],
  cname: [
    {
      cd: "xxxxxx",
      th: "พิชัย จิตภักดี",
      en: "Pichai Jitphakdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "A",
    },
    {
      cd: "xxxxxx",
      th: "นิรุจ เสมากิจ",
      en: "Nirut Semakij",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "VIP",
    },
    {
      cd: "xxxxxx",
      th: "บวรศักดิ์ นิลสุภาพ",
      en: "Borwonsak Nilsupap",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "S",
    },
    {
      cd: "xxxxxx",
      th: "ชิดชนก วิมลพัฒน์",
      en: "Chidchanok Wimonpatr",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "C",
    },
    {
      cd: "xxxxxx",
      th: "สมบูรณ์ทรัพย์ ทองดี",
      en: "Somboonsap Thongdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "B",
    },
    {
      cd: "xxxxxx",
      th: "พิชัย จิตภักดี",
      en: "Pichai Jitphakdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "A",
    },
    {
      cd: "xxxxxx",
      th: "นิรุจ เสมากิจ",
      en: "Nirut Semakij",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "VIP",
    },
    {
      cd: "xxxxxx",
      th: "บวรศักดิ์ นิลสุภาพ",
      en: "Borwonsak Nilsupap",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "S",
    },
    {
      cd: "xxxxxx",
      th: "ชิดชนก วิมลพัฒน์",
      en: "Chidchanok Wimonpatr",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "C",
    },
    {
      cd: "xxxxxx",
      th: "สมบูรณ์ทรัพย์ ทองดี",
      en: "Somboonsap Thongdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "B",
    },
  ],
})

async function onLookupDetail() {
  swal({
    html:
      '<div class="preview-attached">' +
      '<section class="panel">' +
      '<img src="https://unsplash.it/400/200" alt="1" height="400" width="200">' +
      '<img src="https://unsplash.it/400/200" alt="2" height="400" width="200">' +
      '<img src="https://unsplash.it/400/200" alt="3" height="400" width="200">' +
      '<img src="https://unsplash.it/400/200" alt="4" height="400" width="200">' +
      '</section>' +
      // '</div>' +
      // '<div class="preview-detail">' +
      // '<h6 class="mb">Notice:</h6>' +
      // '<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content here, making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for lorem ipsum will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>' +
      '</div>',
    allowOutsideClick: true,
    showCloseButton: true,
    showCancelButton: false,
    showConfirmButton: false,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
  });
}

</script>
    
<style lang="scss" scoped></style>