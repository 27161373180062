<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12 ">

        <ul id="customerInfoTab" class="nav nav-tabs" role="tablist">

          <li class="nav-item" role="presentation">
            <button id="first-customerInfo" class="nav-link active" data-bs-toggle="tab"
              data-bs-target="#firstCustomerInfo" type="button" role="tab" aria-controls="firstCustomerInfo"
              aria-selected="true">
              {{ $t('content.gcustomer') }}
            </button>
          </li>

          <li class="nav-item" role="presentation">
            <button id="second-customerInfo" class="nav-link" data-bs-toggle="tab" data-bs-target="#secondCustomerInfo"
              type="button" role="tab" aria-controls="secondCustomerInfo" aria-selected="false">
              {{ $t('content.gcustomerman') }}
            </button>
          </li>
        </ul>

        <div id="customerInfoTabContent" class="tab-content">

          <div id="firstCustomerInfo" class="tab-pane fade show active" role="tabpanel"
            aria-labelledby="first-customerInfo">
            <div class="card mt-2">
              <div class="card-header mb-0">
                <h5>{{ $t('content.gcustomer') }}</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.ccode') }}</label>
                    <material-input type="text" />
                  </div>

                  <div class="col-lg-5">
                    <label class="form-label ms-0">{{ $t('content.cnam') }}</label>
                    <material-input type="text" />
                  </div>

                  <div class="col-lg-5">
                    <label class="form-label ms-0">{{ $t('content.clsnam') }}</label>
                    <material-input type="text" />
                  </div>

                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t('content.cidcard') }}</label>
                    <material-input type="text" />
                  </div>

                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t('content.cgrade') }}</label>
                    <select class="form-select">
                      <option :value="$t('content.optall')">{{ $t('content.optall') }}</option>
                      <option v-for="(i, s2) in datas.demo2" :key="s2" :value="i.items">{{ i.items }}</option>
                    </select>
                  </div>

                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t('content.cstatus') }}</label>
                    <select class="form-select">
                      <option :value="$t('content.optall')">{{ $t('content.optall') }}</option>
                      <option v-for="(i, s1) in datas.demo1" :key="s1" :value="i.items">{{ i.items }}</option>
                    </select>
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0 png"></label>
                    <material-button color="primary" class="float-lg-start float-sm-end mb-3">
                      <i class="material-icons material-symbols-outlined">search</i> {{ btnSearch }}
                    </material-button>
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>{{ $t('content.ccode') }}</th>
                          <th>{{ $t('content.names') }}</th>
                          <th>{{ $t('content.cidcard') }}</th>
                          <th>{{ $t('content.cgrade') }}</th>
                          <th>{{ $t('content.activate') }}</th>
                          <th>{{ $t('content.lastact') }}</th>
                          <th>{{ $t('content.cstatus') }}</th>
                          <th width="100px">{{ $t('content.act') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(i, ci) in samples" :key="ci">
                          <td class="text-sm font-weight-normal">{{ i.no }}</td>
                          <td class="text-sm font-weight-normal">{{ i.ccd }}</td>
                          <td class="text-sm font-weight-normal">{{ i.nam }}</td>
                          <td class="text-sm font-weight-normal">{{ i.crd }}</td>
                          <td class="text-sm font-weight-normal">{{ i.grd }}</td>
                          <td class="text-sm font-weight-normal">{{ i.fir }}</td>
                          <td class="text-sm font-weight-normal">{{ i.dat }}</td>
                          <td class="text-sm font-weight-normal">{{ i.css }}</td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="info" variant="outline" class="rounded-circle me-1"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('buttons.edit')">
                              <i class="material-icons material-symbols-outlined">edit</i>
                            </material-button>
                            <material-button color="warning" variant="outline" class="rounded-circle me-1"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('content.resetpwd')">
                              <i class="material-icons material-symbols-outlined">lock_reset</i>
                            </material-button>
                          </td>
                          <!-- <td class="text-sm font-weight-normal"></td> -->
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="secondCustomerInfo" class="tab-pane fade" role="tabpanel" aria-labelledby="second-customerInfo">
            <div class="card mt-2">
              <div class="card-header">
                <h5>{{ $t('content.gcustomer') }}</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-lg-2 col-sm-3">
                    <div class="row">
                      <div class="col-12">
                        <div class="image-card">
                          <label for="uploadAvatar" class="image-card-container">
                            <input id="uploadAvatar" type="file" hidden />
                            <img :src="Avatar" class="img img-fluid" alt="">
                            <div class="press-change">
                              <i class="material-icons material-symbols-outlined me-1">add_photo_alternate</i>
                              {{ $t('content.cupload') }}
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-10 col-sm-9">
                    <div class="row">
                      <div class="col-lg-3 col-sm-5">
                        <label class="form-label ms-0">{{ $t('content.cardtype') }}</label>
                        <select class="form-select">
                          <option :value="$t('content.select')">{{ $t('content.select') }}</option>
                          <option v-for="(i, fs1) in datas.demo3" :key="fs1" :value="i.items">{{ i.items }}</option>
                        </select>
                      </div>
                      <div class="col-lg-9 col-sm-7">
                        <label class="form-label ms-0">{{ $t('content.cardno') }}</label>
                        <material-input type="text" />
                      </div>
                      <div class="col-lg-6">
                        <label class="form-label ms-0">{{ $t('content.pcissue') }}</label>
                        <material-input type="text" />
                      </div>
                      <div class="col-lg-3">
                        <label class="form-label ms-0">{{ $t('content.cdissue') }}</label>
                        <material-input type="date" />
                      </div>
                      <div class="col-lg-3">
                        <label class="form-label ms-0">{{ $t('content.cdexpire') }}</label>
                        <material-input type="date" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.cprefix') }}</label>
                    <select class="form-select">
                      <option :value="$t('content.select')">{{ $t('content.select') }}</option>
                      <option v-for="(i, fs2) in datas.demo4" :key="fs2" :value="i.items">{{ i.items }}</option>
                    </select>
                  </div>
                  <div class="col-lg-5">
                    <label class="form-label ms-0">{{ $t('content.cnam') }}</label>
                    <material-input type="text" />
                  </div>
                  <div class="col-lg-5">
                    <label class="form-label ms-0">{{ $t('content.clsnam') }}</label>
                    <material-input type="text" />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.cbirth') }}</label>
                    <material-input type="date" />
                  </div>
                  <div class="col-lg-1">
                    <label class="form-label ms-0">{{ $t('content.cage') }}</label>
                    <material-input type="text" disabled="true" value="xx" />
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t('content.crelig') }}</label>
                    <select class="form-select">
                      <option :value="$t('content.select')">{{ $t('content.select') }}</option>
                      <option v-for="(i, fs5) in datas.demo5" :key="fs5" :value="i.items">{{ i.items }}</option>
                    </select>
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t('content.cblood') }}</label>
                    <select class="form-select">
                      <option :value="$t('content.select')">{{ $t('content.select') }}</option>
                      <option v-for="(i, fs6) in datas.demo6" :key="fs6" :value="i.items">{{ i.items }}</option>
                    </select>
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t('content.cnation') }}</label>
                    <material-input type="text" />
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t('content.amntypecustomer') }}</label>
                    <select class="form-select">
                      <option :value="$t('content.select')">{{ $t('content.select') }}</option>
                      <option v-for="(i, fs3) in datas.demo2" :key="fs3" :value="i.items">{{ i.items }}</option>
                    </select>
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t('content.amncredit') }}</label>
                    <material-input type="text" />
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-12">
                    <label class="form-label ms-0">{{ $t('content.caddress') }}</label>
                    <material-input type="text" />
                  </div>
                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t('content.cstate') }}</label>
                    <div class="input-group">
                      <input type="text" class="form-control">
                      <button class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                      </button>
                      <ul class="dropdown-menu dropdown-menu-end">
                        <li>
                          <div class="input-group">
                            <input type="text" class="form-control mb-0" placeholder="ค้นหา" />
                          </div>
                        </li>
                        <li>
                          <hr class="dropdown-divider">
                        </li>
                        <li><a class="dropdown-item" href="#">กรุงเทพฯ</a></li>
                        <li><a class="dropdown-item" href="#">นนทบุรี</a></li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t('content.csprov') }}</label>
                    <div class="input-group">
                      <input type="text" class="form-control">
                      <button class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                      </button>
                      <ul class="dropdown-menu dropdown-menu-end">
                        <li>
                          <div class="input-group">
                            <input type="text" class="form-control mb-0" placeholder="ค้นหา" />
                          </div>
                        </li>
                        <li>
                          <hr class="dropdown-divider">
                        </li>
                        <li><a class="dropdown-item" href="#">พระนคร</a></li>
                        <li><a class="dropdown-item" href="#">ป้อมปราบฯ</a></li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t('content.cscity') }}</label>
                    <div class="input-group">
                      <input type="text" class="form-control">
                      <button class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                      </button>
                      <ul class="dropdown-menu dropdown-menu-end">
                        <li>
                          <div class="input-group">
                            <input type="text" class="form-control mb-0" placeholder="ค้นหา" />
                          </div>
                        </li>
                        <li>
                          <hr class="dropdown-divider">
                        </li>
                        <li><a class="dropdown-item" href="#">บวรนิเวศ</a></li>
                        <li><a class="dropdown-item" href="#">ตลาดยอด</a></li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t('content.cmobile') }}1*</label>
                    <material-input type="phone" />
                  </div>
                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t('content.cmobile') }}2</label>
                    <material-input type="phone" />
                  </div>
                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t('content.cmobile') }}3</label>
                    <material-input type="phone" />
                  </div>
                  <div class="col-12">
                    <label class="form-label ms-0">{{ $t('content.cemail') }}</label>
                    <material-input type="email" />
                  </div>
                </div>
              </div>
            </div>

            <div class="card mt-4">
              <div class="card-header mb-0">
                <div class="row no-gutters">
                  <div class="col-12 d-flex justify-content-between align-items-center">
                    <h5 class="m-0">{{ $t('content.cbankinfo') }}</h5>
                    <div>
                      <material-button color="primary" class="float-lg-start float-sm-end mb-0" @click="addNewItems">
                        <i class="material-icons material-symbols-outlined">add</i> {{ btnAddNew }}
                      </material-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body pt-0">
                <div class="row mt-1">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th style="width: 70px;">#</th>
                          <th>{{ $t('content.cdefault') }}</th>
                          <th>{{ $t('content.cbankname') }}</th>
                          <th>{{ $t('content.cbankno') }}</th>
                          <th>{{ $t('content.cbankacc') }}</th>
                          <th style="width: 120px;">{{ $t('content.act') }}</th>
                        </tr>
                      </thead>

                      <tbody v-if="datas.addNew" data-group-index="0">
                        <tr class="group-items">
                          <td class="text-sm font-weight-normal">#</td>
                          <td class="text-sm font-weight-normal">
                            <select class="form-select">
                              <option :value="$t('content.select')">{{ $t('content.select') }}</option>
                              <option v-for="(j, fs7) in datas.demo7" :key="fs7" :value="j.items">
                                {{ j.items }}
                              </option>
                            </select>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <select class="form-select">
                              <option :value="$t('content.select')">{{ $t('content.select') }}</option>
                              <option v-for="(j, fs8) in datas.demo8" :key="fs8" :value="j.items">
                                {{ j.items }}
                              </option>
                            </select>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-input type="text" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-input type="text" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave" @click="saveNewItems">
                              <i class="material-icons material-symbols-outlined">save</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                              @click="cancelSaveNewItems">
                              <i class="material-icons material-symbols-outlined">close</i>
                            </material-button>
                          </td>
                        </tr>

                        <tr class="group-sub-header">
                          <td class="text-sm font-weight-normal"></td>
                          <td class="text-sm font-weight-normal" colspan="2">
                            <label class="form-label ms-0">{{ $t('content.doctype') }}</label>
                          </td>
                          <td class="text-sm font-weight-normal" colspan="2">
                            <label class="form-label ms-0">{{ $t('content.file') }}</label>
                          </td>
                          <td class="text-sm font-weight-normal"></td>
                        </tr>

                        <tr>
                          <td class="text-sm font-weight-normal">#</td>
                          <td class="text-sm font-weight-normal" colspan="2">
                            <select class="form-select">
                              <option :value="$t('content.select')">{{ $t('content.select') }}</option>
                              <option v-for="(x, fs20) in datas.demo10" :key="fs20" :value="x.items">
                                {{ x.items }}
                              </option>
                            </select>
                          </td>
                          <td class="text-sm font-weight-normal" colspan="2">
                            <div class="input-group custom-file-button">
                              <label for="upload" class="input-group-text mb-0">{{ $t('buttons.file') }}</label>
                              <input id="upload" type="file" class="form-control mb-0">
                            </div>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnAddNew"
                              @click="datas.addRef = true">
                              <i class="material-icons material-symbols-outlined">add</i>
                            </material-button>
                          </td>
                        </tr>

                        <tr v-if="datas.rowRef">
                          <td class="text-sm font-weight-normal">1</td>
                          <td class="text-sm font-weight-normal" colspan="2">
                            <select class="form-select">
                              <option :value="$t('content.select')">{{ $t('content.select') }}</option>
                              <option v-for="(x, fs21) in datas.demo10" :key="fs21" :value="x.items">
                                {{ x.items }}
                              </option>
                            </select>
                          </td>
                          <td class="text-sm font-weight-normal" colspan="2">
                            <div class="input-group custom-file-button">
                              <label for="uploadAddSub" class="input-group-text mb-0">{{ $t('buttons.file') }}</label>
                              <input id="uploadAddSub" type="file" class="form-control mb-0">
                            </div>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnConfirm"
                              @click="datas.rowRef = false">
                              <i class="material-icons material-symbols-outlined">check</i>
                            </material-button>
                          </td>
                        </tr>
                        <tr v-else-if="!datas.rowRef">
                          <td class="text-sm font-weight-normal">1</td>
                          <td class="text-sm font-weight-normal" colspan="2">
                            xxxxxx
                          </td>
                          <td class="text-sm font-weight-normal" colspan="2">
                            xxxxxx.png
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnEdit" @click="editSubRowItems">
                              <i class="material-icons material-symbols-outlined">edit</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel"
                              @click="datas.rowRef = false">
                              <i class="material-icons material-symbols-outlined">close</i>
                            </material-button>
                          </td>
                        </tr>
                        <tr v-if="datas.addRef">
                          <td class="text-sm font-weight-normal">2</td>
                          <td class="text-sm font-weight-normal" colspan="2">
                            xxxxxx
                          </td>
                          <td class="text-sm font-weight-normal" colspan="2">
                            xxxxxx.png
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnEdit">
                              <i class="material-icons material-symbols-outlined">edit</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel"
                              @click="datas.addRef = false">
                              <i class="material-icons material-symbols-outlined">close</i>
                            </material-button>
                          </td>
                        </tr>
                      </tbody>

                      <tbody v-for="(i, bd1) in bankdoc" :key="bd1" :data-group-index="bd1 + 1">
                        <template v-if="!i.ed">
                          <tr class="group-items">
                            <td class="text-sm font-weight-normal">{{ i.g }}</td>
                            <td class="text-sm font-weight-normal">{{ i.dt }}</td>
                            <td class="text-sm font-weight-normal">{{ i.nm }}</td>
                            <td class="text-sm font-weight-normal">{{ i.no }}</td>
                            <td class="text-sm font-weight-normal">{{ i.ty }}</td>
                            <td class="text-sm font-weight-normal">
                              <material-button color="info" variant="outline" class="rounded-circle"
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="btnEdit"
                                @click="editRowItems(bd1, i.ed)">
                                <i class="material-icons material-symbols-outlined">edit</i>
                              </material-button>
                              <material-button color="danger" variant="outline" class="rounded-circle"
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel">
                                <i class="material-icons material-symbols-outlined">delete</i>
                              </material-button>
                            </td>
                          </tr>
                          <tr class="group-sub-header">
                            <td class="text-sm font-weight-normal"></td>
                            <td class="text-sm font-weight-normal" colspan="2">
                              <label class="form-label ms-0">{{ $t('content.doctype') }}</label>
                            </td>
                            <td class="text-sm font-weight-normal" colspan="2">
                              <label class="form-label ms-0">{{ $t('content.file') }}</label>
                            </td>
                            <td class="text-sm font-weight-normal"></td>
                          </tr>
                        </template>

                        <template v-else>
                          <tr class="group-items">
                            <td class="text-sm font-weight-normal">{{ i.g }}</td>
                            <td class="text-sm font-weight-normal">
                              <select class="form-select">
                                <option :value="$t('content.select')">{{ $t('content.select') }}</option>
                                <option v-for="(j, fs17) in datas.demo7" :key="fs17" :value="j.items">
                                  {{ j.items }}
                                </option>
                              </select>
                            </td>
                            <td class="text-sm font-weight-normal">
                              <select class="form-select">
                                <option :value="$t('content.select')">{{ $t('content.select') }}</option>
                                <option v-for="(j, fs18) in datas.demo8" :key="fs18" :value="j.items">
                                  {{ j.items }}
                                </option>
                              </select>
                            </td>
                            <td class="text-sm font-weight-normal">
                              <material-input type="text" value="xxxxx" />
                            </td>
                            <td class="text-sm font-weight-normal">
                              <material-input type="text" value="xxxxx" />
                            </td>
                            <td class="text-sm font-weight-normal">
                              <material-button color="info" variant="outline" class="rounded-circle"
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave"
                                @click="saveEditRowItems(bd1, i.ed)">
                                <i class="material-icons material-symbols-outlined">save</i>
                              </material-button>
                              <material-button color="danger" variant="outline" class="rounded-circle"
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="btnCancel"
                                @click="cancelEditRowItems(bd1, i.ed)">
                                <i class="material-icons material-symbols-outlined">close</i>
                              </material-button>
                            </td>
                          </tr>
                          <tr class="group-sub-header">
                            <td class="text-sm font-weight-normal"></td>
                            <td class="text-sm font-weight-normal" colspan="2">
                              <label class="form-label ms-0">{{ $t('content.doctype') }}</label>
                            </td>
                            <td class="text-sm font-weight-normal" colspan="2">
                              <label class="form-label ms-0">{{ $t('content.file') }}</label>
                            </td>
                            <td class="text-sm font-weight-normal"></td>
                          </tr>
                          <tr>
                            <td class="text-sm font-weight-normal">#</td>
                            <td class="text-sm font-weight-normal" colspan="2">
                              <select class="form-select">
                                <option :value="$t('content.select')">{{ $t('content.select') }}</option>
                                <option v-for="(x, fs25) in datas.demo10" :key="fs25" :value="x.items">
                                  {{ x.items }}
                                </option>
                              </select>
                            </td>
                            <td class="text-sm font-weight-normal" colspan="2">
                              <div class="input-group custom-file-button">
                                <label for="uploadforfix" class="input-group-text mb-0">{{ $t('buttons.file') }}</label>
                                <input id="uploadforfix" type="file" class="form-control mb-0">
                              </div>
                            </td>
                            <td class="text-sm font-weight-normal">
                              <material-button color="primary" variant="outline" class="rounded-circle"
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="btnAddNew"
                                @click="datas.addSubRef = true">
                                <i class="material-icons material-symbols-outlined">add</i>
                              </material-button>
                            </td>
                          </tr>
                        </template>

                        <tr v-for="(j, bd2) in i.tr" :key="bd2">
                          <td class="text-sm font-weight-normal">{{ j.id }}</td>
                          <td v-if="i.ed && j.ed" class="text-sm font-weight-normal" colspan="2">
                            <select class="form-select">
                              <option :value="$t('content.select')">{{ $t('content.select') }}</option>
                              <option v-for="(x, fs25) in datas.demo10" :key="fs25" :value="x.items">
                                {{ x.items }}
                              </option>
                            </select>
                          </td>
                          <td v-if="i.ed && j.ed" class="text-sm font-weight-normal" colspan="2">
                            <div class="input-group custom-file-button">
                              <label for="uploadOnSub" class="input-group-text mb-0">{{ $t('buttons.file') }}</label>
                              <input id="uploadOnSub" type="file" class="form-control mb-0">
                            </div>
                          </td>
                          <td v-if="!j.ed" class="text-sm font-weight-normal" colspan="2">{{ j.td }}</td>
                          <td v-if="!j.ed" class="text-sm font-weight-normal" colspan="2">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnPreview"
                              @click="onLookupDetail">
                              <i class="material-icons material-symbols-outlined">attach_file</i>
                            </material-button>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button v-if="i.ed && j.ed" color="info" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave"
                              @click="saveEditSubRowItems(bd1, bd2, i.ed)">
                              <i class="material-icons material-symbols-outlined">save</i>
                            </material-button>
                            <material-button v-if="i.ed && j.ed" color="warning" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnCancel"
                              @click="cancelEditSubRowItems(bd1, bd2, i.ed)">
                              <i class="material-icons material-symbols-outlined">close</i>
                            </material-button>
                            <material-button v-if="i.ed && !j.ed" color="info" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnEdit"
                              @click="editOnSubRowItems(bd1, bd2, i.ed)">
                              <i class="material-icons material-symbols-outlined">edit</i>
                            </material-button>
                            <material-button v-if="i.ed && !j.ed" color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel">
                              <i class="material-icons material-symbols-outlined">delete</i>
                            </material-button>
                          </td>
                        </tr>

                        <tr v-if="i.ed && datas.addSubRef">
                          <td class="text-sm font-weight-normal">{{ Object.keys(i.tr).length + 1 }}</td>
                          <td class="text-sm font-weight-normal" colspan="2">xxxxx</td>
                          <td class="text-sm font-weight-normal" colspan="2">xxxxx</td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="info" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnEdit">
                              <i class="material-icons material-symbols-outlined">edit</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel"
                              @click="datas.addSubRef = false">
                              <i class="material-icons material-symbols-outlined">delete</i>
                            </material-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <nav aria-label="Table Paginator">
                    <ul class="pagination justify-content-end">
                      <li class="page-item disabled">
                        <a class="page-link" href="#" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                      <li class="page-item"><a class="page-link" href="#">1</a></li>
                      <li class="page-item"><a class="page-link" href="#">2</a></li>
                      <li class="page-item"><a class="page-link" href="#">3</a></li>
                      <li class="page-item">
                        <a class="page-link" href="#" aria-label="Next">
                          <span aria-hidden="true">&raquo;</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
                <!-- <div class="row no-gutters mt-3">
                  <div class="col-12 d-flex justify-content-end">
                    <div>
                      <material-button color="primary" class="float-lg-start float-sm-end mb-3" @click="confirmNewRecord">
                        <i class="material-icons material-symbols-outlined">save</i> {{ btnSave }}
                      </material-button>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>

            <div class="card mt-4">
              <div class="card-header">
                <div class="row no-gutters">
                  <div class="col-12 d-flex align-items-center">
                    <h5 class="m-0">{{ $t('content.cintrate') }}</h5>
                    <div class="ms-3 d-flex align-items-center">
                      <material-radio id="intRate1" v-model="selectInt" name="selInt" class="radio-horizon" value="0">
                        {{ $t('content.cintrate1') }}
                      </material-radio>
                      <material-radio id="intRate2" v-model="selectInt" name="selInt" class="radio-horizon" value="1">
                        {{ $t('content.cintrate2') }}
                      </material-radio>
                      <div class="other-options">
                        <select v-if="selectInt == 1" class="form-select" style="margin: 0 0 0 1em !important;">
                          <option :value="$t('content.select')">{{ $t('content.select') }}</option>
                          <option v-for="(j, n3) in datas.demo9" :key="n3" :value="j.items">
                            {{ j.items }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card mt-4">
              <div class="card-header">
                <div class="row no-gutters">
                  <div class="col-lg-6 d-flex align-items-center">
                    <h5 class="m-0">{{ $t('content.activate') }}</h5>
                    <div class="ms-3 d-flex align-items-center">
                      <h6 class="card-title mb-0">xx/xx/xxxx xx:xx</h6>
                    </div>
                  </div>
                  <div class="col-lg-6 d-flex align-items-center">
                    <h5 class="m-0">{{ $t('content.lastact') }}</h5>
                    <div class="ms-3 d-flex align-items-center">
                      <h6 class="card-title mb-0">xx/xx/xxxx xx:xx</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card mt-4">
              <div class="card-header">
                <div class="row no-gutters">
                  <div class="col-12 d-flex justify-content-between align-items-center">
                    <h5 class="m-0">{{ $t('content.csign') }}</h5>
                    <div>
                      <material-button color="primary" class="float-lg-start float-sm-end mb-0" @click="addNewImage">
                        <i class="material-icons material-symbols-outlined">add</i> {{ btnAddNew }}
                      </material-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body pt-0">
                <div class="d-flex flex-row flex-nowrap">
                  <div class="card card-body mx-2">
                    <figure class="figure mx-auto">
                      <img :src="Signature" class="img img-fluid" alt="" />
                      <figcaption class="figure-caption pt-3 text-center">ลายเซ็นต์ 1</figcaption>
                    </figure>
                  </div>
                  <div class="card card-body mx-2">
                    <figure class="figure mx-auto">
                      <img :src="Signature" class="img img-fluid" alt="" />
                      <figcaption class="figure-caption pt-3 text-center">ลายเซ็นต์ 2</figcaption>
                    </figure>
                  </div>
                  <div class="card card-body mx-2">
                    <figure class="figure mx-auto">
                      <img :src="Signature" class="img img-fluid" alt="" />
                      <figcaption class="figure-caption pt-3 text-center">ลายเซ็นต์ 3</figcaption>
                    </figure>
                  </div>
                </div>
              </div>
            </div>

            <div class="card mt-4">
              <div class="card-header">
                <div class="row no-gutters">
                  <div class="col-12 d-flex justify-content-between align-items-center">
                    <h5 class="m-0">{{ $t('content.cfprint') }}</h5>
                    <div>
                      <material-button color="primary" class="float-lg-start float-sm-end mb-0" @click="addNewImage">
                        <i class="material-icons material-symbols-outlined">add</i> {{ btnAddNew }}
                      </material-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body pt-0">
                <div class="d-flex flex-row flex-nowrap">
                  <div class="card card-body mx-2">
                    <figure class="figure mx-auto">
                      <img :src="FingerPrint" class="img img-fluid" alt="" />
                      <figcaption class="figure-caption pt-3 text-center">นิ้วโป้งขวา</figcaption>
                    </figure>
                  </div>
                  <div class="card card-body mx-2">
                    <figure class="figure mx-auto">
                      <img :src="FingerPrint" class="img img-fluid" alt="" />
                      <figcaption class="figure-caption pt-3 text-center">นิ้วโป้งซ้าย</figcaption>
                    </figure>
                  </div>
                  <div class="card card-body mx-2">
                    <figure class="figure mx-auto">
                      <img :src="FingerPrint" class="img img-fluid" alt="" />
                      <figcaption class="figure-caption pt-3 text-center">นิ้วชี้ซ้าย</figcaption>
                    </figure>
                  </div>
                </div>
              </div>
            </div>

            <div class="card mt-4">
              <div class="card-header">
                <div class="row no-gutters">
                  <div class="col-12 d-flex justify-content-between align-items-center">
                    <h5 class="m-0">{{ $t('content.cabove') }}</h5>
                    <div class="col ms-lg-5 ms-sm-2">
                      <div class="input-group">
                        <input v-model="datas.searchBox3" type="text" class="form-control"
                          style="margin-bottom:0 !important" aria-describedby="searchRelation"
                          :placeholder="$t('content.crlname')" readonly>
                        <button id="searchRelation" class="btn btn-primary mb-0" type="button" data-bs-toggle="modal"
                          data-bs-target="#searchNameModal">
                          <i class="material-icons material-symbols-outlined">search</i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body pt-0">
                <div class="table-responsive">
                  <table class="table table-flush">
                    <thead class="thead-light">
                      <tr>
                        <th style="width: 70px;">#</th>
                        <th style="width: 200px;">{{ $t('content.ccode') }}</th>
                        <th>{{ $t('content.names') }}</th>
                        <th>{{ $t('content.amncredituse') }}</th>
                        <th style="width: 100px;">{{ $t('content.act') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="text-sm font-weight-normal">1</td>
                        <td class="text-sm font-weight-normal">xxxxx</td>
                        <td class="text-sm font-weight-normal">xxxxx</td>
                        <td class="text-sm font-weight-normal">{{ fmNumber(88888888.88) }}</td>
                        <td class="text-sm font-weight-normal">
                          <material-button color="danger" variant="outline" class="rounded-circle"
                            data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel">
                            <i class="material-icons material-symbols-outlined">delete</i>
                          </material-button>
                        </td>
                      </tr>

                      <tr>
                        <td class="text-sm font-weight-normal">2</td>
                        <td class="text-sm font-weight-normal">xxxxx</td>
                        <td class="text-sm font-weight-normal">xxxxx</td>
                        <td class="text-sm font-weight-normal">{{ fmNumber(88888888.88) }}</td>
                        <td class="text-sm font-weight-normal">
                          <material-button color="danger" variant="outline" class="rounded-circle"
                            data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel">
                            <i class="material-icons material-symbols-outlined">delete</i>
                          </material-button>
                        </td>
                      </tr>

                    </tbody>

                    <tfoot>
                      <tr class="group-sub-header">
                        <td class="text-lg font-weight-bold" colspan="2"><b>{{ $t('content.amnrelatenum') }}</b></td>
                        <td class="text-lg font-weight-bold"><b>{{ fmNumber(88) }}</b></td>
                        <td class="text-lg font-weight-bold"><b>{{ fmNumber(88888888.88) }}</b></td>
                        <td class="text-lg font-weight-bold"><b></b></td>
                      </tr>
                    </tfoot>

                  </table>
                </div>
                <nav aria-label="Table Paginator">
                  <ul class="pagination justify-content-end">
                    <li class="page-item disabled">
                      <a class="page-link" href="#" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item">
                      <a class="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>

          </div>
        </div>

        <!-- Search Customer Dialog -->
        <div id="searchNameModal" class="modal fade" tabindex="-1" aria-labelledby="searchNameModalLabel"
          aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">

              <div class="modal-header">
                <h4 class="modal-title">{{ $t('content.cnamelist') }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <div class="preview-table">
                  <section class="panel">
                    <table class="table table-striped table-responsive">
                      <thead>
                        <tr>
                          <th style="width: 100px;">{{ $t('content.ccode') }}</th>
                          <th style="width: 180px;">{{ $t('content.cnameth') }}</th>
                          <th style="width: 180px;">{{ $t('content.cnameen') }}</th>
                          <th style="width: 160px;">{{ $t('content.cidcard') }}</th>
                          <th style="width: 110px;">{{ $t('content.cmobile') }}</th>
                          <th style="width: 120px;">{{ $t('content.cgrade') }}</th>
                          <!-- <th></th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="row-search">
                          <td>
                            <material-input type="text" :label="t('content.ccode')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cnameth')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cnameen')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cidcard')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cmobile')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cgrade')" />
                          </td>
                          <!-- <td>
                                  <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                                    data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch">
                                    <i class="material-icons material-symbols-outlined">search</i>
                                  </material-button>
                                </td> -->
                        </tr>
                        <tr v-for="(i, cs) in datas.cname" :key="cs" data-bs-dismiss="modal">
                          <td>{{ i.cd }}</td>
                          <td>{{ i.th }}</td>
                          <td>{{ i.en }}</td>
                          <td>{{ i.id }}</td>
                          <td>{{ i.mb }}</td>
                          <td>{{ i.gd }}</td>
                          <!-- <td></td> -->
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </div>
              </div>

              <div class="modal-footer">
                <!-- <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button> -->
                <nav aria-label="Table Paginator">
                  <ul class="pagination justify-content-end">
                    <li class="page-item disabled">
                      <a class="page-link" href="#" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item">
                      <a class="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script setup>
import { reactive, inject, computed, ref } from 'vue'
import { useI18n } from "vue-i18n"

import MaterialButton from "@/components/MaterialButton.vue"
import MaterialInput from "@/components/MaterialInput.vue"
// import MaterialCheckbox from "@/components/MaterialCheckbox.vue"
import MaterialRadio from "@/components/MaterialRadio.vue"

import Signature from "@/assets/img/pawn/signature_test.png"
import FingerPrint from "@/assets/img/pawn/fingerprint_test.jpg"
import Avatar from "@/assets/img/ivana-squares.jpg"

const { t } = useI18n()
const swal = inject('$swal')

const selectInt = ref("0")

const datas = reactive({
  addNew: false,
  rowEdit: false,
  coreCopy: true,
  searchBox1: "",
  searchBox2: "",
  searchBox3: "",
  addRef: false,
  rowRef: false,
  addSubRef: false,
  demo1: [
    { items: "ปกติ" },
    { items: "ล็อค" },
  ],
  demo2: [
    { items: "ธรรมดา" },
    { items: "VIP 1" },
    { items: "VIP 2" },
    { items: "VIP 3" },
    { items: "VIP 4" },
  ],
  demo3: [
    { items: "บัตรประชาชน" },
    { items: "ใบอนุญาตทำงาน" },
    { items: "บัตรต่างด้าว" },
    { items: "หนังสือเดินทาง" },
    { items: "ใบขับขี่" },
  ],
  demo4: [
    { items: "นาย" },
    { items: "นาง" },
    { items: "นางสาว" },
  ],
  demo5: [
    { items: "พุทธ" },
    { items: "คริสต์" },
    { items: "อิสลาม" },
    { items: "ฮินดู" },
    { items: "ซิกต์" },
    { items: "ไม่มี" },
  ],
  demo6: [
    { items: "A" },
    { items: "B" },
    { items: "O" },
    { items: "AB" },
  ],
  demo7: [
    { items: "ปกติ" },
    { items: "ค่าเริ่มต้น" },
    { items: "หยุดใช้งาน" },
    { items: "ผิดปกติ" },
  ],
  demo8: [
    { items: "กสิกรไทย" },
    { items: "ไทยพาณิชย์" },
    { items: "กรุงไทย" },
    { items: "กรุงเทพ" },
  ],
  demo9: [
    { items: "1.75%" },
    { items: "1.5%" },
    { items: "1%" },
    { items: "0.75%" },
  ],
  demo10: [
    { items: "ประเภทเอกสาร 1" },
    { items: "ประเภทเอกสาร 2" },
    { items: "ประเภทเอกสาร 3" },
    { items: "ประเภทเอกสาร 4" },
  ],
  cname: [
    {
      cd: "xxxxxx",
      th: "พิชัย จิตภักดี",
      en: "Pichai Jitphakdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "A",
    },
    {
      cd: "xxxxxx",
      th: "นิรุจ เสมากิจ",
      en: "Nirut Semakij",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "VIP",
    },
    {
      cd: "xxxxxx",
      th: "บวรศักดิ์ นิลสุภาพ",
      en: "Borwonsak Nilsupap",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "S",
    },
    {
      cd: "xxxxxx",
      th: "ชิดชนก วิมลพัฒน์",
      en: "Chidchanok Wimonpatr",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "C",
    },
    {
      cd: "xxxxxx",
      th: "สมบูรณ์ทรัพย์ ทองดี",
      en: "Somboonsap Thongdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "B",
    },
    {
      cd: "xxxxxx",
      th: "พิชัย จิตภักดี",
      en: "Pichai Jitphakdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "A",
    },
    {
      cd: "xxxxxx",
      th: "นิรุจ เสมากิจ",
      en: "Nirut Semakij",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "VIP",
    },
    {
      cd: "xxxxxx",
      th: "บวรศักดิ์ นิลสุภาพ",
      en: "Borwonsak Nilsupap",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "S",
    },
    {
      cd: "xxxxxx",
      th: "ชิดชนก วิมลพัฒน์",
      en: "Chidchanok Wimonpatr",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "C",
    },
    {
      cd: "xxxxxx",
      th: "สมบูรณ์ทรัพย์ ทองดี",
      en: "Somboonsap Thongdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "B",
    },
  ],
})

const samples = reactive([
  {
    no: "1",
    ccd: "code-a",
    fir: "xx/xx/xxxx",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "tk-3048",
    biz: "จำนำ",
    ass: "ทองคำ",
    wei: "30.32",
    qty: "5",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ A",
    css: datas.demo1[0].items,
    grd: datas.demo2[1].items,
    crd: "x-xxxx-xxxxx-xx-x"
  },
  {
    no: "2",
    ccd: "code-b",
    fir: "xx/xx/xxxx",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "tk-1132",
    biz: "ต่อดอก",
    ass: "เพชร",
    wei: "12.34",
    qty: "2",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ B",
    css: datas.demo1[0].items,
    grd: datas.demo2[4].items,
    crd: "x-xxxx-xxxxx-xx-x"
  },
  {
    no: "3",
    ccd: "code-c",
    fir: "xx/xx/xxxx",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "tk-5611",
    biz: "จำนำ",
    ass: "อื่นๆ",
    wei: "30.32",
    qty: "5",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ B",
    css: datas.demo1[0].items,
    grd: datas.demo2[2].items,
    crd: "x-xxxx-xxxxx-xx-x"
  },
  {
    no: "4",
    ccd: "code-d",
    fir: "xx/xx/xxxx",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "tk-0021",
    biz: "ไถ่ถอน",
    ass: "ทองคำ",
    wei: "15.63",
    qty: "1",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ A",
    css: datas.demo1[1].items,
    grd: datas.demo2[0].items,
    crd: "x-xxxx-xxxxx-xx-x"
  },
])

const bankdoc = reactive([
  {
    g: "1",
    dt: "xxxx",
    nm: "xxxx",
    no: 88888888888,
    ty: "xxxx",
    ed: false,
    tr: [
      {
        id: 1,
        td: "xxxx",
        at: "xxxx",
        ed: false,
      },
      {
        id: 2,
        td: "xxxx",
        at: "xxxx",
        ed: false,
      },
      {
        id: 3,
        td: "xxxx",
        at: "xxxx",
        ed: false,
      },
      {
        id: 4,
        td: "xxxx",
        at: "xxxx",
        ed: false,
      },
    ],
  },
  {
    g: "2",
    dt: "xxxx",
    nm: "xxxx",
    no: 88888888888,
    ty: "xxxx",
    ed: false,
    tr: [
      {
        id: 1,
        td: "xxxx",
        at: "xxxx",
        ed: false,
      },
      {
        id: 2,
        td: "xxxx",
        at: "xxxx",
        ed: false,
      },
    ],
  },
])

const btnAddNew = computed(() => t("buttons.impo"))
const btnEdit = computed(() => t("buttons.edit"))
const btnSearch = computed(() => t("buttons.sear"))
const btnSave = computed(() => t("buttons.save"))
const btnDel = computed(() => t("buttons.remo"))
const btnClose = computed(() => t("buttons.clos"))
const btnConfirm = computed(() => t("buttons.conf"))
const btnCancel = computed(() => t("buttons.canc"))
const btnPreview = computed(() => t("content.file"))

const fmNumber = (val) => {
  return Intl.NumberFormat("en-US").format(val)
}

async function onLookupDetail() {
  swal({
    html:
      '<div class="preview-attached">' +
      '<section class="panel">' +
      '<img src="https://unsplash.it/400/200" alt="1" height="400" width="200">' +
      '<img src="https://unsplash.it/400/200" alt="2" height="400" width="200">' +
      '<img src="https://unsplash.it/400/200" alt="3" height="400" width="200">' +
      '<img src="https://unsplash.it/400/200" alt="4" height="400" width="200">' +
      '</section>' +
      // '</div>' +
      // '<div class="preview-detail">' +
      // '<h6 class="mb">Notice:</h6>' +
      // '<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content here, making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for lorem ipsum will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>' +
      '</div>',
    allowOutsideClick: true,
    showCloseButton: true,
    showCancelButton: false,
    showConfirmButton: false,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
  });
}

async function addNewItems() {
  swal({
    title: "เพิ่มรายการ",
    text: "คุณต้องการเพิ่มรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      datas.addNew = true
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกรายการ", "", "error")
      datas.addNew = false
    }
  })
}

async function saveNewItems() {
  swal({
    title: "ต้องการบันทึกรายการนี้",
    text: "คุณต้องการบันทึกรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.save'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      swal("บันทึกสำเร็จ", "", "success")
      datas.addNew = false
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกการบันทึก", "", "error")
      datas.addNew = false
    }
  })
}

async function cancelSaveNewItems() {
  swal({
    title: "ยกเลิกบันทึกรายการนี้",
    text: "คุณต้องการยกเลิกบันทึกรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      datas.addNew = false
    } else if (result.dismiss == 'cancel') {
      datas.addNew = true
    }
  })
}

async function editRowItems(id, bool) {
  swal({
    title: "แก้ไขรายการ",
    text: "คุณต้องการแก้ไขรายการที่ " + id + " นี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      bool = true
      bankdoc[id].ed = bool
    } else if (result.dismiss == 'cancel') {
      bool = false
      bankdoc[id].ed = bool
    }
  })
}

async function saveEditRowItems(id, bool) {
  swal({
    title: "บันทึกแก้ไขรายการ",
    text: "คุณต้องการบันทึกแก้ไขรายการที่ " + id + " นี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.save'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      swal("บันทึกสำเร็จ", "", "success")
      bool = false
      bankdoc[id].ed = bool
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกแก้ไข", "", "error")
      bool = false
      bankdoc[id].ed = bool
    }
  })
}

async function cancelEditRowItems(id, bool) {
  swal({
    title: "ยกเลิกแก้ไขรายการ",
    text: "คุณต้องการยกเลิกแก้ไขรายการที่ " + id + " นี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      bool = false
      bankdoc[id].ed = bool
    } else if (result.dismiss == 'cancel') {
      bool = true
      bankdoc[id].ed = bool
    }
  })
}

async function editOnSubRowItems(id, sub, bool) {
  swal({
    title: "แก้ไขรายการ",
    text: "คุณต้องการแก้ไขรายการที่ " + sub + " นี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      bool = true
      bankdoc[id].tr[sub].ed = bool
    } else if (result.dismiss == 'cancel') {
      bool = false
      bankdoc[id].tr[sub].ed = bool
    }
  })
}

async function saveEditSubRowItems(id, sub, bool) {
  swal({
    title: "บันทึกแก้ไขรายการ",
    text: "คุณต้องการบันทึกแก้ไขรายการที่ " + sub + " นี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.save'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      swal("บันทึกสำเร็จ", "", "success")
      bool = false
      bankdoc[id].tr[sub].ed = bool
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกแก้ไข", "", "error")
      bool = true
      bankdoc[id].tr[sub].ed = bool
    }
  })
}

async function cancelEditSubRowItems(id, sub, bool) {
  swal({
    title: "ยกเลิกแก้ไขรายการ",
    text: "คุณต้องการยกเลิกแก้ไขรายการที่ " + sub + " นี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      bool = false
      bankdoc[id].tr[sub].ed = bool
    } else if (result.dismiss == 'cancel') {
      bool = true
      bankdoc[id].tr[sub].ed = bool
    }
  })
}

async function addNewImage() {
  swal({
    title: "เพิ่มรูปภาพ",
    text: "คุณต้องการเพิ่มรูปภาพนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  })
}

async function editSubRowItems() {
  swal({
    title: "แก้ไขรายการเอกสาร",
    text: "คุณต้องการแก้ไขรายการเอกสารนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      datas.rowRef = true
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกรายการ", "", "error")
      datas.rowRef = false
    }
  })
}

</script>
    
<style lang="scss" scoped></style>