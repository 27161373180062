<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12 ">

        <ul id="homeTab" class="nav nav-tabs" role="tablist">

          <li class="nav-item" role="presentation">
            <button id="first-home-tab" class="nav-link active" data-bs-toggle="tab" data-bs-target="#firstHome"
              type="button" role="tab" aria-controls="firstHome" aria-selected="true">{{ $t('content.amnticketlist')
              }}</button>
          </li>

          <li class="nav-item" role="presentation">
            <button id="second-home-tab" class="nav-link" data-bs-toggle="tab" data-bs-target="#secondHome" type="button"
              role="tab" aria-controls="secondHome" aria-selected="false">{{ $t('content.amnticketmanage') }}</button>
          </li>

          <li class="nav-item" role="presentation">
            <button id="third-home-tab" class="nav-link" data-bs-toggle="tab" data-bs-target="#thirdHome" type="button"
              role="tab" aria-controls="thirdHome" aria-selected="false">{{ $t('content.amnonprocess') }}</button>
          </li>

          <li class="nav-item" role="presentation">
            <button id="fourth-home-tab" class="nav-link" data-bs-toggle="tab" data-bs-target="#fourthHome" type="button"
              role="tab" aria-controls="fourthHome" aria-selected="false">{{ $t('content.amncancelticket') }}</button>
          </li>
        </ul>

        <div id="homeTabContent" class="tab-content">

          <div id="firstHome" class="tab-pane fade show active" role="tabpanel" aria-labelledby="first-home-tab">
            <div class="card mt-2">
              <div class="card-header mb-0">
                <h5>{{ $t('content.amnsearchticker') }}</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.sdate') }}</label>
                    <material-input id="startdate" type="date" />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.edate') }}</label>
                    <material-input id="enddate" type="date" />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.names') }}</label>
                    <div class="input-group">
                      <input v-model="datas.searchBox1" type="text" class="form-control" aria-describedby="searchName1"
                        readonly>
                      <button id="searchName1" class="btn btn-primary" type="button" data-bs-toggle="modal"
                        data-bs-target="#searchNameModal">
                        <i class="material-icons material-symbols-outlined">search</i>
                      </button>
                    </div>
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.amnbiz') }}</label>
                    <select class="form-select">
                      <option value="card0">{{ $t('content.optall') }}</option>
                      <option value="card2">{{ $t('content.actpawn1') }}</option>
                      <option value="card3">{{ $t('content.actpawn2') }}</option>
                      <option value="card4">{{ $t('content.actpawn3') }}</option>
                      <option value="card5">{{ $t('content.actpawn4') }}</option>
                      <option value="card6">{{ $t('content.actpawn5') }}</option>
                    </select>
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.statusticket') }}</label>
                    <select class="form-select">
                      <option value="card0">{{ $t('content.optall') }}</option>
                      <option value="card1">{{ $t('content.amnstateacting') }}</option>
                      <option value="card2">{{ $t('content.amnstateending') }}</option>
                    </select>
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0 png"></label>
                    <material-button id="cardSearch" color="primary" class="float-lg-start float-sm-end mb-3">
                      <i class="material-icons material-symbols-outlined">search</i> ค้นหา
                    </material-button>
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>{{ $t('content.amndatetime') }}</th>
                          <th>{{ $t('content.cname') }}</th>
                          <th>{{ $t('content.amnbiz') }}</th>
                          <th>{{ $t('content.amnassetgroup') }}</th>
                          <th>{{ $t('content.amnweightvalall') }}</th>
                          <th>{{ $t('content.amnanalysvalall') }}</th>
                          <th>{{ $t('content.amnmaxvalall') }}</th>
                          <th>{{ $t('content.amnqtydiamond') }}</th>
                          <th>{{ $t('content.amnqtyunitall') }}</th>
                          <th>{{ $t('content.act') }}</th>
                        </tr>
                      </thead>

                      <tbody data-group-index="0">
                        <tr class="group-id-ticket">
                          <td class="text-sm font-weight-normal" colspan="11">
                            <div class="group-tickect">
                              <h6 class="mb-0"><b>{{ $t('content.amngroupticket') }} #1</b></h6>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-sm font-weight-normal">1</td>
                          <td class="text-sm font-weight-normal">xx/xx/xxxx xx:xx</td>
                          <td class="text-sm font-weight-normal">xxxxx xxxxxxxxx</td>
                          <td class="text-sm font-weight-normal">จำนำ</td>
                          <td class="text-sm font-weight-normal">อื่นๆ</td>
                          <td class="text-sm font-weight-normal">30.32</td>
                          <td class="text-sm font-weight-normal">88,888,888,88</td>
                          <td class="text-sm font-weight-normal">88,888,888,88</td>
                          <td class="text-sm font-weight-normal">2</td>
                          <td class="text-sm font-weight-normal">5</td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="จัดการตั๋ว">
                              <i class="material-icons material-symbols-outlined">add</i>
                            </material-button>
                            <material-button color="warning" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="ตั๋วตีกลับ">
                              <i class="material-icons material-symbols-outlined">keyboard_return</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="ยกเลิกตั๋ว">
                              <i class="material-icons material-symbols-outlined">delete</i>
                            </material-button>
                          </td>
                        </tr>

                        <tr>
                          <td class="text-sm font-weight-normal">2</td>
                          <td class="text-sm font-weight-normal">xx/xx/xxxx xx:xx</td>
                          <td class="text-sm font-weight-normal">xxxxx xxxxxxxxx</td>
                          <td class="text-sm font-weight-normal">จำนำ</td>
                          <td class="text-sm font-weight-normal">อื่นๆ</td>
                          <td class="text-sm font-weight-normal">30.32</td>
                          <td class="text-sm font-weight-normal">88,888,888,88</td>
                          <td class="text-sm font-weight-normal">88,888,888,88</td>
                          <td class="text-sm font-weight-normal">2</td>
                          <td class="text-sm font-weight-normal">5</td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="จัดการตั๋ว">
                              <i class="material-icons material-symbols-outlined">add</i>
                            </material-button>
                            <material-button color="warning" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="ตั๋วตีกลับ">
                              <i class="material-icons material-symbols-outlined">keyboard_return</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="ยกเลิกตั๋ว">
                              <i class="material-icons material-symbols-outlined">delete</i>
                            </material-button>
                          </td>
                        </tr>

                        <tr>
                          <td class="text-sm font-weight-normal">3</td>
                          <td class="text-sm font-weight-normal">xx/xx/xxxx xx:xx</td>
                          <td class="text-sm font-weight-normal">xxxxx xxxxxxxxx</td>
                          <td class="text-sm font-weight-normal">จำนำ</td>
                          <td class="text-sm font-weight-normal">อื่นๆ</td>
                          <td class="text-sm font-weight-normal">30.32</td>
                          <td class="text-sm font-weight-normal">88,888,888,88</td>
                          <td class="text-sm font-weight-normal">88,888,888,88</td>
                          <td class="text-sm font-weight-normal">2</td>
                          <td class="text-sm font-weight-normal">5</td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="จัดการตั๋ว">
                              <i class="material-icons material-symbols-outlined">add</i>
                            </material-button>
                            <material-button color="warning" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="ตั๋วตีกลับ">
                              <i class="material-icons material-symbols-outlined">keyboard_return</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="ยกเลิกตั๋ว">
                              <i class="material-icons material-symbols-outlined">delete</i>
                            </material-button>
                          </td>
                        </tr>

                        <tr>
                          <td class="text-sm font-weight-normal">4</td>
                          <td class="text-sm font-weight-normal">xx/xx/xxxx xx:xx</td>
                          <td class="text-sm font-weight-normal">xxxxx xxxxxxxxx</td>
                          <td class="text-sm font-weight-normal">จำนำ</td>
                          <td class="text-sm font-weight-normal">อื่นๆ</td>
                          <td class="text-sm font-weight-normal">30.32</td>
                          <td class="text-sm font-weight-normal">88,888,888,88</td>
                          <td class="text-sm font-weight-normal">88,888,888,88</td>
                          <td class="text-sm font-weight-normal">2</td>
                          <td class="text-sm font-weight-normal">5</td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="จัดการตั๋ว">
                              <i class="material-icons material-symbols-outlined">add</i>
                            </material-button>
                            <material-button color="warning" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="ตั๋วตีกลับ">
                              <i class="material-icons material-symbols-outlined">keyboard_return</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="ยกเลิกตั๋ว">
                              <i class="material-icons material-symbols-outlined">delete</i>
                            </material-button>
                          </td>
                        </tr>

                        <tr>
                          <td class="text-sm font-weight-normal">5</td>
                          <td class="text-sm font-weight-normal">xx/xx/xxxx xx:xx</td>
                          <td class="text-sm font-weight-normal">xxxxx xxxxxxxxx</td>
                          <td class="text-sm font-weight-normal">จำนำ</td>
                          <td class="text-sm font-weight-normal">อื่นๆ</td>
                          <td class="text-sm font-weight-normal">30.32</td>
                          <td class="text-sm font-weight-normal">88,888,888,88</td>
                          <td class="text-sm font-weight-normal">88,888,888,88</td>
                          <td class="text-sm font-weight-normal">2</td>
                          <td class="text-sm font-weight-normal">5</td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="จัดการตั๋ว">
                              <i class="material-icons material-symbols-outlined">add</i>
                            </material-button>
                            <material-button color="warning" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="ตั๋วตีกลับ">
                              <i class="material-icons material-symbols-outlined">keyboard_return</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="ยกเลิกตั๋ว">
                              <i class="material-icons material-symbols-outlined">delete</i>
                            </material-button>
                          </td>
                        </tr>

                        <tr>
                          <td class="text-sm font-weight-normal">6</td>
                          <td class="text-sm font-weight-normal">xx/xx/xxxx xx:xx</td>
                          <td class="text-sm font-weight-normal">xxxxx xxxxxxxxx</td>
                          <td class="text-sm font-weight-normal">จำนำ</td>
                          <td class="text-sm font-weight-normal">อื่นๆ</td>
                          <td class="text-sm font-weight-normal">30.32</td>
                          <td class="text-sm font-weight-normal">88,888,888,88</td>
                          <td class="text-sm font-weight-normal">88,888,888,88</td>
                          <td class="text-sm font-weight-normal">2</td>
                          <td class="text-sm font-weight-normal">5</td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="จัดการตั๋ว">
                              <i class="material-icons material-symbols-outlined">add</i>
                            </material-button>
                            <material-button color="warning" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="ตั๋วตีกลับ">
                              <i class="material-icons material-symbols-outlined">keyboard_return</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="ยกเลิกตั๋ว">
                              <i class="material-icons material-symbols-outlined">delete</i>
                            </material-button>
                          </td>
                        </tr>
                      </tbody>

                      <tbody data-group-index="1">
                        <tr class="group-id-ticket">
                          <td class="text-sm font-weight-normal" colspan="11">
                            <div class="group-tickect">
                              <h6 class="mb-0"><b>{{ $t('content.amngroupticket') }} #2</b></h6>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-sm font-weight-normal">1</td>
                          <td class="text-sm font-weight-normal">xx/xx/xxxx xx:xx</td>
                          <td class="text-sm font-weight-normal">xxxxx xxxxxxxxx</td>
                          <td class="text-sm font-weight-normal">จำนำ</td>
                          <td class="text-sm font-weight-normal">อื่นๆ</td>
                          <td class="text-sm font-weight-normal">30.32</td>
                          <td class="text-sm font-weight-normal">88,888,888,88</td>
                          <td class="text-sm font-weight-normal">88,888,888,88</td>
                          <td class="text-sm font-weight-normal">2</td>
                          <td class="text-sm font-weight-normal">5</td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="จัดการตั๋ว">
                              <i class="material-icons material-symbols-outlined">add</i>
                            </material-button>
                            <material-button color="warning" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="ตั๋วตีกลับ">
                              <i class="material-icons material-symbols-outlined">keyboard_return</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="ยกเลิกตั๋ว">
                              <i class="material-icons material-symbols-outlined">delete</i>
                            </material-button>
                          </td>
                        </tr>

                        <tr>
                          <td class="text-sm font-weight-normal">2</td>
                          <td class="text-sm font-weight-normal">xx/xx/xxxx xx:xx</td>
                          <td class="text-sm font-weight-normal">xxxxx xxxxxxxxx</td>
                          <td class="text-sm font-weight-normal">จำนำ</td>
                          <td class="text-sm font-weight-normal">อื่นๆ</td>
                          <td class="text-sm font-weight-normal">30.32</td>
                          <td class="text-sm font-weight-normal">88,888,888,88</td>
                          <td class="text-sm font-weight-normal">88,888,888,88</td>
                          <td class="text-sm font-weight-normal">2</td>
                          <td class="text-sm font-weight-normal">5</td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="จัดการตั๋ว">
                              <i class="material-icons material-symbols-outlined">add</i>
                            </material-button>
                            <material-button color="warning" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="ตั๋วตีกลับ">
                              <i class="material-icons material-symbols-outlined">keyboard_return</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="ยกเลิกตั๋ว">
                              <i class="material-icons material-symbols-outlined">delete</i>
                            </material-button>
                          </td>
                        </tr>

                        <tr>
                          <td class="text-sm font-weight-normal">3</td>
                          <td class="text-sm font-weight-normal">xx/xx/xxxx xx:xx</td>
                          <td class="text-sm font-weight-normal">xxxxx xxxxxxxxx</td>
                          <td class="text-sm font-weight-normal">จำนำ</td>
                          <td class="text-sm font-weight-normal">อื่นๆ</td>
                          <td class="text-sm font-weight-normal">30.32</td>
                          <td class="text-sm font-weight-normal">88,888,888,88</td>
                          <td class="text-sm font-weight-normal">88,888,888,88</td>
                          <td class="text-sm font-weight-normal">2</td>
                          <td class="text-sm font-weight-normal">5</td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="จัดการตั๋ว">
                              <i class="material-icons material-symbols-outlined">add</i>
                            </material-button>
                            <material-button color="warning" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="ตั๋วตีกลับ">
                              <i class="material-icons material-symbols-outlined">keyboard_return</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="ยกเลิกตั๋ว">
                              <i class="material-icons material-symbols-outlined">delete</i>
                            </material-button>
                          </td>
                        </tr>

                        <tr>
                          <td class="text-sm font-weight-normal">4</td>
                          <td class="text-sm font-weight-normal">xx/xx/xxxx xx:xx</td>
                          <td class="text-sm font-weight-normal">xxxxx xxxxxxxxx</td>
                          <td class="text-sm font-weight-normal">จำนำ</td>
                          <td class="text-sm font-weight-normal">อื่นๆ</td>
                          <td class="text-sm font-weight-normal">30.32</td>
                          <td class="text-sm font-weight-normal">88,888,888,88</td>
                          <td class="text-sm font-weight-normal">88,888,888,88</td>
                          <td class="text-sm font-weight-normal">2</td>
                          <td class="text-sm font-weight-normal">5</td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="จัดการตั๋ว">
                              <i class="material-icons material-symbols-outlined">add</i>
                            </material-button>
                            <material-button color="warning" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="ตั๋วตีกลับ">
                              <i class="material-icons material-symbols-outlined">keyboard_return</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="ยกเลิกตั๋ว">
                              <i class="material-icons material-symbols-outlined">delete</i>
                            </material-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="secondHome" class="tab-pane fade" role="tabpanel" aria-labelledby="second-home-tab">
            <div class="card mt-2">
              <div class="card-body pt-4">
                <div class="row">
                  <div class="col-6 col-lg-2">
                    <label class="form-label ms-0">ตั๋วเล่มที่</label>
                    <material-input id="ticketBook" disabled="true" value="xx" />
                  </div>
                  <div class="col-6 col-lg-3">
                    <label class="form-label ms-0">ตั๋วเลขที่</label>
                    <material-input id="ticketNo" disabled="true" value="xxxxxxx" />
                  </div>
                </div>
              </div>
            </div>

            <div class="card mt-4">
              <div class="card-header">
                <h5>ข้อมูลบัตร</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.cprefix') }}</label>
                    <material-input type="text" />
                  </div>
                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t('content.cnam') }}</label>
                    <material-input type="text" />
                  </div>
                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t('content.clsnam') }}</label>
                    <material-input type="text" />
                  </div>
                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t('content.cmobile') }}1*</label>
                    <material-input id="mobile1" type="phone" />
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t('content.cmobile') }}2</label>
                    <material-input id="mobile2" type="phone" />
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t('content.cmobile') }}3</label>
                    <material-input id="mobile3" type="phone" />
                  </div>
                  <div class="col-lg-2 col-sm-3">
                    <label class="form-label ms-0 png"></label>
                    <material-button id="cardSearch" color="primary" class="float-lg-start float-sm-end mb-3"
                      @click="toggleInfo">
                      <i v-if="datas.hide" class="material-icons material-symbols-outlined">expand_less</i>
                      <i v-else class="material-icons material-symbols-outlined">expand_more</i>
                    </material-button>
                  </div>
                </div>
                <div v-if="datas.hide" class="row mt-4">
                  <div class="col-lg-3 col-sm-4">
                    <label class="form-label ms-0">ประเภทบัตร</label>
                    <select class="form-select">
                      <option value="card1">บัตรประชาชน</option>
                      <option value="card2">ใบอนุญาตทำงาน</option>
                      <option value="card3">บัตรต่างด้าว</option>
                      <option value="card4">บัตรต่างด้าว</option>
                      <option value="card5">หนังสือเดินทาง</option>
                      <option value="card6">ใบขับขี่</option>
                    </select>
                  </div>
                  <div class="col-lg-7 col-sm-5">
                    <label class="form-label ms-0">เลขที่บัตร</label>
                    <material-input id="cardNumber" />
                  </div>
                  <div class="col-lg-2 col-sm-3">
                    <label class="form-label ms-0 png"></label>
                    <material-button id="cardSearch" color="primary" class="float-lg-start float-sm-end mb-3">
                      <i class="material-icons material-symbols-outlined">search</i> {{ $t('buttons.sear') }}
                    </material-button>
                  </div>
                  <div class="col-lg-4">
                    <label class="form-label ms-0">สถานที่ออกบัตร</label>
                    <material-input id="cardBy" />
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">วันออกบัตร</label>
                    <material-input id="cardIssue" />
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">วันหมดอายุ</label>
                    <material-input id="cardExpire" />
                  </div>
                </div>
                <div v-if="datas.hide" class="row mt-4">
                  <div class="col-lg-2">
                    <label class="form-label ms-0">วัน/เดือน/ปี เกิด</label>
                    <material-input id="birhdate" type="date" />
                  </div>
                  <div class="col-lg-1">
                    <label class="form-label ms-0">อายุ</label>
                    <material-input id="age" disabled="true" value="xx" />
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">ศาสนา</label>
                    <select class="form-select">
                      <option value="rel1">พุทธ</option>
                      <option value="rel2">อิสลาม</option>
                      <option value="rel3">คริสต์</option>
                      <option value="rel4">ฮินดู</option>
                      <option value="rel5">ซิกต์</option>
                      <option value="rel6">อื่นๆ</option>
                    </select>
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">หมู่เลือด</label>
                    <select class="form-select">
                      <option value="rel1">A</option>
                      <option value="rel2">B</option>
                      <option value="rel3">O</option>
                      <option value="rel4">AB</option>
                    </select>
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">สัญชาติ</label>
                    <material-input id="nationality" />
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t('content.amntypecustomer') }}</label>
                    <select class="form-select">
                      <option :value="$t('content.select')">{{ $t('content.select') }}</option>
                      <option v-for="(i, fs3) in datas.demo1" :key="fs3" :value="i.items">{{ i.items }}</option>
                    </select>
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t('content.amncredit') }}</label>
                    <material-input type="text" />
                  </div>
                </div>
                <div v-if="datas.hide" class="row mt-4">
                  <div class="col-12">
                    <label class="form-label ms-0">ที่อยู่</label>
                    <material-input id="address" />
                  </div>
                  <div class="col-lg-4">
                    <label class="form-label ms-0">จังหวัด</label>
                    <div class="input-group">
                      <input type="text" class="form-control">
                      <button class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                      </button>
                      <ul class="dropdown-menu dropdown-menu-end">
                        <li>
                          <div class="input-group">
                            <input type="text" class="form-control mb-0" placeholder="ค้นหา" />
                          </div>
                        </li>
                        <li>
                          <hr class="dropdown-divider">
                        </li>
                        <li><a class="dropdown-item" href="#">กรุงเทพฯ</a></li>
                        <li><a class="dropdown-item" href="#">นนทบุรี</a></li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label class="form-label ms-0">เขต/อำเภอ</label>
                    <div class="input-group">
                      <input type="text" class="form-control">
                      <button class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                      </button>
                      <ul class="dropdown-menu dropdown-menu-end">
                        <li>
                          <div class="input-group">
                            <input type="text" class="form-control mb-0" placeholder="ค้นหา" />
                          </div>
                        </li>
                        <li>
                          <hr class="dropdown-divider">
                        </li>
                        <li><a class="dropdown-item" href="#">พระนคร</a></li>
                        <li><a class="dropdown-item" href="#">ป้อมปราบฯ</a></li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <label class="form-label ms-0">แขวง/ตำบล</label>
                    <div class="input-group">
                      <input type="text" class="form-control">
                      <button class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                      </button>
                      <ul class="dropdown-menu dropdown-menu-end">
                        <li>
                          <div class="input-group">
                            <input type="text" class="form-control mb-0" placeholder="ค้นหา" />
                          </div>
                        </li>
                        <li>
                          <hr class="dropdown-divider">
                        </li>
                        <li><a class="dropdown-item" href="#">บวรนิเวศ</a></li>
                        <li><a class="dropdown-item" href="#">ตลาดยอด</a></li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-12">
                    <label class="form-label ms-0">อีเมล</label>
                    <material-input id="email" type="email" />
                  </div>
                </div>
              </div>
            </div>

            <div class="card mt-4">
              <div class="card-header">
                <div class="d-flex justify-content-between align-items-center">
                  <h5 class="mb-0 me-4"><b>{{ $t('content.amnpawnlist') }}</b></h5>

                  <div class="col">
                    <div class="input-group">
                      <input v-model="datas.searchBox1" type="text" class="form-control"
                        style="margin-bottom: 0 !important;" aria-describedby="searchNameOwner"
                        :placeholder="$t('buttons.sear') + ' ' + $t('content.amnassetowner') + '/' + $t('content.cabove')"
                        readonly>
                      <button id="searchNameOwner" class="btn btn-primary  mb-0" type="button" data-bs-toggle="modal"
                        data-bs-target="#relationPersonalModal">
                        <i class="material-icons material-symbols-outlined">search</i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body pt-0">
                <div class="row mt-3 mb-4">
                  <div class="col-12">
                    <div class="d-flex justify-content-between align-items-center">
                      <h6 class="text-info mb-2 mb-lg-0 me-4">{{ $t('content.amnsplitlist') }} 1/5</h6>

                      <div class="col">
                        <material-input type="text" class="input-field-no-margin mb-2 mb-lg-0"
                          :placeholder="$t('content.amnoldmessage')" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.massetgroup') }}</label>
                    <select class="form-select">
                      <option value="card0">{{ gold }}</option>
                      <option value="card2">{{ diamond }}</option>
                      <option value="card3">{{ frame }}</option>
                      <option value="card4">{{ copper }}</option>
                      <option value="card5">{{ watch }}</option>
                      <option value="card6">{{ others }}</option>
                    </select>
                  </div>
                  <div class="col-lg-5">
                    <label class="form-label ms-0">{{ $t('content.amntypeassets') }}</label>
                    <div class="input-group">
                      <input type="text" class="form-control">
                      <button class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                      </button>
                      <ul class="dropdown-menu dropdown-menu-end">
                        <li>
                          <div class="input-group">
                            <input type="text" class="form-control mb-0" placeholder="ค้นหา" />
                          </div>
                        </li>
                        <li>
                          <hr class="dropdown-divider">
                        </li>
                        <li><a class="dropdown-item" href="#">
                            Type1 >> Type2 >> Type3 >> Type4 >> Type5 >> Type6</a></li>
                        <li><a class="dropdown-item" href="#">
                            Type1 >> Type2 >> Type3 >> Type4 >> Type5 >> Type6</a></li>
                        <li><a class="dropdown-item" href="#">
                            Type1 >> Type2 >> Type3 >> Type4 >> Type5 >> Type6</a></li>
                      </ul>
                    </div>
                  </div>

                  <div class="col-lg-1">
                    <label class="form-label ms-0">{{ $t('content.amnqtyunit') }}</label>
                    <material-input id="amountPawn" />
                  </div>

                  <div class="col-lg-1">
                    <label class="form-label ms-0">{{ $t('content.amnqtydiamond') }}</label>
                    <material-input id="qtyDiamond" />
                  </div>

                  <div class="col-lg-3">
                    <label class="form-label ms-0">Serial Number</label>
                    <material-input id="serialPawn" />
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.amnweightval') }}</label>
                    <material-input id="weightPawn" />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.amnweightvalall') }}</label>
                    <material-input id="weightAllPawn" disabled="true" value="888.88" />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.amnanalysval') }}</label>
                    <material-input id="analysePawn" />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.amnanalysvalall') }}</label>
                    <material-input id="analyseAllPawn" disabled="true" value="88,888,888.88" />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.amnmaxval') }}</label>
                    <material-input id="fullPawn" />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.amnmaxvalall') }}</label>
                    <material-input id="fullAllPawn" disabled="true" value="88,888,888.88" />
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.cintrate') }}(%)</label>
                    <select class="form-select">
                      <option :value="$t('content.select')">{{ $t('content.select') }}</option>
                      <option v-for="(j, n3) in datas.demo2" :key="n3" :value="j.items">
                        {{ j.items }}
                      </option>
                    </select>
                  </div>

                  <div class="col-lg-8">
                    <label class="form-label ms-0">{{ $t('content.amnassetdetail') }}</label>
                    <material-input id="detailPawn" />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0 png"></label>
                    <material-button id="cardSearch" color="primary" class="float-lg-start float-sm-end mb-3">
                      <i class="material-icons material-symbols-outlined">add</i> {{ add }}
                    </material-button>
                  </div>
                </div>

                <div class="row mt-4">
                  <h6><b>{{ $t('content.amnassetdatas') }}</b></h6>
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>{{ $t('content.amndatetime') }}</th>
                          <th>{{ $t('content.amnbiz') }}</th>
                          <th>{{ $t('content.amnassetgroup') }}</th>
                          <th>{{ $t('content.amnweightval') }}</th>
                          <th>{{ $t('content.amnweightvalall') }}</th>
                          <th>{{ $t('content.amnanalysval') }}</th>
                          <th>{{ $t('content.amnanalysvalall') }}</th>
                          <th>{{ $t('content.amnmaxval') }}</th>
                          <th>{{ $t('content.amnmaxvalall') }}</th>
                          <th>{{ $t('content.amnqtydiamond') }}</th>
                          <th>{{ $t('content.amnqtyunit') }}</th>
                          <th>{{ $t('content.amnassetdetail') }}</th>
                          <th>{{ $t('content.act') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-sm font-weight-normal">1</td>
                          <td class="text-sm font-weight-normal">xx/xx/xxxx xx:xx</td>
                          <td class="text-sm font-weight-normal">จำนำ</td>
                          <td class="text-sm font-weight-normal">อื่นๆ</td>
                          <td class="text-sm font-weight-normal">12.56</td>
                          <td class="text-sm font-weight-normal">30.32</td>
                          <td class="text-sm font-weight-normal">88,888,888,88</td>
                          <td class="text-sm font-weight-normal">88,888,888,88</td>
                          <td class="text-sm font-weight-normal">88,888,888,88</td>
                          <td class="text-sm font-weight-normal">88,888,888,88</td>
                          <td class="text-sm font-weight-normal">2</td>
                          <td class="text-sm font-weight-normal">5</td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="info" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="seed" @click="onLookupAssetDetail">
                              <i class="material-icons material-symbols-outlined">description</i>
                            </material-button>
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="warning" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="split" @click="onSplitTicketList">
                              <i class="material-icons material-symbols-outlined">vertical_split</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="cancel">
                              <i class="material-icons material-symbols-outlined">delete</i>
                            </material-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="col-12 d-flex justify-content-end">
                    <div class="me-3">
                      <material-button id="saveTicket" color="primary" class="float-lg-start float-sm-end mb-3"
                        @click="onScanFinger">
                        <i class="material-icons material-symbols-outlined">fingerprint</i> {{
                          $t('content.amnfingerprint') }}
                      </material-button>
                    </div>
                    <div class="me-3">
                      <material-button id="saveTicket" color="primary" class="float-lg-start float-sm-end mb-3">
                        <i class="material-icons material-symbols-outlined">save</i> {{ save }}
                      </material-button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div id="thirdHome" class="tab-pane fade" role="tabpanel" aria-labelledby="third-home-tab">
            <div class="card mt-2">
              <div class="card-header mb-0">
                <h5>{{ $t('content.amnonprocess') }}</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.amnbiz') }}</label>
                    <select class="form-select">
                      <option value="card0">{{ $t('content.optall') }}</option>
                      <option value="card2">{{ $t('content.actpawn1') }}</option>
                      <option value="card3">{{ $t('content.actpawn2') }}</option>
                      <option value="card4">{{ $t('content.actpawn3') }}</option>
                      <option value="card5">{{ $t('content.actpawn4') }}</option>
                      <option value="card6">{{ $t('content.actpawn5') }}</option>
                    </select>
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.ticketno') }}</label>
                    <material-input type="text" />
                  </div>

                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t('content.names') }}</label>
                    <div class="input-group">
                      <input v-model="datas.searchBox1" type="text" class="form-control" aria-describedby="searchName1"
                        readonly>
                      <button id="searchName1" class="btn btn-primary" type="button" data-bs-toggle="modal"
                        data-bs-target="#searchNameModal">
                        <i class="material-icons material-symbols-outlined">search</i>
                      </button>
                    </div>
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0 png"></label>
                    <material-button id="cardSearch" color="primary" class="float-lg-start float-sm-end mb-3">
                      <i class="material-icons material-symbols-outlined">search</i> ค้นหา
                    </material-button>
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th class="align-top">#</th>
                          <th class="align-top">{{ $t('content.amndatetime') }}</th>
                          <th class="align-top">{{ $t('content.cname') }}</th>
                          <th class="align-top">{{ $t('content.ticketno') }}</th>
                          <th class="align-top">{{ $t('content.amnbiz') }}</th>
                          <th class="align-top">{{ $t('content.amnassetgroup') }}</th>
                          <th class="align-top">{{ $t('content.amnweightvalall') }}</th>
                          <th class="align-top">{{ $t('content.amnqtyunitall') }}</th>
                          <th class="align-top">{{ $t('content.amnqtydiamond') }}</th>
                          <th class="align-top">{{ $t('content.amnanalysvalall') }}</th>
                          <th colspan="2" class="align-top text-center">
                            {{ $t('content.amnchannel') }}
                          </th>
                          <th class="align-top">{{ $t('content.amnbankprocess') }}</th>
                        </tr>

                        <tr>
                          <th colspan="10"></th>
                          <th class="align-top text-center">{{ $t('content.amncash') }}</th>
                          <th class="align-top text-center">{{ $t('content.amntransfer') }}</th>
                          <th></th>
                        </tr>
                      </thead>

                      <tbody v-for="(i, wait1) in waiting" :key="wait1" :data-group-index="wait1">
                        <tr class="group-id-ticket">
                          <td class="text-sm font-weight-normal" colspan="13">
                            <div class="group-tickect">
                              <h6 class="mb-0"><b>{{ $t('content.amngroupticket') }} #{{ i.g }}</b></h6>
                            </div>
                          </td>
                        </tr>
                        <tr v-for="(j, wait2) in i.tr" :key="wait2">
                          <td class="text-sm font-weight-normal">{{ j.id }}</td>
                          <td class="text-sm font-weight-normal">{{ j.dt }}</td>
                          <td class="text-sm font-weight-normal">{{ j.nm }}</td>
                          <td class="text-sm font-weight-normal">{{ j.no }}</td>
                          <td class="text-sm font-weight-normal">{{ j.ty }}</td>
                          <td class="text-sm font-weight-normal">{{ j.as }}</td>
                          <td class="text-sm font-weight-normal">{{ fmNumber(j.tw) }}</td>
                          <td class="text-sm font-weight-normal">{{ fmNumber(j.qt) }}</td>
                          <td class="text-sm font-weight-normal">{{ fmNumber(j.bl) }}</td>
                          <td class="text-sm font-weight-normal">{{ fmNumber(j.tp) }}</td>
                          <td v-if="wait2 == 0" :rowspan="Object.keys(i.tr).length" class="text-sm font-weight-normal">{{
                            fmNumber(i.cc) }}</td>
                          <td v-if="wait2 == 0" :rowspan="Object.keys(i.tr).length" class="text-sm font-weight-normal">{{
                            fmNumber(i.ct) }}</td>
                          <td v-if="wait2 == 0" :rowspan="Object.keys(i.tr).length" class="text-sm font-weight-normal"
                            :class="i.ps == 'Submited' ? 'text-warning' : (i.ps == 'Verified' ? 'text-success' : 'text-info')">
                            {{ i.ps }}
                          </td>
                        </tr>
                      </tbody>

                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="fourthHome" class="tab-pane fade" role="tabpanel" aria-labelledby="fourth-home-tab">
            <div class="card mt-2">
              <div class="card-header mb-0">
                <h5>{{ $t('content.amncancelticket') }}</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.sdate') }}</label>
                    <material-input type="date" />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.edate') }}</label>
                    <material-input type="date" />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.names') }}</label>
                    <div class="input-group">
                      <input v-model="datas.searchBox1" type="text" class="form-control" aria-describedby="searchName1"
                        readonly>
                      <button id="searchName1" class="btn btn-primary" type="button" data-bs-toggle="modal"
                        data-bs-target="#searchNameModal">
                        <i class="material-icons material-symbols-outlined">search</i>
                      </button>
                    </div>
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.ticketno') }}</label>
                    <material-input type="text" />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.amnbiz') }}</label>
                    <select class="form-select">
                      <option value="card0">{{ $t('content.optall') }}</option>
                      <option value="card2">{{ $t('content.actpawn1') }}</option>
                      <option value="card3">{{ $t('content.actpawn2') }}</option>
                      <option value="card4">{{ $t('content.actpawn3') }}</option>
                      <option value="card5">{{ $t('content.actpawn4') }}</option>
                      <option value="card6">{{ $t('content.actpawn5') }}</option>
                    </select>
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0 png"></label>
                    <material-button color="primary" class="float-lg-start float-sm-end mb-3">
                      <i class="material-icons material-symbols-outlined">search</i> ค้นหา
                    </material-button>
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th class="align-top">#</th>
                          <th class="align-top">{{ $t('content.amndatetime') }}</th>
                          <th class="align-top">{{ $t('content.cname') }}</th>
                          <th class="align-top">{{ $t('content.ticketno') }}</th>
                          <th class="align-top">{{ $t('content.amnbiz') }}</th>
                          <th class="align-top">{{ $t('content.amnassetgroup') }}</th>
                          <th class="align-top">{{ $t('content.amnweightvalall') }}</th>
                          <th class="align-top">{{ $t('content.amnqtyunitall') }}</th>
                          <th class="align-top">{{ $t('content.amnqtydiamond') }}</th>
                          <th class="align-top">{{ $t('content.amnanalysvalall') }}</th>
                          <th colspan="2" class="align-top text-center">
                            {{ $t('content.amnchannel') }}
                          </th>
                          <th class="align-top">{{ $t('content.amnbankprocess') }}</th>
                          <th>{{ $t('content.act') }}</th>
                        </tr>

                        <tr>
                          <th colspan="10"></th>
                          <th class="align-top text-center">{{ $t('content.amncash') }}</th>
                          <th class="align-top text-center">{{ $t('content.amntransfer') }}</th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr v-for="(c, cancel1) in cancelling" :key="cancel1">
                          <td class="text-sm font-weight-normal">{{ c.id }}</td>
                          <td class="text-sm font-weight-normal">{{ c.dt }}</td>
                          <td class="text-sm font-weight-normal">{{ c.nm }}</td>
                          <td class="text-sm font-weight-normal">{{ c.no }}</td>
                          <td class="text-sm font-weight-normal">{{ c.ty }}</td>
                          <td class="text-sm font-weight-normal">{{ c.as }}</td>
                          <td class="text-sm font-weight-normal">{{ fmNumber(c.tw) }}</td>
                          <td class="text-sm font-weight-normal">{{ fmNumber(c.qt) }}</td>
                          <td class="text-sm font-weight-normal">{{ fmNumber(c.bl) }}</td>
                          <td class="text-sm font-weight-normal">{{ fmNumber(c.tp) }}</td>
                          <td class="text-sm font-weight-normal">{{ fmNumber(c.cc) }}</td>
                          <td class="text-sm font-weight-normal">{{ fmNumber(c.ct) }}</td>
                          <td class="text-sm font-weight-normal"
                            :class="c.ps == 'Submited' ? 'text-warning' : (c.ps == 'Verified' ? 'text-success' : 'text-info')">
                            {{ c.ps }}
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('buttons.canc')"
                              @click="onCancelTicket">
                              <i class="material-icons material-symbols-outlined">close</i>
                            </material-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <!-- Search Customer Dialog -->
        <div id="searchNameModal" class="modal fade" tabindex="-1" aria-labelledby="searchNameModalLabel"
          aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">

              <div class="modal-header">
                <h4 class="modal-title">{{ $t('content.cnamelist') }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <div class="preview-table">
                  <section class="panel">
                    <table class="table table-striped table-responsive">
                      <thead>
                        <tr>
                          <th style="width: 100px;">{{ $t('content.ccode') }}</th>
                          <th style="width: 180px;">{{ $t('content.cnameth') }}</th>
                          <th style="width: 180px;">{{ $t('content.cnameen') }}</th>
                          <th style="width: 160px;">{{ $t('content.cidcard') }}</th>
                          <th style="width: 110px;">{{ $t('content.cmobile') }}</th>
                          <th style="width: 120px;">{{ $t('content.cgrade') }}</th>
                          <!-- <th></th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="row-search">
                          <td>
                            <material-input type="text" :label="t('content.ccode')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cnameth')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cnameen')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cidcard')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cmobile')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cgrade')" />
                          </td>
                          <!-- <td>
                                <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                                  data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch">
                                  <i class="material-icons material-symbols-outlined">search</i>
                                </material-button>
                              </td> -->
                        </tr>
                        <tr v-for="(i, cs) in datas.cname" :key="cs" data-bs-dismiss="modal">
                          <td>{{ i.cd }}</td>
                          <td>{{ i.th }}</td>
                          <td>{{ i.en }}</td>
                          <td>{{ i.id }}</td>
                          <td>{{ i.mb }}</td>
                          <td>{{ i.gd }}</td>
                          <!-- <td></td> -->
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </div>
              </div>

              <div class="modal-footer">
                <!-- <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button> -->
                <nav aria-label="Table Paginator">
                  <ul class="pagination justify-content-end">
                    <li class="page-item disabled">
                      <a class="page-link" href="#" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item">
                      <a class="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>

            </div>
          </div>
        </div>

        <!-- Search Relation Dialog -->
        <div id="relationPersonalModal" class="modal fade" tabindex="-1" aria-labelledby="searchNameModalLabel"
          aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">

              <div class="modal-header">
                <h4 class="modal-title">{{ $t('content.cnamelist') }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <div class="preview-table">
                  <section class="panel">
                    <table class="table table-striped table-responsive">
                      <thead>
                        <tr>
                          <th style="width: 100px;">{{ $t('content.ccode') }}</th>
                          <th style="width: 180px;">{{ $t('content.cnameth') }}</th>
                          <th style="width: 180px;">{{ $t('content.cnameen') }}</th>
                          <th style="width: 160px;">{{ $t('content.cidcard') }}</th>
                          <th style="width: 110px;">{{ $t('content.cmobile') }}</th>
                          <th style="width: 120px;">{{ $t('content.cgrade') }}</th>
                          <!-- <th></th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="row-search">
                          <td>
                            <material-input type="text" :label="t('content.ccode')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cnameth')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cnameen')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cidcard')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cmobile')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cgrade')" />
                          </td>
                          <!-- <td>
                                <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                                  data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch">
                                  <i class="material-icons material-symbols-outlined">search</i>
                                </material-button>
                              </td> -->
                        </tr>
                        <tr v-for="(i, cs) in datas.cname" :key="cs" data-bs-dismiss="modal">
                          <td>{{ i.cd }}</td>
                          <td>{{ i.th }}</td>
                          <td>{{ i.en }}</td>
                          <td>{{ i.id }}</td>
                          <td>{{ i.mb }}</td>
                          <td>{{ i.gd }}</td>
                          <!-- <td></td> -->
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </div>
              </div>

              <div class="modal-footer">
                <!-- <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button> -->
                <nav aria-label="Table Paginator">
                  <ul class="pagination justify-content-end">
                    <li class="page-item disabled">
                      <a class="page-link" href="#" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item">
                      <a class="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, inject, computed, onMounted } from 'vue'
import { useI18n } from "vue-i18n"
import * as Choices from "choices.js";

import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";

const { t } = useI18n()
const swal = inject('$swal')

const datas = reactive({
  hide: false,
  searchBox1: "",
  searchBox2: "",
  demo1: [
    { items: "ธรรมดา" },
    { items: "VIP 1" },
    { items: "VIP 2" },
    { items: "VIP 3" },
    { items: "VIP 4" },
  ],
  demo2: [
    { items: "1.75%" },
    { items: "1.5%" },
    { items: "1%" },
    { items: "0.75%" },
  ],
  cname: [
    {
      cd: "xxxxxx",
      th: "พิชัย จิตภักดี",
      en: "Pichai Jitphakdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "A",
    },
    {
      cd: "xxxxxx",
      th: "นิรุจ เสมากิจ",
      en: "Nirut Semakij",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "VIP",
    },
    {
      cd: "xxxxxx",
      th: "บวรศักดิ์ นิลสุภาพ",
      en: "Borwonsak Nilsupap",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "S",
    },
    {
      cd: "xxxxxx",
      th: "ชิดชนก วิมลพัฒน์",
      en: "Chidchanok Wimonpatr",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "C",
    },
    {
      cd: "xxxxxx",
      th: "สมบูรณ์ทรัพย์ ทองดี",
      en: "Somboonsap Thongdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "B",
    },
    {
      cd: "xxxxxx",
      th: "พิชัย จิตภักดี",
      en: "Pichai Jitphakdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "A",
    },
    {
      cd: "xxxxxx",
      th: "นิรุจ เสมากิจ",
      en: "Nirut Semakij",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "VIP",
    },
    {
      cd: "xxxxxx",
      th: "บวรศักดิ์ นิลสุภาพ",
      en: "Borwonsak Nilsupap",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "S",
    },
    {
      cd: "xxxxxx",
      th: "ชิดชนก วิมลพัฒน์",
      en: "Chidchanok Wimonpatr",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "C",
    },
    {
      cd: "xxxxxx",
      th: "สมบูรณ์ทรัพย์ ทองดี",
      en: "Somboonsap Thongdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "B",
    },
  ],
})

const waiting = reactive([
  {
    g: "1",
    tr: [
      {
        id: 1,
        dt: "22/12/2023 10:43",
        nm: "xxxx xxxxx",
        no: "2033",
        ty: "จำนำ",
        as: "xxxx >> xxxx >> xxxx >> xxxx",
        tw: 88.88,
        qt: 88,
        bl: 88,
        tp: 88888888.88,
      },
      {
        id: 2,
        dt: "22/12/2023 10:56",
        nm: "xxxx xxxxx",
        no: "2035",
        ty: "ไถ่ถอน",
        as: "xxxx >> xxxx >> xxxx >> xxxx",
        tw: 88.88,
        qt: 88,
        bl: 88,
        tp: 88888888.88,
      },
      {
        id: 3,
        dt: "22/12/2023 10:56",
        nm: "xxxx xxxxx",
        no: "2036",
        ty: "ลดต้น",
        as: "xxxx >> xxxx >> xxxx >> xxxx",
        tw: 88.88,
        qt: 88,
        bl: 88,
        tp: 88888888.88,
      },
      {
        id: 4,
        dt: "22/12/2023 10:56",
        nm: "xxxx xxxxx",
        no: "2037",
        ty: "เพิ่มต้น",
        as: "xxxx >> xxxx >> xxxx >> xxxx",
        tw: 88.88,
        qt: 88,
        bl: 88,
        tp: 88888888.88,
      },
    ],
    cc: 88888888.88,
    ct: 88888888.88,
    ps: "Submited"
  },
  {
    g: "2",
    tr: [
      {
        id: 1,
        dt: "22/12/2023 10:43",
        nm: "xxxx xxxxx",
        no: "2033",
        ty: "จำนำ",
        as: "xxxx >> xxxx >> xxxx >> xxxx",
        tw: 88.88,
        qt: 88,
        bl: 88,
        tp: 88888888.88,
      },
      {
        id: 2,
        dt: "22/12/2023 10:56",
        nm: "xxxx xxxxx",
        no: "2035",
        ty: "ไถ่ถอน",
        as: "xxxx >> xxxx >> xxxx >> xxxx",
        tw: 88.88,
        qt: 88,
        bl: 88,
        tp: 88888888.88,
      },
      {
        id: 3,
        dt: "22/12/2023 10:56",
        nm: "xxxx xxxxx",
        no: "2036",
        ty: "ลดต้น",
        as: "xxxx >> xxxx >> xxxx >> xxxx",
        tw: 88.88,
        qt: 88,
        bl: 88,
        tp: 88888888.88,
      },
    ],
    cc: 88888888.88,
    ct: 88888888.88,
    ps: "Verified"
  },
  {
    g: "3",
    tr: [
      {
        id: 1,
        dt: "22/12/2023 10:43",
        nm: "xxxx xxxxx",
        no: "2033",
        ty: "จำนำ",
        as: "xxxx >> xxxx >> xxxx >> xxxx",
        tw: 88.88,
        qt: 88,
        bl: 88,
        tp: 88888888.88,
      },
      {
        id: 2,
        dt: "22/12/2023 10:56",
        nm: "xxxx xxxxx",
        no: "2035",
        ty: "ไถ่ถอน",
        as: "xxxx >> xxxx >> xxxx >> xxxx",
        tw: 88.88,
        qt: 88,
        bl: 88,
        tp: 88888888.88,
      },
      {
        id: 3,
        dt: "22/12/2023 10:56",
        nm: "xxxx xxxxx",
        no: "2036",
        ty: "ลดต้น",
        as: "xxxx >> xxxx >> xxxx >> xxxx",
        tw: 88.88,
        qt: 88,
        bl: 88,
        tp: 88888888.88,
      },
      {
        id: 4,
        dt: "22/12/2023 10:56",
        nm: "xxxx xxxxx",
        no: "2037",
        ty: "เพิ่มต้น",
        as: "xxxx >> xxxx >> xxxx >> xxxx",
        tw: 88.88,
        qt: 88,
        bl: 88,
        tp: 88888888.88,
      },
    ],
    cc: 88888888.88,
    ct: 88888888.88,
    ps: "Waiting for Authorize"
  },
])

const cancelling = reactive([
  {
    id: 1,
    dt: "22/12/2023 10:43",
    nm: "xxxx xxxxx",
    no: "2033",
    ty: "จำนำ",
    as: "xxxx >> xxxx >> xxxx >> xxxx",
    tw: 88.88,
    qt: 88,
    bl: 88,
    tp: 88888888.88,
    cc: 88888888.88,
    ct: 88888888.88,
    ps: "Submited"
  },
  {
    id: 2,
    dt: "22/12/2023 10:56",
    nm: "xxxx xxxxx",
    no: "2035",
    ty: "ไถ่ถอน",
    as: "xxxx >> xxxx >> xxxx >> xxxx",
    tw: 88.88,
    qt: 88,
    bl: 88,
    tp: 88888888.88,
    cc: 88888888.88,
    ct: 88888888.88,
    ps: "Verified"
  },
  {
    id: 3,
    dt: "22/12/2023 10:56",
    nm: "xxxx xxxxx",
    no: "2036",
    ty: "ลดต้น",
    as: "xxxx >> xxxx >> xxxx >> xxxx",
    tw: 88.88,
    qt: 88,
    bl: 88,
    tp: 88888888.88,
    cc: 88888888.88,
    ct: 88888888.88,
    ps: "Verified"
  },
  {
    id: 4,
    dt: "22/12/2023 10:56",
    nm: "xxxx xxxxx",
    no: "2037",
    ty: "เพิ่มต้น",
    as: "xxxx >> xxxx >> xxxx >> xxxx",
    tw: 88.88,
    qt: 88,
    bl: 88,
    tp: 88888888.88,
    cc: 88888888.88,
    ct: 88888888.88,
    ps: "Waiting for Authorize"
  },
])

///// computed
const add = computed(() => t("buttons.addd"))
const save = computed(() => t("buttons.save"))
const cancel = computed(() => t("buttons.canc"))
const split = computed(() => t("buttons.split"))
const seed = computed(() => t("buttons.seed"))

const gold = computed(() => t("content.amngold"))
const diamond = computed(() => t("content.amndiamond"))
const frame = computed(() => t("content.amnframe"))
const copper = computed(() => t("content.amncopper"))
const watch = computed(() => t("content.amnwatch"))
const others = computed(() => t("content.amnothers"))

const toggleInfo = () => {
  if (!datas.hide) {
    datas.hide = true
  } else {
    datas.hide = false
  }
}

const fmNumber = (val) => {
  return Intl.NumberFormat("en-US").format(val)
}

async function onLookupAssetDetail() {
  swal({
    title: t('content.amnassetdetail'),
    html:
      '<div class="preview-note">' +
      '<h6 class="mb">Notice:</h6>' +
      '<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content here, making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for lorem ipsum will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>' +
      '</div>',
    allowOutsideClick: true,
    showCloseButton: true,
    showCancelButton: false,
    showConfirmButton: false,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
  });
}

async function onSplitTicketList() {
  swal({
    title: "ต้องการแยกทรัพย์นี้หรือไม่",
    text: "สามารถกรอกจำนวนรายการแยกทรัพย์ได้สูงสุด 5 รายการ",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: "บันทึก",
    cancelButtonText: "ยกเลิก",
    input: 'text',
    inputPlaceholder: "กรอกจำนวนรายการ"
  },
    function (inputValue) {
      if (inputValue === null) return false;

      if (inputValue === "") {
        swal.showInputError("กรุณากรอกรายการแยกทรัพย์");
        return false
      }

      swal("ยืนยันรายการแยกทรัพย์", "จำนวนรายการแยกทรัพย์ " + inputValue, "success");
    }).then((result) => {
      if (result.isConfirmed) {
        swal("แยกทรัพย์เสร็จสิ้น", "", "success");
      } else if (result.dismiss == 'cancel') {
        swal("ยกเลิกการแยกทรัพย์", "", "error");
      }
    });
}

async function onScanFinger() {
  swal({
    title: "พิมพ์ลายนิ้วมือ",
    text: "ระบบจะนำท่านสู่ขั้นตอนการสแกนลายนิ้วมือดิจิตอลตอนนี้",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: "ตกลง",
    cancelButtonText: "ยกเลิก",
  });
}

async function onCancelTicket() {
  swal({
    title: t('content.amncancelticket'),
    text: "คุณต้องการยกเลิกธุรกรรมตั๋วรายการนี้",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    showDenyButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('content.amncancelticket'),
    cancelButtonText: t('buttons.clos'),
    denyButtonText: t('content.amncanceladdnew'),
    confirmButtonColor: "#b71c1c",
    cancelButtonColor: "#757575",
    denyButtonColor: "#0066b3",
  });
}

onMounted(() => {
  if (document.getElementById("choices-gender")) {
    var gender = document.getElementById("choices-gender");
    new Choices(gender);
  }

  if (document.getElementById("choices-language")) {
    var language = document.getElementById("choices-language");
    new Choices(language);
  }

  if (document.getElementById("choices-month")) {
    var month = document.getElementById("choices-month");
    setTimeout(function () {
      new Choices(month);
    }, 1);

    // eslint-disable-next-line no-unused-vars
    var d = new Date();
    var monthArray = new Array();
    monthArray[0] = "January";
    monthArray[1] = "February";
    monthArray[2] = "March";
    monthArray[3] = "April";
    monthArray[4] = "May";
    monthArray[5] = "June";
    monthArray[6] = "July";
    monthArray[7] = "August";
    monthArray[8] = "September";
    monthArray[9] = "October";
    monthArray[10] = "November";
    monthArray[11] = "December";
    for (let m = 0; m <= 11; m++) {
      var optn = document.createElement("OPTION");
      optn.text = monthArray[m];
      // server side month start from one\
      optn.value = m + 1;
      // if june selected
      if (m == 1) {
        optn.selected = true;
      }
      month.options.add(optn);
    }
  }

  if (document.getElementById("choices-day")) {
    var day = document.getElementById("choices-day");
    setTimeout(function () {
      new Choices(day);
    }, 1);

    for (let y = 1; y <= 31; y++) {
      var optn1 = document.createElement("OPTION");
      optn1.text = y;
      optn1.value = y;

      if (y == 1) {
        optn1.selected = true;
      }

      day.options.add(optn1);
    }
  }

  if (document.getElementById("choices-year")) {
    var year = document.getElementById("choices-year");
    setTimeout(function () {
      new Choices(year);
    }, 1);

    for (let y = 1900; y <= 2020; y++) {
      var optn2 = document.createElement("OPTION");
      optn2.text = y;
      optn2.value = y;

      if (y == 2020) {
        optn.selected = true;
      }

      year.options.add(optn2);
    }
  }

  if (document.getElementById("choices-skills")) {
    var skills = document.getElementById("choices-skills");
    new Choices(skills, {
      delimiter: ",",
      editItems: true,
      maxItemCount: 5,
      removeItemButton: true,
      addItems: true,
    });
  }

})
</script>

<style lang="scss" scoped></style>