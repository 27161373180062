/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useSanctionPersonStore = defineStore("sanctionperson", {
  actions: {
    async sanctionpListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          docNumber: payload.docNumber,
          "name:contains": payload.name,
          severity: payload.severity,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/watch-svc/platform/sanction-person/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async sanctionpInfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl =
            "/watch-svc/platform/sanction-person/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async sanctionpAddFetch(payload) {
      const body = {
        docNumber: payload.docNumber,
        name: payload.name,
        remark: payload.remark,
        severity: payload.severity,
        reportBy: payload.reportBy,
        reportDate: payload.reportDate,
        refDocUid: payload.refDocUid,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/watch-svc/platform/sanction-person/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
    async sanctionpUpdateFetch(payload) {
      const body = {
        uid: payload.uid,
        docNumber: payload.docNumber,
        name: payload.name,
        remark: payload.remark,
        severity: payload.severity,
        reportBy: payload.reportBy,
        reportDate: payload.reportDate,
        refDocUid: payload.refDocUid,
        version: payload.version,
        status: payload.status,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/watch-svc/platform/sanction-person/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async sanctionpDeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl =
            "/watch-svc/platform/sanction-person/delete/" + payload.uid;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
