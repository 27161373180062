<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12">
        <div class="card h-100 mt-3">
          <div class="card-header mb-0">
            <div class="row no-gutters">
              <div class="col-12 d-flex justify-content-between align-items-center">
                <h5>{{ $t("content.munit") }}</h5>
                <div>
                  <material-button color="primary" class="float-lg-start float-sm-end mb-0" @click="addNewItems">
                    <i class="material-icons material-symbols-outlined">add</i>
                    {{ btnAddNew }}
                  </material-button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="table-responsive">
                <table class="table table-flush">
                  <thead class="thead-light">
                    <tr>
                      <th style="width: 70px;">#</th>
                      <th>{{ $t('content.munit') }}</th>
                      <!-- <th style="width: 180px;">{{ $t('content.amnassetgroup') }}</th> -->
                      <th style="width: 100px;">{{ $t('content.act') }}</th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr class="group-id-ticket">
                      <td class="text-sm font-weight-normal">
                        <div class="group-tickect">
                          <h6 class="mb-0"><b>{{ btnSearch }}</b></h6>
                        </div>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-input type="text" class="mb-0" :label="$t('content.detail')" />
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch" @click="loadDataApi">
                          <i class="material-icons material-symbols-outlined">search</i>
                        </material-button>
                      </td>
                    </tr>

                    <tr v-if="addNew">
                      <td class="text-sm font-weight-normal">#</td>
                      <td class="text-sm font-weight-normal">
                        <material-input v-model="newunit" type="text" class="mb-0" />
                      </td>
                      <!-- <td class="text-sm font-weight-normal">
                        <select class="form-select">
                          <option v-for="(a, opt1) in datas.assetAll" :key="opt1" :value="a.items">
                            {{ a.items }}
                          </option>
                        </select>
                      </td> -->
                      <td class="text-sm font-weight-normal">
                        <material-button color="primary" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave"
                          :disabled="newunit.trim() === ''" @click="saveNewItems">
                          <i class="material-icons material-symbols-outlined">save</i>
                        </material-button>
                        <material-button color="danger" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                          @click="cancelSaveNewItems">
                          <i class="material-icons material-symbols-outlined">close</i>
                        </material-button>
                      </td>
                    </tr>

                    <tr v-for="(row, index) in list" :key="index">
                      <td class="text-sm font-weight-normal">
                        {{ formatNumberString((currentPage - 1) * perPage + index + 1) }}
                      </td>
                      <td v-if="row.uid === uid" class="text-sm font-weight-normal">
                        <material-input v-model="newunit" type="text" class="mb-0" />
                      </td>
                      <!-- <td v-if="row.uid === uid" class="text-sm font-weight-normal">
                        <select class="form-select">
                          <option v-for="(b, opt2) in datas.assetAll" :key="opt2" :value="b.items">
                            {{ b.items }}
                          </option>
                        </select>
                      </td> -->
                      <td v-if="row.uid === uid" class="text-sm font-weight-normal">
                        <material-button color="primary" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave"
                          :disabled="newunit.trim() === ''" @click="saveEditRowItems">
                          <i class="material-icons material-symbols-outlined">save</i>
                        </material-button>
                        <material-button color="danger" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                          @click="cancelEditRowItems">
                          <i class="material-icons material-symbols-outlined">close</i>
                        </material-button>
                      </td>
                      <td v-if="row.uid !== uid" class="text-sm font-weight-normal">
                        {{ row.name }}
                      </td>
                      <!-- <td v-if="row.uid !== uid" class="text-sm font-weight-normal">
                        {{ datas.assetAll[2].items }}
                      </td> -->
                      <td v-if="row.uid !== uid" class="text-sm font-weight-normal">

                        <material-button color="info" variant="outline" class="rounded-circle" data-bs-toggle="tooltip"
                          data-bs-placement="top" :title="btnEdit" @click="editRowItems(row)">
                          <i class="material-icons material-symbols-outlined">edit</i>
                        </material-button>
                        <material-button color="danger" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel" @click="deleteRowItems(row)">
                          <i class="material-icons material-symbols-outlined">delete</i>
                        </material-button>
                      </td>
                    </tr>

                    <tr v-if="list.length === 0">
                      <td></td>
                      <td class="text-sm font-weight-normal">
                        <p style="text-align: center">No Record Found</p>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="4" style="text-align: right">Total Rows : {{ totalRows_s }}</td>
                    </tr>
                  </tfoot>
                </table>
                <ul v-show="list.length > 0" class="pagination justify-content-end">

                  <div class="project-container">
                    <div class="example-one">
                      <vue-awesome-paginate v-model="currentPage" :total-items="totalRows" :items-per-page="perPage"
                        @click="onClickHandler" />
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import { reactive, inject, computed, ref } from "vue";
import { useI18n } from "vue-i18n";

import MaterialButton from "@/components/MaterialButton.vue";
import MaterialInput from "@/components/MaterialInput.vue";
import { useUniteStore } from '@/stores/unit'
// import { useStore } from "vuex";
// import MaterialRadio from "@/components/MaterialRadio.vue"
// import MaterialCheckbox from "@/components/MaterialCheckbox.vue"

// import logo from "@/assets/img/pawn/nvtx_logo.png"
const storeu = useUniteStore()
const { t } = useI18n();
const swal = inject("$swal");

// const datas = reactive({
//   addNew: false,
//   rowEdit: false,
//   demo1: [
//     { items: "ประเภท1.1" },
//     { items: "ประเภท1.2" },
//     { items: "ประเภท1.3" },
//     { items: "ประเภท1.4" },
//     { items: "ประเภท1.5" },
//     { items: "ประเภท1.6" },
//   ],
// });
const datas = reactive({
  assetAll: [
    { items: computed(() => t("content.optall")) },
    { items: computed(() => t("content.amngold")) },
    { items: computed(() => t("content.amnamulet")) },
    { items: computed(() => t("content.amnframe")) },
    { items: computed(() => t("content.amnsilver")) },
    { items: computed(() => t("content.amndiamond")) },
    { items: computed(() => t("content.amngem")) },
    { items: computed(() => t("content.amncopper")) },
    { items: computed(() => t("content.amnwatch")) },
    { items: computed(() => t("content.amnothers")) },
  ],
});

const btnAddNew = computed(() => t("buttons.impo"));
const btnEdit = computed(() => t("buttons.edit"));
const btnSearch = computed(() => t("buttons.sear"));
const btnSave = computed(() => t("buttons.save"));
const btnDel = computed(() => t("buttons.remo"));
const btnClose = computed(() => t("buttons.clos"));

// const store = useStore();
const filterText = ref("");
const list = ref([]);
const newunit = ref("");
const addNew = ref(false);
const rowEdit = ref(false);
const uid = ref("");
const version = ref("");

const onClickHandler = (page) => {
  // console.log('onClickHandler :' + page);
  updatePagination()
};
const currentPage = ref(1);
const totalRows = ref(1);
const perPage = ref(10);
const totalRows_s = ref();


const loadDataApi = async (val) => {
  clearItems();
  if (currentPage.value !== 1) {
    if (list.value.length === 1 && val === 'del') {
      currentPage.value = currentPage.value - 1
    }
  }
  let data = "";
  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    name: filterText.value,
  };
  const payload = data;
  // const response = await store.dispatch("unitListFetch", payload);
  const response = await storeu.unitListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        list.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
        // console.log('list : '+list.value.length)
      }
    }
  }


};

loadDataApi();

function formatNumberString(num) {
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}

async function updatePagination() {
  let data = "";
  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    name: filterText.value,
  };
  const payload = data;
  // const response = await store.dispatch("unitListFetch", payload);
  const response = await storeu.unitListFetch(payload);
  // console.log("loadDataApi : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        list.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
        // console.log('list : '+list.value.length)
      }
    }
  }
}

async function addNewItems() {
  swal({
    title: "เพิ่มรายการ",
    text: "คุณต้องการเพิ่มรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      addNew.value = true;
      rowEdit.value = false;
      uid.value = "";
      newunit.value = "";
      version.value = "";
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกรายการ", "", "error");
      addNew.value = false;
      clearItems();
    }
  });
}

async function saveNewItems() {
  swal({
    title: "ต้องการบันทึกรายการนี้",
    text: "คุณต้องการบันทึกรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.save"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      // swal("บันทึกสำเร็จ", "", "success");
      addNew.value = false;
      addunit();
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกการบันทึก", "", "error");
      addNew.value = false;
      clearItems();
    }
  });
}

async function cancelSaveNewItems() {
  swal({
    title: "ยกเลิกบันทึกรายการนี้",
    text: "คุณต้องการยกเลิกบันทึกรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      addNew.value = false;
      clearItems();
    } else if (result.dismiss == "cancel") {
      addNew.value = true;
    }
  });
}

async function editRowItems(item) {
  swal({
    title: "แก้ไขรายการ",
    text: "คุณต้องการแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      addNew.value = false;
      rowEdit.value = true;
      uid.value = item.uid;
      newunit.value = item.name;
      version.value = item.version;
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกรายการ", "", "error");
      rowEdit.value = false;
      clearItems();
    }
  });
}

async function saveEditRowItems() {
  swal({
    title: "บันทึกแก้ไขรายการ",
    text: "คุณต้องการบันทึกแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.save"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      // swal("บันทึกสำเร็จ", "", "success");
      rowEdit.value = false;
      if (uid.value !== "" && version.value !== "") {
        updateunit();
      }
    } else if (result.dismiss == "cancel") {
      swal("ยกเลิกแก้ไข", "", "error");
      rowEdit.value = false;
      clearItems();
    }
  });
}

async function cancelEditRowItems() {
  swal({
    title: "ยกเลิกแก้ไขรายการ",
    text: "คุณต้องการยกเลิกแก้ไขรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      rowEdit.value = false;
      clearItems();
    } else if (result.dismiss == "cancel") {
      rowEdit.value = true;
    }
  });
}

async function deleteRowItems(item) {
  swal({
    title: "ลบรายการ",
    text: "คุณต้องการลบรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {

      if (item.uid !== "") {
        deleteunit(item.uid);
      }
    }
  });
}

async function addunit() {
  let data = "";
  data = {
    name: newunit.value,
  };
  const payload = data;
  // const response = await store.dispatch("unitAddFetch", payload);
  const response = await storeu.unitAddFetch(payload);
  // console.log('loadDataApi : '+JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi('add');
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function updateunit() {
  let data = "";
  data = {
    uid: uid.value,
    name: newunit.value,
    version: version.value,
  };
  const payload = data;
  // const response = await store.dispatch("unitUpdateFetch", payload);
  const response = await storeu.unitUpdateFetch(payload);
  // console.log('loadDataApi : '+JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi('update');
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function deleteunit(id) {
  let data = "";
  data = {
    uid: id,
  };
  const payload = data;
  // const response = await store.dispatch("unitDeleteFetch", payload);
  const response = await storeu.unitDeleteFetch(payload);
  // console.log('loadDataApi : '+JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadDataApi('del');
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}
async function clearItems() {
  addNew.value = false;
  rowEdit.value = false;
  uid.value = "";
  newunit.value = "";
  version.value = "";
}
</script>

<style lang="scss" scoped></style>
