/* eslint-disable no-unused-vars */
import { defineStore } from "pinia";
import http from "../config/apiConfig";

export const useBankStore = defineStore("bank", {
  actions: {
    async bankListFetch(payload) {
      const body = {
        page: {
          index: payload.index,
          size: payload.size,
          sort: "",
        },
        filter: {
          "nameTh:contains": payload.nameTh,
          "nameEn:contains": payload.nameEn,
        },
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/master-svc/bank/list";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async bankInfoFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/master-svc/bank/info/" + payload.uid;
          const response = http.get(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async bankAddFetch(payload) {
      const body = {
        nameTh: payload.nameTh,
        nameEn: payload.nameEn,
        logoUid: payload.logoUid,
        color: payload.color,
        code: payload.code,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/master-svc/bank/new";
          const response = http.post(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async bankUpdateFetch(payload) {
      const body = {
        uid: payload.uid,
        nameTh: payload.nameTh,
        nameEn: payload.nameEn,
        logoUid: payload.logoUid,
        color: payload.color,
        version: payload.version,
        code: payload.code,
      };
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/master-svc/bank/update";
          const response = http.put(pathUrl, body);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },

    async bankDeleteFetch(payload) {
      return new Promise((resolve, reject) => {
        try {
          const pathUrl = "/master-svc/bank/delete/" + payload.uid;
          const response = http.delete(pathUrl);
          resolve(response);
        } catch (e) {
          reject(e);
        }
      });
    },
  },
});
