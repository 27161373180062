<template>
	<div class="container-fluid">
		<div class="row mb-5">
			<div v-if="datas.viewGroup" class="col-12">
				<div class="card mt-2">
					<div class="card-header mb-0">
						<div class="row">
							<div class="col">
								<h5>{{ $t('content.gpsearch') }}</h5>
							</div>
							<div class="col-auto">
								<material-button color="primary" variant="contain" class="" @click="createNewGroup">
									<i class="material-icons material-symbols-outlined">add</i> {{ $t('content.gpadd')
									}}
								</material-button>
							</div>
						</div>
					</div>
					<div class="card-body pt-0">
						<div class="row">
							<div class="col-12 col-lg">
								<label class="form-label ms-0">{{ $t('content.gpcode') }}</label>
								<material-input type="text" />
							</div>

							<div class="col-12 col-lg-4">
								<label class="form-label ms-0">{{ $t('content.gpnameth') }}</label>
								<material-input type="text" />
							</div>

							<div class="col-12 col-lg-4">
								<label class="form-label ms-0">{{ $t('content.gpnameen') }}</label>
								<material-input type="text" />
							</div>

							<div class="col-auto">
								<label class="form-label ms-0 png"></label>
								<material-button color="primary" class="float-lg-start float-sm-end mb-3">
									<i class="material-icons material-symbols-outlined">search</i> {{ btnSearch }}
								</material-button>
							</div>
						</div>
						<hr>
						<div class="row">
							<div class="table-responsive">
								<table class="table table-flush">
									<thead class="thead-light">
										<tr>
											<th>#</th>
											<th>{{ $t('content.gpcode') }}</th>
											<th>{{ $t('content.gpnameth') }}</th>
											<th>{{ $t('content.gpnameen') }}</th>
											<th>{{ $t('content.gpdomain') }}</th>
											<th>{{ $t('content.gpcontact') }}</th>
											<th>{{ $t('content.gpcontactno') }}</th>
											<th>{{ $t('content.gpemail') }}</th>
											<th>{{ $t('content.gpfeature') }}</th>
											<th>{{ $t('content.gpfunction') }}</th>
											<th>{{ $t('content.gpattach') }}</th>
											<th width="100px">{{ $t('content.act') }}</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(i, ci) in 4" :key="ci">
											<td class="text-sm font-weight-normal">{{ ci + 1 }}</td>
											<td class="text-sm font-weight-normal">cod</td>
											<td class="text-sm font-weight-normal">โค้ด</td>
											<td class="text-sm font-weight-normal">code</td>
											<td class="text-sm font-weight-normal">www.code.com</td>
											<td class="text-sm font-weight-normal">John doe</td>
											<td class="text-sm font-weight-normal">+668199998877</td>
											<td class="text-sm font-weight-normal">john.d@code.com</td>
											<td class="text-sm font-weight-normal">
												<material-button color="info" variant="outline"
													class="rounded-circle me-1" data-bs-toggle="modal"
													data-bs-target="#featureModal">
													<i class="material-icons material-symbols-outlined">visibility</i>
												</material-button>
											</td>
											<td class="text-sm font-weight-normal">
												<material-button color="info" variant="outline"
													class="rounded-circle me-1" data-bs-toggle="modal"
													data-bs-target="#functionModal">
													<i class="material-icons material-symbols-outlined">visibility</i>
												</material-button>
											</td>
											<td class="text-sm font-weight-normal">
												<material-button color="info" variant="outline"
													class="rounded-circle me-1" data-bs-toggle="modal"
													data-bs-target="#attachModal">
													<i class="material-icons material-symbols-outlined">attach_file</i>
												</material-button>
											</td>
											<td class="text-sm font-weight-normal">
												<material-button color="info" variant="outline"
													class="rounded-circle me-1" data-bs-toggle="modal"
													data-bs-target="#editGroupModal">
													<i class="material-icons material-symbols-outlined">edit</i>
												</material-button>
												<material-button color="warning" variant="outline"
													class="rounded-circle me-1" data-bs-toggle="tooltip"
													data-bs-placement="top" :title="btnReset" @click="viewShop">
													<i class="material-icons material-symbols-outlined">store</i>
												</material-button>
												<material-button color="danger" variant="outline"
													class="rounded-circle me-1" data-bs-toggle="tooltip"
													data-bs-placement="top" :title="btnDel">
													<i class="material-icons material-symbols-outlined">delete</i>
												</material-button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-else-if="!datas.viewGroup && datas.viewShop" class="col-12">
				<div class="card mt-2">
					<div class="card-header mb-0">
						<!-- <h5>{{ $t('content.opsearch') }}</h5> -->
						<div class="row">
							<div class="col-auto">
								<material-button color="warning" variant="contain" class="" @click="backToGroup">
									<i class="material-icons material-symbols-outlined">chevron_left</i> {{
										$t('buttons.back') }}
								</material-button>
							</div>
							<div class="col d-flex align-items-center">
								<h5 class="mb-0">{{ $t('content.opsearch') }}</h5>
							</div>
						</div>
					</div>
					<div class="card-body pt-0">
						<div class="row">
							<div class="col-12 col-lg">
								<label class="form-label ms-0">{{ $t('content.opshopcode') }}</label>
								<material-input type="text" />
							</div>

							<div class="col-12 col-lg">
								<label class="form-label ms-0">{{ $t('content.opshopth') }}</label>
								<material-input type="text" />
							</div>

							<div class="col-12 col-lg">
								<label class="form-label ms-0">{{ $t('content.opshopen') }}</label>
								<material-input type="text" />
							</div>

							<div class="col-12 col-lg">
								<label class="form-label ms-0">{{ $t('content.opshopown') }}</label>
								<material-input type="text" />
							</div>

							<div class="col-lg-auto">
								<label class="form-label ms-0 png"></label>
								<material-button color="primary" class="float-lg-start float-sm-end mb-3">
									<i class="material-icons material-symbols-outlined">search</i> {{ btnSearch }}
								</material-button>
							</div>
						</div>
						<hr>
						<div class="row">
							<div class="table-responsive">
								<table class="table table-flush">
									<thead class="thead-light">
										<tr>
											<th>#</th>
											<th>{{ $t('content.opshopcode') }}</th>
											<th>{{ $t('content.opshopth') }}</th>
											<th>{{ $t('content.opshopen') }}</th>
											<th>{{ $t('content.opdomain') }}</th>
											<th>{{ $t('content.opeffect') }}</th>
											<th>{{ $t('content.openddate') }}</th>
											<th>{{ $t('content.opaddr') }}</th>
											<th>{{ $t('content.opcon') }}</th>
											<th>{{ $t('content.opconno') }}</th>
											<th>{{ $t('content.opconemail') }}</th>
											<th>{{ $t('content.opattach') }}</th>
											<th>{{ $t('content.opremark') }}</th>
											<th>{{ $t('content.oplcown') }}</th>
											<th>{{ $t('content.opshopown') }}</th>
											<th>{{ $t('content.ssadminth') }}</th>
											<th>{{ $t('content.ssadminen') }}</th>
											<th>{{ $t('content.ssadminno') }}</th>
											<th>{{ $t('content.ssadminemail') }}</th>
											<th>{{ $t('content.ssusername') }}</th>
											<th width="100px">{{ $t('content.act') }}</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(i, ci) in 4" :key="ci">
											<td class="text-sm font-weight-normal">{{ ci + 1 }}</td>
											<td class="text-sm font-weight-normal">shop-cod</td>
											<td class="text-sm font-weight-normal">shop-โค้ด</td>
											<td class="text-sm font-weight-normal">shop-code</td>
											<td class="text-sm font-weight-normal">www.shop-code.com</td>
											<td class="text-sm font-weight-normal">xx/xx/xxxx</td>
											<td class="text-sm font-weight-normal">xx/xx/xxxx</td>
											<td class="text-sm font-weight-normal">xxx xxxxx xxxxxxxx</td>
											<td class="text-sm font-weight-normal">John Store</td>
											<td class="text-sm font-weight-normal">+668199998822</td>
											<td class="text-sm font-weight-normal">john.store@shop-code.com</td>
											<td class="text-sm font-weight-normal">
												<material-button color="info" variant="outline"
													class="rounded-circle me-1" data-bs-toggle="modal"
													data-bs-target="#attachModal">
													<i class="material-icons material-symbols-outlined">attach_file</i>
												</material-button>
											</td>
											<td class="text-sm font-weight-normal">remark lolem....</td>
											<td class="text-sm font-weight-normal">LC-mr.x</td>
											<td class="text-sm font-weight-normal">x calibur</td>
											<td class="text-sm font-weight-normal">ลิลี่ โด</td>
											<td class="text-sm font-weight-normal">lily doe</td>
											<td class="text-sm font-weight-normal">8888888888</td>
											<td class="text-sm font-weight-normal">lily.doe@shop-code.com</td>
											<td class="text-sm font-weight-normal">lily.doe@shop-code.com</td>
											<td class="text-sm font-weight-normal">
												<material-button color="info" variant="outline"
													class="rounded-circle me-1" data-bs-toggle="modal"
													data-bs-target="#editShopModal">
													<i class="material-icons material-symbols-outlined">edit</i>
												</material-button>
												<material-button color="danger" variant="outline"
													class="rounded-circle me-1" data-bs-toggle="tooltip"
													data-bs-placement="top" :title="btnDel">
													<i class="material-icons material-symbols-outlined">delete</i>
												</material-button>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div v-if="!datas.viewGroup && datas.createGroup" class="col-12 ">
				<FormWizard ref="wizard" color="#5F3FB9" @onComplete="onComplete" :nextButtonText="t('buttons.next')"
					:backButtonText="t('buttons.prev')" :finishButtonText="t('buttons.comp')">

					<TabContent :title="t('content.openshop1')" icon="fa fa-users">
						<div class="card">
							<div class="card-header">
								<div class="row">
									<div class="col-auto">
										<material-button color="warning" variant="contain" class=""
											@click="backToGroup">
											<i class="material-icons material-symbols-outlined">chevron_left</i> {{
												$t('buttons.back') }}
										</material-button>
									</div>
									<div class="col d-flex align-items-center">
										<h5 class="mb-0">{{ $t('content.openshop1') }}</h5>
									</div>
								</div>
							</div>
							<div class="card-body pt-0">
								<div class="row">

									<div class="col-lg-2">
										<label class="form-label ms-0">{{ $t('content.gpcode') }}</label>
										<material-input type="text" />
									</div>

									<div class="col-lg-5">
										<label class="form-label ms-0">{{ $t('content.gpnameth') }}</label>
										<material-input type="text" />
									</div>

									<div class="col-lg-5">
										<label class="form-label ms-0">{{ $t('content.gpnameen') }}</label>
										<material-input type="text" />
									</div>

									<div class="col-12">
										<label class="form-label ms-0">{{ $t('content.gpdomain') }}</label>
										<material-input type="text" />
									</div>

									<div class="col-lg-8">
										<label class="form-label ms-0">{{ $t('content.gpcontact') }}</label>
										<material-input type="text" />
									</div>

									<div class="col-lg-4">
										<label class="form-label ms-0">{{ $t('content.gpcontactno') }}</label>
										<material-input type="tel" />
									</div>

									<div class="col-12">
										<label class="form-label ms-0">{{ $t('content.gpemail') }}</label>
										<material-input type="email" />
									</div>

									<div class="col-12">
										<label class="form-label ms-0">{{ $t('content.gpremark') }}</label>
										<material-textarea placeholder="" class="mb-3" />
									</div>

									<div class="col-12">
										<label class="form-label ms-0">{{ $t('content.gpfeature') }}</label>
										<table class="table table-flush">
											<thead class="thead-light">
												<tr>
													<th width="50">#</th>
													<th>{{ $t("content.item") }}</th>
													<th width="160">{{ $t("content.enable") }}/{{ $t("content.disable")
														}}</th>
												</tr>
											</thead>

											<tbody data-group-index="0">
												<tr v-for="(i, set1) in 3" :key="set1">
													<td class="text-sm font-weight-normal">{{ set1 + 1 }}</td>
													<td class="text-sm font-weight-normal">Feature {{ set1 + 1 }}</td>
													<td class="text-sm font-weight-normal text-center">
														<material-checkbox />
													</td>
												</tr>
											</tbody>
										</table>
									</div>

									<div class="col-12">
										<label class="form-label ms-0">{{ $t('content.gpfunction') }}</label>
										<table class="table table-flush">
											<thead class="thead-light">
												<tr>
													<th width="50">#</th>
													<th>{{ $t("content.item") }}</th>
													<th width="100" class="text-center">{{ $t("content.view") }}</th>
													<th width="100" class="text-center">{{ $t("content.add") }}</th>
													<th width="100" class="text-center">{{ $t("content.edit") }}</th>
													<th width="100" class="text-center">{{ $t("content.delete") }}</th>
													<th width="100" class="text-center">{{ $t("content.print") }}</th>
												</tr>
											</thead>

											<tbody data-group-index="0">
												<tr v-for="(i, set2) in 3" :key="set2">
													<td class="text-sm font-weight-normal">{{ set2 + 1 }}</td>
													<td class="text-sm font-weight-normal">Function {{ set2 + 1 }}</td>
													<td class="text-sm font-weight-normal text-center">
														<material-checkbox />
													</td>
													<td class="text-sm font-weight-normal text-center">
														<material-checkbox />
													</td>
													<td class="text-sm font-weight-normal text-center">
														<material-checkbox />
													</td>
													<td class="text-sm font-weight-normal text-center">
														<material-checkbox />
													</td>
													<td class="text-sm font-weight-normal text-center">
														<material-checkbox />
													</td>
												</tr>
											</tbody>
										</table>
									</div>

									<div class="col-12">
										<label class="form-label ms-0">{{ $t('content.gpattach') }}</label>
										<div class="row">
											<div class="col-lg-6">
												<div class="input-group custom-file-button">
													<label for="upload-group" class="input-group-text mb-0">{{
														$t('buttons.file') }}</label>
													<input id="upload-group" type="file" class="form-control mb-0">
												</div>
											</div>
											<div class="col-lg-6">
												<material-input type="text" :label="t('content.addfilename')" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</TabContent>

					<TabContent :title="t('content.openshop2')" icon="fa fa-store">
						<div class="card">
							<div class="card-header">
								<h5>{{ $t('content.openshop2') }}</h5>
							</div>
							<div class="card-body pt-0">
								<div v-for="(i, shop) in 1" :key="shop" class="row">

									<div class="col-12">
										<h6 class="ms-0">Shop #00{{ shop + 1 }}</h6>
									</div>

									<div class="col-lg-2">
										<label class="form-label ms-0">{{ $t('content.opshopcode') }}</label>
										<material-input type="text" />
									</div>

									<div class="col-lg-5">
										<label class="form-label ms-0">{{ $t('content.opshopth') }}</label>
										<material-input type="text" />
									</div>

									<div class="col-lg-5">
										<label class="form-label ms-0">{{ $t('content.opshopen') }}</label>
										<material-input type="text" />
									</div>

									<div class="col-12">
										<label class="form-label ms-0">{{ $t('content.opdomain') }}</label>
										<material-input type="text" />
									</div>

									<div class="col-lg-6">
										<label class="form-label ms-0">{{ $t('content.opeffect') }}</label>
										<material-input type="date" />
									</div>

									<div class="col-lg-6">
										<label class="form-label ms-0">{{ $t('content.openddate') }}</label>
										<material-input type="date" />
									</div>

									<div class="col-12">
										<label class="form-label ms-0">{{ $t('content.opaddr') }}</label>
										<material-textarea placeholder="" class="mb-3" rows="2" />
									</div>

									<div class="col-lg-8">
										<label class="form-label ms-0">{{ $t('content.gpcontact') }}</label>
										<material-input type="text" />
									</div>

									<div class="col-lg-4">
										<label class="form-label ms-0">{{ $t('content.gpcontactno') }}</label>
										<material-input type="tel" />
									</div>

									<div class="col-12">
										<label class="form-label ms-0">{{ $t('content.gpemail') }}</label>
										<material-input type="email" />
									</div>

									<div class="col-12">
										<label class="form-label ms-0">{{ $t('content.gpattach') }}</label>
										<div class="row">
											<div class="col-lg-6">
												<div class="input-group custom-file-button">
													<label for="upload-shop" class="input-group-text mb-0">{{
														$t('buttons.file') }}</label>
													<input id="upload-shop" type="file" class="form-control mb-0">
												</div>
											</div>
											<div class="col-lg-6">
												<material-input type="text" :label="t('content.addfilename')" />
											</div>
										</div>
									</div>

									<div class="col-12">
										<label class="form-label ms-0">{{ $t('content.gpremark') }}</label>
										<material-textarea placeholder="" class="mb-3" />
									</div>

									<div class="col-lg-6">
										<label class="form-label ms-0">{{ $t('content.oplcbookno') }}</label>
										<material-input type="text" />
									</div>

									<div class="col-lg-6">
										<label class="form-label ms-0">{{ $t('content.oplcno') }}</label>
										<material-input type="text" />
									</div>

									<div class="col-lg-6">
										<label class="form-label ms-0">{{ $t('content.oplcown') }}</label>
										<material-input type="text" />
									</div>

									<div class="col-lg-6">
										<label class="form-label ms-0">{{ $t('content.opshopown') }}</label>
										<material-input type="text" />
									</div>

									<div class="col-12 mb-5">
										<hr />
									</div>

								</div>

								<div class="row">
									<div class="col-12 d-flex justify-content-center align-items-center">
										<material-button id="addShop" color="primary" class="btn-lg mb-1">
											<i class="material-icons material-symbols-outlined">add</i> {{
												t('content.addshop') }}
										</material-button>
									</div>
								</div>
							</div>
						</div>
					</TabContent>

					<TabContent :title="t('content.openshop3')" icon="fa fa-tools">
						<div class="card">
							<div class="card-header">
								<h5>{{ $t('content.openshop3') }}</h5>
							</div>
							<div class="card-body pt-0">
								<div class="row">

									<div class="col-12">
										<label class="form-label ms-0">{{ $t('content.sssms') }}</label>
										<div class="row">
											<div class="col-lg-4">
												<select class="form-select">
													<option value="proid0">{{ $t("content.select") }}</option>
													<option v-for="(i, opt1) in 3" :key="opt1"
														:value="`proid` + (opt1 + 1)">Provider{{ opt1 + 1 }}
													</option>
												</select>
											</div>
											<div class="col-lg-4">
												<material-input type="text" :label="t('content.amnusername')" />
											</div>
											<div class="col-lg-4">
												<material-input type="password" :label="t('heads.pwd')" />
											</div>
										</div>
									</div>

									<div class="col-12">
										<label class="form-label ms-0">{{ $t('content.sstype') }}</label>
										<div class="text-left mb-3">
											<material-radio v-for="(i, radio1) in datas.types" :key="radio1"
												:id="`type` + (radio1 + 1)" v-model="selectSetType" name="setType"
												class="radio-vertical" :value="radio1">
												{{ i.items }}
											</material-radio>
										</div>
									</div>

									<div class="col-12">
										<label class="form-label ms-0">{{ $t('content.ssgrade') }}</label>
										<div class="table-responsive">
											<table class="table table-flush">
												<thead class="thead-light">
													<tr>
														<th style="width: 70px;">#</th>
														<th>{{ $t('content.amntypecustomer') }}</th>
														<th>{{ $t('content.amncredit') }}</th>
														<th>{{ $t('content.cdefault') }}</th>
														<th style="width: 100px;">{{ $t('content.act') }}</th>
													</tr>
												</thead>
												<tbody>
													<tr class="group-id-ticket">
														<td class="text-sm font-weight-normal">
															<div class="group-tickect">
																<h6 class="mb-0"><b>{{ btnSearch }}</b></h6>
															</div>
														</td>
														<td class="text-sm font-weight-normal">
															<material-input type="text" class="mb-0"
																:label="$t('content.amntypecustomer')" />
														</td>
														<td class="text-sm font-weight-normal">
															<material-input type="text" class="mb-0"
																:label="$t('content.amncredit')" />
														</td>
														<td class="text-sm font-weight-normal"></td>
														<td class="text-sm font-weight-normal">
															<material-button color="primary" variant="contain"
																class="rounded-circle ms-1" data-bs-toggle="tooltip"
																data-bs-placement="top" :title="btnSearch">
																<i
																	class="material-icons material-symbols-outlined">search</i>
															</material-button>
														</td>
													</tr>

													<tr v-for="(i, grade) in 2" :key="grade">
														<td class="text-sm font-weight-normal">{{ grade + 1 }}</td>
														<td class="text-sm font-weight-normal">xxxxx</td>
														<td class="text-sm font-weight-normal">xxxxx</td>
														<td class="text-sm font-weight-normal text-center">
															<material-checkbox />
														</td>
														<td class="text-sm font-weight-normal">
															<material-button color="info" variant="outline"
																class="rounded-circle" data-bs-toggle="tooltip"
																data-bs-placement="top" :title="btnEdit"
																@click="editRowItems">
																<i
																	class="material-icons material-symbols-outlined">edit</i>
															</material-button>
															<material-button color="danger" variant="outline"
																class="rounded-circle" data-bs-toggle="tooltip"
																data-bs-placement="top" :title="btnDel">
																<i
																	class="material-icons material-symbols-outlined">delete</i>
															</material-button>
														</td>
													</tr>

												</tbody>
											</table>
										</div>
										<nav class="mt-3" aria-label="Table Paginator">
											<ul class="pagination justify-content-end">
												<li class="page-item disabled">
													<a class="page-link" href="#" aria-label="Previous">
														<span aria-hidden="true">&laquo;</span>
													</a>
												</li>
												<li class="page-item"><a class="page-link" href="#">1</a></li>
												<li class="page-item"><a class="page-link" href="#">2</a></li>
												<li class="page-item"><a class="page-link" href="#">3</a></li>
												<li class="page-item">
													<a class="page-link" href="#" aria-label="Next">
														<span aria-hidden="true">&raquo;</span>
													</a>
												</li>
											</ul>
										</nav>
									</div>

									<div class="col-12">
										<label class="form-label ms-0">{{ $t('content.ssbank') }}</label>
										<div class="table-responsive">
											<table class="table table-flush">
												<thead class="thead-light">
													<tr>
														<th style="width: 70px;">#</th>
														<th style="width: 120px;">{{ $t('content.mbankpshort') }}</th>
														<th>{{ $t('content.mbankname') }}</th>
														<th>{{ $t('content.cbankno') }}</th>
														<th>{{ $t('content.amncreditmax') }}</th>
														<th>{{ $t('content.act') }}</th>
													</tr>
												</thead>
												<tbody>
													<tr class="group-id-ticket">
														<td class="text-sm font-weight-normal">
															<div class="group-tickect">
																<h6 class="mb-0"><b>{{ btnSearch }}</b></h6>
															</div>
														</td>
														<td class="text-sm font-weight-normal">
															<material-input type="text" class="mb-0" :label="lbShort" />
														</td>
														<td class="text-sm font-weight-normal">
															<select class="form-select">
																<option value="card0">ธนาคาร 1</option>
																<option value="card2">ธนาคาร 2</option>
																<option value="card3">ธนาคาร 3</option>
															</select>
														</td>
														<td class="text-sm font-weight-normal">
															<material-input type="text" class="mb-0"
																:label="$t('content.cbankno')" />
														</td>
														<td class="text-sm font-weight-normal">
															<material-input type="text" class="mb-0"
																:label="$t('content.amncreditmax')" />
														</td>
														<td class="text-sm font-weight-normal">
															<material-button color="primary" variant="contain"
																class="rounded-circle ms-1" data-bs-toggle="tooltip"
																data-bs-placement="top" :title="btnSearch">
																<i
																	class="material-icons material-symbols-outlined">add</i>
															</material-button>
														</td>
													</tr>
													<tr v-for="(i, bank) in 2" :key="bank">
														<td class="text-sm font-weight-normal">{{ bank + 1 }}</td>
														<td class="text-sm font-weight-normal">xxxxx</td>
														<td class="text-sm font-weight-normal">xxxxx</td>
														<td class="text-sm font-weight-normal">xxxxx</td>
														<td class="text-sm font-weight-normal">xxxxx</td>
														<td class="text-sm font-weight-normal">
															<material-button color="info" variant="outline"
																class="rounded-circle" data-bs-toggle="tooltip"
																data-bs-placement="top" :title="btnEdit"
																@click="editRowItems">
																<i
																	class="material-icons material-symbols-outlined">edit</i>
															</material-button>
															<material-button color="danger" variant="outline"
																class="rounded-circle" data-bs-toggle="tooltip"
																data-bs-placement="top" :title="btnDel">
																<i
																	class="material-icons material-symbols-outlined">delete</i>
															</material-button>
														</td>
													</tr>

												</tbody>
											</table>
										</div>
										<nav class="mt-3" aria-label="Table Paginator">
											<ul class="pagination justify-content-end">
												<li class="page-item disabled">
													<a class="page-link" href="#" aria-label="Previous">
														<span aria-hidden="true">&laquo;</span>
													</a>
												</li>
												<li class="page-item"><a class="page-link" href="#">1</a></li>
												<li class="page-item"><a class="page-link" href="#">2</a></li>
												<li class="page-item"><a class="page-link" href="#">3</a></li>
												<li class="page-item">
													<a class="page-link" href="#" aria-label="Next">
														<span aria-hidden="true">&raquo;</span>
													</a>
												</li>
											</ul>
										</nav>
									</div>

									<div class="col-12">
										<label class="form-label ms-0">{{ $t('content.sscash') }}</label>
										<div class="table-responsive">
											<table class="table table-flush">
												<thead class="thead-light">
													<tr>
														<th style="width: 70px;">#</th>
														<th>{{ $t('content.mcashname') }}</th>
														<th>{{ $t('content.amncreditmax') }}</th>
														<th>{{ $t('content.act') }}</th>
													</tr>
												</thead>
												<tbody>
													<tr class="group-id-ticket">
														<td class="text-sm font-weight-normal">
															<div class="group-tickect">
																<h6 class="mb-0"><b>{{ btnSearch }}</b></h6>
															</div>
														</td>
														<td class="text-sm font-weight-normal">
															<material-input type="text" class="mb-0"
																:label="$t('content.mcashname')" />
														</td>
														<td class="text-sm font-weight-normal">
															<material-input type="text" class="mb-0"
																:label="$t('content.amncreditmax')" />
														</td>
														<td class="text-sm font-weight-normal">
															<material-button color="primary" variant="contain"
																class="rounded-circle ms-1" data-bs-toggle="tooltip"
																data-bs-placement="top" :title="btnSearch">
																<i
																	class="material-icons material-symbols-outlined">add</i>
															</material-button>
														</td>
													</tr>
													<tr v-for="(i, cash) in 2" :key="cash">
														<td class="text-sm font-weight-normal">{{ cash + 1 }}</td>
														<td class="text-sm font-weight-normal">xxxxx</td>
														<td class="text-sm font-weight-normal">xxxxx</td>
														<td class="text-sm font-weight-normal">
															<material-button color="info" variant="outline"
																class="rounded-circle" data-bs-toggle="tooltip"
																data-bs-placement="top" :title="btnEdit"
																@click="editRowItems">
																<i
																	class="material-icons material-symbols-outlined">edit</i>
															</material-button>
															<material-button color="danger" variant="outline"
																class="rounded-circle" data-bs-toggle="tooltip"
																data-bs-placement="top" :title="btnDel">
																<i
																	class="material-icons material-symbols-outlined">delete</i>
															</material-button>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
										<nav class="mt-3" aria-label="Table Paginator">
											<ul class="pagination justify-content-end">
												<li class="page-item disabled">
													<a class="page-link" href="#" aria-label="Previous">
														<span aria-hidden="true">&laquo;</span>
													</a>
												</li>
												<li class="page-item"><a class="page-link" href="#">1</a></li>
												<li class="page-item"><a class="page-link" href="#">2</a></li>
												<li class="page-item"><a class="page-link" href="#">3</a></li>
												<li class="page-item">
													<a class="page-link" href="#" aria-label="Next">
														<span aria-hidden="true">&raquo;</span>
													</a>
												</li>
											</ul>
										</nav>
									</div>

									<div class="col-12">
										<label class="form-label ms-0">{{ $t('content.sstxn') }}</label>
										<div class="table-responsive">
											<table class="table table-flush">
												<thead class="thead-light">
													<tr>
														<th style="width: 70px;">#</th>
														<th>{{ $t('content.financetypes') }}</th>
														<th>{{ $t('content.transc') }}</th>
														<th>{{ $t('content.act') }}</th>
													</tr>
												</thead>
												<tbody>
													<tr class="group-id-ticket">
														<td class="text-sm font-weight-normal">
															<div class="group-tickect">
																<h6 class="mb-0"><b>{{ btnSearch }}</b></h6>
															</div>
														</td>
														<td class="text-sm font-weight-normal">
															<material-input type="text" class="mb-0"
																:label="$t('content.mcashname')" />
														</td>
														<td class="text-sm font-weight-normal">
															<select class="form-select">
																<option v-for="(i, txn) in datas.finances" :key="txn"
																	:value="i.items">
																	{{ i.items }}
																</option>
															</select>
														</td>
														<td class="text-sm font-weight-normal">
															<material-button color="primary" variant="contain"
																class="rounded-circle ms-1" data-bs-toggle="tooltip"
																data-bs-placement="top" :title="btnSearch">
																<i
																	class="material-icons material-symbols-outlined">add</i>
															</material-button>
														</td>
													</tr>
													<tr v-for="(i, cash) in 2" :key="cash">
														<td class="text-sm font-weight-normal">{{ cash + 1 }}</td>
														<td class="text-sm font-weight-normal">xxxxx</td>
														<td class="text-sm font-weight-normal">xxxxx</td>
														<td class="text-sm font-weight-normal">
															<material-button color="info" variant="outline"
																class="rounded-circle" data-bs-toggle="tooltip"
																data-bs-placement="top" :title="btnEdit"
																@click="editRowItems">
																<i
																	class="material-icons material-symbols-outlined">edit</i>
															</material-button>
															<material-button color="danger" variant="outline"
																class="rounded-circle" data-bs-toggle="tooltip"
																data-bs-placement="top" :title="btnDel">
																<i
																	class="material-icons material-symbols-outlined">delete</i>
															</material-button>
														</td>
													</tr>

												</tbody>
											</table>
										</div>
										<nav class="mt-3" aria-label="Table Paginator">
											<ul class="pagination justify-content-end">
												<li class="page-item disabled">
													<a class="page-link" href="#" aria-label="Previous">
														<span aria-hidden="true">&laquo;</span>
													</a>
												</li>
												<li class="page-item"><a class="page-link" href="#">1</a></li>
												<li class="page-item"><a class="page-link" href="#">2</a></li>
												<li class="page-item"><a class="page-link" href="#">3</a></li>
												<li class="page-item">
													<a class="page-link" href="#" aria-label="Next">
														<span aria-hidden="true">&raquo;</span>
													</a>
												</li>
											</ul>
										</nav>
									</div>

									<div class="col-lg-4">
										<label class="form-label ms-0">{{ $t('content.ssdue') }}</label>
										<material-input type="text" :label="`(` + t('content.fillmonth') + `)`" />
									</div>

									<div class="col-12">
										<hr />
									</div>

									<div class="col">
										<label class="form-label ms-0">{{ $t('content.ssburole') }}</label>
										<material-input type="text" :label="`(` + t('content.fillrole') + `)`" />
									</div>

									<div class="col-auto">
										<label class="form-label ms-0 png"></label>
										<material-button color="primary" class="float-lg-start float-sm-end mb-3">
											<i class="material-icons material-symbols-outlined">add</i> {{ btnAdd }}
										</material-button>
									</div>

									<div class="col-12">
										<label class="form-label ms-0">{{ $t('content.ssrole') }}</label>
										<select class="form-select">
											<option value="0">{{ t('content.select') }}</option>
											<option v-for="(i, role) in 12" :key="role" :value="role + 1">
												Role Name - {{ role + 1 }}
											</option>
										</select>

										<table class="table table-flush">
											<thead class="thead-light">
												<tr>
													<th width="50">#</th>
													<th>{{ $t("content.item") }}</th>
													<th width="160">{{ $t("content.enable") }}/{{ $t("content.disable")
														}}</th>
												</tr>
											</thead>

											<tbody data-group-index="0">
												<tr v-for="(i, set1) in 3" :key="set1">
													<td class="text-sm font-weight-normal">{{ set1 + 1 }}</td>
													<td class="text-sm font-weight-normal">Feature {{ set1 + 1 }}</td>
													<td class="text-sm font-weight-normal text-center">
														<material-checkbox />
													</td>
												</tr>
											</tbody>
										</table>

										<table class="table table-flush">
											<thead class="thead-light">
												<tr>
													<th width="50">#</th>
													<th>{{ $t("content.item") }}</th>
													<th width="100" class="text-center">{{ $t("content.view") }}</th>
													<th width="100" class="text-center">{{ $t("content.add") }}</th>
													<th width="100" class="text-center">{{ $t("content.edit") }}</th>
													<th width="100" class="text-center">{{ $t("content.delete") }}</th>
													<th width="100" class="text-center">{{ $t("content.print") }}</th>
												</tr>
											</thead>

											<tbody data-group-index="0">
												<tr v-for="(i, set2) in 3" :key="set2">
													<td class="text-sm font-weight-normal">{{ set2 + 1 }}</td>
													<td class="text-sm font-weight-normal">Function {{ set2 + 1 }}</td>
													<td class="text-sm font-weight-normal text-center">
														<material-checkbox />
													</td>
													<td class="text-sm font-weight-normal text-center">
														<material-checkbox />
													</td>
													<td class="text-sm font-weight-normal text-center">
														<material-checkbox />
													</td>
													<td class="text-sm font-weight-normal text-center">
														<material-checkbox />
													</td>
													<td class="text-sm font-weight-normal text-center">
														<material-checkbox />
													</td>
												</tr>
											</tbody>
										</table>
									</div>

									<div class="col-lg-6">
										<label class="form-label ms-0">{{ $t('content.ssadminth') }}</label>
										<material-input type="text" />
									</div>

									<div class="col-lg-6">
										<label class="form-label ms-0">{{ $t('content.ssadminen') }}</label>
										<material-input type="text" />
									</div>

									<div class="col-lg-6">
										<label class="form-label ms-0">{{ $t('content.ssadminno') }}</label>
										<material-input type="tel" />
									</div>

									<div class="col-lg-6">
										<label class="form-label ms-0">{{ $t('content.ssadminemail') }}</label>
										<material-input type="email" />
									</div>

									<div class="col-12">
										<label class="form-label ms-0">{{ $t('content.ssusername') }}</label>
										<div class="row">
											<div class="col-lg-4">
												<material-input type="text" />
											</div>

											<div class="col-12">
												<div class="d-flex align-items-center pt-2 mb-3">
													<label class="form-label ms-0 mb-0">{{ $t('content.sssetpwd') }} :
													</label>
													<material-radio v-for="(i, rspwd) in datas.pwd" :key="rspwd"
														:id="`rspwd` + (rspwd + 1)" v-model="selectSetPwd"
														name="setSendPwd" class="radio-horizon" :value="rspwd">
														{{ i.items }}
													</material-radio>
												</div>
											</div>

											<div class="col-12">
												<div class="d-flex align-items-center pt-2 mb-3">
													<label class="form-label ms-0 mb-0">{{ $t('content.sssetpin') }} :
													</label>
													<material-radio v-for="(i, repin) in datas.pwd" :key="repin"
														:id="`repin` + (repin + 1)" v-model="selectSetPin"
														name="setSendPin" class="radio-horizon" :value="repin">
														{{ i.items }}
													</material-radio>
												</div>
											</div>
										</div>
									</div>

								</div>
							</div>
						</div>
					</TabContent>
				</FormWizard>
			</div>
		</div>

		<!-- Feature Dialog -->
		<div id="featureModal" class="modal fade" tabindex="-1" aria-labelledby="featureModalLabel" aria-hidden="true">
			<div class="modal-dialog" style="max-width: 420px">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">{{ $t("content.gpfeature") }}</h4>
						<div class="d-flex justify-content-end align-items-center">
							<material-button color="info" variant="contain" class="" @click="editFeatureNow">
								<i class="material-icons material-symbols-outlined">edit</i> {{ t('buttons.edit') }}
							</material-button>
							<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
								<i class="material-icons material-symbols-outlined">close</i>
							</button>
						</div>
					</div>

					<div class="modal-body">
						<div class="row">
							<div class="col-12 table-responsive">
								<table class="table">
									<thead class="thead-light">
										<tr>
											<th width="50">#</th>
											<th>{{ $t("content.item") }}</th>
											<th width="160">{{ $t("content.enable") }}/{{ $t("content.disable") }}</th>
										</tr>
									</thead>

									<tbody data-group-index="0">
										<tr v-for="(i, set1) in 3" :key="set1">
											<td class="text-sm font-weight-normal">{{ set1 + 1 }}</td>
											<td class="text-sm font-weight-normal">Feature {{ set1 + 1 }}</td>
											<td class="text-sm font-weight-normal text-center">
												<material-checkbox :disabled="!datas.editFeature" />
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>

					<div class="modal-footer">
						<material-button color="default" variant="contain" class="" data-bs-dismiss="modal"
							aria-label="Close">
							<i class="material-icons material-symbols-outlined">close</i> {{ t('buttons.canc') }}
						</material-button>
						<material-button v-if="datas.editFeature" color="primary" variant="contain" class=""
							data-bs-dismiss="modal" aria-label="Close">
							<i class="material-icons material-symbols-outlined">save</i> {{ t('buttons.save') }}
						</material-button>
					</div>
				</div>
			</div>
		</div>

		<!-- Function Dialog -->
		<div id="functionModal" class="modal fade" tabindex="-1" aria-labelledby="functionModalLabel"
			aria-hidden="true">
			<div class="modal-dialog" style="max-width: 420px">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">{{ $t("content.gpfunction") }}</h4>
						<div class="d-flex justify-content-end align-items-center">
							<material-button color="info" variant="contain" class="" @click="editFunctionNow">
								<i class="material-icons material-symbols-outlined">edit</i> {{ t('buttons.edit') }}
							</material-button>
							<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
								<i class="material-icons material-symbols-outlined">close</i>
							</button>
						</div>
					</div>

					<div class="modal-body">
						<div class="row">
							<div class="col-12 table-responsive">
								<table class="table">
									<thead class="thead-light">
										<tr>
											<th width="50">#</th>
											<th>{{ $t("content.item") }}</th>
											<th width="100" class="text-center">{{ $t("content.view") }}</th>
											<th width="100" class="text-center">{{ $t("content.add") }}</th>
											<th width="100" class="text-center">{{ $t("content.edit") }}</th>
											<th width="100" class="text-center">{{ $t("content.delete") }}</th>
											<th width="100" class="text-center">{{ $t("content.print") }}</th>
										</tr>
									</thead>

									<tbody data-group-index="0">
										<tr v-for="(i, set2) in 3" :key="set2">
											<td class="text-sm font-weight-normal">{{ set2 + 1 }}</td>
											<td class="text-sm font-weight-normal">Function {{ set2 + 1 }}</td>
											<td class="text-sm font-weight-normal text-center">
												<material-checkbox :disabled="datas.editFeature" />
											</td>
											<td class="text-sm font-weight-normal text-center">
												<material-checkbox :disabled="datas.editFeature" />
											</td>
											<td class="text-sm font-weight-normal text-center">
												<material-checkbox :disabled="datas.editFeature" />
											</td>
											<td class="text-sm font-weight-normal text-center">
												<material-checkbox :disabled="datas.editFeature" />
											</td>
											<td class="text-sm font-weight-normal text-center">
												<material-checkbox :disabled="datas.editFeature" />
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>

					<div class="modal-footer">
						<material-button color="default" variant="contain" class="" data-bs-dismiss="modal"
							aria-label="Close">
							<i class="material-icons material-symbols-outlined">close</i> {{ t('buttons.canc') }}
						</material-button>
						<material-button v-if="datas.editFunction" color="primary" variant="contain" class=""
							data-bs-dismiss="modal" aria-label="Close">
							<i class="material-icons material-symbols-outlined">save</i> {{ t('buttons.save') }}
						</material-button>
					</div>
				</div>
			</div>
		</div>

		<!-- Attachments Dialog -->
		<div id="attachModal" class="modal fade" tabindex="-1" aria-labelledby="attachModalLabel" aria-hidden="true">
			<div class="modal-dialog" style="max-width: 420px">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">{{ $t("content.file") }}</h4>
						<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
							<i class="material-icons material-symbols-outlined">close</i>
						</button>
					</div>

					<div class="modal-body">
						<div id="attachImages" class="carousel slide" data-bs-ride="carousel">
							<div class="carousel-indicators">
								<button type="button" data-bs-target="#attachImages" data-bs-slide-to="0" class="active"
									aria-current="true" aria-label="Slide 1"></button>
								<button type="button" data-bs-target="#attachImages" data-bs-slide-to="1"
									aria-label="Slide 2"></button>
								<button type="button" data-bs-target="#attachImages" data-bs-slide-to="2"
									aria-label="Slide 3"></button>
							</div>
							<div class="carousel-inner">
								<div class="carousel-item active">
									<img src="https://unsplash.it/300/300" class="d-block w-100" alt="..." />
								</div>
								<div class="carousel-item">
									<img src="https://unsplash.it/300/300" class="d-block w-100" alt="..." />
								</div>
								<div class="carousel-item">
									<img src="https://unsplash.it/300/300" class="d-block w-100" alt="..." />
								</div>
							</div>
							<button class="carousel-control-prev" type="button" data-bs-target="#attachImages"
								data-bs-slide="prev">
								<span class="carousel-control-prev-icon" aria-hidden="true"></span>
								<span class="visually-hidden">Previous</span>
							</button>
							<button class="carousel-control-next" type="button" data-bs-target="#attachImages"
								data-bs-slide="next">
								<span class="carousel-control-next-icon" aria-hidden="true"></span>
								<span class="visually-hidden">Next</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Edit Group Dialog -->
		<div id="editGroupModal" class="modal fade" tabindex="-1" aria-labelledby="editGroupModalLabel"
			aria-hidden="true">
			<div class="modal-dialog" style="max-width: 600px">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">{{ $t("content.gpedit") }}</h4>
						<div class="d-flex justify-content-end align-items-center">
							<material-button color="info" variant="contain" class="" @click="editGroup">
								<i class="material-icons material-symbols-outlined">edit</i> {{ t('buttons.edit') }}
							</material-button>
							<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
								<i class="material-icons material-symbols-outlined">close</i>
							</button>
						</div>
					</div>

					<div class="modal-body" style="height: 70vh; overflow-y: auto;">
						<div class="row">
							<div class="col-lg-2">
								<label class="form-label ms-0">{{ $t('content.gpcode') }}</label>
								<material-input type="text" :disabled="datas.editGroupForm" />
							</div>

							<div class="col-lg-5">
								<label class="form-label ms-0">{{ $t('content.gpnameth') }}</label>
								<material-input type="text" :disabled="datas.editGroupForm" />
							</div>

							<div class="col-lg-5">
								<label class="form-label ms-0">{{ $t('content.gpnameen') }}</label>
								<material-input type="text" :disabled="datas.editGroupForm" />
							</div>

							<div class="col-12">
								<label class="form-label ms-0">{{ $t('content.gpdomain') }}</label>
								<material-input type="text" :disabled="datas.editGroupForm" />
							</div>

							<div class="col-lg-8">
								<label class="form-label ms-0">{{ $t('content.gpcontact') }}</label>
								<material-input type="text" :disabled="datas.editGroupForm" />
							</div>

							<div class="col-lg-4">
								<label class="form-label ms-0">{{ $t('content.gpcontactno') }}</label>
								<material-input type="tel" :disabled="datas.editGroupForm" />
							</div>

							<div class="col-12">
								<label class="form-label ms-0">{{ $t('content.gpemail') }}</label>
								<material-input type="email" :disabled="datas.editGroupForm" />
							</div>

							<div class="col-12">
								<label class="form-label ms-0">{{ $t('content.gpremark') }}</label>
								<material-textarea placeholder="" class="mb-3" :disabled="datas.editGroupForm" />
							</div>

							<div class="col-12">
								<label class="form-label ms-0">{{ $t('content.gpattach') }}</label>
								<div class="row">
									<div class="col-lg-6">
										<div class="input-group custom-file-button">
											<label for="upload-group" class="input-group-text mb-0">{{
												$t('buttons.file') }}</label>
											<input id="upload-group" type="file" class="form-control mb-0"
												:disabled="datas.editGroupForm">
										</div>
									</div>
									<div class="col-lg-6">
										<material-input type="text" :label="t('content.addfilename')"
											:disabled="datas.editGroupForm" />
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="modal-footer">
						<material-button color="default" variant="contain" class="" data-bs-dismiss="modal"
							aria-label="Close">
							<i class="material-icons material-symbols-outlined">close</i> {{ t('buttons.canc') }}
						</material-button>
						<material-button v-if="!datas.editGroupForm" color="primary" variant="contain" class=""
							data-bs-dismiss="modal" aria-label="Close">
							<i class="material-icons material-symbols-outlined">save</i> {{ t('buttons.save') }}
						</material-button>
					</div>
				</div>
			</div>
		</div>

		<!-- Edit Shop Dialog -->
		<div id="editShopModal" class="modal fade" tabindex="-1" aria-labelledby="editShopModalLabel"
			aria-hidden="true">
			<div class="modal-dialog" style="max-width: 600px">
				<div class="modal-content">
					<div class="modal-header">
						<h4 class="modal-title">{{ $t("content.opedit") }}</h4>
						<div class="d-flex justify-content-end align-items-center">
							<material-button color="info" variant="contain" class="" @click="editShop">
								<i class="material-icons material-symbols-outlined">edit</i> {{ t('buttons.edit') }}
							</material-button>
							<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
								<i class="material-icons material-symbols-outlined">close</i>
							</button>
						</div>
					</div>

					<div class="modal-body" style="height: 70vh; overflow-y: auto;">
						<div class="row">
							<div class="col-12">
								<h6 class="ms-0">Shop #???</h6>
							</div>

							<div class="col-lg-2">
								<label class="form-label ms-0">{{ $t('content.opshopcode') }}</label>
								<material-input type="text" :disabled="datas.editShopForm" />
							</div>

							<div class="col-lg-5">
								<label class="form-label ms-0">{{ $t('content.opshopth') }}</label>
								<material-input type="text" :disabled="datas.editShopForm" />
							</div>

							<div class="col-lg-5">
								<label class="form-label ms-0">{{ $t('content.opshopen') }}</label>
								<material-input type="text" :disabled="datas.editShopForm" />
							</div>

							<div class="col-12">
								<label class="form-label ms-0">{{ $t('content.opdomain') }}</label>
								<material-input type="text" :disabled="datas.editShopForm" />
							</div>

							<div class="col-lg-6">
								<label class="form-label ms-0">{{ $t('content.opeffect') }}</label>
								<material-input type="date" :disabled="datas.editShopForm" />
							</div>

							<div class="col-lg-6">
								<label class="form-label ms-0">{{ $t('content.openddate') }}</label>
								<material-input type="date" :disabled="datas.editShopForm" />
							</div>

							<div class="col-12">
								<label class="form-label ms-0">{{ $t('content.opaddr') }}</label>
								<material-textarea placeholder="" class="mb-3" rows="2"
									:disabled="datas.editShopForm" />
							</div>

							<div class="col-lg-8">
								<label class="form-label ms-0">{{ $t('content.gpcontact') }}</label>
								<material-input type="text" :disabled="datas.editShopForm" />
							</div>

							<div class="col-lg-4">
								<label class="form-label ms-0">{{ $t('content.gpcontactno') }}</label>
								<material-input type="tel" :disabled="datas.editShopForm" />
							</div>

							<div class="col-12">
								<label class="form-label ms-0">{{ $t('content.gpemail') }}</label>
								<material-input type="email" :disabled="datas.editShopForm" />
							</div>

							<div class="col-12">
								<label class="form-label ms-0">{{ $t('content.gpattach') }}</label>
								<div class="row">
									<div class="col-lg-6">
										<div class="input-group custom-file-button">
											<label for="upload-shop" class="input-group-text mb-0">{{ $t('buttons.file')
												}}</label>
											<input id="upload-shop" type="file" class="form-control mb-0"
												:disabled="datas.editShopForm">
										</div>
									</div>
									<div class="col-lg-6">
										<material-input type="text" :label="t('content.addfilename')"
											:disabled="datas.editShopForm" />
									</div>
								</div>
							</div>

							<div class="col-12">
								<label class="form-label ms-0">{{ $t('content.gpremark') }}</label>
								<material-textarea placeholder="" class="mb-3" :disabled="datas.editShopForm" />
							</div>

							<div class="col-lg-6">
								<label class="form-label ms-0">{{ $t('content.oplcown') }}</label>
								<material-input type="text" :disabled="datas.editShopForm" />
							</div>

							<div class="col-lg-6">
								<label class="form-label ms-0">{{ $t('content.opshopown') }}</label>
								<material-input type="text" :disabled="datas.editShopForm" />
							</div>

							<div class="col-12">
								<hr />
							</div>

							<div class="col-lg-6">
								<label class="form-label ms-0">{{ $t('content.ssadminth') }}</label>
								<material-input type="text" :disabled="datas.editShopForm" />
							</div>

							<div class="col-lg-6">
								<label class="form-label ms-0">{{ $t('content.ssadminen') }}</label>
								<material-input type="text" :disabled="datas.editShopForm" />
							</div>

							<div class="col-lg-6">
								<label class="form-label ms-0">{{ $t('content.ssadminno') }}</label>
								<material-input type="tel" :disabled="datas.editShopForm" />
							</div>

							<div class="col-lg-6">
								<label class="form-label ms-0">{{ $t('content.ssadminemail') }}</label>
								<material-input type="email" :disabled="datas.editShopForm" />
							</div>

							<div class="col-12">
								<label class="form-label ms-0">{{ $t('content.ssusername') }}</label>
								<material-input type="text" :disabled="datas.editShopForm" />
							</div>
						</div>
					</div>

					<div class="modal-footer">
						<material-button color="default" variant="contain" class="" data-bs-dismiss="modal"
							aria-label="Close">
							<i class="material-icons material-symbols-outlined">close</i> {{ t('buttons.canc') }}
						</material-button>
						<material-button v-if="!datas.editShopForm" color="primary" variant="contain" class=""
							data-bs-dismiss="modal" aria-label="Close">
							<i class="material-icons material-symbols-outlined">save</i> {{ t('buttons.save') }}
						</material-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { reactive, ref, inject, computed } from 'vue';
import { useI18n } from "vue-i18n"
import { FormWizard, TabContent } from "vue3-form-wizard";
import "vue3-form-wizard/dist/style.css";

import MaterialButton from "@/components/MaterialButton.vue"
import MaterialInput from "@/components/MaterialInput.vue"
import MaterialCheckbox from "@/components/MaterialCheckbox.vue"
import MaterialRadio from "@/components/MaterialRadio.vue"
import MaterialTextarea from "@/components/MaterialTextarea.vue"

// import logo from "@/assets/img/pawn/nvtx_logo.png"

const { t } = useI18n()
const swal = inject('$swal')

const onComplete = async () => {
	swal({
		title: t('dlg.tt_save'),
		text: t('dlg.sb_save'),
		icon: "info",
		allowOutsideClick: false,
		showCloseButton: false,
		showCancelButton: true,
		closeOnConfirm: false,
		focusConfirm: false,
		animation: "slide-from-top",
		confirmButtonText: t('buttons.save'),
		cancelButtonText: t('buttons.canc'),
	}).then((result) => {
		if (result.isConfirmed) {
			swal(t('dlg.st_save_fn'), "", "success")
			backToGroup()
		} else if (result.dismiss == 'cancel') {
			swal(t('dlg.st_save_cc'), "", "error")
		}
	})
}

const wizard = ref(null)
// const nextTab = () => {wizard.value.nextTab()}
// const prevTab = () => {wizard.value.prevTab()}
// const reset = () => {wizard.value.reset()}
// const navigateToTab = (index) => {wizard.value.navigateToTab(index)}

const selectSetType = ref("0")
const selectSetPwd = ref("0")
const selectSetPin = ref("0")

const datas = reactive({
	viewGroup: true,
	createGroup: false,
	editGroup: false,
	viewShop: false,
	editShop: false,
	types: [
		{ items: computed(() => t("content.sstype1")) },
		{ items: computed(() => t("content.sstype2")) },
	],
	finances: [
		{ items: computed(() => t("content.select")) },
		{ items: computed(() => t("content.ctranstin")) },
		{ items: computed(() => t("content.ctranstout")) },
		{ items: computed(() => t("content.ctranstexp")) },
		{ items: computed(() => t("content.ctranstacc")) },
	],
	pwd: [
		{ items: computed(() => t("content.csms")) },
		{ items: computed(() => t("content.cemail")) },
	],
	editFeature: false,
	editFunction: false,
	editGroupForm: true,
	editShopForm: true,
});

const editGroup = () => {
	datas.editGroupForm = false;
};

const createNewGroup = () => {
	datas.viewGroup = false;
	datas.createGroup = true;
	datas.viewShop = false;
};

const backToGroup = () => {
	datas.viewGroup = true;
	datas.createGroup = false;
	datas.viewShop = false;
};

const viewShop = () => {
	datas.viewGroup = false;
	datas.createGroup = false;
	datas.viewShop = true;
};

const editShop = () => {
	datas.editShopForm = false;
};


const editFeatureNow = () => {
	datas.editFeature = !datas.editFeature;
};

const editFunctionNow = () => {
	datas.editFunction = !datas.editFunction;
};

const btnAdd = computed(() => t("buttons.addd"))

// const fmNumber = (val) => {
//   return Intl.NumberFormat("en-US").format(val);
// };
</script>

<style lang="scss" scoped></style>