<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12 ">
        <div class="card mt-3">
          <div class="card-header mb-0">
            <h5>{{ $t('apps.gticketchain') }}</h5>
          </div>
          <div class="card-body pt-0">
            <div class="row">

              <div class="col-lg-3">
                <label class="form-label ms-0">{{ $t('content.ticketno') }}</label>
                <material-input type="text" />
              </div>

              <div class="col-lg-3">
                <label class="form-label ms-0 png"></label>
                <material-button id="cardSearch" color="primary" class="float-lg-start float-sm-end mb-3">
                  <i class="material-icons material-symbols-outlined">search</i> {{ btnSearch }}
                </material-button>
              </div>
            </div>
            <hr>
            <div class="row no-gutters">
              <div class="col-12 d-flex justify-content-end align-items-center">
                <label class="form-label m-0 me-2">{{ $t('content.expfile') }} :</label>
                <div>
                  <material-button v-for="(i, exp) in exportFile" :key="exp" :color="i.cl" variant="outline"
                    class="float-lg-start float-sm-end mb-0 me-2" :class="i.cs">
                    <i class="material-icons material-symbols-outlined">{{ i.ic }}</i> {{ i.nm }}
                  </material-button>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="table-responsive">
                <table class="table table-flush">
                  <thead class="thead-light">
                    <tr>
                      <th>#</th>
                      <th>{{ $t('content.dant') }}</th>
                      <th>{{ $t('content.names') }}</th>
                      <th>{{ $t('content.trans') }}</th>
                      <th>{{ $t('content.ticketno') }}</th>
                      <th>{{ $t('content.massetgroup') }}</th>
                      <th>{{ $t('content.allweight') }}</th>
                      <th>{{ $t('content.allqty') }}</th>
                      <th>{{ $t('content.allprice') }}</th>
                      <th>{{ $t('content.file') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(i, tc) in datatable" :key="tc">
                      <td class="text-sm font-weight-normal">{{ i.no }}</td>
                      <td class="text-sm font-weight-normal">{{ i.dat }}</td>
                      <td class="text-sm font-weight-normal">{{ i.nam }}</td>
                      <td class="text-sm font-weight-normal">{{ i.biz }}</td>
                      <td class="text-sm font-weight-normal">{{ i.new }}</td>
                      <td class="text-sm font-weight-normal">{{ i.ass }}</td>
                      <td class="text-sm font-weight-normal">{{ i.wei }}</td>
                      <td class="text-sm font-weight-normal">{{ i.qty }}</td>
                      <td class="text-sm font-weight-normal">{{ i.amo }}</td>
                      <td class="text-sm font-weight-normal">
                        <material-button color="info" variant="outline" class="rounded-circle" data-bs-toggle="tooltip"
                          data-bs-placement="top" title="ดูรายละเอียด" @click="onLookupDetail">
                          <i class="material-icons material-symbols-outlined">attach_file</i>
                        </material-button>
                        <!-- <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="ตั๋วตีกลับ">
                              <i class="material-icons material-symbols-outlined">keyboard_return</i>
                            </material-button>
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="ยกเลิกตั๋ว">
                              <i class="material-icons material-symbols-outlined">delete</i>
                            </material-button> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script setup>
import { inject, reactive, computed } from 'vue'
import { useI18n } from "vue-i18n"

import MaterialButton from "@/components/MaterialButton.vue"
import MaterialInput from "@/components/MaterialInput.vue"

const { t } = useI18n()
const swal = inject('$swal')

const exportFile = reactive([
  {
    cl: "info",
    cs: "me-2",
    ic: "picture_as_pdf",
    nm: "pdf",
    ac: "",
  },
  {
    cl: "info",
    cs: "",
    ic: "table_rows",
    nm: "excel",
    ac: "",
  },
])

const datatable = reactive([
  {
    no: "1",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "tk-3048",
    biz: "จำนำ",
    ass: "ทองคำ",
    wei: "30.32",
    qty: "5",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ A"
  },
  {
    no: "2",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "tk-1132",
    biz: "ต่อดอก",
    ass: "เพชร",
    wei: "12.34",
    qty: "2",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ B"
  },
  {
    no: "3",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "tk-5611",
    biz: "จำนำ",
    ass: "อื่นๆ",
    wei: "30.32",
    qty: "5",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ B"
  },
  {
    no: "4",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "tk-0021",
    biz: "ไถ่ถอน",
    ass: "ทองคำ",
    wei: "15.63",
    qty: "1",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ A"
  },
])

const btnSearch = computed(() => t("buttons.sear"))

async function onLookupDetail() {
  swal({
    // title: "",
    // text: "ไฟล์แนบที่ 1",
    // imageUrl: "https://unsplash.it/400/200",
    // imageWidth: 400,
    // imageHeight: 200,
    // imageAlt: "ไฟล์รูป 1",
    html:
      '<div class="preview-attached">' +
      '<section class="panel">' +
      '<img src="https://unsplash.it/400/200" alt="1" height="400" width="200">' +
      '<img src="https://unsplash.it/400/200" alt="2" height="400" width="200">' +
      '<img src="https://unsplash.it/400/200" alt="3" height="400" width="200">' +
      '<img src="https://unsplash.it/400/200" alt="4" height="400" width="200">' +
      '</section>' +
      // '</div>' +
      // '<div class="preview-detail">' +
      // '<h6 class="mb">Notice:</h6>' +
      // '<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content here, making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for lorem ipsum will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>' +
      '</div>',
    allowOutsideClick: true,
    showCloseButton: true,
    showCancelButton: false,
    showConfirmButton: false,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
  });
}

</script>
  
<style lang="scss" scoped></style>