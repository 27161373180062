<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12 ">

        <ul id="financeTab" class="nav nav-tabs" role="tablist">

          <li class="nav-item" role="presentation">
            <button id="wait-finance" class="nav-link active" data-bs-toggle="tab" data-bs-target="#waitFinance"
              type="button" role="tab" aria-controls="waitFinance" aria-selected="true">{{ $t('content.amnfinancelist') }}</button>
          </li>

          <li class="nav-item" role="presentation">
            <button id="action-finance" class="nav-link" data-bs-toggle="tab" data-bs-target="#actionFinance"
              type="button" role="tab" aria-controls="actionFinance" aria-selected="false">{{ $t('content.amnfinancenmanage') }}</button>
          </li>
        </ul>

        <div id="financeTabContent" class="tab-content">

          <div id="waitFinance" class="tab-pane fade show active" role="tabpanel" aria-labelledby="wait-finance">
            <div id="que-finances" class="card mt-2">
              <div class="card-header mb-0">
                <h5>{{ $t('content.amnfinancesearch') }}</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t('content.sdate') }}</label>
                    <material-input id="startdate" type="date" />
                  </div>

                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t('content.edate') }}</label>
                    <material-input id="enddate" type="date" />
                  </div>

                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t('content.names') }}</label>
                    <div class="input-group">
                      <input v-model="datas.searchBox1" type="text" class="form-control" aria-describedby="searchName1"
                        readonly>
                      <button id="searchName1" class="btn btn-primary" type="button" data-bs-toggle="modal"
                        data-bs-target="#searchNameModal">
                        <i class="material-icons material-symbols-outlined">search</i>
                      </button>
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t('content.ticketno') }}</label>
                    <material-input type="text" />
                  </div>

                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t('content.amnfinanceno') }}</label>
                    <material-input type="text" />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.amnbiz') }}</label>
                    <select class="form-select">
                      <option value="card0">{{ $t('content.optall') }}</option>
                      <option value="card2">{{ $t('content.actpawn1') }}</option>
                      <option value="card3">{{ $t('content.actpawn2') }}</option>
                      <option value="card4">{{ $t('content.actpawn3') }}</option>
                      <option value="card5">{{ $t('content.actpawn4') }}</option>
                      <option value="card6">{{ $t('content.actpawn5') }}</option>
                    </select>
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.amnfinancestatus') }}</label>
                    <select class="form-select">
                      <option value="card0">{{ $t('content.optall') }}</option>
                      <option value="card1">{{ $t('content.amnfinanceok') }}</option>
                      <option value="card2">{{ $t('content.amnfinancenot') }}</option>
                      <option value="card2">{{ $t('content.amnfinancepartial') }}</option>
                    </select>
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.statusticket') }}</label>
                    <select class="form-select">
                      <option value="card0">{{ $t('content.optall') }}</option>
                      <option value="card1">{{ $t('content.amnstateacting') }}</option>
                      <option value="card2">{{ $t('content.amnstateending') }}</option>
                    </select>
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0 png"></label>
                    <material-button id="cardSearch-fn" color="primary" class="float-lg-start float-sm-end mb-3">
                      <i class="material-icons material-symbols-outlined">search</i> {{ $t('buttons.sear') }}
                    </material-button>
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>{{ $t('content.names') }}</th>
                          <th>{{ $t('content.ticketno') }}</th>
                          <th>{{ $t('content.amnfinanceno') }}</th>
                          <th>{{ $t('content.amnbiz') }}</th>
                          <th>{{ $t('content.amnfinancedoneall') }}</th>
                          <th>{{ $t('content.amnfinanceremain') }}</th>
                          <th>{{ $t('content.amnfinancestatus') }}</th>
                          <th>{{ $t('content.statusticket') }}</th>
                          <th>{{ $t('content.act') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-sm font-weight-normal"><material-checkbox class="m-0" checked="true" /></td>
                          <td class="text-sm font-weight-normal">bbbbb aaaaaa</td>
                          <td class="text-sm font-weight-normal">xxxxx</td>
                          <td class="text-sm font-weight-normal">-</td>
                          <td class="text-sm font-weight-normal">จำนำ</td>
                          <td class="text-sm font-weight-normal text-success">88,888,888,88</td>
                          <td class="text-sm font-weight-normal">88,888,888,88</td>
                          <td class="text-sm font-weight-normal">ยังไม่ได้ชำระ</td>
                          <td class="text-sm font-weight-normal">กำลังดำเนินการ</td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="warning" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('buttons.prev')">
                              <i class="material-icons material-symbols-outlined">keyboard_return</i>
                            </material-button>
                            <material-button color="info" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('buttons.print')">
                              <i class="material-icons material-symbols-outlined">print</i>
                            </material-button>
                          </td>
                        </tr>

                        <tr>
                          <td class="text-sm font-weight-normal"><material-checkbox class="m-0" checked="true" /></td>
                          <td class="text-sm font-weight-normal">bbbbb aaaaaa</td>
                          <td class="text-sm font-weight-normal">xxxxx</td>
                          <td class="text-sm font-weight-normal">-</td>
                          <td class="text-sm font-weight-normal">ต่อดอก</td>
                          <td class="text-sm font-weight-normal text-danger">88,888,888,88</td>
                          <td class="text-sm font-weight-normal">88,888,888,88</td>
                          <td class="text-sm font-weight-normal">ยังไม่ได้ชำระ</td>
                          <td class="text-sm font-weight-normal">กำลังดำเนินการ</td>
                          <td class="text-sm font-weight-normal">                            
                            <material-button color="warning" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('buttons.prev')">
                              <i class="material-icons material-symbols-outlined">keyboard_return</i>
                            </material-button>
                            <material-button color="info" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="$t('buttons.print')">
                              <i class="material-icons material-symbols-outlined">print</i>
                            </material-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-12 d-flex justify-content-end">
                    <div class="me-3">
                      <material-button color="primary" class="float-lg-start float-sm-end mb-3">
                        <i class="material-icons material-symbols-outlined">check</i> {{ $t('content.amnselectlist') }}
                      </material-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="actionFinance" class="tab-pane fade" role="tabpanel" aria-labelledby="action-finance">
            <div id="bank-finances" class="card mt-2">
              <div class="card-header">
                <h5>{{ $t('content.amnbankinfo') }}</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t('content.mbank') }}</label>
                    <select class="form-select">
                      <option value="card1">กสิกรไทย</option>
                      <option value="card2">ไทยพาณิชย์</option>
                    </select>
                  </div>
                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t('content.amnbankaccno') }}</label>
                    <material-input id="bankNo" />
                  </div>
                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t('content.cbankacc') }}</label>
                    <material-input id="bankAccName" />
                  </div>
                  <div class="col-lg-2">
                    <label class="form-label ms-0 png"></label>
                    <material-button id="cardSearch" color="primary" class="float-lg-start float-sm-end mb-3">
                      <i class="material-icons material-symbols-outlined">add</i> {{ $t('buttons.addd') }}
                    </material-button>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>{{ $t('content.amnbankactive') }}</th>
                          <th>{{ $t('content.mbank') }}</th>
                          <th>{{ $t('content.amnbankaccno') }}</th>
                          <th>{{ $t('content.cbankacc') }}</th>
                          <th>{{ $t('content.act') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-sm font-weight-normal">
                            <material-checkbox class="m-0" checked="true" />
                          </td>
                          <td class="text-sm font-weight-normal">กสิกรไทย</td>
                          <td class="text-sm font-weight-normal">065-252888-9</td>
                          <td class="text-sm font-weight-normal">xxxxxx xxxxxxxx</td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle">
                              <i class="material-icons material-symbols-outlined">edit</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle">
                              <i class="material-icons material-symbols-outlined">delete</i>
                            </material-button>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-sm font-weight-normal">
                            <material-checkbox class="m-0" />
                          </td>
                          <td class="text-sm font-weight-normal">ไทยพาณิชย์</td>
                          <td class="text-sm font-weight-normal">067-552448-3</td>
                          <td class="text-sm font-weight-normal">xxxxxx xxxxxxxx</td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle">
                              <i class="material-icons material-symbols-outlined">edit</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle">
                              <i class="material-icons material-symbols-outlined">delete</i>
                            </material-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div id="pawn-finances" class="card mt-4">
              <div class="card-header">
                <h5>{{ $t('content.amnfinanceinfo') }}</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-flush table-hover">
                      <thead class="thead-light">
                        <tr>
                          <th>{{ $t('content.cname') }}</th>
                          <th>{{ $t('content.ticketno') }}</th>
                          <th>{{ $t('content.amnbiz') }}</th>
                          <th>{{ $t('content.amnfinanceoutput') }}</th>
                          <th>{{ $t('content.amnfinanceinput') }}</th>
                          <th>{{ $t('content.amnfinancetype') }}</th>
                          <th>{{ $t('content.amnfinancetotal') }}</th>
                          <th>{{ $t('content.act') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-sm font-weight-normal">bbbbb aaaaaa</td>
                          <td class="text-sm font-weight-normal">xxxxx</td>
                          <td class="text-sm font-weight-normal">จำนำ</td>
                          <td class="text-sm font-weight-normal">88,888,888,88</td>
                          <td class="text-sm font-weight-normal"></td>
                          <td class="text-sm font-weight-normal"></td>
                          <td class="text-sm font-weight-normal"></td>
                          <td class="text-sm font-weight-normal">
                            <!-- <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="ชำระตั๋ว">
                              <i class="material-icons material-symbols-outlined">calculate</i>
                            </material-button> -->
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="นำรายการออก">
                              <i class="material-icons material-symbols-outlined">cancel</i>
                            </material-button>
                          </td>
                        </tr>

                        <tr>
                          <td class="text-sm font-weight-normal">bbbbb aaaaaa</td>
                          <td class="text-sm font-weight-normal">xxxxx</td>
                          <td class="text-sm font-weight-normal">ต่อดอก</td>
                          <td class="text-sm font-weight-normal"></td>
                          <td class="text-sm font-weight-normal">88,888,888,88</td>
                          <td class="text-sm font-weight-normal"></td>
                          <td class="text-sm font-weight-normal"></td>
                          <td class="text-sm font-weight-normal">
                            <!-- <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="ชำระตั๋ว">
                              <i class="material-icons material-symbols-outlined">calculate</i>
                            </material-button> -->
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="นำรายการออก">
                              <i class="material-icons material-symbols-outlined">cancel</i>
                            </material-button>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot class="table-light">
                        <tr>
                          <td class="text-sm font-weight-bold" colspan="3">{{ $t('content.amntotaltransact') }}</td>
                          <td class="text-sm font-weight-bold">88,888,888,88</td>
                          <td class="text-sm font-weight-bold">88,888,888,88</td>
                          <td class="text-sm font-weight-bold text-success">{{ $t('content.amnpay') }}</td>
                          <td class="text-sm font-weight-bold text-success">88,888,888,88</td>
                          <td class="text-sm font-weight-bold"></td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>

                <div class="row mt-2">
                  <div class="col-12">
                    <h5>{{ $t('content.amnfinanceprocess') }} <span class="text-success">({{ $t('content.amnpay') }} 88,888,888,88)</span></h5>
                  </div>

                  <div class="col-lg-6">
                    <label class="form-label ms-0">{{ $t('content.amnfinanceno') }}</label>
                    <material-input id="issuePay" disabled="true" value="receipt-xxxxxxxx-xxx" />
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.amntypetrans') }}</label>
                    <select class="form-select">
                      <option value="card1">{{ $t('content.amncash') }}</option>
                      <option value="card2">{{ $t('content.amntransfer') }}</option>
                      <option value="card3">{{ $t('content.amncreditcard') }}</option>
                      <option value="card4">{{ $t('content.amncheque') }}</option>
                    </select>
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.amnbankaccno') }}</label>
                    <select class="form-select">
                      <option value="card1">กสิกรไทย</option>
                      <option value="card2">ไทยพาณิชย์</option>
                    </select>
                  </div>

                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t('content.amnhowtomoney') }}</label>
                    <material-input />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.amninputmoneytype') }}</label>
                    <material-input />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.amnsourcemoney') }}</label>
                    <select class="form-select">
                      <optgroup :label="$t('content.amnfromdrawer')">
                        <option>{{ $t('content.amndrawer') }} 1</option>
                        <option>{{ $t('content.amndrawer') }} 2</option>
                        <option>{{ $t('content.amndrawer') }} 3</option>
                      </optgroup>
                      <optgroup :label="$t('content.amnfrombankacc')">
                        <option>{{ $t('content.amnbankacc') }} 1</option>
                        <option>{{ $t('content.amnbankacc') }} 2</option>
                        <option>{{ $t('content.amnbankacc') }} 3</option>
                      </optgroup>
                    </select>
                  </div>

                  <div class="col-lg-1">
                    <label class="form-label ms-0 png"></label>
                    <material-button color="primary" variant="outline" class="rounded-circle cs mt-1"
                      data-bs-toggle="tooltip" data-bs-placement="top" title="เพิ่ม">
                      <i class="material-icons material-symbols-outlined">add</i>
                    </material-button>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <label class="form-label ms-0">{{ $t('content.amnfinancenote') }}</label>
                    <material-input id="detailPay" />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.amnpayremain') }}</label>
                    <material-input disabled="true" value="88,888,888.88" />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.amnreceiveremain') }}</label>
                    <material-input disabled="true" value="88,888,888.88" />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0 png"></label>
                    <material-button color="primary" class="float-lg-start float-sm-end mb-3" @click="onConfirmPayment">
                      <i class="material-icons material-symbols-outlined">check</i> {{ $t('buttons.comp') }}
                    </material-button>
                  </div>
                </div>

                <div v-if="datas.showQR" class="row mt-4">
                  <div class="col-12 text-center">
                    <hr>
                    <label class="d-block form-label ms-0 ">{{ $t('content.amnqrrunapp') }}</label>
                    <img :src="QrCode" class="img img-fluid qr-scan-app" />
                    <div class="d-flex justify-content-center mt-2">
                      <material-button color="primary" class="float-lg-start float-sm-end mb-3" @click="onEditPayment">
                        <i class="material-icons material-symbols-outlined">edit</i>
                        {{ $t('buttons.edit') }}
                      </material-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Search Customer Dialog -->
        <div id="searchNameModal" class="modal fade" tabindex="-1" aria-labelledby="searchNameModalLabel"
          aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">

              <div class="modal-header">
                <h4 class="modal-title">{{ $t('content.cnamelist') }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <div class="preview-table">
                  <section class="panel">
                    <table class="table table-striped table-responsive">
                      <thead>
                        <tr>
                          <th style="width: 100px;">{{ $t('content.ccode') }}</th>
                          <th style="width: 180px;">{{ $t('content.cnameth') }}</th>
                          <th style="width: 180px;">{{ $t('content.cnameen') }}</th>
                          <th style="width: 160px;">{{ $t('content.cidcard') }}</th>
                          <th style="width: 110px;">{{ $t('content.cmobile') }}</th>
                          <th style="width: 120px;">{{ $t('content.cgrade') }}</th>
                          <!-- <th></th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="row-search">
                          <td>
                            <material-input type="text" :label="t('content.ccode')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cnameth')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cnameen')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cidcard')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cmobile')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cgrade')" />
                          </td>
                          <!-- <td>
                                <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                                  data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch">
                                  <i class="material-icons material-symbols-outlined">search</i>
                                </material-button>
                              </td> -->
                        </tr>
                        <tr v-for="(i, cs) in datas.cname" :key="cs" data-bs-dismiss="modal">
                          <td>{{ i.cd }}</td>
                          <td>{{ i.th }}</td>
                          <td>{{ i.en }}</td>
                          <td>{{ i.id }}</td>
                          <td>{{ i.mb }}</td>
                          <td>{{ i.gd }}</td>
                          <!-- <td></td> -->
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </div>
              </div>

              <div class="modal-footer">
                <!-- <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button> -->
                <nav aria-label="Table Paginator">
                  <ul class="pagination justify-content-end">
                    <li class="page-item disabled">
                      <a class="page-link" href="#" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item">
                      <a class="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script setup>
import { reactive, inject, onMounted } from 'vue'
import { useI18n } from "vue-i18n"

import * as Choices from "choices.js"
import MaterialButton from "@/components/MaterialButton.vue"
import MaterialInput from "@/components/MaterialInput.vue"
import MaterialCheckbox from "@/components/MaterialCheckbox.vue"
// import MaterialRadio from "@/components/MaterialRadio.vue"

import QrCode from "@/assets/img/pawn/qr_sample.png"

const { t } = useI18n()
const swal = inject('$swal')

const datas = reactive({
  showQR: false,
  searchBox1: '',
  cname: [
    {
      cd: "xxxxxx",
      th: "พิชัย จิตภักดี",
      en: "Pichai Jitphakdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "A",
    },
    {
      cd: "xxxxxx",
      th: "นิรุจ เสมากิจ",
      en: "Nirut Semakij",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "VIP",
    },
    {
      cd: "xxxxxx",
      th: "บวรศักดิ์ นิลสุภาพ",
      en: "Borwonsak Nilsupap",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "S",
    },
    {
      cd: "xxxxxx",
      th: "ชิดชนก วิมลพัฒน์",
      en: "Chidchanok Wimonpatr",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "C",
    },
    {
      cd: "xxxxxx",
      th: "สมบูรณ์ทรัพย์ ทองดี",
      en: "Somboonsap Thongdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "B",
    },
    {
      cd: "xxxxxx",
      th: "พิชัย จิตภักดี",
      en: "Pichai Jitphakdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "A",
    },
    {
      cd: "xxxxxx",
      th: "นิรุจ เสมากิจ",
      en: "Nirut Semakij",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "VIP",
    },
    {
      cd: "xxxxxx",
      th: "บวรศักดิ์ นิลสุภาพ",
      en: "Borwonsak Nilsupap",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "S",
    },
    {
      cd: "xxxxxx",
      th: "ชิดชนก วิมลพัฒน์",
      en: "Chidchanok Wimonpatr",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "C",
    },
    {
      cd: "xxxxxx",
      th: "สมบูรณ์ทรัพย์ ทองดี",
      en: "Somboonsap Thongdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "B",
    },
  ],
})

async function onConfirmPayment() {
  swal({
    title: "ยืนยันชำระรายการนี้",
    text: "ระบบจะทำการชำระต่อไป",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: "ยืนยัน",
    cancelButtonText: "ยกเลิก",
  }).then((result) => {
    if (result.isConfirmed) {
      swal("ยืนยันการชำระสำเร็จ", "", "success");
      datas.showQR = true
      console.log(datas.showQR)
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกการชำระ", "", "error");
      datas.showQR = false
      console.log(datas.showQR)
    }
  })
}

async function onEditPayment() {
  swal({
    title: "แก้ไขรายการนี้",
    text: "ระบบจะย้อนกลับไปแก้ไขรายการใหม่อีกครั้ง",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: "ยืนยัน",
    cancelButtonText: "ยกเลิก",
  }).then((result) => {
    if (result.isConfirmed) {
      swal("กลับไปแก้ไข", "", "success")
      datas.showQR = false
      console.log(datas.showQR)
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกการแก้ไขรายการ", "", "error")
      datas.showQR = true
      console.log(datas.showQR)
    }
  })
}

onMounted(() => {
  if (document.getElementById("choices-month")) {
    var month = document.getElementById("choices-month");
    setTimeout(function () {
      new Choices(month);
    }, 1);

    // eslint-disable-next-line no-unused-vars
    var d = new Date();
    var monthArray = new Array();
    monthArray[0] = "January";
    monthArray[1] = "February";
    monthArray[2] = "March";
    monthArray[3] = "April";
    monthArray[4] = "May";
    monthArray[5] = "June";
    monthArray[6] = "July";
    monthArray[7] = "August";
    monthArray[8] = "September";
    monthArray[9] = "October";
    monthArray[10] = "November";
    monthArray[11] = "December";
    for (let m = 0; m <= 11; m++) {
      var optn = document.createElement("OPTION");
      optn.text = monthArray[m];
      // server side month start from one\
      optn.value = m + 1;
      // if june selected
      if (m == 1) {
        optn.selected = true;
      }
      month.options.add(optn);
    }
  }

  if (document.getElementById("choices-day")) {
    var day = document.getElementById("choices-day");
    setTimeout(function () {
      new Choices(day);
    }, 1);

    for (let y = 1; y <= 31; y++) {
      var optn1 = document.createElement("OPTION");
      optn1.text = y;
      optn1.value = y;

      if (y == 1) {
        optn1.selected = true;
      }

      day.options.add(optn1);
    }
  }

  if (document.getElementById("choices-year")) {
    var year = document.getElementById("choices-year");
    setTimeout(function () {
      new Choices(year);
    }, 1);

    for (let y = 1900; y <= 2020; y++) {
      var optn2 = document.createElement("OPTION");
      optn2.text = y;
      optn2.value = y;

      if (y == 2020) {
        optn.selected = true;
      }

      year.options.add(optn2);
    }
  }
})

</script>
  
<style lang="scss" scoped></style>