<template>
  <div class="container-fluid">
    <div class="loading-state" v-if="loading">
      <ProgressSpinner fill="transparent" aria-label="Loading" />
    </div>
    <div class="row mb-5">
      <div class="col-12 ">
        <div class="card h-100 mt-3">
          <div class="card-header mb-0">
            <div class="row no-gutters">
              <div class="col-12 d-flex justify-content-between align-items-center">
                <h5>{{ $t('content.mwarning') }}</h5>
                <div>
                  <material-button color="primary" class="float-lg-start float-sm-end mb-0" @click="addNewItems">
                    <i class="material-icons material-symbols-outlined">add</i> {{ btnAddNew }}
                  </material-button>
                  <material-button color="primary" class="float-lg-start float-sm-end mb-0" data-bs-toggle="modal"
                    data-bs-target="#wanningDataModal" id="wanningModal" v-show="false" />
                </div>
              </div>
            </div>

          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="table-responsive">
                <table class="table">
                  <thead class="thead-light">
                    <tr>
                      <th>#</th>
                      <th>{{ $t('content.names') }}</th>
                      <th>{{ $t('content.docnum') }}</th>
                      <th>{{ $t('content.warnlvl') }}</th>
                      <th>{{ $t('content.refer') }}</th>
                      <th>{{ $t('content.file') }}</th>
                      <th>{{ $t('content.detail') }}</th>
                      <th>{{ $t('content.act') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="group-id-ticket">
                      <td class="text-sm font-weight-normal">
                        <div class="group-tickect">
                          <h6 class="mb-0"><b>{{ btnSearch }}</b></h6>
                        </div>
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-input v-model="name_search" type="text" class="mb-0" :label="$t('content.names')" />
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-input v-model="docno_search" type="text" class="mb-0" :label="$t('content.docnum')" />
                      </td>
                      <td class="text-sm font-weight-normal">
                        <select class="form-select" v-model="severity_search">
                          <option value="">{{ $t("content.optall") }}</option>
                          <option v-for="(i, d2) in datas.demo2" :key="d2" :value="i.val">{{ i.items }}
                          </option>
                        </select>
                      </td>
                      <td class="text-sm font-weight-normal" colspan="3">

                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch"
                          @click="loadWanningListApi">
                          <i class="material-icons material-symbols-outlined">search</i>
                        </material-button>
                      </td>
                    </tr>

                    <tr v-for="(row, index) in wanningList" :key="index">
                      <td class="text-sm font-weight-normal">
                        {{ formatNumberString((currentPage - 1) * perPage + index + 1) }}
                      </td>
                      <td class="text-sm font-weight-normal">
                        {{ row.name }}
                      </td>
                      <td class="text-sm font-weight-normal">
                        {{ row.docNumber }}
                      </td>
                      <td class="text-sm font-weight-normal">
                        {{ filterSeverity(row.severity) }}
                      </td>
                      <td class="text-sm font-weight-normal">
                        {{ row.reportBy }}
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-button color="primary" variant="outline" class="rounded-circle"
                          v-if="row.refDocUid !== '0'" @click="getFileData(row)" :title="t('content.fileview')">
                          <i class="material-icons material-symbols-outlined">attach_file</i>
                        </material-button>
                        <material-button color="primary" variant="outline" class="rounded-circle" data-bs-toggle="modal"
                          data-bs-target="#attachmentsModal" v-show="false" id="attachmenDialog">
                          <i class="material-icons material-symbols-outlined">attach_file</i>
                        </material-button>
                      </td>
                      <td class="text-sm font-weight-normal">
                        {{ row.remark }}
                      </td>
                      <td class="text-sm font-weight-normal">
                        <material-button color="info" variant="outline" class="rounded-circle" data-bs-toggle="tooltip"
                          data-bs-placement="top" :title="btnEdit" @click="editRowItems(row)">
                          <i class="material-icons material-symbols-outlined">edit</i>
                        </material-button>
                        <material-button color="danger" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel" @click="deleteRowItems(row)">
                          <i class="material-icons material-symbols-outlined">delete</i>
                        </material-button>
                      </td>
                      <!-- <td v-if="row.uid === sanctionp_detail.uid" class="text-sm font-weight-normal">
                        <material-button color="primary" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave"
                          :disabled="sanctionp_detail.name === '' || sanctionp_detail.docNumber === ''"
                          @click="saveEditRowItems">
                          <i class="material-icons material-symbols-outlined">save</i>
                        </material-button>
                        <material-button color="danger" variant="outline" class="rounded-circle"
                          data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                          @click="cancelEditRowItems">
                          <i class="material-icons material-symbols-outlined">close</i>
                        </material-button>
                      </td> -->
                    </tr>
                    <tr v-if="wanningList.length === 0">
                      <td colspan="7" class="text-sm font-weight-normal">
                        <p style="text-align: center">No Record Found</p>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="14" style="text-align: right">Total Rows : {{ totalRows_s }}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <pre></pre>
            <div style="display: flex; justify-content: flex-end">
              <ul v-show="wanningList.length > 0" class="pagination justify-content-end">
                <div class="project-container">
                  <div class="example-one">
                    <vue-awesome-paginate v-model="currentPage" :total-items="totalRows" :items-per-page="perPage"
                      @click="onClickHandler" />
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>

        <!-- Search Customer Dialog -->
        <div id="searchNameModal" class="modal fade" tabindex="-1" aria-labelledby="searchNameModalLabel"
          aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">

              <div class="modal-header">
                <h4 class="modal-title">{{ $t('content.cnamelist') }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <div class="preview-table">
                  <section class="panel">
                    <table class="table table-striped table-responsive">
                      <thead>
                        <tr>
                          <th>{{ $t('content.ccode') }}</th>
                          <th>{{ $t('content.cnameth') }}</th>
                          <th>{{ $t('content.cnameen') }}</th>
                          <th>{{ $t('content.cidcard') }}</th>
                          <th>{{ $t('content.cmobile') }}</th>
                          <th>{{ $t('content.cgrade') }}</th>
                          <!-- <th></th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="row-search">
                          <td>
                            <material-input type="text" :label="t('content.ccode')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cnameth')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cnameen')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cidcard')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cmobile')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cgrade')" />
                          </td>
                          <!-- <td>
                                <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                                  data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch">
                                  <i class="material-icons material-symbols-outlined">search</i>
                                </material-button>
                              </td> -->
                        </tr>
                        <tr v-for="(i, cs) in datas.cname" :key="cs" data-bs-dismiss="modal">
                          <td>{{ i.cd }}</td>
                          <td>{{ i.th }}</td>
                          <td>{{ i.en }}</td>
                          <td>{{ i.id }}</td>
                          <td>{{ i.mb }}</td>
                          <td>{{ i.gd }}</td>
                          <!-- <td></td> -->
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </div>
              </div>

              <div class="modal-footer">
                <!-- <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button> -->
                <nav aria-label="Table Paginator">
                  <ul class="pagination justify-content-end">
                    <li class="page-item disabled">
                      <a class="page-link" href="#" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item">
                      <a class="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>

            </div>
          </div>
        </div>

        <!-- Attachments Dialog -->
        <div id="attachmentsModal" class="modal fade" tabindex="-1" aria-labelledby="lookupModalLabel"
          aria-hidden="true">
          <div class="modal-dialog" style="max-width: 420px">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.detail") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <div id="attachmentsImages" class="carousel slide" data-bs-ride="carousel">
                  <div class="carousel-indicators" id="demo2">
                  </div>
                  <div class="carousel-inner" id="demo3">
                  </div>
                  <button class="carousel-control-prev" type="button" data-bs-target="#attachmentsImages"
                    data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button class="carousel-control-next" type="button" data-bs-target="#attachmentsImages"
                    data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!-- Add/Eedit PlatformSanctionPerson Dialog -->
        <div id="wanningDataModal" class="modal fade" tabindex="-1" aria-labelledby="wanningModalLabel"
          aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">{{ $t("content.mwarning") }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">

                <div class="row">

                  <div class="col-lg-4">
                    <label class="form-label ms-0"> {{ $t("content.names") }}</label>
                    <material-input type="text" class="mb-0" v-model="sanctionp_detail.name" />
                  </div>

                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t("content.docnum") }}</label>
                    <material-input type="text" class="mb-0" v-model="sanctionp_detail.docNumber" />
                  </div>

                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t("content.warnlvl") }}</label>
                    <select class="form-select" v-model="sanctionp_detail.severity">
                      <option value="" disabled selected>{{ $t("content.select") }}</option>
                      <option v-for="(i, d2) in datas.demo2" :key="d2" :value="i.val">{{ i.items }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="row">
                  <div class="col-lg-6">
                    <label class="form-label ms-0">{{ $t("content.refer") }}</label>
                    <material-input type="text" class="mb-0" v-model="sanctionp_detail.reportBy" />
                  </div>
                  <div class="col-lg-6">
                    <label class="form-label ms-0">{{ $t("content.file") }}</label>
                    <div class="input-group custom-file-button">
                      <label for="upload" class="input-group-text mb-0">{{ $t("buttons.file") }}</label>
                      <input id="upload" type="file" accept="application/pdf,image/*" class="form-control mb-0"
                        @change="addFile" />
                    </div>
                  </div>
                </div>


                <div class="col-12 mt-2">
                  <div class="d-flex align-items-center mb-1">
                    <label class="form-label ms-0 mb-0 me-3">
                      {{ $t("content.detail") }}
                    </label>
                  </div>
                  <Textarea rows="3" v-model="sanctionp_detail.remark" class="w-100" />
                </div>

              </div>

              <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
                  {{ btnClose }}
                </button>
                <button type="button" class="btn btn-success" data-bs-dismiss="modal" :disabled="sanctionp_detail.name === '' || sanctionp_detail.docNumber === ''
                  || sanctionp_detail.severity === '' || sanctionp_detail.remark === ''" @click="checkprocess">
                  {{ btnSave }}
                </button>
              </div>



            </div>
          </div>
        </div>




      </div>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable no-unused-vars */
import ProgressSpinner from 'primevue/progressspinner';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import { reactive, inject, computed, ref, } from 'vue'
import { useI18n } from "vue-i18n"
import MaterialButton from "@/components/MaterialButton.vue"
import MaterialInput from "@/components/MaterialInput.vue"
import Textarea from 'primevue/textarea';

import * as st from "@/config/setting.js"
import axios from "axios";
import moment from 'moment'

import { useDocTypeStore } from '@/stores/doctype'
import { useDocumentStore } from '@/stores/document'
import { useSanctionPersonStore } from '@/stores/sanctionperson'

const storedoct = useDocTypeStore()
const storedoc = useDocumentStore()
const storesancp = useSanctionPersonStore()



const { t } = useI18n()
const swal = inject('$swal')

const datas = reactive({
  addNew: false,
  rowEdit: false,
  coreCopy: true,
  coreBox1: "",
  cname: [
    {
      cd: "xxxxxx",
      th: "พิชัย จิตภักดี",
      en: "Pichai Jitphakdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "A",
    },
    {
      cd: "xxxxxx",
      th: "นิรุจ เสมากิจ",
      en: "Nirut Semakij",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "VIP",
    },
    {
      cd: "xxxxxx",
      th: "บวรศักดิ์ นิลสุภาพ",
      en: "Borwonsak Nilsupap",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "S",
    },
    {
      cd: "xxxxxx",
      th: "ชิดชนก วิมลพัฒน์",
      en: "Chidchanok Wimonpatr",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "C",
    },
    {
      cd: "xxxxxx",
      th: "สมบูรณ์ทรัพย์ ทองดี",
      en: "Somboonsap Thongdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "B",
    },
    {
      cd: "xxxxxx",
      th: "พิชัย จิตภักดี",
      en: "Pichai Jitphakdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "A",
    },
    {
      cd: "xxxxxx",
      th: "นิรุจ เสมากิจ",
      en: "Nirut Semakij",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "VIP",
    },
    {
      cd: "xxxxxx",
      th: "บวรศักดิ์ นิลสุภาพ",
      en: "Borwonsak Nilsupap",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "S",
    },
    {
      cd: "xxxxxx",
      th: "ชิดชนก วิมลพัฒน์",
      en: "Chidchanok Wimonpatr",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "C",
    },
    {
      cd: "xxxxxx",
      th: "สมบูรณ์ทรัพย์ ทองดี",
      en: "Somboonsap Thongdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "B",
    },
  ],
  demo1: [
    { items: "บัตรประชาชน" },
    { items: "บัตรประจำตัวคนซึ่งไม่มีสัญชาติไทย" },
    { items: "ใบอนุญาตทำงาน" },
    { items: "หนังสือเดินทาง" },
  ],
  demo2: [
    { items: "ยอมรับได้", val: 'LOW' },
    { items: "อันตรายน้อย", val: 'MEDIUM' },
    { items: "อันตรายมาก", val: 'HIGH' },
  ],
})

const btnAddNew = computed(() => t("buttons.impo"))
const btnEdit = computed(() => t("buttons.edit"))
const btnSearch = computed(() => t("buttons.sear"))
const btnSave = computed(() => t("buttons.save"))
const btnDel = computed(() => t("buttons.remo"))
const btnClose = computed(() => t("buttons.clos"))

const thumb_name = ref("");
const thumb_id = ref("");
const thumb = ref(null);
const name_search = ref("");
const docno_search = ref("");
const severity_search = ref("");


const doctypeAccList = ref([])
const wanningList = ref([])

const images = ref([])
const images_l = ref([])
const loading = ref(false);

const sanctionp_detail = ref({
  uid: '',
  groupUid: '',
  name: '',
  docNumber: '',
  refDocUid: '0',
  severity: '',
  reportDate: null,
  reportBy: '',
  remark: '',
  version: '',
  status: '',
})


const onClickHandler = (page) => {
  updatePagination()
};

const currentPage = ref(1);
const totalRows = ref(1);
const perPage = ref(10);
const totalRows_s = ref();

const loadWanningListApi = async (val) => {
  let data = "";
  if (currentPage.value !== 1) {
    if (wanningList.value.length === 1 && val === 'del') {
      currentPage.value = currentPage.value - 1
    }
  }
  if (name_search.value !== '' || docno_search.value !== '' || severity_search.value !== '' && val !== 'del') {
    currentPage.value = 1
  }
  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    name: name_search.value,
    docNumber: docno_search.value,
    severity: severity_search.value
  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storesancp.sanctionpListFetch(payload);
  // console.log("loadWanningListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        wanningList.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
      }
    }
  }
}

loadWanningListApi()


const loadDocTypeListApi = async (val) => {
  let data = "";
  data = {
    index: 0,
    size: 100,
    name: "",
    docGroupUid: '601433176200681039'
  };

  const payload = data;
  const response = await storedoct.docTypeListFetch(payload);
  // console.log("loadDocTypeListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response) {
      if (response.status === 200) {
        if (response.data.rtnDesc === "success") {
          doctypeAccList.value = response.data.data.list;
          // console.log('list : '+list.value.length)
        }
      }
    }
  }
};

// loadDocTypeListApi()


function formatNumberString(num) {
  return String(num).replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    "$1,"
  );
}


async function addFile(e) {
  thumb.value = null
  thumb_name.value = ""
  let date = new Date()
  if (e.target.files[0] !== undefined) {
    thumb.value = await toBase64(e.target.files[0])
    thumb_name.value = 'file-' + date.getFullYear() + date.getMonth() + 1
      + date.getDate() + date.getHours() + date.getMinutes() + date.getSeconds()
    if (e.target.files[0].type === "image/jpeg") {
      thumb_name.value = thumb_name.value + ".jpg"
    } else if (e.target.files[0].type === "image/png") {
      thumb_name.value = thumb_name.value + ".png"
    } else if (e.target.files[0].type === 'application/pdf') {
      thumb_name.value = thumb_name.value + ".pdf"
    }
  }

}

const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result.replace('data:', '')
    .replace(/^.+,/, ''));
  reader.onerror = reject;
});

function filterSeverity(str) {
  let side = ''
  let name = datas.demo2.filter((c) => c.val === str);
  if (name.length > 0) {
    side = name[0].items
  }
  return side
}

function clearItems() {
  thumb.value = null
  datas.addNew = false
  sanctionp_detail.value = {
    uid: '',
    groupUid: '',
    name: '',
    docNumber: '',
    refDocUid: '0',
    severity: '',
    reportDate: null,
    reportBy: '',
    remark: '',
    version: '',
    status: '',
  }
}

async function addSanctionp() {
  const payload = sanctionp_detail.value;
  if (thumb.value !== null) {
    thumb_id.value = '0'
    await addfileApi()
    payload.refDocUid = thumb_id.value
  }
  payload.reportDate = moment(new Date()).format('YYYY-MM-DD')

  // if (payload.reportDate !== null) {
  //   payload.reportDate = moment(new Date(payload.reportDate)).format('YYYY-MM-DD')
  // } else {
  //   payload.reportDate = null
  // }


  const response = await storesancp.sanctionpAddFetch(payload);
  // console.log('addSanctionp r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        clearItems()
        swal("บันทึกสำเร็จ", "", "success");
        loadWanningListApi('add')
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function updateSanctionp() {
  const payload = sanctionp_detail.value;
  if (thumb.value !== null) {
    thumb_id.value = '0'
    await addfileApi()
    payload.refDocUid = thumb_id.value
  }

  // if (payload.reportDate !== null) {
  //   payload.reportDate = moment(new Date(payload.reportDate)).format('YYYY-MM-DD')
  // } else {
  //   payload.reportDate = null
  // }


  const response = await storesancp.sanctionpUpdateFetch(payload);
  // console.log('addLost r : ' + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        clearItems()
        swal("บันทึกสำเร็จ", "", "success");
        loadWanningListApi('update')
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function deleteSanctionp(id) {
  let data = "";
  data = {
    uid: id,
  };
  const payload = data;
  // const response = await store.dispatch("docTypeDeleteFetch", payload);
  const response = await storesancp.sanctionpDeleteFetch(payload);
  // console.log("deletedocType : " + JSON.stringify(response));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        swal("บันทึกสำเร็จ", "", "success");
        loadWanningListApi('del');
      } else {
        swal("บันทึกไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}


async function addfileApi() {
  let data = "";
  data = {
    name: thumb_name.value,
    data: thumb.value,
  };

  const payload = data;
  // console.log("doc 1 : " + JSON.stringify(payload));
  const response = await storedoc.addFetch(payload);
  // console.log("doc 2 : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        thumb_id.value = response.data.data.uid
      } else {
        swal("บันทึกไฟล์ไม่สำเร็จ", response.data.rtnDesc, "error");
      }
    }
  }
}

async function getFileData(item) {
  loading.value = true
  let url = []
  images.value = []
  url.push(String(st.url_api + "/doc-svc/document/info/" + item.refDocUid));
  if (url.length > 0) {
    await getFile(url)
    if (images.value.length > 0) {
      if (images.value[0].title === 'application/pdf') {
        window.open(images.value[0].src, '_blank').focus();
      } else {
        setGallery2()
        document.getElementById("attachmenDialog").click();
      }

      loading.value = false
    } else {
      loading.value = false
    }
  }
}



async function getFile(urls) {
  let file_L = []
  const key = JSON.parse(localStorage.getItem("FR-SDK-nvtx-platform"));
  const accessToken = key.accessToken;
  axios.defaults.headers["Authorization"] = `Bearer ${accessToken}`;
  const requests = urls.map((url) => axios.get(url));
  await Promise.all(requests).then(allResults => {
    if (allResults) {
      file_L = allResults
      if (file_L !== null) {
        for (let i = 0; i < file_L.length; i++) {
          // console.log('file_L : ' + JSON.stringify(file_L.value[i].data.data) + ' file_L 2 : ' + JSON.stringify(file_L.value[i].data.name));
          if (file_L[i].data.data !== null) {
            const file = getFileFromBase64(file_L[i].data.data.data, file_L[i].data.data.name)
            // console.log('file : ' + file.size + ' file t ' + file.type)
            const data = URL.createObjectURL(file);
            let obj = {
              src: data,
              thumbnailImageSrc: data,
              alt: '',
              title: file.type,
              filename: file_L[i].data.data.name,
            }
            images.value.push(obj)
          } else {
            console.log('no dataFile ')
          }

          // console.log('File xx : ', file_L.value[i].file.type + 'File size : ', file_L.value[i].file.size + ' bytes in size  name : ' + file_L.value[i].data.data.name);
        }
      }
    }
  })

}


function getFileFromBase64(string64, fileName) {
  const trimmedString = string64.replace('dataimage/jpegbase64', '');
  var ext = fileName.split('.').pop();
  let type = '';
  let imageContent = null
  if (ext === 'jpg') {
    type = 'image/jpeg'
  } else if (ext === 'png') {
    type = 'image/png'
  } else if (ext === 'pdf') {
    type = 'application/pdf'
  }

  if (type === 'application/pdf') {
    imageContent = atob(string64);
  } else {
    imageContent = atob(trimmedString);
  }

  const buffer = new ArrayBuffer(imageContent.length);
  const view = new Uint8Array(buffer);

  for (let n = 0; n < imageContent.length; n++) {
    view[n] = imageContent.charCodeAt(n);
  }

  const blob = new Blob([buffer], { type });
  return new File([blob], fileName, { lastModified: new Date().getTime(), type });
}


function setGallery2() {

  var x = "", i, y = "";

  for (i = 0; i < images.value.length; i++) {

    if (i == 0) {
      // console.log('itemImg xxx : ' + i + " " + JSON.stringify(images.value[i].src))
      x = x + '<div class="carousel-item active"> ' +
        '<img src="' + images.value[i].src + '" class="d-block w-100" alt="..." />' +
        '</div>';
      y = y + '<button type = "button" data-bs-target="#attachmentsImages" data-bs-slide-to="' + String(i) + '"' +
        ' class="active" aria-current="true" aria-label="Slide ' + Number(i + 1) + '"> </button >';
    } else {
      // console.log('itemImg zzz : ' + i + " " + JSON.stringify(images.value[i].src))
      x = x + '<div class="carousel-item"> ' +
        '<img src="' + images.value[i].src + '" class="d-block w-100" alt="..." />' +
        '</div>';
      y = y + '<button type = "button" data-bs-target="#attachmentsImages" data-bs-slide-to="' + String(i) + '"' +
        ' aria-label="Slide ' + Number(i + 1) + '"> </button >';
    }

  }
  document.getElementById("demo2").innerHTML = y;
  document.getElementById("demo3").innerHTML = x;

}


async function updatePagination() {
  wanningList.value = []
  let data = "";
  data = {
    index: currentPage.value - 1,
    size: perPage.value,
    name: name_search.value,
    docNumber: docno_search.value,
    severity: severity_search.value


  };
  const payload = data;
  // console.log("payload : " + JSON.stringify(payload));
  const response = await storesancp.sanctionpListFetch(payload);
  // console.log("loadCustomerListApi : " + JSON.stringify(response.data));
  if (response) {
    if (response.status === 200) {
      if (response.data.rtnDesc === "success") {
        wanningList.value = response.data.data.list;
        totalRows.value = response.data.data.ext.total
        totalRows_s.value = formatNumberString(totalRows.value)
      }
    }
  }
}

async function addNewItems() {
  swal({
    title: "เพิ่มรายการ",
    text: "คุณต้องการเพิ่มรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItems()
      datas.addNew = true
      document.getElementById("upload").value = null;
      document.getElementById('wanningModal').click()
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกรายการ", "", "error")
      clearItems()
    }
  })
}

async function saveNewItems() {
  swal({
    title: "ต้องการบันทึกรายการนี้",
    text: "คุณต้องการบันทึกรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.save'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      addSanctionp()
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกการบันทึก", "", "error")
      clearItems()
    }
  })
}

async function cancelSaveNewItems() {
  swal({
    title: "ยกเลิกบันทึกรายการนี้",
    text: "คุณต้องการยกเลิกบันทึกรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItems()
    } else if (result.dismiss == 'cancel') {
      datas.addNew = true
    }
  })
}

async function editRowItems(item) {
  swal({
    title: "แก้ไขรายการ",
    text: "คุณต้องการแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItems()
      sanctionp_detail.value = {
        uid: item.uid,
        groupUid: item.groupUid,
        name: item.name,
        docNumber: item.docNumber,
        refDocUid: item.refDocUid,
        severity: item.severity,
        reportDate: item.reportDate,
        reportBy: item.reportBy,
        remark: item.remark,
        version: item.version,
        status: item.status,
      }
      if (sanctionp_detail.value.reportDate !== null) {
        sanctionp_detail.value.reportDate = new Date(sanctionp_detail.value.reportDate)
      }
      document.getElementById("upload").value = null;
      document.getElementById('wanningModal').click()
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกรายการ", "", "error")
      clearItems()
    }
  })
}

async function saveEditRowItems() {
  swal({
    title: "บันทึกแก้ไขรายการ",
    text: "คุณต้องการบันทึกแก้ไขรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.save'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      updateSanctionp()
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกแก้ไข", "", "error")
      clearItems()
    }
  })
}

async function cancelEditRowItems() {
  swal({
    title: "ยกเลิกแก้ไขรายการ",
    text: "คุณต้องการยกเลิกแก้ไขรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      clearItems()
    }
  })
}

async function deleteRowItems(item) {
  swal({
    title: "ลบรายการ",
    text: "คุณต้องการลบรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t("buttons.conf"),
    cancelButtonText: t("buttons.canc"),
  }).then((result) => {
    if (result.isConfirmed) {
      if (item.uid !== "") {
        deleteSanctionp(item.uid);
      }
    }
  });
}

function checkprocess() {
  if (datas.addNew) {
    addSanctionp()
  } else {
    updateSanctionp()
  }
}



</script>

<style lang="scss" scoped>
.loading-state {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>