<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12 ">

        <ul id="splitAssetsTab" class="nav nav-tabs" role="tablist">

          <li class="nav-item" role="presentation">
            <button id="first-splitAssets" class="nav-link active" data-bs-toggle="tab" data-bs-target="#firstSplitAssets"
              type="button" role="tab" aria-controls="firstSplitAssets" aria-selected="true">
              {{ $t('content.amnsplitasset') }}
            </button>
          </li>

          <li class="nav-item" role="presentation">
            <button id="second-splitAssets" class="nav-link" data-bs-toggle="tab" data-bs-target="#secondSplitAssets"
              type="button" role="tab" aria-controls="secondSplitAssets" aria-selected="false">
              {{ $t('content.amnsplitassetman') }}
            </button>
          </li>
        </ul>

        <div id="splitAssetsTabContent" class="tab-content">

          <div id="firstSplitAssets" class="tab-pane fade show active" role="tabpanel"
            aria-labelledby="first-splitAssets">
            <div class="card mt-2">
              <div class="card-header mb-0">
                <h5>{{ $t('content.amnsplitasset') }}</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">

                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t('content.names') }}</label>
                    <div class="input-group">
                      <input v-model="datas.searchBox1" type="text" class="form-control" aria-describedby="searchName"
                        readonly>
                      <button id="searchName" class="btn btn-primary" type="button" data-bs-toggle="modal"
                        data-bs-target="#searchNameModal">
                        <i class="material-icons material-symbols-outlined">search</i>
                      </button>
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <label class="form-label ms-0">{{ $t('content.ticketno') }}</label>
                    <material-input type="text" />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0 png"></label>
                    <material-button color="primary" class="float-lg-start float-sm-end mb-3">
                      <i class="material-icons material-symbols-outlined">search</i> {{ btnSearch }}
                    </material-button>
                  </div>
                </div>
                <hr>
                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>{{ $t('content.names') }}</th>
                          <th>{{ $t('content.ticketno') }}</th>
                          <th>{{ $t('content.massetgroup') }}</th>
                          <th>{{ $t('content.amnweightvalall') }}</th>
                          <th>{{ $t('content.amnqtyunitall') }}</th>
                          <th>{{ $t('content.amnanalysvalall') }}</th>
                          <th>{{ $t('content.amnassetdetail') }}</th>
                          <th>{{ $t('content.refno') }}</th>
                          <th width="100px">{{ $t('content.act') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(i, ci) in datatable" :key="ci">
                          <td class="text-sm font-weight-normal">{{ i.no }}</td>
                          <td class="text-sm font-weight-normal">{{ i.nam }}</td>
                          <td class="text-sm font-weight-normal">{{ i.new }}</td>
                          <td class="text-sm font-weight-normal">{{ i.ass }}</td>
                          <td class="text-sm font-weight-normal">{{ i.wei }}</td>
                          <td class="text-sm font-weight-normal">{{ i.qty }}</td>
                          <td class="text-sm font-weight-normal">{{ i.amo }}</td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="info" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="seed" @click="onLookupAssetDetail">
                              <i class="material-icons material-symbols-outlined">description</i>
                            </material-button>
                          </td>
                          <td class="text-sm font-weight-normal">{{ i.ref + ci }}</td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="warning" variant="outline" class="rounded-circle me-1"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSplit">
                              <i class="material-icons material-symbols-outlined">vertical_split</i>
                            </material-button>
                            <material-button color="info" variant="outline" class="rounded-circle me-1"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnEdit">
                              <i class="material-icons material-symbols-outlined">edit</i>
                            </material-button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="secondSplitAssets" class="tab-pane fade" role="tabpanel" aria-labelledby="second-splitAssets">
            <div class="card mt-2">
              <div class="card-header">
                <div class="row no-gutters">
                  <div class="col-12 d-flex justify-content-between align-items-center">
                    <h5>{{ $t('content.amnsplitassetman') }}</h5>
                    <div>
                      <material-button color="primary" class="float-lg-start float-sm-end mb-0" @click="addNewItems">
                        <i class="material-icons material-symbols-outlined">add</i> {{ btnAddNew }}
                      </material-button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body pt-0">
                <div class="row mb-4">
                  <div class="col-12">
                    <div class="d-flex justify-content-between align-items-center">
                      <h6 class="text-info mb-2 mb-lg-0 me-4">{{ $t('content.amnsplitlist') }} 1/10</h6>

                      <div class="col">
                        <material-input type="text" class="input-field-no-margin mb-2 mb-lg-0"
                          :placeholder="$t('content.amnoldmessage')" />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th colspan="2" class="text-center">#</th>
                          <th colspan="4" class="text-center bg-bn800">{{ $t('content.amntypegold') }}</th>
                          <th colspan="4" class="text-center bg-gy800">{{ $t('content.amnnotgold') }}</th>
                          <th>{{ $t('content.amnassetdetail') }}</th>
                          <th width="100px">{{ $t('content.act') }}</th>
                        </tr>
                        <tr>
                          <th>{{ $t('content.refno') }} <br /> {{ $t('content.amnweightvalall') }}</th>
                          <th>{{ datatable[0].ref }} <br /> {{ datatable[0].wei }}</th>
                          <th style="min-width: 240px" class=" bg-bn800">{{ $t('content.item') }}</th>
                          <th class=" bg-bn800">{{ $t('content.amnweightvalall') }}</th>
                          <th class=" bg-bn800">{{ $t('content.amnqtyunitall') }}</th>
                          <th class=" bg-bn800">{{ $t('content.amnanalysvalall') }}</th>
                          <th style="min-width: 240px" class=" bg-gy800">{{ $t('content.item') }}</th>
                          <th class=" bg-gy800">{{ $t('content.amnweightvalall') }}</th>
                          <th class=" bg-gy800">{{ $t('content.amnqtyunitall') }}</th>
                          <th class=" bg-gy800">{{ $t('content.amnanalysvalall') }}</th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-if="datas.addNew">
                          <td class="text-sm font-weight-normal text-center" colspan="2">#</td>
                          <td class="text-sm font-weight-normal bn200">
                            <div class="input-group">
                              <input type="text" class="form-control">
                              <button class="btn btn-outline-primary dropdown-toggle mb-0" type="button"
                                data-bs-toggle="dropdown" aria-expanded="false">
                              </button>
                              <ul class="dropdown-menu dropdown-menu-end">
                                <li>
                                  <div class="input-group">
                                    <input type="text" class="form-control mb-0" placeholder="ค้นหา" />
                                  </div>
                                </li>
                                <li>
                                  <hr class="dropdown-divider">
                                </li>
                                <li><a class="dropdown-item" href="#">
                                    Type1 >> Type2 >> Type3 >> Type4 >> Type5 >> Type6</a></li>
                                <li><a class="dropdown-item" href="#">
                                    Type1 >> Type2 >> Type3 >> Type4 >> Type5 >> Type6</a></li>
                                <li><a class="dropdown-item" href="#">
                                    Type1 >> Type2 >> Type3 >> Type4 >> Type5 >> Type6</a></li>
                              </ul>
                            </div>
                          </td>
                          <td class="text-sm font-weight-normal bn200">
                            <material-input type="text" class="mb-0" />
                          </td>
                          <td class="text-sm font-weight-normal bn200">
                            <material-input type="text" class="mb-0" />
                          </td>
                          <td class="text-sm font-weight-normal bn200">
                            <material-input type="text" class="mb-0" />
                          </td>
                          <td class="text-sm font-weight-normal gy200">
                            <div class="input-group">
                              <input type="text" class="form-control">
                              <button class="btn btn-outline-primary dropdown-toggle mb-0" type="button"
                                data-bs-toggle="dropdown" aria-expanded="false">
                              </button>
                              <ul class="dropdown-menu dropdown-menu-end">
                                <li>
                                  <div class="input-group">
                                    <input type="text" class="form-control mb-0" placeholder="ค้นหา" />
                                  </div>
                                </li>
                                <li>
                                  <hr class="dropdown-divider">
                                </li>
                                <li><a class="dropdown-item" href="#">
                                    Type1 >> Type2 >> Type3 >> Type4 >> Type5 >> Type6</a></li>
                                <li><a class="dropdown-item" href="#">
                                    Type1 >> Type2 >> Type3 >> Type4 >> Type5 >> Type6</a></li>
                                <li><a class="dropdown-item" href="#">
                                    Type1 >> Type2 >> Type3 >> Type4 >> Type5 >> Type6</a></li>
                              </ul>
                            </div>
                          </td>
                          <td class="text-sm font-weight-normal gy200">
                            <material-input type="text" class="mb-0" />
                          </td>
                          <td class="text-sm font-weight-normal gy200">
                            <material-input type="text" class="mb-0" />
                          </td>
                          <td class="text-sm font-weight-normal gy200">
                            <material-input type="text" class="mb-0" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-input type="text" class="mb-0" />
                          </td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave" @click="saveNewItems">
                              <i class="material-icons material-symbols-outlined">save</i>
                            </material-button>
                            <material-button color="danger" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" :title="btnClose"
                              @click="cancelSaveNewItems">
                              <i class="material-icons material-symbols-outlined">close</i>
                            </material-button>
                          </td>
                        </tr>
                        <tr v-for="(i, ci) in datatable" :key="ci">
                          <template v-if="!i.edt">
                            <td class="text-sm font-weight-normal text-center" colspan="2">{{ i.no }}</td>
                            <td class="text-sm font-weight-normal bn200">{{ i.ass }}</td>
                            <td class="text-sm font-weight-normal bn200">{{ i.wei }}</td>
                            <td class="text-sm font-weight-normal bn200">{{ i.qty }}</td>
                            <td class="text-sm font-weight-normal bn200">{{ i.amo }}</td>
                            <td class="text-sm font-weight-normal gy200">{{ i.ass }}</td>
                            <td class="text-sm font-weight-normal gy200">{{ i.wei }}</td>
                            <td class="text-sm font-weight-normal gy200">{{ i.qty }}</td>
                            <td class="text-sm font-weight-normal gy200">{{ i.amo }}</td>
                            <td class="text-sm font-weight-normal">
                              <material-button color="info" variant="outline" class="rounded-circle"
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="seed"
                                @click="onLookupAssetDetail">
                                <i class="material-icons material-symbols-outlined">description</i>
                              </material-button>
                            </td>
                            <td class="text-sm font-weight-normal">
                              <material-button color="info" variant="outline" class="rounded-circle me-1"
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="btnEdit"
                                @click="editRowItems(ci, i.edt)">
                                <i class="material-icons material-symbols-outlined">edit</i>
                              </material-button>
                              <material-button color="danger" variant="outline" class="rounded-circle me-1"
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="btnDel">
                                <i class="material-icons material-symbols-outlined">delete</i>
                              </material-button>
                            </td>
                          </template>

                          <template v-else-if="i.edt">
                            <td class="text-sm font-weight-normal text-center" colspan="2">{{ i.no }}</td>
                            <td class="text-sm font-weight-normal bn200">
                              <div class="input-group">
                                <input type="text" class="form-control">
                                <button class="btn btn-outline-primary dropdown-toggle mb-0" type="button"
                                  data-bs-toggle="dropdown" aria-expanded="false">
                                </button>
                                <ul class="dropdown-menu dropdown-menu-end">
                                  <li>
                                    <div class="input-group">
                                      <input type="text" class="form-control mb-0" placeholder="ค้นหา" />
                                    </div>
                                  </li>
                                  <li>
                                    <hr class="dropdown-divider">
                                  </li>
                                  <li><a class="dropdown-item" href="#">
                                      Type1 >> Type2 >> Type3 >> Type4 >> Type5 >> Type6</a></li>
                                  <li><a class="dropdown-item" href="#">
                                      Type1 >> Type2 >> Type3 >> Type4 >> Type5 >> Type6</a></li>
                                  <li><a class="dropdown-item" href="#">
                                      Type1 >> Type2 >> Type3 >> Type4 >> Type5 >> Type6</a></li>
                                </ul>
                              </div>
                            </td>
                            <td class="text-sm font-weight-normal bn200">
                              <material-input type="text" class="mb-0" />
                            </td>
                            <td class="text-sm font-weight-normal bn200">
                              <material-input type="text" class="mb-0" />
                            </td>
                            <td class="text-sm font-weight-normal bn200">
                              <material-input type="text" class="mb-0" />
                            </td>
                            <td class="text-sm font-weight-normal gy200">
                              <div class="input-group">
                                <input type="text" class="form-control">
                                <button class="btn btn-outline-primary dropdown-toggle mb-0" type="button"
                                  data-bs-toggle="dropdown" aria-expanded="false">
                                </button>
                                <ul class="dropdown-menu dropdown-menu-end">
                                  <li>
                                    <div class="input-group">
                                      <input type="text" class="form-control mb-0" placeholder="ค้นหา" />
                                    </div>
                                  </li>
                                  <li>
                                    <hr class="dropdown-divider">
                                  </li>
                                  <li><a class="dropdown-item" href="#">
                                      Type1 >> Type2 >> Type3 >> Type4 >> Type5 >> Type6</a></li>
                                  <li><a class="dropdown-item" href="#">
                                      Type1 >> Type2 >> Type3 >> Type4 >> Type5 >> Type6</a></li>
                                  <li><a class="dropdown-item" href="#">
                                      Type1 >> Type2 >> Type3 >> Type4 >> Type5 >> Type6</a></li>
                                </ul>
                              </div>
                            </td>
                            <td class="text-sm font-weight-normal gy200">
                              <material-input type="text" class="mb-0" />
                            </td>
                            <td class="text-sm font-weight-normal gy200">
                              <material-input type="text" class="mb-0" />
                            </td>
                            <td class="text-sm font-weight-normal gy200">
                              <material-input type="text" class="mb-0" />
                            </td>
                            <td class="text-sm font-weight-normal">
                              <material-input type="text" class="mb-0" />
                            </td>
                            <td class="text-sm font-weight-normal">
                              <material-button color="primary" variant="outline" class="rounded-circle me-1"
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSave"
                                @click="saveEditRowItems(ci, i.edt)">
                                <i class="material-icons material-symbols-outlined">save</i>
                              </material-button>
                              <material-button color="danger" variant="outline" class="rounded-circle me-1"
                                data-bs-toggle="tooltip" data-bs-placement="top" :title="btnCancel"
                                @click="cancelEditRowItems(ci, i.edt)">
                                <i class="material-icons material-symbols-outlined">delete</i>
                              </material-button>
                            </td>
                          </template>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <!-- <div class="row mt-4">
                  <div class="col-12 d-flex justify-content-end align-items-center">
                    <material-button color="default" class="float-lg-start float-sm-end mb-0 me-2" @click="clearNewItems">
                      <i class="material-icons material-symbols-outlined">delete</i> {{ btnClear }}
                    </material-button>
                    <material-button color="primary" class="float-lg-start float-sm-end mb-0" @click="saveNewItems">
                      <i class="material-icons material-symbols-outlined">save</i> {{ btnSave }}
                    </material-button>
                  </div>
                </div> -->
              </div>
            </div>

          </div>
        </div>

        <!-- Search Customer Dialog -->
        <div id="searchNameModal" class="modal fade" tabindex="-1" aria-labelledby="searchNameModalLabel"
          aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">

              <div class="modal-header">
                <h4 class="modal-title">{{ $t('content.cnamelist') }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <div class="preview-table">
                  <section class="panel">
                    <table class="table table-striped table-responsive">
                      <thead>
                        <tr>
                          <th style="width: 100px;">{{ $t('content.ccode') }}</th>
                          <th style="width: 180px;">{{ $t('content.cnameth') }}</th>
                          <th style="width: 180px;">{{ $t('content.cnameen') }}</th>
                          <th style="width: 160px;">{{ $t('content.cidcard') }}</th>
                          <th style="width: 110px;">{{ $t('content.cmobile') }}</th>
                          <th style="width: 120px;">{{ $t('content.cgrade') }}</th>
                          <!-- <th></th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="row-search">
                          <td>
                            <material-input type="text" :label="t('content.ccode')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cnameth')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cnameen')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cidcard')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cmobile')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cgrade')" />
                          </td>
                          <!-- <td>
                                  <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                                    data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch">
                                    <i class="material-icons material-symbols-outlined">search</i>
                                  </material-button>
                                </td> -->
                        </tr>
                        <tr v-for="(i, cs) in datas.cname" :key="cs" data-bs-dismiss="modal">
                          <td>{{ i.cd }}</td>
                          <td>{{ i.th }}</td>
                          <td>{{ i.en }}</td>
                          <td>{{ i.id }}</td>
                          <td>{{ i.mb }}</td>
                          <td>{{ i.gd }}</td>
                          <!-- <td></td> -->
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </div>
              </div>

              <div class="modal-footer">
                <!-- <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button> -->
                <nav aria-label="Table Paginator">
                  <ul class="pagination justify-content-end">
                    <li class="page-item disabled">
                      <a class="page-link" href="#" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item">
                      <a class="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script setup>
import { reactive, inject, computed, ref } from 'vue'
import { useI18n } from "vue-i18n"

import MaterialButton from "@/components/MaterialButton.vue"
import MaterialInput from "@/components/MaterialInput.vue"
// import MaterialCheckbox from "@/components/MaterialCheckbox.vue"
// import MaterialRadio from "@/components/MaterialRadio.vue"

// import Signature from "@/assets/img/pawn/signature_test.png"
// import FingerPrint from "@/assets/img/pawn/fingerprint_test.jpg"
// import Avatar from "@/assets/img/ivana-squares.jpg"

const { t } = useI18n()
const swal = inject('$swal')

// const selectAdmin = ref(false)

var sd = new Date()
const sd2 = "2023-12-21"

const datas = reactive({
  addNew: false,
  rowEdit: false,
  cname: [
    {
      cd: "xxxxxx",
      th: "พิชัย จิตภักดี",
      en: "Pichai Jitphakdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "A",
    },
    {
      cd: "xxxxxx",
      th: "นิรุจ เสมากิจ",
      en: "Nirut Semakij",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "VIP",
    },
    {
      cd: "xxxxxx",
      th: "บวรศักดิ์ นิลสุภาพ",
      en: "Borwonsak Nilsupap",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "S",
    },
    {
      cd: "xxxxxx",
      th: "ชิดชนก วิมลพัฒน์",
      en: "Chidchanok Wimonpatr",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "C",
    },
    {
      cd: "xxxxxx",
      th: "สมบูรณ์ทรัพย์ ทองดี",
      en: "Somboonsap Thongdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "B",
    },
    {
      cd: "xxxxxx",
      th: "พิชัย จิตภักดี",
      en: "Pichai Jitphakdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "A",
    },
    {
      cd: "xxxxxx",
      th: "นิรุจ เสมากิจ",
      en: "Nirut Semakij",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "VIP",
    },
    {
      cd: "xxxxxx",
      th: "บวรศักดิ์ นิลสุภาพ",
      en: "Borwonsak Nilsupap",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "S",
    },
    {
      cd: "xxxxxx",
      th: "ชิดชนก วิมลพัฒน์",
      en: "Chidchanok Wimonpatr",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "C",
    },
    {
      cd: "xxxxxx",
      th: "สมบูรณ์ทรัพย์ ทองดี",
      en: "Somboonsap Thongdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "B",
    },
  ],
})

// const samples = reactive([
//   {
//     no: "1",
//     usr: "user-a",
//     fir: "xx/xx/xxxx",
//     dat: "xx/xx/xxxx xx:xx",
//     nam: "xxxxx xxxxxxxxx",
//     old: "aaaaa",
//     new: "tk-3048",
//     biz: "จำนำ",
//     ass: "ทองคำ",
//     wei: "30.32",
//     qty: "5",
//     amo: "88,888,888.88",
//     int: "8,888.88",
//     by: "หลงจู๊ A",
//     css: datas.demo1[0].items,
//     grd: datas.demo2[1].items,
//     crd: "x-xxxx-xxxxx-xx-x",
//     nic: "nickname",
//     mob: "8888888888",
//   },
//   {
//     no: "2",
//     usr: "user-b",
//     fir: "xx/xx/xxxx",
//     dat: "xx/xx/xxxx xx:xx",
//     nam: "xxxxx xxxxxxxxx",
//     old: "aaaaa",
//     new: "tk-1132",
//     biz: "ต่อดอก",
//     ass: "เพชร",
//     wei: "12.34",
//     qty: "2",
//     amo: "88,888,888.88",
//     int: "8,888.88",
//     by: "หลงจู๊ B",
//     css: datas.demo1[0].items,
//     grd: datas.demo2[4].items,
//     crd: "x-xxxx-xxxxx-xx-x",
//     nic: "nickname",
//     mob: "8888888888",
//   },
//   {
//     no: "3",
//     usr: "user-c",
//     fir: "xx/xx/xxxx",
//     dat: "xx/xx/xxxx xx:xx",
//     nam: "xxxxx xxxxxxxxx",
//     old: "aaaaa",
//     new: "tk-5611",
//     biz: "จำนำ",
//     ass: "อื่นๆ",
//     wei: "30.32",
//     qty: "5",
//     amo: "88,888,888.88",
//     int: "8,888.88",
//     by: "หลงจู๊ B",
//     css: datas.demo1[0].items,
//     grd: datas.demo2[2].items,
//     crd: "x-xxxx-xxxxx-xx-x",
//     nic: "nickname",
//     mob: "8888888888",
//   },
//   {
//     no: "4",
//     usr: "user-d",
//     fir: "xx/xx/xxxx",
//     dat: "xx/xx/xxxx xx:xx",
//     nam: "xxxxx xxxxxxxxx",
//     old: "aaaaa",
//     new: "tk-0021",
//     biz: "ไถ่ถอน",
//     ass: "ทองคำ",
//     wei: "15.63",
//     qty: "1",
//     amo: "88,888,888.88",
//     int: "8,888.88",
//     by: "หลงจู๊ A",
//     css: datas.demo1[1].items,
//     grd: datas.demo2[0].items,
//     crd: "x-xxxx-xxxxx-xx-x",
//     nic: "nickname",
//     mob: "8888888888",
//   },
// ])

const datatable = reactive([
  {
    no: "1",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "tk-3048",
    biz: "จำนำ",
    ass: "ทองคำ",
    wei: "30.32",
    qty: "5",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ A",
    dut: "xx/xx/xxxx",
    due: ref(sd),
    add: 15,
    ref: "ref-00xx00",
    edt: false,
  },
  {
    no: "2",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "tk-3048",
    biz: "ต่อดอก",
    ass: "เพชร",
    wei: "12.34",
    qty: "2",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ B",
    dut: "xx/xx/xxxx",
    due: ref(sd2),
    add: 7,
    ref: "ref-00xx00",
    edt: false,
  },
  {
    no: "3",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "tk-3048",
    biz: "จำนำ",
    ass: "อื่นๆ",
    wei: "30.32",
    qty: "5",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ B",
    dut: "xx/xx/xxxx",
    due: ref(sd2),
    add: 30,
    ref: "ref-00xx00",
    edt: false,
  },
  {
    no: "4",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "tk-3048",
    biz: "ไถ่ถอน",
    ass: "ทองคำ",
    wei: "15.63",
    qty: "1",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ A",
    dut: "xx/xx/xxxx",
    due: ref(sd2),
    add: 24,
    ref: "ref-00xx00",
    edt: false,
  },
])

const btnAddNew = computed(() => t("buttons.impo"))
const btnEdit = computed(() => t("buttons.edit"))
// const btnSearch = computed(() => t("buttons.sear"))
const btnSave = computed(() => t("buttons.save"))
// const btnClear = computed(() => t("buttons.clea"))
const btnSplit = computed(() => t("buttons.split"))
const btnDel = computed(() => t("buttons.remo"))
const btnClose = computed(() => t("buttons.clos"))
// const btnConfirm = computed(() => t("buttons.conf"))
const btnCancel = computed(() => t("buttons.canc"))
// const btnPreview = computed(() => t("content.file"))
// const btnReset = computed(() => t("content.resetpwd"))
// const sAll = computed(() => t("content.optall"))

async function addNewItems() {
  swal({
    title: "เพิ่มรายการ",
    text: "คุณต้องการเพิ่มรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      datas.addNew = true
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกรายการ", "", "error")
      datas.addNew = false
    }
  })
}

async function saveNewItems() {
  swal({
    title: "ต้องการบันทึกรายการนี้",
    text: "คุณต้องการบันทึกรายการนี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.save'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      swal("บันทึกสำเร็จ", "", "success")
      datas.addNew = false
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกการบันทึก", "", "error")
      datas.addNew = false
    }
  })
}

async function cancelSaveNewItems() {
  swal({
    title: "ยกเลิกบันทึกรายการนี้",
    text: "คุณต้องการยกเลิกบันทึกรายการนี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      datas.addNew = false
    } else if (result.dismiss == 'cancel') {
      datas.addNew = true
    }
  })
}

async function editRowItems(id, val) {
  swal({
    title: "แก้ไขรายการ",
    text: "คุณต้องการแก้ไขรายการที่ " + id + " นี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      val = true
      datatable[id].edt = val
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกรายการ", "", "error")
      val = false
      datatable[id].edt = val
    }
  })
}

async function saveEditRowItems(id, val) {
  swal({
    title: "บันทึกแก้ไขรายการ",
    text: "คุณต้องการบันทึกแก้ไขรายการที่ " + id + " นี้ใช่ไหม",
    icon: "info",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.save'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      swal("บันทึกสำเร็จ", "", "success")
      val = false
      datatable[id].edt = val
    } else if (result.dismiss == 'cancel') {
      swal("ยกเลิกแก้ไข", "", "error")
      val = false
      datatable[id].edt = val
    }
  })
}

async function cancelEditRowItems(id, val) {
  swal({
    title: "ยกเลิกแก้ไขรายการ",
    text: "คุณต้องการยกเลิกแก้ไขรายการที่ " + id + " นี้ใช่ไหม",
    icon: "warning",
    allowOutsideClick: false,
    showCloseButton: false,
    showCancelButton: true,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
    confirmButtonText: t('buttons.conf'),
    cancelButtonText: t('buttons.canc'),
  }).then((result) => {
    if (result.isConfirmed) {
      val = false
      datatable[id].edt = val
    } else if (result.dismiss == 'cancel') {
      val = true
      datatable[id].edt = val
    }
  })
}

async function onLookupAssetDetail() {
  swal({
    title: t('content.amnassetdetail'),
    html:
      '<div class="preview-note">' +
      '<h6 class="mb">Notice:</h6>' +
      '<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content here, making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for lorem ipsum will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>' +
      '</div>',
    allowOutsideClick: true,
    showCloseButton: true,
    showCancelButton: false,
    showConfirmButton: false,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
  });
}

</script>
    
<style lang="scss" scoped></style>