<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12 ">

        <ul id="bmTab" class="nav nav-tabs" role="tablist">

          <li class="nav-item" role="presentation">
            <button id="wait-tab" class="nav-link active" data-bs-toggle="tab" data-bs-target="#waitList" type="button"
              role="tab" aria-controls="waitList" aria-selected="true">ประวัติธุรกรรม</button>
          </li>

        </ul>

        <div id="bmTabContent" class="tab-content">

          <div id="waitList" class="tab-pane fade show active" role="tabpanel" aria-labelledby="wait-tab">
            <div id="que-info" class="card mt-2">
              <div class="card-header mb-0">
                <h5>ค้นหาประวัติธุรกรรม</h5>
              </div>
              <div class="card-body pt-0">
                <div class="row">
                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.sdate') }}</label>
                    <material-input type="date" />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.amntimestart') }}</label>
                    <material-input type="time" />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.sdate') }}</label>
                    <material-input type="date" />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">{{ $t('content.amntimeend') }}</label>
                    <material-input type="time" />
                  </div>

                  <div class="col-lg-4">
                    <label class="form-label ms-0">{{ $t('content.names') }}</label>
                    <div class="input-group">
                      <input v-model="datas.searchBox1" type="text" class="form-control" aria-describedby="searchName1"
                        readonly>
                      <button id="searchName1" class="btn btn-primary" type="button" data-bs-toggle="modal"
                        data-bs-target="#searchNameModal">
                        <i class="material-icons material-symbols-outlined">search</i>
                      </button>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <label class="form-label ms-0">เลขที่ตั๋ว</label>
                    <material-input type="text" />
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">ธุรกรรม</label>
                    <select class="form-select">
                      <option value="card0">ทั้งหมด</option>
                      <option value="card2">จำนำ</option>
                      <option value="card3">ต่อดอก</option>
                      <option value="card4">เพิ่มต้น</option>
                      <option value="card5">ลดต้น</option>
                      <option value="card6">ไถ่ถอน</option>
                    </select>
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">ประเภททรัพย์</label>
                    <select class="form-select">
                      <option value="card0">ทั้งหมด</option>
                      <option value="card0">ทอง</option>
                      <option value="card2">เพชร</option>
                      <option value="card3">กรอบพระ</option>
                      <option value="card4">นาค</option>
                      <option value="card5">นาฬิกา</option>
                      <option value="card6">อื่นๆ</option>
                    </select>
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0">สถานะชำระ</label>
                    <select class="form-select">
                      <option value="card0">ทั้งหมด</option>
                      <option value="card1">สมบูรณ์</option>
                      <option value="card2">ยังไม่ชำระ</option>
                      <option value="card2">ชำระบางส่วน</option>
                    </select>
                  </div>

                  <div class="col-lg-2">
                    <label class="form-label ms-0 png"></label>
                    <material-button id="cardSearch" color="primary" class="float-lg-start float-sm-end mb-3">
                      <i class="material-icons material-symbols-outlined">search</i> ค้นหา
                    </material-button>
                  </div>
                </div>
                <hr>
                <div class="row no-gutters">
                  <div class="col-12 d-flex justify-content-end align-items-center">
                    <label class="form-label m-0 me-2">{{ $t('content.expfile') }} :</label>
                    <div>
                      <material-button v-for="(i, exp) in exportFile" :key="exp" :color="i.cl" variant="outline"
                        class="float-lg-start float-sm-end mb-0 me-2" :class="i.cs">
                        <i class="material-icons material-symbols-outlined">{{ i.ic }}</i> {{ i.nm }}
                      </material-button>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="table-responsive">
                    <table class="table table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th>#</th>
                          <th>วัน/เวลา</th>
                          <th>ชื่อ-นามสกุล</th>
                          <th>เลขที่ตั๋วปัจจุบัน</th>
                          <th>เลขที่ตั๋วก่อนหน้า</th>
                          <th>ประเภทธุรกรรม</th>
                          <th>ประเภททรัพย์</th>
                          <th>น้ำหนักรวม</th>
                          <th>จำนวนรวม</th>
                          <th>ราคาจำนำรวม</th>
                          <th>ดอกเบี้ย</th>
                          <th>ผู้ประเมิน</th>
                          <th>จัดการ</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(i, history) in datatable" :key="history">
                          <td class="text-sm font-weight-normal">{{ i.no }}</td>
                          <td class="text-sm font-weight-normal">{{ i.dat }}</td>
                          <td class="text-sm font-weight-normal">{{ i.nam }}</td>
                          <td class="text-sm font-weight-normal">{{ i.old }}</td>
                          <td class="text-sm font-weight-normal">{{ i.new }}</td>
                          <td class="text-sm font-weight-normal">{{ i.biz }}</td>
                          <td class="text-sm font-weight-normal">{{ i.ass }}</td>
                          <td class="text-sm font-weight-normal">{{ i.wei }}</td>
                          <td class="text-sm font-weight-normal">{{ i.qty }}</td>
                          <td class="text-sm font-weight-normal">{{ i.amo }}</td>
                          <td class="text-sm font-weight-normal">{{ i.int }}</td>
                          <td class="text-sm font-weight-normal">{{ i.by }}</td>
                          <td class="text-sm font-weight-normal">
                            <material-button color="info" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="ดูรายละเอียด"
                              @click="onLookupDetail">
                              <i class="material-icons material-symbols-outlined">visibility</i>
                            </material-button>
                            <!-- <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="ตั๋วตีกลับ">
                              <i class="material-icons material-symbols-outlined">keyboard_return</i>
                            </material-button>
                            <material-button color="primary" variant="outline" class="rounded-circle"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="ยกเลิกตั๋ว">
                              <i class="material-icons material-symbols-outlined">delete</i>
                            </material-button> -->
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Search Customer Dialog -->
        <div id="searchNameModal" class="modal fade" tabindex="-1" aria-labelledby="searchNameModalLabel"
          aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">

              <div class="modal-header">
                <h4 class="modal-title">{{ $t('content.cnamelist') }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <div class="preview-table">
                  <section class="panel">
                    <table class="table table-striped table-responsive">
                      <thead>
                        <tr>
                          <th style="width: 100px;">{{ $t('content.ccode') }}</th>
                          <th style="width: 180px;">{{ $t('content.cnameth') }}</th>
                          <th style="width: 180px;">{{ $t('content.cnameen') }}</th>
                          <th style="width: 160px;">{{ $t('content.cidcard') }}</th>
                          <th style="width: 110px;">{{ $t('content.cmobile') }}</th>
                          <th style="width: 120px;">{{ $t('content.cgrade') }}</th>
                          <!-- <th></th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="row-search">
                          <td>
                            <material-input type="text" :label="t('content.ccode')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cnameth')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cnameen')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cidcard')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cmobile')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cgrade')" />
                          </td>
                          <!-- <td>
                                <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                                  data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch">
                                  <i class="material-icons material-symbols-outlined">search</i>
                                </material-button>
                              </td> -->
                        </tr>
                        <tr v-for="(i, cs) in datas.cname" :key="cs" data-bs-dismiss="modal">
                          <td>{{ i.cd }}</td>
                          <td>{{ i.th }}</td>
                          <td>{{ i.en }}</td>
                          <td>{{ i.id }}</td>
                          <td>{{ i.mb }}</td>
                          <td>{{ i.gd }}</td>
                          <!-- <td></td> -->
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </div>
              </div>

              <div class="modal-footer">
                <!-- <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button> -->
                <nav aria-label="Table Paginator">
                  <ul class="pagination justify-content-end">
                    <li class="page-item disabled">
                      <a class="page-link" href="#" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item">
                      <a class="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script setup>
import { inject, reactive } from 'vue'
import { useI18n } from "vue-i18n"

import MaterialButton from "@/components/MaterialButton.vue"
import MaterialInput from "@/components/MaterialInput.vue"

const { t } = useI18n()
const swal = inject('$swal')

const datas = reactive({
  searchBox1: "",
  searchBox2: "",
  demo1: [
    { items: "ฝากเพื่อชำระ" },
  ],
  demo2: [
    { items: "ฝาก" },
    { items: "ถอน" },
  ],
  cname: [
    {
      cd: "xxxxxx",
      th: "พิชัย จิตภักดี",
      en: "Pichai Jitphakdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "A",
    },
    {
      cd: "xxxxxx",
      th: "นิรุจ เสมากิจ",
      en: "Nirut Semakij",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "VIP",
    },
    {
      cd: "xxxxxx",
      th: "บวรศักดิ์ นิลสุภาพ",
      en: "Borwonsak Nilsupap",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "S",
    },
    {
      cd: "xxxxxx",
      th: "ชิดชนก วิมลพัฒน์",
      en: "Chidchanok Wimonpatr",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "C",
    },
    {
      cd: "xxxxxx",
      th: "สมบูรณ์ทรัพย์ ทองดี",
      en: "Somboonsap Thongdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "B",
    },
    {
      cd: "xxxxxx",
      th: "พิชัย จิตภักดี",
      en: "Pichai Jitphakdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "A",
    },
    {
      cd: "xxxxxx",
      th: "นิรุจ เสมากิจ",
      en: "Nirut Semakij",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "VIP",
    },
    {
      cd: "xxxxxx",
      th: "บวรศักดิ์ นิลสุภาพ",
      en: "Borwonsak Nilsupap",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "S",
    },
    {
      cd: "xxxxxx",
      th: "ชิดชนก วิมลพัฒน์",
      en: "Chidchanok Wimonpatr",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "C",
    },
    {
      cd: "xxxxxx",
      th: "สมบูรณ์ทรัพย์ ทองดี",
      en: "Somboonsap Thongdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "B",
    },
  ],
})

const exportFile = reactive([
  {
    cl: "info",
    cs: "me-2",
    ic: "picture_as_pdf",
    nm: "pdf",
    ac: "",
  },
  {
    cl: "info",
    cs: "",
    ic: "table_rows",
    nm: "excel",
    ac: "",
  },
])

const datatable = reactive([
  {
    no: "1",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "bbbbb",
    biz: "จำนำ",
    ass: "ทองคำ",
    wei: "30.32",
    qty: "5",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ A"
  },
  {
    no: "2",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "bbbbb",
    biz: "ต่อดอก",
    ass: "เพชร",
    wei: "12.34",
    qty: "2",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ B"
  },
  {
    no: "3",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "bbbbb",
    biz: "จำนำ",
    ass: "อื่นๆ",
    wei: "30.32",
    qty: "5",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ B"
  },
  {
    no: "4",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "bbbbb",
    biz: "ไถ่ถอน",
    ass: "ทองคำ",
    wei: "15.63",
    qty: "1",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ A"
  },
])


async function onLookupDetail() {
  swal({
    html:
      '<div class="preview-attached">' +
      '<section class="panel">' +
      '<img src="https://unsplash.it/400/200" alt="1" height="400" width="200">' +
      '<img src="https://unsplash.it/400/200" alt="2" height="400" width="200">' +
      '<img src="https://unsplash.it/400/200" alt="3" height="400" width="200">' +
      '<img src="https://unsplash.it/400/200" alt="4" height="400" width="200">' +
      '</section>' +
      '</div>' +
      '<div class="preview-detail">' +
      '<h6 class="mb">Notice:</h6>' +
      '<p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using Content here, content here, making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for lorem ipsum will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like).</p>' +
      '</div>',
    allowOutsideClick: true,
    showCloseButton: true,
    showCancelButton: false,
    showConfirmButton: false,
    closeOnConfirm: false,
    focusConfirm: false,
    animation: "slide-from-top",
  });
}

</script>
  
<style lang="scss" scoped></style>