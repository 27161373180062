<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12 ">
        <div class="card mt-3">
          <div class="card-header mb-0">
            <h5>{{ $t('content.amndeferitems') }}</h5>
          </div>
          <div class="card-body pt-0">
            <div class="row">

              <div class="col-lg-6">
                <label class="form-label ms-0">{{ $t('content.names') }}</label>
                <div class="input-group">
                  <input v-model="datas.searchBox1" type="text" class="form-control" aria-describedby="searchName"
                    readonly>
                  <button id="searchName" class="btn btn-primary" type="button" data-bs-toggle="modal"
                    data-bs-target="#searchNameModal">
                    <i class="material-icons material-symbols-outlined">search</i>
                  </button>
                </div>
              </div>

              <div class="col-lg-3">
                <label class="form-label ms-0">{{ $t('content.ticketno') }}</label>
                <material-input type="text" />
              </div>

              <div class="col-lg-3">
                <label class="form-label ms-0 png"></label>
                <material-button id="cardSearch" color="primary" class="float-lg-start float-sm-end mb-3">
                  <i class="material-icons material-symbols-outlined">search</i> {{ btnSearch }}
                </material-button>
              </div>
            </div>
            <hr>
            <!-- <div class="row no-gutters">
              <div class="col-12 d-flex justify-content-end align-items-center">
                <label class="form-label m-0 me-2">{{ $t('content.expfile') }} :</label>
                <div>
                  <material-button v-for="(i, exp) in exportFile" :key="exp" :color="i.cl" variant="outline"
                    class="float-lg-start float-sm-end mb-0 me-2" :class="i.cs">
                    <i class="material-icons material-symbols-outlined">{{ i.ic }}</i> {{ i.nm }}
                  </material-button>
                </div>
              </div>
            </div> -->
            <div class="row mt-3">
              <div class="table-responsive">
                <table class="table table-flush">
                  <thead class="thead-light">
                    <tr>
                      <th>#</th>
                      <th>{{ $t('content.names') }}</th>
                      <th>{{ $t('content.ticketno') }}</th>
                      <th>{{ $t('content.massetgroup') }}</th>
                      <th>{{ $t('content.amnweightvalall') }}</th>
                      <th>{{ $t('content.amnqtyunitall') }}</th>
                      <th>{{ $t('content.amnanalysvalall') }}</th>
                      <th>{{ $t('content.amninterest') }}</th>
                      <th>{{ $t('content.amnduedate') }}</th>
                      <th>{{ $t('content.amndefertime') }}</th>
                      <th>{{ $t('content.amnduedatenew') }}</th>
                      <th>{{ $t('content.amnduedateadd') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(i, tc) in datatable" :key="tc">
                      <td class="text-sm font-weight-normal">{{ i.no }}</td>
                      <td class="text-sm font-weight-normal">{{ i.nam }}</td>
                      <td class="text-sm font-weight-normal">{{ i.new }}</td>
                      <td class="text-sm font-weight-normal">{{ i.ass }}</td>
                      <td class="text-sm font-weight-normal">{{ i.wei }}</td>
                      <td class="text-sm font-weight-normal">{{ i.qty }}</td>
                      <td class="text-sm font-weight-normal">{{ i.amo }}</td>
                      <td class="text-sm font-weight-normal">{{ i.int }}</td>
                      <td class="text-sm font-weight-normal">{{ i.dut }}</td>
                      <td class="text-sm font-weight-normal">{{ i.def }}</td>
                      <td class="text-sm font-weight-normal">
                        <material-input :id="'dateId' + (tc + 1)" v-model="i.due" type="date" :name="'nameId' + (tc + 1)"
                          @input="addDays(sd, i.add)" />
                      </td>
                      <td class="text-sm font-weight-normal">{{ i.add }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <!-- Search Customer Dialog -->
        <div id="searchNameModal" class="modal fade" tabindex="-1" aria-labelledby="searchNameModalLabel"
          aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">

              <div class="modal-header">
                <h4 class="modal-title">{{ $t('content.cnamelist') }}</h4>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                  <i class="material-icons material-symbols-outlined">close</i>
                </button>
              </div>

              <div class="modal-body">
                <div class="preview-table">
                  <section class="panel">
                    <table class="table table-striped table-responsive">
                      <thead>
                        <tr>
                          <th style="width: 100px;">{{ $t('content.ccode') }}</th>
                          <th style="width: 180px;">{{ $t('content.cnameth') }}</th>
                          <th style="width: 180px;">{{ $t('content.cnameen') }}</th>
                          <th style="width: 160px;">{{ $t('content.cidcard') }}</th>
                          <th style="width: 110px;">{{ $t('content.cmobile') }}</th>
                          <th style="width: 120px;">{{ $t('content.cgrade') }}</th>
                          <!-- <th></th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="row-search">
                          <td>
                            <material-input type="text" :label="t('content.ccode')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cnameth')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cnameen')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cidcard')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cmobile')" />
                          </td>
                          <td>
                            <material-input type="text" :label="t('content.cgrade')" />
                          </td>
                          <!-- <td>
                                <material-button color="primary" variant="contain" class="rounded-circle ms-1"
                                  data-bs-toggle="tooltip" data-bs-placement="top" :title="btnSearch">
                                  <i class="material-icons material-symbols-outlined">search</i>
                                </material-button>
                              </td> -->
                        </tr>
                        <tr v-for="(i, cs) in datas.cname" :key="cs" data-bs-dismiss="modal">
                          <td>{{ i.cd }}</td>
                          <td>{{ i.th }}</td>
                          <td>{{ i.en }}</td>
                          <td>{{ i.id }}</td>
                          <td>{{ i.mb }}</td>
                          <td>{{ i.gd }}</td>
                          <!-- <td></td> -->
                        </tr>
                      </tbody>
                    </table>
                  </section>
                </div>
              </div>

              <div class="modal-footer">
                <nav aria-label="Table Paginator">
                  <ul class="pagination justify-content-end">
                    <li class="page-item disabled">
                      <a class="page-link" href="#" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                      </a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item">
                      <a class="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script setup>
import { ref, reactive, computed } from 'vue'
import { useI18n } from "vue-i18n"
// import moment from "moment"

import MaterialButton from "@/components/MaterialButton.vue"
import MaterialInput from "@/components/MaterialInput.vue"

const { t } = useI18n()

const datas = reactive({
  searchBox1: "",
  cname: [
    {
      cd: "xxxxxx",
      th: "พิชัย จิตภักดี",
      en: "Pichai Jitphakdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "A",
    },
    {
      cd: "xxxxxx",
      th: "นิรุจ เสมากิจ",
      en: "Nirut Semakij",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "VIP",
    },
    {
      cd: "xxxxxx",
      th: "บวรศักดิ์ นิลสุภาพ",
      en: "Borwonsak Nilsupap",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "S",
    },
    {
      cd: "xxxxxx",
      th: "ชิดชนก วิมลพัฒน์",
      en: "Chidchanok Wimonpatr",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "C",
    },
    {
      cd: "xxxxxx",
      th: "สมบูรณ์ทรัพย์ ทองดี",
      en: "Somboonsap Thongdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "B",
    },
    {
      cd: "xxxxxx",
      th: "พิชัย จิตภักดี",
      en: "Pichai Jitphakdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "A",
    },
    {
      cd: "xxxxxx",
      th: "นิรุจ เสมากิจ",
      en: "Nirut Semakij",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "VIP",
    },
    {
      cd: "xxxxxx",
      th: "บวรศักดิ์ นิลสุภาพ",
      en: "Borwonsak Nilsupap",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "S",
    },
    {
      cd: "xxxxxx",
      th: "ชิดชนก วิมลพัฒน์",
      en: "Chidchanok Wimonpatr",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "C",
    },
    {
      cd: "xxxxxx",
      th: "สมบูรณ์ทรัพย์ ทองดี",
      en: "Somboonsap Thongdee",
      id: "0-0000-00000-00-0",
      mb: "098-7654321",
      gd: "B",
    },
  ],
})

var sd = new Date()
const sd2 = "2023-12-21"

const datatable = reactive([
  {
    no: "1",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "tk-3048",
    biz: "จำนำ",
    ass: "ทองคำ",
    wei: "30.32",
    qty: "5",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ A",
    dut: "xx/xx/xxxx",
    def: 2,
    due: ref(sd),
    add: 15,
  },
  {
    no: "2",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "tk-1132",
    biz: "ต่อดอก",
    ass: "เพชร",
    wei: "12.34",
    qty: "2",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ B",
    dut: "xx/xx/xxxx",
    def: 1,
    due: ref(sd2),
    add: 7,
  },
  {
    no: "3",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "tk-5611",
    biz: "จำนำ",
    ass: "อื่นๆ",
    wei: "30.32",
    qty: "5",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ B",
    dut: "xx/xx/xxxx",
    def: 1,
    due: ref(sd2),
    add: 30,
  },
  {
    no: "4",
    dat: "xx/xx/xxxx xx:xx",
    nam: "xxxxx xxxxxxxxx",
    old: "aaaaa",
    new: "tk-0021",
    biz: "ไถ่ถอน",
    ass: "ทองคำ",
    wei: "15.63",
    qty: "1",
    amo: "88,888,888.88",
    int: "8,888.88",
    by: "หลงจู๊ A",
    dut: "xx/xx/xxxx",
    def: 1,
    due: ref(sd2),
    add: 24,
  },
])

const btnSearch = computed(() => t("buttons.sear"))

// const setD = (id) => {
//   $('#'+id).data({date: sd}).datepicker('update').children('input').val(sd)
// }

// const addDays = async (date, days) => {
//   var res = new Date(date)
//   res.setDate(res.getDate() + days)
//   return res
// }

</script>
    
<style lang="scss" scoped></style>